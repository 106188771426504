import React from 'react';
import './nbcd.css';

const ContactDetail = ({ image, title, subTitle }) => {
        return (
                <>
                        <div className='d-flex  '>
                                <div className='pt-1'>
                                        <img src={image} alt="" style={{ width: "20px" }} />
                                </div>
                                <div className='px-3'>
                                        <h4 className='box-title'>{title}</h4>
                                        <h5 className='box-sub-title'>{subTitle}</h5>
                                </div>
                        </div>
                </>
        )
}

export default ContactDetail