import React from "react";
import "./ContactUs.css";
import Phone from "../../assets/images/phone.svg";
import Maps from "../../assets/images/map.svg";
import Email from "../../assets/images/email.svg";
import Layout from "../../components/Layout";
import { useEffect } from "react";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Layout>
      <section className="contact-section">
        <div className="contact-heading">
          <h1>Contact Us</h1>
          <h2>ConQt - First Businesse Matching Marketplace</h2>
        </div>
        <div className="form-data">
          <div className="contact-data">
            <h1>Contact Information</h1>
            <ul className="">
              <li>
                <img src={Phone} alt="phone" className="c-icons" /> +66 98 865
                6621
              </li>
              <li>
                <img src={Email} alt="phone" className="c-icons" />
                info@conqt.com
              </li>
              <li>
                <img src={Maps} alt="phone" className="c-icons" /> ConQt
                Technology Pte 11 Collyer Quay <br />
                #17-00, The Arcade <br />
                Singapore – 049317
              </li>
            </ul>
          </div>
          <div className="cform">
            <form>
              <div className="form-row">
                <div className="column">
                  <label for="Name">Your Name</label>
                  <input type="text" id="name" placeholder="Your Name Here" />
                </div>
                <div className="column">
                  <label for="Name">Your Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Your Email Id Here"
                  />
                </div>
              </div>
              <div className="form-row-full">
                <div className="column">
                  <label for="Name">Your Subject</label>
                  <input
                    type="text"
                    id="subject"
                    placeholder="Your Subject Here"
                  />
                </div>
              </div>
              <div className="form-row-full">
                <div className="column">
                  <label for="Name">Message</label>
                  <input
                    type="textbox"
                    id="subject"
                    placeholder="Write your message here."
                  />
                </div>
              </div>
              <button className="btn">Send Message</button>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactUs;
