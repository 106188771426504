import React, { useEffect } from "react";
import Layout from "../../components/Layout";

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout className="content">
      <div className="termsconditions" id="#">
        <p>
          <strong>Conqt Terms and Conditions</strong>
        </p>
        <br />
        <h3>
          PLEASE READ THE FOLLOWING TERMS AND CONDITIONS OF USE AGREEMENT
          CAREFULLY
        </h3>
        <p>
          The following agreement captures the terms and conditions of use
          ("Agreement"), applicable to Your use of conqt.com ("Conqt"), which
          promotes business between suppliers and buyers globally. It is an
          agreement between You as the user of the Web Site/CONQT Services and
          Fyoocher Private Ltd. ("Fyoocher"). The expressions “You” “Your” or
          “User(s)” refers to any person who accesses or uses the Web Site for
          any purpose.
        </p>
        <p>
          By subscribing to or interacting with other User(s) on or entering
          into negotiations in respect of sale or supply of goods or services on
          or using the Web Site or CONQT Services in any manner for any purpose,
          You undertake and agree that You have fully read, understood and
          accepted the Agreement.
        </p>
        <p>
          If You do not agree to or do not wish to be bound by the Agreement,
          You may not access or otherwise use the Web Site in any manner.
        </p>
        <br />
        <h3>I. WEBSITE- MERELY A VENUE/PLATFORM</h3>
        <p>
          The Web Site acts as a match-making platform for User(s) to negotiate
          and interact with other User(s) for entering into negotiations in
          respect thereof for sale or supply of goods or services. CONQT or
          conqt.com are not parties to any negotiations that take place between
          the User(s) of the Web Site and are further not parties to any
          agreement including an agreement for sale or supply of goods or
          services or otherwise, concluded between the User(s) of the Web Site.
        </p>
        <p>
          Conqt does not control and is not liable in respect of or responsible
          for the quality, safety, genuineness, lawfulness or availability of
          the products or services offered for sale on the Web Site or the
          ability of the User(s) selling or supplying the goods or services to
          complete a sale or the ability of User(s) purchasing goods or services
          to complete a purchase. This agreement shall not be deemed to create
          any partnership, joint venture, or any other joint business
          relationship between CONQT and any other party.
        </p>
        <br />
        <h3>II. SERVICES PROVIDED BY CONQT</h3>
        <p>
          CONQT provides the following services to its Customers and their
          respective definitions are classified here under:
        </p>
        <ul>
          <li>
            "Leading Supplier" : It gives the User(s)s priority listing within
            categories of their choice as available on Fyoocher, thus increasing
            visibility of their products.
          </li>
          <li>
            "Star Supplier" : It is add-on service by Fyoocher which gives its
            User(s) priority listing in their chosen category of products. By
            availing this service, the User(s) will get benefits of increased
            leads and enquiries.
          </li>
          <li>
            "TrustSEAL" : is a seal that User(s) gets after getting its
            business-related documents and information verified. · "Maximiser" :
            User(s) availing this service could maximize its return on
            investment by availing the specialized feature of this package.
          </li>
          <li>
            "Mini Dynamic Catalog" : It is a professionally-designed catalog on
            Fyoocher along with independent control to add, delete, edit text
            and images as per requirement of customers.
          </li>
          <li>
            "Verified" User(s) : Users are said to be verified if any of their
            provided primary/ secondary, mobile or email is verified by CONQT.
          </li>
        </ul>
        <br />
        <h3>III. USER(S) ELIGIBILITY</h3>
        <p>
          User(s) represent and warrant that they have the right to avail or use
          the services provided by CONQT, including but limited to the Web Site
          or any other services provided by CONQT in relation to the use of the
          Web Site ("CONQT’s Services"). CONQT’s Services can only be availed by
          those individuals or business entities, including sole proprietorship
          firms, companies and partnerships, which are authorized under
          applicable law to form legally binding agreements. As such, natural
          persons below 18 years of age and business entities or organizations
          that are not authorized by law to operate in India or other countries
          are not authorized to avail or use CONQT’s Services.
        </p>
        <p>
          User(s) agree to abide by the Agreement and any other rules and
          regulations imposed by the applicable law from time to time. CONQT or
          the website shall have no liability to the User(s) or anyone else for
          any content, information or any other material transmitted over
          CONQT’s Services, including any fraudulent, untrue, misleading,
          inaccurate, defamatory, offensive or illicit material and that the
          risk of damage from such material rests entirely with each User(s).The
          user shall do its own due diligence before entering into any
          transaction with other users on the website. CONQT at it’s sole
          discretion reserves the right to refuse CONQT’s Services to anyone at
          any time. CONQT’s Services are not available and may not be availed or
          used by User(s) whose Accounts have been temporarily or indefinitely
          suspended by CONQT.
        </p>
        <br />
        <h3>IV. USER(S) AGREEMENT</h3>
        <p>
          This Agreement applies to any person who accesses or uses the Web Site
          or uses CONQT Services for any purpose. It also applies to any legal
          entity which may be represented by any person who accesses or uses the
          Web Site, under actual or apparent authority. User(s) may use this Web
          Site and/or CONQT Services solely for their commercial/business
          purposes.
        </p>
        <p>
          This Agreement applies to all services offered on the Web Site and by
          CONQT, collectively with any additional terms and conditions that may
          be applicable in respect of any specific service used or accessed by
          User(s) on the Web Site. In the event of any conflict or inconsistency
          between any provision of this Agreement and any additional terms and
          conditions applicable in respect of any service offered on the Web
          Site, such additional terms and conditions applicable in respect of
          that service shall prevail over this Agreement. This Agreement shall
          govern the User’s usage of CONQT Services and, the User acknowledges
          that this Agreement shall supersede all or any term, agreement,
          contract executed between CONQT and the User.
        </p>
        <br />
        <h3>V. AMENDMENT TO USER(S) AGREEMENT</h3>
        <p>
          CONQT reserves the right to change, modify, amend, or update the
          Agreement from time to time and such amended provisions of the
          Agreement shall be effective immediately upon being posted on the Web
          Site. If You do not agree to such provisions, You must stop using the
          service with immediate effect. Your continuous use of the service will
          be deemed to signify your acceptance of the amended provisions of the
          Agreement.
        </p>
        <br />
        <h3>VI. INTELLECTUAL PROPERTY RIGHTS</h3>
        <p>
          CONQT is the sole owner and the lawful licensee of all the rights to
          the Web Site and its content ("Web Site Content"). Web Site Content
          means the design, layout, text, images, graphics, sound, video etc. of
          or made available on the Web Site. The Web Site Content embodies trade
          secrets and other intellectual property rights protected under
          worldwide copyright and other applicable laws pertaining to
          intellectual property. All title, ownership and intellectual property
          rights in the Web Site and the Web Site Content shall remain in CONQT,
          its affiliates or licensor’s of the Web Site content, as the case may
          be.
        </p>
        <p>
          All rights, not otherwise claimed under this Agreement by CONQT, are
          hereby reserved. Any information or advertisements contained on,
          distributed through, or linked, downloaded or accessed from any of the
          services contained on the Web Site or any offer displayed on or in
          connection with any service offered on the Web Site ("Website
          Information") is intended, solely to provide general information for
          the personal use of the User(s), who fully accept any and all
          responsibility and liabilities arising from and out of the use of such
          Information. CONQT does not represent, warrant or endorse in any
          manner the accuracy or reliability of Website Information, or the
          quality of any products and/or services obtained by the User(s) as a
          result of any Website Information.
        </p>
        <p>
          For any content and or link uploaded to the Website by the User from
          YouTube, the User agrees to abide and accepts, the terms of service of
          YouTube, available at https://www.youtube.com/t/terms.
        </p>
        <p>
          The Information is provided “as is” with no guarantee of completeness,
          accuracy, timeliness or of the results obtained from the use of the
          Information, and without warranty of any kind, express or implied,
          including, but not limited to warranties of performance,
          merchantability and fitness for a particular purpose. Nothing
          contained in the Agreement shall to any extent substitute for the
          independent investigations and the sound technical and business
          judgment of the User(s). In no event shall CONQT be liable for any
          direct, indirect, incidental, punitive, or consequential damages of
          any kind whatsoever with respect to CONQT’s Services. User(s) hereby
          acknowledge that any reliance upon the Information shall be at their
          sole risk and further understand and acknowledge that the Information
          has been compiled from publicly aired and published sources. CONQT
          respects the rights of such entities and cannot be deemed to be
          infringing on the respective copyrights or businesses of such
          entities. CONQT reserves the right, in its sole discretion and without
          any obligation, to make improvements to, or correct any error or
          omissions in any portion of the Information.
        </p>
        <ul>
          <h4>Trademark</h4>
          <p>
            "Fyoocher" and related icons and logos are registered trademarks or
            trademarks or service marks of CONQT in various jurisdictions and
            are protected under applicable copyright, trademark and other
            proprietary and intellectual property rights laws. The unauthorized
            adoption copying, modification, use or publication of these marks is
            strictly prohibited.
          </p>
          <br />
          <h4>Copyright</h4>
          <p>
            All Web Site Content including Website Information is copyrighted to
            CONQT excluding any third-party content and any links to any
            third-party websites being made available or contained on the Web
            Site. User(s) may not use any trademark, service mark or logo of any
            independent third parties without prior written approval from such
            parties.
          </p>
          <p>
            User(s) acknowledge and agree that CONQT is not an arbiter or judge
            of disputes concerning intellectual property rights and as such
            cannot verify that User(s) selling or supplying merchandise or
            providing services on the Web Site have the right to sell the
            merchandise or provide the services offered by such User(s). CONQT
            encourages User(s) to assist CONQT in identifying listings on the
            Web Site which in the User(s) knowledge or belief infringe their
            rights. User(s) further acknowledge and agree by taking down a
            listing, CONQT does not and cannot be deemed to be endorsing a claim
            of infringement and further that in those instances in which CONQT
            declines to take down a listing, CONQT does not and cannot be deemed
            to be endorsing that the listing is not infringing of third party
            rights or endorsing any sale or supply of merchandise or services
            pursuant to or on account of such listing.
          </p>
          <p>
            CONQT respects the intellectual property rights of others, and we
            expect our User(s) to do the same. User(s) agree to not copy,
            download or reproduce the Web Site Content, Information or any other
            material, text, images, video clips, directories, files, databases
            or listings available on or through the Web Site ("CONQT Content")
            for the purpose of re-selling or re-distributing, mass mailing (via
            email, wireless text messages, physical mail or otherwise) operating
            a business competing with CONQT, or otherwise commercially
            exploiting the CONQT Content unless otherwise agreed between the
            parties. Systematic retrieval of CONQT Content to create or compile,
            directly or indirectly, a collection, compilation, database or
            directory (whether through robots, spiders, automatic devices or
            manual processes) without written permission from CONQT is
            prohibited.
          </p>
          <p>
            In addition, use of the CONQT Content for any purpose not expressly
            permitted in this Agreement is prohibited and entitles CONQT to
            initiate appropriate legal action. User(s) agree that as a condition
            of their access to and use of CONQT's Services, they will not use
            CONQT’s Services to infringe the intellectual property rights of any
            third parties in any way. CONQT reserves the right to terminate the
            right of any User(s) to access or use CONQT’s Services for any
            infringement of the rights of third parties in conjunction with use
            of the CONQT’s Service, or in the event CONQT is of the believes
            that User(s) conduct is prejudicial to the interests of CONQT, its
            affiliates, or other User(s), or for any other reason, at CONQT’s
            sole discretion, with or without cause.
          </p>
          <br />
          <h4> URL's/Sub-Domain</h4>
          <p>
            URL’s/ Sub-domain names assigned by CONQT to User(s) (including both
            paid and free User(s)) are the exclusive property of CONQT and it
            cannot be assumed to be permanent in any case. CONQT reserves the
            right, without prior notice, at any point of time, to suspend or
            terminate or restrict access to or edit any URL's/Sub-domain names.
            IN ALL SUCH CASES, CONQT WILL NOT BE LIABLE TO ANY PARTY FOR ANY
            DIRECT, INDIRECT, SPECIAL OR OTHER CONSEQUENTIAL DAMAGES, INCLUDING,
            WITHOUT LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION OR
            OTHERWISE.
          </p>
          <p>
            CONQT may allow User(s) access to content, products or services
            offered by third parties through hyperlinks (in the form of word
            link, banners, channels or otherwise) to the websites offered by
            such third parties ("Third Party Websites"). CONQT advises its
            User(s) to read the terms and conditions of use and/or privacy
            policies applicable in respect of such Third Party Websites prior to
            using or accessing such Third Party Websites. Users acknowledge and
            agree that CONQT has no control over any content offered on Third
            Party Websites, does not monitor such Third Party Websites, and
            shall in no manner be deemed to be liable or responsible to any
            person for such Third Party Sites, or any content, products or
            services made available thereof.
          </p>
        </ul>
        <br />
        <h3>VII. LINKS TO THIRD PARTY SITES</h3>
        <p>
          Links to third party sites are provided on Web Site as a convenience
          to User(s). User(s) acknowledge and agree that CONQT does not have any
          control over the content of such websites and/ or any information,
          resources or materials provided therein.
        </p>
        <p>
          CONQT may allow User(s) access to content, products or services
          offered by third parties through hyperlinks (in the form of word link,
          banners, channels or otherwise) to the websites offered by such third
          parties ("Third Party Websites"). CONQT advises its User(s) to read
          the terms and conditions of use and/or privacy policies applicable in
          respect of such Third Party Websites prior to using or accessing such
          Third Party Websites. Users acknowledge and agree that CONQT has no
          control over any content offered on Third Party Websites, does not
          monitor such Third Party Websites, and shall in no manner be deemed to
          be liable or responsible to any person for such Third Party Sites, or
          any content, products or services made available thereof.
        </p>
        <br />
        <h3>VIII. TERMINATION</h3>
        <p>
          Most content and some of the features on the Web Site are made
          available to User(s) free of charge. However, CONQT reserves the right
          to terminate access to certain areas or features of the Web Site (to
          paying or registered User(s)) at any time without assigning any reason
          and with or without notice to such User(s). CONQT also reserves the
          universal right to deny access to particular User(s) to any or all of
          its services or content without any prior notice or explanation in
          order to protect the interests of CONQT and/ or other User(s) of the
          Web Site. CONQT further reserves the right to limit, deny or create
          different access to the Web Site and its features with respect to
          different User(s), or to change any or all of the features of the Web
          Site or introduce new features without any prior notice to User(s).
          CONQT reserves the right to terminate the membership/subscription of
          any User(s) temporarily or permanently for any of the following
          reasons:
        </p>
        <ul>
          <li>
            (a) If any false information in connection with their account
            registered with CONQT is provided by such User(s), or if such
            User(s) are engaged in fraudulent or illegal
            activities/transactions.
          </li>
          <li>(b) If such User(s) breaches any provisions of the Agreement.</li>
          <li>
            (c) If such User(s) utilizes the Web Site to send spam messages or
            repeatedly publish the same product information.
          </li>
          <li>
            (d) If such User(s) posts any material that is not related to trade
            or business cooperation.
          </li>
          <li>
            (e) If such User(s) impersonates or unlawfully uses another person’s
            or business entity’s name to post information or conduct business in
            any manner.
          </li>
          <li>
            (f) If such User(s) is involved in unauthorized access, use,
            modification, or control of the Web Site database, network, or
            related services.
          </li>
          <li>
            (g) If such User(s) obtains by any means another registered User(s)
            Username and/or Password. (h) Or any other User(s) activity that may
            not be in accordance with the ethics and honest business practices.
          </li>
        </ul>
        <p>
          If CONQT terminates the membership of any registered User(s) including
          those User(s) who have subscribed for the paid services of CONQT, such
          person will not have the right to re-enroll or join the Web Site under
          a new account or name unless invited to do so in writing by CONQT In
          any case of termination, no subscription/membership fee/charges paid
          by the User(s) will be refunded. User(s) acknowledge that inability to
          use the Web Site wholly or partially for whatever reason may have
          adverse effect on their business. User(s) hereby agree that in no
          event shall CONQT be liable to any User(s) or any third parties for
          any inability to use the Web Site (whether due to disruption, limited
          access, changes to or termination of any features on the Web Site or
          otherwise), any delays, errors or omissions with respect to any
          communication or transmission, or any damage (direct, indirect,
          consequential or otherwise) arising from the use of or inability to
          use the Web Site or any of its features.
        </p>
        <br />
        <h3>IX. REGISTERED USER(S)</h3>
        <p>
          To become a registered User(s) of the Web Site a proper procedure has
          been made available on the Web Site which is for the convenience of
          User(s) so that they can easily use the website.
        </p>
        <p>
          User(s) can become registered User(s) by filling an on-line
          registration form on the Web Site by providing the required
          information (including name, contact information, details of User(s)
          business, etc.). CONQT will establish an account ("Account") for the
          User(s) upon successful registration and will assign a user alias
          ("User ID") and password ("Password") for log-in access to the
          User(s)’s Account. CONQT may at its sole discretion assign to User(s)
          upon registration a web-based email or messaging account (“Email
          Account”) with limited storage space to send or receive emails or
          messages. Users will be responsible for the content of all the
          messages communicated through the account.
        </p>
        <p>
          User(s) registering on the Web Site on behalf of business entities
          represent and warrant that: (a) they have the requisite authority to
          bind such business entity this Agreement; (b) the address provided by
          such User(s) at the time of registration is the principal place of
          business of such business entity; and (c) all other information
          provided to CONQT during the registration process is true, accurate,
          current and complete. For purposes of this provision, a branch or
          representative office of a User(s) will not be considered a separate
          entity and the principal place of business of the User(s) will be
          deemed to be that of its head office.
        </p>
        <p>
          User(s) agree that by registering on the Web Site, they consent to the
          inclusion of their personal data in CONQT’s on-line database and
          authorize CONQT to share such information with other User(s). CONQT
          may refuse registration and deny the membership and associated User ID
          and Password to any User(s) for whatever reason. CONQT may suspend or
          terminate a registered membership at any time without any prior
          notification in interest of CONQT or general interest of its User(s)
          without assigning any reason thereof and there shall arise no further
          liability on CONQT of whatsoever nature due to the suspension or
          termination of the User account. User(s) registered on the Web Site
          are in no manner a part of or affiliated to CONQT.
        </p>
        <p>
          User(s) further agree and consent to be contacted by CONQT through
          phone calls, SMS notifications or any other means of communication, in
          respect to the services provided by CONQT even if contact number(s)
          provided to CONQT upon registration are on Do Not Call Registry.
        </p>
        <br />
        <h3>X. DATA PROTECTION </h3>
        <p>
          Personal information supplied by User(s) during the use of the Web
          Site is governed by CONQT’s privacy policy ("Privacy Policy"). Please
          click here to know about the Privacy Policy.
        </p>
        <br />
        <h3>XI. POSTING YOUR CONTENT ON WEBSITE</h3>
        <p>
          Some content displayed on the Web Site is provided or posted by third
          parties. User(s) can post their content on some of the
          sections/services of the Web Site using the self-help submit and edit
          tools made at the respective sections of the Web Site. User(s) may
          need to register and/or pay for using or availing some of these
          services.
        </p>
        <p>
          User(s) understand and agree that CONQT in such case is not the author
          of the content and that neither CONQT nor any of its affiliates,
          directors, officers or employees have entered into any arrangement
          including any agreement of sale or agency with such third parties by
          virtue of the display of such content on the Web Site. User(s) further
          understand and agree CONQT is not responsible for the accuracy,
          propriety, lawfulness or truthfulness of any third party content made
          available on the Web Site and shall not be liable to any User(s) in
          connection with any damage suffered by the User(s) on account of the
          User(s)’s reliance on such content. CONQT shall not be liable for a
          User(s) activities on the Web Site, and shall not be liable to any
          person in connection with any damage suffered by any person as a
          result of any User's conduct.
        </p>
        <p>User(s) solely represent, warrant and agree to:</p>
        <p>
          (a) provide CONQT with true, accurate, current and complete
          information to be displayed on the Web Site;
        </p>
        <p>
          (b) maintain and promptly amend all information provided on the Web
          Site to keep it true, accurate, current and complete.
        </p>
        <p>
          User(s) hereby grant CONQT an irrevocable, perpetual, worldwide and
          royalty-free, sub-licensable (through multiple tiers) license to
          display and use all information provided by them in accordance with
          the purposes set forth in the Agreement and to exercise the copyright,
          publicity and database rights User(s) have in such material or
          information, in any form of media, third party copyrights, trademarks,
          trade secret rights, patents and other personal or proprietary rights
          affecting or relating to material or information displayed on the Web
          Site, including but not limited to rights of personality and rights of
          privacy, or affecting or relating to products that are offered or
          displayed on the Web Site (hereafter referred to as "Third Party
          Rights").
        </p>
        <p>
          User(s) hereby represent, warrants and agree that User(s) shall be
          solely responsible for ensuring that any material or information
          posted by User(s) on the Web Site or provided to the Web Site or
          authorized by the User(s) for display on the Web Site, does not, and
          that the products represented thereby do not, violate any Third Party
          Rights, or is posted with the permission of the owner(s) of such Third
          Party Rights. User(s) hereby represent, warrant and agree that they
          have the right to manufacture, offer, sell, import and distribute the
          products offered and displayed on the Web Site, and that such
          manufacture, offer, sale, importation and/or distribution of those
          products violates no Third Party Rights.
        </p>
        <p>
          User(s) agree that they will not use CONQT Content and/or CONQT’s
          Services to send junk mail, chain letters or spamming. Further,
          registered User(s) of the Web Site agree that they will not use the
          Email Account to publish, distribute, transmit or circulate any
          unsolicited advertising or promotional information. User(s) further
          hereby represent, warrant and agree (i) to host, display, upload,
          modify, publish, transmit, store, update or share ; or (ii) submit to
          CONQT for display on the Web Site or transmit or sought to be
          transmitted through CONQT’s Services any content, material or
          information that does not and shall at no point:
        </p>
        <ul>
          <li>
            Contain fraudulent information or make fraudulent offers of items or
            involve the sale or attempted sale of counterfeit or stolen items or
            items whose sales and/or marketing is prohibited by applicable law,
            or otherwise promote other illegal activities;
          </li>
          <li>
            Belong to another person and to which User(s) do not have any right
            to;
          </li>
          <li>
            Be part of a scheme to defraud other User(s) of the Web Site or for
            any other unlawful purpose;
          </li>
          <li>
            Be intended to deceive or mislead the addressee about the origin of
            such messages or knowingly and intentionally is used to communicate
            any information which (i) is patently false or grossly offensive or
            menacing/misleading in nature but may reasonably be perceived as a
            fact; or (ii) harass a person, entity or agency for financial gain
            or to cause any injury to any person;
          </li>
          <li>
            Relate to sale of products or services that infringe or otherwise
            abet or encourage the infringement or violation of any third party's
            copyright, patent, trademarks, trade secret or other proprietary
            right or rights of publicity or privacy, or any other Third Party
            Rights;
          </li>
          <li>
            Violate any applicable law, statute, ordinance or regulation
            (including without limitation those governing export control,
            consumer protection, unfair competition, anti-discrimination or
            false advertising);
          </li>
          <li>
            Relate to any controversial weapons, cluster munitions or
            anti-personnel mines and other such defense equipment;
          </li>
          <li>
            Be defamatory, abusive libelous, unlawfully threatening, unlawfully
            harassing, grossly harmful, indecent, seditious, blasphemous,
            pedophilic, hateful, invasive of another’s privacy, including bodily
            privacy racially, ethnically objectionable, disparaging, relating or
            encouraging money laundering or gambling, leading to breach of
            confidence, or otherwise unlawful or objectionable in any manner
            whatever;
          </li>
          <li>
            Be vulgar, obscene or contain or infer any pornography or
            sex-related merchandising or any other content or otherwise promotes
            sexually explicit materials or is otherwise harmful to minors;
          </li>
          <li>
            Promote discrimination based on race, sex, religion, nationality,
            disability, sexual orientation or age;
          </li>
          <li>
            Contain any material that constitutes unauthorized advertising or
            harassment (including but not limited to spamming), invades anyone's
            privacy or encourages conduct that would constitute a criminal
            offense, give rise to civil liability, or otherwise violate any law
            or regulation;
          </li>
          <li>
            Solicit business from any User(s) in connection with a commercial
            activity that competes with CONQT;
          </li>
          <li>
            Threaten the unity, integrity, defense, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation;
          </li>
          <li>
            Contain any computer viruses or other destructive devices and codes
            that have the effect of damaging, interfering with, intercepting or
            expropriating any software or hardware system, data or personal
            information or that are designed to interrupt, destroy or limit the
            functionality of any computer resource;
          </li>
          <li>
            Link directly or indirectly to or include descriptions of goods or
            services that are prohibited under the prevailing law; or Otherwise
            create any liability for CONQT or its affiliates.
          </li>
        </ul>
        <p>
          CONQT reserves the right in its sole discretion to remove any
          material/content/photos/offers displayed on the Web Site which in
          CONQT’s reasonable belief is unlawful or could subject CONQT to
          liability or in violation of the Agreement or is otherwise found
          inappropriate in CONQT's opinion. CONQT reserves the right to
          cooperate fully with governmental authorities, private investigators
          and/or injured third parties in the investigation of any suspected
          criminal or civil wrongdoing.
        </p>
        <p>
          In connection with any of the foregoing, CONQT reserves the right to
          suspend or terminate the Account of any User(s) as deemed appropriate
          by CONQT at its sole discretion. User(s) agree that CONQT shall have
          no liability to any User(s), including liability in respect of
          consequential or any other damages, in the event CONQT takes any of
          the actions mentioned in this provision.
        </p>
        <p>
          User(s) understand and agree that the Web Site acts as a content
          integrator and is not responsible for the information provided by
          User(s) displayed on the Web Site. CONQT does not have any role in
          developing the content displayed on the Web Site. CONQT has the right
          to promote any content including text, images, videos, brochures etc.
          provided by User(s) on various platforms owned by the company.
        </p>
        <br />
        <h3>XII. INTERACTION BETWEEN USERS</h3>
        <p>
          CONQT provides an on-line platform to facilitate interaction between
          buyers and suppliers of products and services. CONQT does not
          represent the seller or the buyer in transactions and does not charge
          any commission for enabling any transaction. CONQT does not control
          and is not liable to or responsible for the quality, safety,
          lawfulness or availability of the products or services offered for
          sale on the Web Site or the ability of the suppliers to complete a
          sale or the ability of buyers to complete a purchase. User(s) are
          cautioned that there may be risks of dealing with foreign nationals or
          people acting under false pretenses on the Web Site. Web Site uses
          several tools and techniques to verify the accuracy and authenticity
          of the information provided by User(s). CONQT however, cannot and does
          not confirm each User(s)’s purported identity on the Web Site. CONQT
          encourages User(s) to evaluate the User(s) with whom they would like
          to deal with and use the common prudence while dealing with them.
        </p>
        <p>
          User(s) agree to fully assume the risks of any transactions
          ("Transaction Risks") conducted on the basis of any content,
          information or any other material provided on the Web Site and further
          assume the risks of any liability or harm of any kind arising due to
          or caused in connection with any subsequent activity relating to any
          products or services that are the subject of any such transaction.
        </p>
        <ul>
          <li>
            Such risks include, but are not limited to, misrepresentation of
            products and services, fraudulent schemes, unsatisfactory quality,
            failure to meet specifications, defective or dangerous products,
            unlawful products, delay or default in delivery or payment, cost
            miscalculations, breach of warranty, breach of contract and
            transportation accidents.
          </li>

          <li>
            Such risks also include the risks that the manufacture, importation,
            distribution, offer, display, purchase, sale and/or use of products
            or services offered or displayed on the Web Site may violate or may
            be asserted to violate Third Party Rights, and the risk that that
            User(s) may incur costs of defense or other costs in connection with
            third parties' assertion of Third Party Rights, or in connection
            with any claims by any party that they are entitled to defense or
            indemnification in relation to assertions of rights, demands or
            claims by Third Party Rights claimants.
          </li>

          <li>
            Such risks further include the risks that r the purchasers,
            end-users of products or others claiming to have suffered injuries
            or harms relating to product originally obtained by User(s) of the
            Web Site as a result of purchase and sale transactions in connection
            with using any content, information or any other material provided
            on the Web Site may suffer harms and/or assert claims arising from
            their use of such products.
          </li>
        </ul>
        <p>
          User(s) agree that CONQT shall not be liable or responsible for any
          damages, liabilities, costs, harms, inconveniences, business
          disruptions or expenditures of any kind that may occur/arise as a
          result of or in connection with any Transaction Risks. User(s) are
          solely responsible for all of the terms and conditions of the
          transactions conducted on, through or as a result of use of any
          content, information or any other material provided on the Web Site ,
          including, without limitation, terms regarding payment, returns,
          warranties, shipping, insurance, fees, taxes, title, licenses, fines,
          permits, handling, transportation and storage. In the event of a
          dispute with any party to a transaction, User(s) agrees to release and
          indemnify CONQT (and our agents, affiliates, directors, officers and
          employees) from all claims, demands, actions, proceedings, costs,
          expenses and damages (including without limitation any actual,
          special, incidental or consequential damages) arising out of or in
          connection with such transaction
        </p>
        <p>
          Fyoocher reserves the right to add/modify/discontinue any of the
          features offered on CONQT’s Services.
        </p>
        <br />
        <h3>XIII. LIMITATION OF LIABILITY/DISCLAIMER</h3>
        <p>
          The features and services on the Web Site are provided on an " as is "
          and " as available " basis, and CONQT hereby expressly disclaims any
          and all warranties, express or implied, including but not limited to
          any warranties of condition, quality, durability, performance,
          accuracy, reliability, merchantability or fitness for a particular
          purpose. All such warranties, representations, conditions,
          undertakings and terms are hereby excluded. CONQT makes no
          representations or warranties about the validity, accuracy,
          correctness, reliability, quality, stability or completeness of any
          information provided on or through the Web Site including display or
          listing of tenders on the Web Site which in no manner is endorsed by
          CONQT. CONQT has no association of whatsoever nature with the
          publisher and/or the published contents. Moreover, CONQT does not
          facilitate or participate in any sale, delivery, transaction and / or
          storage related to any product including but not limited to
          controversial weapons, cluster munitions or anti-personnel mines and
          other such defense equipment. CONQT does not represent or warrant that
          the manufacture, importation, distribution, offer, display, purchase,
          sale and/or use of products or services offered or displayed on the
          Web Site does not violate any Third Party Rights; and CONQT makes no
          representations or warranties of any kind concerning any product or
          service offered or displayed on the Web site. Any material downloaded
          or otherwise obtained through the Web site is at the User(s) sole
          discretion and risk and the User(s) is solely responsible for any
          damage to its computer system or loss of data that may result from the
          download of any such material. No advice or information, whether oral
          or written, obtained by the User(s) from Web Site or through or from
          the Web Site shall create or be deemed to create any warranty not
          expressly stated herein.
        </p>
        <p>
          Under no circumstances shall CONQT be held liable for any delay or
          failure or disruption of the content or services delivered through the
          Web Site resulting directly or indirectly from acts of nature, forces
          or causes beyond its reasonable control, including without limitation,
          Internet failures, computer, telecommunications or any other equipment
          failures, electrical power failures, strikes, labour disputes, riots,
          insurrections, civil disturbances, shortages of labour or materials,
          fires, flood, storms, explosions, Acts of God, natural calamities,
          war, governmental actions, orders of domestic or foreign courts or
          tribunals or non-performance of third parties. User(s) hereby agree to
          indemnify and save CONQT, its affiliates, directors, officers and
          employees harmless, from any and all losses, claims, liabilities
          (including legal costs on a full indemnity basis) which may arise from
          their use of the Web Site (including but not limited to the display of
          User(s) information on the Web Site) or from User(s)’s breach of any
          of the terms and conditions of this Agreement. User(s) hereby further
          agree to indemnify and save CONQT, its affiliates, directors, officers
          and employees harmless, from any and all losses, claims, liabilities
          (including legal costs on a full indemnity basis) which may arise from
          User(s)’s breach of any representations and warranties made by the
          User(s) to CONQT.
        </p>
        <p>
          User(s) hereby further agree to indemnify and save CONQT, its
          affiliates, directors, officers and employees harmless, from any and
          all losses, claims, liabilities (including legal costs on a full
          indemnity basis) which may arise, directly or indirectly, as a result
          of any claims asserted by Third Party Rights claimants or other third
          parties relating to products offered or displayed on the Web Site.
          User(s) hereby further agree that CONQT is not responsible and shall
          have no liability for any material posted by other User(s) or any
          other person, including defamatory, offensive or illicit material and
          that the risk of damage from such material rests entirely with the
          User(s). CONQT reserves the right, at its own expense, to assume the
          exclusive defense and control of any matter otherwise subject to
          indemnification by any User(s), in which event such User(s) shall
          cooperate with CONQT in asserting any available defenses.
        </p>
        <p>
          CONQT shall not be liable for any special, direct, indirect, punitive,
          incidental or consequential damages or any damages whatsoever
          (including but not limited to damages for loss of profits or savings,
          business interruption, loss of information), whether in contract,
          negligence, tort, strict liability or otherwise or any other damages
          resulting from any of the following:
        </p>
        <ul>
          <li>The use or the inability to use the Web Site;</li>
          <li>
            Any defect in goods, samples, data, information or services
            purchased or obtained from a User(s) or a third-party service
            provider through the web site;
          </li>
          <li>
            Violation of Third Party Rights or claims or demands that User(s)
            manufacture, importation, distribution, offer, display, purchase,
            sale and/or use of products or services offered or displayed on the
            web site may violate or may be asserted to violate Third Party
            Rights; or claims by any party that they are entitled to defense or
            indemnification in relation to assertions of rights, demands or
            claims by Third Party Rights claimants;
          </li>
          <li>
            Unauthorized access by third parties to data or private information
            of any User(s);
          </li>
          <li>Statements or conduct of any User(s) of the web site; or</li>
          <li>
            Any matters relating to Premium Services however arising, including
            negligence.
          </li>
        </ul>
        <br />
        <h3>XIV. NOTICES</h3>
        <p>
          All notices or demands to or upon CONQT shall be effective if in
          writing and shall be deemed to be duly made when sent to CONQT to
          Fyoocher Limited, 667 T1 01 SARAFIPURA TIWARIPURA Mahoba, 210427
          India.
        </p>
        <p>
          All notices or demands to or upon a User(s) shall be effective if
          either delivered personally, sent by courier, certified mail, by
          facsimile or email to the last-known correspondence, fax or email
          address provided by the User(s) on the Web Site, or by posting such
          notice or demand on an area of the Web Site that is publicly
          accessible without a charge.
        </p>
        <p>
          Notice to a User(s) shall be deemed to be received by such User(s) if
          and when Web Site is able to demonstrate that communication, whether
          in physical or electronic form, has been sent to such User(s), or
          immediately upon Web Site’s posting such notice on an area of the Web
          Site that is publicly accessible without charge.
        </p>
        <br />
        <h3>XV. GOVERNING LAW AND DISPUTE RESOLUTIONS</h3>
        <p>
          This Agreement and the Privacy Policy shall be governed in all
          respects by the laws of Indian Territory. CONQT considers itself and
          intends itself to be subject to the jurisdiction of the Courts of
          Noida, Uttar Pradesh, India only. The parties to this Agreement hereby
          submit to the exclusive jurisdiction of the courts of Noida, Uttar
          Pradesh, India.
        </p>
        <br />
        <h3>XVI. MISCELLANEOUS</h3>
        <ul>
          <li>
            Headings for any section of the Agreement are for reference purposes
            only and in no way define, limit, construe or describe the scope or
            extent of such section.
          </li>
          <li>
            CONQT’s failure to enforce any right or failure to act with respect
            to any breach by a User(s) under the Agreement and/or Privacy Policy
            will not be deemed to be a CONQT’s waiver of that right or CONQT's
            waiver of the right to act with respect with subsequent or similar
            breaches.
          </li>
          <li>
            CONQT shall have the right to assign its obligations and duties in
            this Agreement and in any other agreement relating CONQT’s Services
            to any person or entity
          </li>

          <li>
            If any provision of this Agreement is held to be invalid or
            unenforceable, such provision shall be struck out and the remaining
            provisions of the Agreement shall be enforced.
          </li>
          <li>
            All calls to Fyoocher are completely confidential. However, Your
            call may be recorded to ensure quality of service. Further, for
            training purpose and to ensure excellent customer service, calls
            from Fyoocher may be monitored and recorded.
          </li>
          <li>
            ConQt Team is the designated Grievance Officer in respect of CONQT’s
            Services. Any complaints or concerns with regards to any content on
            irected to the designated Grievance Officer in writing at Fyoocher
            Private Ltd, 67 T1 01 SARAFIPURA TIWARIPURA Mahoba, 210427 India. or
            through an email signed with the electronic signature sent to
            info@conqt.com
          </li>
          <li>
            The Agreement and the Privacy Policy constitute the entire agreement
            between the User(s) and CONQT with respect to access to and use of
            the Web Site, superseding any prior written or oral agreements in
            relation to the same subject matter herein
          </li>
        </ul>
        <br />
        <h3>XVII. BIG BUYER ("BB") TERMS OF USE</h3>
        <ul>
          <li>
            Under BB banner, CONQT shall develop a microsite for the User(s)
            company ("BB Company") on the Web Site. CONQT shall display all the
            leads of the BB Company on the microsite, to enable the BB Company
            to find out suppliers in a short period of time.BB Company hereby
            grants CONQT the right to use its intellectual property on the
            microsite and Web Site in relation to the requirements posted by the
            BB Company on Web Site. It is clarified that all intellectual
            property rights in the logos, brands and trademarks of the BB
            Company used in relation to the above shall vest in the BB Company
            only.
          </li>
          <li>
            BB Company shall ensure that the contents provided to CONQT shall
            not contain any material which is offensive, derogator, explicit or
            perverse to any specific race, gender or class of persons or
            degrading to public conscience or morals and does not breach any
            applicable law.
          </li>
          <li>
            CONQT makes no representations or warranties to BB Company with
            respect to services provided by CONQT under BB banner.
          </li>
          <li>
            CONQT hereby disclaims all warranties express and implied, including
            the implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement with respect to the services provided
            by CONQT under the BB banner.
          </li>
          <li>
            In no event shall CONQT be liable to BB Company for any special,
            exemplary, indirect, incidental, consequential, punitive damages or
            for any damages arising out of or in connection with respect to the
            above mentioned service.
          </li>
          <li>
            Apart from the said terms & conditions, the Agreement shall be
            deemed to form part and parcel of BB term and conditions.
          </li>
        </ul>
        <br />
        <h3>XVIII. PHARMACEUTICAL PRODUCT/SERVICE POLICIES</h3>
        <p>
          The Web Site does not facilitate the purchase of pharmaceutical
          products, and only advertises and/or showcases the pharmaceutical
          products posted by Users(s). User(s) involved in the purchase and
          supply of pharmaceutical products hereby agree to abide by and be
          compliant of any applicable laws, rules, regulations, notifications or
          orders issued by the Government of India or any of its agencies from
          time to time in this regard including but not limited to Drugs and
          Cosmetics Act, 1940, Drugs and Cosmetics Rules, 1945 ("Drug Rules"),
          Drugs and Magic Remedies (Objectionable Advertisements) Act, 1954
          ("Drug and Magic Remedies Act").
        </p>
        <p>
          CONQT shall not be responsible for any information, content or
          material in respect of or related to any pharmaceutical product
          posted, provided or displayed by User(s) on the Web Site. Accordingly,
          the User posting the content shall ensure that the content so posted
          does not violate any statue in the rules made thereunder including the
          Act(s) as referred above, so that no consequences of any nature could
          be attributed to CONQT in any manner whatsoever.
        </p>
        <p>
          Users(s) hereby undertake that they shall solely be responsible and
          shall bear all the liabilities in respect of selling prescription
          medicines and/or drugs mentioned in any of the Schedules of the Drug
          Rules without a prescription issued by a registered medical
          practitioner and in accordance with the conditions laid down in such
          rules. In the event of breach of such condition, CONQT shall not be
          liable and responsible in any manner whatsoever.
        </p>
        <p>
          Users(s) undertake and agree to indemnify and hold harmless CONQT
          and/or any of its affiliates, directors, officers, employees or
          representatives from and against any and all losses, liabilities,
          damages, claims, costs and expenses (including attorney’s fees and
          expenses, any third party claims), which CONQT may incur or suffer as
          a result of or in connection with any illegal sales of drugs and/or
          medicines.
        </p>
        <p>
          CONQT does not offer any guarantees or warranties on the medicinal
          products or services displayed or listed on the Web Site and is not
          liable for any relevant transaction between the User(s), including
          transactions involving sale of any medicine(s) restricted and/or
          banned for sale by any governmental or any other regulatory
          authorities.
        </p>
      </div>
    </Layout>
  );
};

export default TermsConditions;
