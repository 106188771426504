import React, { useState, useEffect } from "react";
import "../../Vendor/vendorProfile/page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import logo from "../../Vendor/vendorProfile/images/logo.png";
import { BsInfoCircle } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { FcCameraIdentification } from "react-icons/fc";
import "react-phone-number-input/style.css";
// import PhoneInput from 'react-phone-number-input'
import bar20 from "../../Vendor/vendorProfile/images/bar20.png";
import { GrFormUpload } from "react-icons/gr";
import { useForm } from "react-hook-form";
import PhoneInput, {
        isPossiblePhoneNumber,
        isValidPhoneNumber,
} from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./utils";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CommonApi from "../../../Api/commonApi";
import { BaseUrl } from "../../../Api/api";
import axios from "axios";

const MyProfile = () => {
        const dispatch = useDispatch();
        const [error, showerror] = useState(false);
        const [showBar, setShowBar] = useState("0");
        const [phone, setPhone] = useState("");
        const [picture, setPicture] = useState("");
        const [pictureUrl, setPictureUrl] = useState("");
        const [selectedFilesNames, setSelectedFileNames] = useState([]);
        const [userFile, setUserFile] = useState({});
        const [data, setData] = useState([]);
        const [profile, setProfile] = useState(false);
        const [overAllProgressValue, setOverAllProgressValue] = useState(0);
        const [showFileError, setShowFileError] = useState("");
        const [gallery, setGallery] = useState([]);
        const [galleryUrl, setGalleryUrl] = useState([]);
        const [countryList, setCountryList] = useState([]);


        const [countries, setCountries] = useState([]);
        const [states, setStates] = useState([]);
        const [cities, setCities] = useState([]);
        const [state, setState] = useState();
        const [city, setCity] = useState();
        const [country, setCountry] = useState();

        let navigate = useNavigate();
        const userToken = localStorage.getItem("token");
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const userinfoD = JSON.parse(localStorage.getItem("user"));

        const {
                register,
                handleSubmit,
                getValues,
                setValue,
                watch,
                formState: { errors },
        } = useForm();
        const handleChange = (e) => {
                setPicture(e.target.files[0]);
                let url = URL.createObjectURL(e.target.files[0]);
                setProfile(true);
                setPictureUrl(url);
        };

        // calling the country API
        useEffect(() => {
                axios
                        .get(`${BaseUrl}/common/Get-All-Country`)
                        .then((response) => {
                                if (response.data.status === 2000) {
                                        setCountries(response.data.data.country_list);
                                }
                                else if (response.data.status === 401) {
                                        console.log("Unauthorized")
                                }
                        })
                        .catch((error) => {
                                console.log("country catch error", error)
                        })
        }, [])

        const selectedCountry = watch('agency_country');

        // state api caliing based on the selected country

        useEffect(() => {
                if (selectedCountry) {
                        axios
                                .get(`${BaseUrl}/universal/getAllStates/${selectedCountry}`)
                                .then((res) => {
                                        if (res.data.status === 200) {
                                                setStates(res.data.data)

                                        }
                                        else if (res.data.status === 401) {
                                                console.log("state error")
                                        }
                                })
                                .catch((err) => {
                                        console.log("state catch error", err)
                                })
                }
        }, [selectedCountry]);

        const selectedState = watch('agency_state');

        // city api calling based on the selected state and the selected country

        useEffect(() => {
                if (selectedCountry && selectedState) {
                        axios
                                .get(`${BaseUrl}/universal//getAllCities/${selectedCountry}/${selectedState}`)
                                .then((res) => {
                                        if (res.data.status === 200) {
                                                setCities(res.data.data)
                                        }
                                        else if (res.data.status === 401) {
                                                console.log("city error")
                                        }
                                })
                                .catch((err) => {
                                        console.log("city catch error", err)
                                })
                }
        }, [selectedCountry && selectedState]);

        const handleChangeState = (e) => {
                setState(e.target.value);
                setCity(null);
        }
        const handleChangeCity = (e) => {
                setCity(e.target.value);
        }
        const handleChangeCountry = (e) => {
                setCountry(e.target.value);
                setState(null);
                setCity(null);

        }

        const onSubmit = (data) => {
                const userToken = localStorage.getItem("token");
                console.log("On Submit", data);
                // const IsImageSelector = () => {
                //   return selectedFilesNames.length > 0 ? true : false;
                // };

                data = { ...data, phone: phone };
                //  console.log("iam here")
                // if (!IsImageSelector()) {
                //   setShowFileError("Please Select a file before saving");
                // }
                if (isValidPhoneNumber(phone)) {
                        // console.log("iam inside if")
                        dispatch(updateStatusValue(progressBarValidation(2)));
                        const formdata = new FormData();
                        console.log("formdata", formdata);
                        if (pictureUrl && pictureUrl.includes("blob")) {
                                formdata.append("files", picture, pictureUrl);
                        }
                        formdata.append(
                                "agency_name",
                                data.agency_name == undefined
                                        ? data.agency_name
                                        : userinfoD.company_name
                        );
                        formdata.append("agency_email", data.agency_email);
                        formdata.append("agency_about", data.about_agency);
                        formdata.append("agency_type", data.agency_type);
                        formdata.append("agency_phone_no", phone);
                        formdata.append("agency_address", data.agency_address);
                        formdata.append("agency_city", city);
                        formdata.append("agency_country", data.agency_country);
                        formdata.append("agency_state", state);
                        formdata.append("agency_zipcode", data.agency_zipcode);
                        formdata.append("agency_website", data.website_link);
                        formdata.append("no_of_employee", data.no_of_employee);
                        formdata.append("linkedin_url", data.linkedin_url);
                        formdata.append("twitter_url", data.twitter_url);
                        formdata.append("instagram_url", data.instagram_url);
                        formdata.append("facebook_url", data.facebook_url);
                        // Object.values(userFile).forEach((value) => {
                        //   let url = URL.createObjectURL(value);
                        //   formdata.append("gallery", value, url);
                        // });
                        for (var pair of formdata.entries()) {
                        }
                        const da = {
                                agency_address: "abc",
                        };
                        console.log("form data da", da);
                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${userToken}`);
                        var requestOptions = {
                                method: "POST",
                                body: formdata,
                                headers: myHeaders,
                                redirect: "follow",
                        };
                        fetch(`${BaseUrl}/user/User-Agency-Profile`, requestOptions)
                                .then((response) => response.json())
                                .then((result) => {
                                        if (result.status === 200) {
                                                toast("Your Profile Saved Successfully");
                                                console.log(result, "api result");
                                                navigate("/company-profile");
                                                showerror(false);
                                        } else {
                                                showerror(true);
                                        }
                                })
                                .catch((error) => console.log("error", error));
                }
        };

        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}(?:\.[A-Z|a-z]{2,})?$/;

        const watchAllFields = watch();

        useEffect(() => {
                const afterRemovingTheDuplicates = Object.values(watchAllFields).filter(
                        (value) => value && value.length > 0
                );
                const percentage = (afterRemovingTheDuplicates.length / 17) * 100;
                if (percentage > 100) {
                        setShowBar("" + 100);
                } else {
                        setShowBar("" + percentage);
                }
        }, [watchAllFields]);

        // const handleSkip = (e) => {
        //   window.location = "/services-and-preference";
        // };
        useEffect(() => {
                try {
                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${userToken}`);
                        myHeaders.append("Content-Type", "application/json");

                        var requestOptions = {
                                method: "GET",
                                headers: myHeaders,
                                redirect: "follow",
                        };

                        fetch(`${BaseUrl}/user/Get-User-Agency-Profile`, requestOptions)
                                .then((response) => response.json())
                                .then((result) => {

                                        setData(result.data.agencyData[0]);

                                        CommonApi.CustomerProfileApi().then((mcpdata) => {

                                                if (mcpdata.status === 200) {
                                                        localStorage.setItem(
                                                                "profile_complete_status",
                                                                JSON.stringify(mcpdata.data[0].profile_complete_status)
                                                        );
                                                        setOverAllProgressValue(mcpdata.data[0].profile_complete_status);
                                                }
                                        });
                                        //navigate("/company-profile");
                                })
                                .catch((error) => console.log("error", error));
                } catch (err) {
                        console.log(err);
                }
        }, []);
        useEffect(() => {
                // setValue("agency_name", userinfoD.user_info.company_name);
                // setValue("agency_city", userinfoD.user_info.city);
                // setValue("agency_country", userinfoD.user_info.country);
                // setValue("agency_zipcode", userinfoD.user_info.zipcode);
                // setValue("agency_state", userinfoD.user_info.state);
                // setValue("agency_address", userinfoD.user_info.address);
                if (data) {
                        Object.entries(data).forEach(([name, value]) => {
                                setValue(name, value);
                        });
                        setPictureUrl(data.logo_url);
                        setPhone(data.agency_phone_no);
                        setPicture(data.logo_name);
                        const getBlobFromUrl = (data) => {
                                return new Promise((resolve, reject) => {
                                        let request = new XMLHttpRequest();
                                        request.open("GET", data.profile_img_url, true);
                                        request.responseType = "image/png";
                                        request.onload = () => {
                                                resolve(request.response);
                                        };
                                        request.onerror = reject;
                                        request.send();
                                });
                        };
                        getBlobFromUrl(data).then((val) => {
                                setPicture(val);
                        });
                }
        }, [data]);

        return (
                <div className="dashboard-container">
                        <Sidebar />
                        <div className="main-dashboard-container">
                                <Topbar
                                        isShow={true}
                                        value={localStorage.getItem("profile_complete_status")}
                                />
                                <main className="maincontent">
                                        <div>
                                                <section>
                                                        <div className="basic-info">
                                                                <h3>Agency Profile</h3>
                                                                {/* <h4>
                  Edit
                  <MdModeEdit />
                </h4> */}
                                                        </div>
                                                </section>
                                                {/* onSubmit={handleSubmit(onSubmit)} */}
                                                <form onSubmit={handleSubmit(onSubmit)} className="profileform">
                                                        <section>
                                                                <div className="division">
                                                                        <div className="upload-file">
                                                                                <h3>
                                                                                        Company Logo<span className="star">*</span>
                                                                                </h3>
                                                                                <div className="profilepicupload">
                                                                                        <img src={pictureUrl ? pictureUrl : logo} alt="" />
                                                                                        <div className="uploadImageProfile">
                                                                                                <FcCameraIdentification />
                                                                                                <input
                                                                                                        type="file"
                                                                                                        name="myfile"
                                                                                                        onChange={handleChange}
                                                                                                />
                                                                                        </div>
                                                                                </div>
                                                                                {profile && <p>Your Profile Uploaded Successfully</p>}
                                                                                <p className="profilepicdes">
                                                                                        300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
                                                                                </p>
                                                                        </div>
                                                                        <div className="progressbar">
                                                                                <div className="bar-profile">
                                                                                        <h4>Completion Progress</h4>
                                                                                        {/* <img src={barr} alt="" /> */}
                                                                                        <div className="circle-container">
                                                                                                <CircularProgressbar
                                                                                                        value={showBar}
                                                                                                        text={Math.round(showBar) + "%"}
                                                                                                        styles={buildStyles({
                                                                                                                pathColor: "#1fc94f",
                                                                                                                textColor: "#001488",
                                                                                                                backgroundColor: "#3e98c7",
                                                                                                        })}
                                                                                                />
                                                                                                <p>
                                                                                                        {showBar == 100 ? "" : "Please Complete Your Profile"}
                                                                                                </p>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                {/* <p className="para-profile2">
                  100 x 100px recommended. JPGs, JPEGs, and PNGs supported.
                </p> */}
                                                        </section>

                                                        <section className="pro-info">
                                                                <div className="proformfield pt-20">
                                                                        <label>
                                                                                Agency Name <span className="star">*</span>
                                                                        </label>
                                                                        <input
                                                                                type="text"
                                                                                placeholder="Enter Your Company Name"
                                                                                value={userinfoD.user_info.company_name}
                                                                                {...register("agency_name", {
                                                                                        required: true,
                                                                                })}
                                                                                // {...register("agency_name", {
                                                                                //   disabled: true,
                                                                                // })}
                                                                                readonly
                                                                        />
                                                                        {errors.agency_name && (
                                                                                <span className="error-msg">Please enter the value</span>
                                                                        )}
                                                                        {/* <div className="protip">
                    <BsInfoCircle />
                    <p>Set at once and can’t be change</p>
                  </div> */}
                                                                </div>

                                                                <div className="proformfield pt-20">
                                                                        <label>
                                                                                Agency Email <span className="star">*</span>
                                                                        </label>
                                                                        <input
                                                                                type="email"
                                                                                placeholder="Add your email here"
                                                                                {...register('agency_email', {
                                                                                        required: 'Email is required',
                                                                                        pattern: {
                                                                                                value: emailRegex,
                                                                                                message: 'Invalid email address'
                                                                                        }
                                                                                })}
                                                                        />
                                                                        {errors.agency_email && (
                                                                                <span className="error-msg">
                                                                                        {errors.agency_email.message}
                                                                                </span>
                                                                        )}
                                                                        {/* <div className="protip">
                    <BsInfoCircle />
                    <p>Set at once and can’t be change</p>
                  </div> */}
                                                                </div>
                                                        </section>

                                                        <section className="details-info">
                                                                <div className="details-about">
                                                                        <label>
                                                                                About Agency<span className="star">*</span>
                                                                        </label>
                                                                        <textarea
                                                                                type="textarea"
                                                                                placeholder="Type here about agency"
                                                                                {...register("about_agency", {
                                                                                        required: true,
                                                                                })}
                                                                                rows="8"
                                                                        />
                                                                        {errors.about_agency && (
                                                                                <span className="error-msg">Please enter the value</span>
                                                                        )}
                                                                        <div className="profile-info svg"></div>
                                                                </div>

                                                                <div className="proformfield">
                                                                        <label>
                                                                                Agency Type
                                                                                <span className="star">*</span>
                                                                        </label>

                                                                        <select
                                                                                type="text"
                                                                                name="category_id"
                                                                                className="selectlist"
                                                                                // onChange={handleChange}
                                                                                placeholder="Select position"
                                                                                {...register("agency_type", {
                                                                                        required: true,
                                                                                        maxLength: 80,
                                                                                })}
                                                                        >
                                                                                <option value="">Select Position</option>
                                                                                <option value="Private company limited">
                                                                                        Private company limited
                                                                                </option>
                                                                                <option value="Public limited company">
                                                                                        Public limited company
                                                                                </option>
                                                                                <option value="Limited partnership">
                                                                                        Limited partnership
                                                                                </option>
                                                                                <option value="General partnership">
                                                                                        General partnership
                                                                                </option>
                                                                                <option value="Chartered company">Chartered company</option>
                                                                                <option value="Statutory corporation">
                                                                                        Statutory corporation
                                                                                </option>
                                                                                <option value="Company Limited">Company Limited</option>
                                                                                <option value="State-owned enterprise">
                                                                                        State-owned enterprise
                                                                                </option>
                                                                                <option value="Holding company">Holding company</option>
                                                                                <option value="Subsidiary company">
                                                                                        Subsidiary company
                                                                                </option>
                                                                                <option value="Sole proprietorship">
                                                                                        Sole proprietorship
                                                                                </option>
                                                                                <option value="Charitable incorporated organisation">
                                                                                        Charitable incorporated organisation (UK)
                                                                                </option>
                                                                                <option value="Reciprocal inter-insurance exchange">
                                                                                        Reciprocal inter-insurance exchange
                                                                                </option>
                                                                                <option value="Other">Other</option>
                                                                        </select>
                                                                        {errors.agency_type && (
                                                                                <span className="error-msg">
                                                                                        Please select the position
                                                                                </span>
                                                                        )}

                                                                        <div className="profile-info svg"></div>
                                                                </div>

                                                                <label>
                                                                        Contact Number<span className="star">*</span>
                                                                </label>

                                                                <PhoneInput
                                                                        country="india"
                                                                        placeholder="6547894388"
                                                                        name="phoneNumber"
                                                                        international
                                                                        value={phone}
                                                                        onChange={(e) => {
                                                                                setPhone(e);
                                                                        }}
                                                                />
                                                                {phone && !isValidPhoneNumber(phone) && (
                                                                        <span className="error-msg">
                                                                                Please enter the valid phone number
                                                                        </span>
                                                                )}
                                                                {!phone && !error && (
                                                                        <span className="error-msg">
                                                                                Please enter the phone number
                                                                        </span>
                                                                )}




                                                                <div className="profile-info svg"></div>

                                                                <section>
                                                                        <div className="proformfield pt-20">
                                                                                <div className="profile-address">
                                                                                        <label>
                                                                                                Location / Address<span className="star">*</span>
                                                                                        </label>

                                                                                        <input
                                                                                                type="text"
                                                                                                placeholder="Add address here"
                                                                                                // value={userInfo.address}
                                                                                                {...register("agency_address", {
                                                                                                        required: true,
                                                                                                })}
                                                                                        />
                                                                                        {errors.agency_address && (
                                                                                                <span className="error-msg">
                                                                                                        Please enter the address
                                                                                                </span>
                                                                                        )}
                                                                                </div>
                                                                        </div>

                                                                        <div className="first-last-names pt-20">
                                                                                <div className="d-flex flex-column col-12 col-md-6" style={{ marginRight: "10px" }}>
                                                                                        <label>
                                                                                                Country <span className="star">*</span>
                                                                                        </label>
                                                                                        <select
                                                                                                type="text"
                                                                                                placeholder="Select Country"
                                                                                                //onChange={handleChange}
                                                                                                // value={userInfo.country}
                                                                                                className="selectlist"
                                                                                                {...register("agency_country", {
                                                                                                        required: true,
                                                                                                        maxLength: 80,
                                                                                                        onChange: handleChangeCountry
                                                                                                })}
                                                                                        >
                                                                                                {countries.map((el) => {
                                                                                                        return (
                                                                                                                <>
                                                                                                                        <option value={el.country}>{el.country}</option>
                                                                                                                </>
                                                                                                        );
                                                                                                })}
                                                                                        </select>
                                                                                        {errors.country && (
                                                                                                <span className="error-msg">
                                                                                                        Please select the country
                                                                                                </span>
                                                                                        )}
                                                                                </div>

                                                                                <div className="d-flex flex-column col-12 col-md-6">
                                                                                        <label>
                                                                                                City<span className="star">*</span>
                                                                                        </label>
                                                                                        <select
                                                                                                type="text"
                                                                                                placeholder="Select Country"
                                                                                                value={city}
                                                                                                className="selectlist"
                                                                                                {...register("agency_city", {
                                                                                                        required: "Please enter the city",
                                                                                                        maxLength: 80,
                                                                                                        onChange: handleChangeCity
                                                                                                })}
                                                                                        >
                                                                                                <option value="">Select City</option>
                                                                                                {cities.map((city, index) => (
                                                                                                        <option value={city} key={index}>
                                                                                                                {city}
                                                                                                        </option>
                                                                                                ))}
                                                                                        </select>
                                                                                        {errors.agency_city && (
                                                                                                <span className="error-msg">{errors.agency_city.message}</span>
                                                                                        )}
                                                                                </div>
                                                                        </div>

                                                                        <div className="first-last-names pt-20">
                                                                                <div className="d-flex flex-column col-12 col-md-6"
                                                                                        style={{ marginRight: "10px" }}>
                                                                                        <label>
                                                                                                State/Province <span className="star">*</span>
                                                                                        </label>
                                                                                        <select
                                                                                                type="text"
                                                                                                placeholder="Select State/Province"
                                                                                                className="selectlist"
                                                                                                value={state}
                                                                                                {...register("agency_state", {
                                                                                                        required: "Please select the State/Province",
                                                                                                        maxLength: 80,
                                                                                                        onChange: handleChangeState
                                                                                                })}
                                                                                        >
                                                                                                <option value="">Select state</option>
                                                                                                {states.map((state, index) => (
                                                                                                        <option key={index} value={state}>
                                                                                                                {state}
                                                                                                        </option>
                                                                                                ))}
                                                                                        </select>
                                                                                        {errors.agency_state && (
                                                                                                <span className="error-msg">
                                                                                                        {errors.agency_state.message}
                                                                                                </span>
                                                                                        )}
                                                                                </div>

                                                                                <div className="d-flex flex-column col-12 col-md-6">
                                                                                        <label>
                                                                                                Pin Code<span className="star">*</span>
                                                                                        </label>
                                                                                        <input
                                                                                                type="text"
                                                                                                placeholder="Add Pin Code here"
                                                                                                //value={userInfo.zipcode}

                                                                                                {...register("agency_zipcode", {
                                                                                                        required: true,
                                                                                                        minLength: 4,
                                                                                                        maxLength: 8,
                                                                                                })}
                                                                                        />
                                                                                        {errors.agency_zipcode && (
                                                                                                <span className="error-msg">
                                                                                                        Please enter the Pin Code
                                                                                                </span>
                                                                                        )}
                                                                                </div>
                                                                        </div>
                                                                </section>

                                                                <div className="proformfield">
                                                                        <label>
                                                                                Website Link<span className="star">*</span>
                                                                        </label>

                                                                        <div className="projectlink">
                                                                                <input
                                                                                        type="link"
                                                                                        placeholder="Add website link here"
                                                                                        {...register("website_link", {
                                                                                                required: "Please enter a website link",
                                                                                                pattern: {
                                                                                                        value: urlRegex,
                                                                                                        message: "Invalid URL format. Please enter a valid URL.",
                                                                                                },
                                                                                        })}
                                                                                />
                                                                        </div>

                                                                        {/* <input
                    type="link"
                    placeholder="Add website link here"
                    {...register("website_link", {
                      required: true,
                    })}
                  /> */}
                                                                        {errors.website_link && (
                                                                                <span className="error-msg">
                                                                                        {errors.website_link.message}
                                                                                </span>
                                                                        )}
                                                                        <div className="profile-info svg"></div>

                                                                        <label>
                                                                                Number of Employee<span className="star">*</span>
                                                                        </label>

                                                                        <input
                                                                                type="number"
                                                                                placeholder="Select no. of employee"
                                                                                {...register("no_of_employee", {
                                                                                        required: true,
                                                                                })}
                                                                                min="0"
                                                                                oninput="validity.valid||(value='')"
                                                                        />
                                                                        {errors.no_of_employee && (
                                                                                <span className="error-msg">
                                                                                        Please enter the number of employee
                                                                                </span>
                                                                        )}

                                                                        <div className="profile-info svg"></div>
                                                                </div>

                                                                <div className="proformfield">
                                                                        <label>Social Proof</label>

                                                                        <div className="details-social">
                                                                                <span>
                                                                                        <div className="checkboxlist">
                                                                                                {/* <input
                          type="checkbox"
                          id="linkendin"
                          name="linkendinlink"
                          value="linkendin"
                        /> */}
                                                                                                <label>Linkedin</label>
                                                                                        </div>

                                                                                        {/* <input 
                                                     type="checkbox"/> */}
                                                                                        <input
                                                                                                type="link"
                                                                                                placeholder="Add link here"
                                                                                                {...register("linkedin_url", {
                                                                                                        required: false,
                                                                                                        pattern: {
                                                                                                                value: urlRegex,
                                                                                                                message: "Invalid URL format. Please enter a valid URL.",
                                                                                                        },
                                                                                                })}
                                                                                        />
                                                                                        {errors.linkedin_url && (
                                                                                                <span className="error-msg">
                                                                                                        {errors.linkedin_url.message}
                                                                                                </span>
                                                                                        )}
                                                                                </span>

                                                                                <span>
                                                                                        <div className="checkboxlist">
                                                                                                {/* <input
                          type="checkbox"
                          id="linkendin"
                          name="linkendinlink"
                          value="linkendin"
                        /> */}
                                                                                                <label>Twitter</label>
                                                                                        </div>

                                                                                        {/* <input 
                                                     type="checkbox"/> */}
                                                                                        <input
                                                                                                type="link"
                                                                                                placeholder="Add link here"
                                                                                                {...register("twitter_url", {
                                                                                                        required: false,
                                                                                                        pattern: {
                                                                                                                value: urlRegex,
                                                                                                                message: "Invalid URL format. Please enter a valid URL.",
                                                                                                        },
                                                                                                })}
                                                                                        />
                                                                                        {errors.twitter_url && (
                                                                                                <span className="error-msg">
                                                                                                        {errors.twitter_url.message}
                                                                                                </span>
                                                                                        )}
                                                                                </span>
                                                                        </div>

                                                                        <div className="details-social">
                                                                                <span>
                                                                                        <div className="checkboxlist">
                                                                                                {/* <input
                          type="checkbox"
                          id="linkendin"
                          name="linkendinlink"
                          value="linkendin"
                        /> */}
                                                                                                <label>Instagram</label>
                                                                                        </div>

                                                                                        {/* <input 
                                                     type="checkbox"/> */}
                                                                                        <input
                                                                                                type="link"
                                                                                                placeholder="Add link here"
                                                                                                {...register("instagram_url", {
                                                                                                        required: false,
                                                                                                        pattern: {
                                                                                                                value: urlRegex,
                                                                                                                message: "Invalid URL format. Please enter a valid URL.",
                                                                                                        },
                                                                                                })}
                                                                                        />
                                                                                        {errors.instagram_url && (
                                                                                                <span className="error-msg">
                                                                                                        {errors.instagram_url.message}
                                                                                                </span>
                                                                                        )}
                                                                                </span>

                                                                                <span>
                                                                                        <div className="checkboxlist">
                                                                                                {/* <input
                          type="checkbox"
                          id="linkendin"
                          name="linkendinlink"
                          value="linkendin"
                        /> */}
                                                                                                <label>Facebook</label>
                                                                                        </div>

                                                                                        {/* <input 
                                                     type="checkbox"/> */}
                                                                                        <input
                                                                                                type="link"
                                                                                                placeholder="Add link here"
                                                                                                {...register("facebook_url", {
                                                                                                        required: false,
                                                                                                        pattern: {
                                                                                                                value: urlRegex,
                                                                                                                message: "Invalid URL format. Please enter a valid URL.",
                                                                                                        },
                                                                                                })}
                                                                                        />
                                                                                        {errors.facebook_url && (
                                                                                                <span className="error-msg">
                                                                                                        {errors.facebook_url.message}
                                                                                                </span>
                                                                                        )}
                                                                                </span>
                                                                        </div>

                                                                        {/* <div className="profile-info svg"></div>

                  <label>Gallery(attached file is any)</label> */}
                                                                </div>
                                                        </section>

                                                        {/* <div className="gallery">
                <GrFormUpload />
                <input
                  type="file"
                  placeholder="Upload to see preview"
                  {...register("gallery", {
                    required: false,
                  })}
                />
                
              </div> 

              <DragDrop onChange={dragFiles} />
              <h6 className="last-heading">
                300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
              </h6>
              {showFileError && <p className="error-msg">{showFileError}</p>}
              {selectedFilesNames.map((value) => {
                return <p>{value}</p>;
              })}

              {Object.values(userFile).map((value) => {
                return (
                  <>
                    <div className="portfolio-screenshots">
                      <img src={URL.createObjectURL(value)} alt="" />
                    </div>
                  </>
                );
              })}

              {galleryUrl.length > 0 &&
                galleryUrl.map((value) => {
                  return (
                    <>
                      <div className="portfolio-screenshots">
                        <img src={value.img_url} alt="" />
                      </div>
                    </>
                  );
                })}*/}

                                                        <div className="formbuton">
                                                                {/* <Link to={`/AgencyProfile`}> */}
                                                                <input type="submit" className="save-button" value="Save" />
                                                                {/*<input
                  type="submit"
                  className="save-button"
                  value="Skip"
                  onClick={handleSkip}
                />
                 </Link> */}

                                                                {/* <Link to={`/AgencyProfile`}>
                <button className="save-button">Skip</button>
              </Link> */}
                                                        </div>

                                                        {/* <div>
                                   <Link to={`/ServicesandPreference`}>
                                   <button className="save-button">Skip</button>
                                   </Link>
                                    </div> */}
                                                </form>
                                        </div>
                                </main>
                        </div>
                </div>
        );
};
export default MyProfile;
