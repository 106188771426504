import React, { useState, useEffect } from 'react';
// import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import Modal from "react-modal";
import "rsuite/dist/rsuite.css";
import './savedraft.css';
import svDraft from "../../../assets/images/svdraft.svg";
import { useNavigate } from 'react-router-dom';



const SecondDraft = ({ showDraft, handleCloseDraft, }) => {
        const navigate = useNavigate()

        const handleDraftTable = () => {
                navigate("/draft-table")
        }

        return (
                <>

                        <Modal
                                isOpen={showDraft}
                                onRequestClose={handleCloseDraft}
                                className="project-restrict-popup-draft"
                                overlayClassName="pr-modal-overlay"
                                shouldCloseOnOverlayClick={false}
                        >
                                <div className="d-flex align-items-center justify-content-center">
                                        <img
                                                src={svDraft}
                                                alt=""
                                                style={{ width: "60px", height: "60px" }}
                                        />
                                </div>

                                <div className="mt-3" style={{ textAlign: "center" }}>
                                        <h2 className="sv-title">Draft Saved</h2>
                                        <p className='sv-text mt-2 mb-4'>Your project have been save as a draft. You can view it from draft project in sidebar</p>
                                       
                                </div>
                                <div className='d-flex align-items-center justify-content-center'>
                                        {/* <button className='discard-btn' onClick={handleClose}>Discard</button> */}
                                        <button className='save-draft-btn' style={{width:"100%"}} onClick={handleDraftTable}>Confirm</button>
                                </div>
                        </Modal>
                </>
        )
}

export default SecondDraft