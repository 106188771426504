import React, { useState, useEffect } from "react";
import "./page.css";
import { BsUpload } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../../Api/api";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { useNavigate } from "react-router-dom";
import BankDetailsUp from "../../../assets/images/bankdetails.png";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { MdModeEdit } from "react-icons/md";

const BankDetails = () => {
  const [data, setData] = useState([]);
  const [showBar, setShowBar] = useState("0");
  const [pageLoading, setPageLoading] = useState(false);
  let navigate = useNavigate();
  const userToken = localStorage.getItem("token");
  const userinfo = JSON.parse(localStorage.getItem("userinfo"));

  useEffect(() => {
    setPageLoading(true);
    try {
      const userToken = localStorage.getItem("token");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
     
      fetch(`${BaseUrl}/vendor/Get-Vendor-Bank-Details-Profile`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          if (result.data.length > 0) {
            setData(result.data);
            setPageLoading(false);
          } else {
            setPageLoading(false);

            // navigate("/bank-details/edit");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleEditDoc = () => {
    navigate("/bank-details/edit")
  }

  const handleNext = (e) => {
    window.location = "/customer-reviews";
  };

  const handleSkip = (e) => {
    window.location = "/customer-reviews";
  };

  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar
            isShow={true}
            value={
              data.length > 0 &&
              data[0].profile_complete_status &&
              data[0].profile_complete_status
            }
          />
          <main className="maincontent">
            <div>
              <section>
                <div className="basic-info">
                  <h3>Bank Details</h3>
                  <h4 onClick={handleEditDoc} style={{ cursor:"pointer"}}>
                    Edit
                    <MdModeEdit />
                  </h4>
                </div>

              </section>

              {data.length > 0 ? (
                data.map((item) => {
                  return (
                    <div className="companydoctext">
                      {/* <table className="rwd-table">
                <tbody>
                      <tr class="tabletopbar">
                        <th>Bank Details Fields</th>
                        <th className="text-center">Details</th>
                      </tr>
                      <tr>
                        <td data-th="Document Name">Bank Name</td>
                        <td data-th="View" className="cell doclist">
                          {item.bank_name}
                        </td>
                      </tr>
                      <tr>
                        <td data-th="Document Name">Account</td>
                        <td data-th="View" className="cell doclist">
                          {item.account_name}
                        </td>
                      </tr>
                      <tr>
                        <td data-th="Document Name">Account Type</td>
                        <td data-th="View" className="cell doclist">
                          {item.account_type}
                        </td>
                      </tr>
                      <tr>
                        <td data-th="Document Name">Bank Account No</td>
                        <td data-th="View" className="cell doclist">
                          {item.bank_account_no}
                        </td>
                      </tr>
                      <tr>
                        <td data-th="Document Name">IFSC Code</td>
                        <td data-th="View" className="cell doclist">
                          {item.ifsc_code}
                        </td>
                      </tr>
                      <tr>
                        <td data-th="Document Name">Bank Address Details</td>
                        <td data-th="View" className="cell doclist">
                          {item.bank_address}
                        </td>
                      </tr>
                    </tbody>
                  </table> */}

                      <div class="card">
                        <div class="infos">
                          <div class="bankName">
                            <h2>{userinfo.company_name}</h2>
                          </div>
                          <p class="BankDetails">
                            <span>Bank Name</span> - {item.bank_name}
                          </p>
                          <p class="BankDetails">
                            <span>Contact Person</span> - {item.account_name}
                          </p>
                          <p class="BankDetails">
                            <span>Account No.</span> - {item.bank_account_no}
                          </p>
                          <p class="BankDetails">
                            <span>Account Type</span> - {item.account_type}
                          </p>
                          <p class="BankDetails">
                            <span>IFSC Code</span> - {item.ifsc_code}
                          </p>
                          <p class="BankDetails">
                            <span>Bank Address</span> - {item.bank_address}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="noreviews">
                  <div className="noreviewcont">
                    <h3>No Bank Details Found</h3>

                    <Link to="/bank-details/edit">
                      <button>Add Bank Details</button>
                    </Link>
                  </div>
                  <img src={BankDetailsUp} alt="bankdetails" />
                </div>
              )}

              <div className="formbuton">
                {/* <Link to={`/AgencyProfile`}> */}
                <input
                  type="submit"
                  className="save-button"
                  value="Skip"
                  onClick={handleSkip}
                />

                <input
                  type="submit"
                  className="save-button"
                  value="Next"
                  onClick={handleNext}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default BankDetails;
