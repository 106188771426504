import React, { useEffect, useState, useRef } from "react";
import "../Login/Login.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { MdPassword } from "react-icons/md";
import { useDispatch } from "react-redux";
import { login } from "../../Store/Reducers/userSlice";
import { BaseUrl } from "../../Api/api.js";

const INITIAL_COUNT = 120;
const twoDigits = (num) => String(num).padStart(2, "0");

const OtpForm = () => {
  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);

  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;

  const handleStart = () => {
    setStatus(STATUS.STARTED);
    setSecondsRemaining(INITIAL_COUNT);
  };

  const STATUS = {
    STOPPED: (
      <Link to="/reset-password">
        <p className="fpass" onClick={handleStart}>
          Resend OTP
        </p>
      </Link>
    ),
  };

  const [status, setStatus] = useState(STATUS.STOPPED);
  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );
  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const userToken = localStorage.getItem("token");

  const initialValues = {
    otp: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = validate(formValues);
    //setFormErrors(validate(formValues));
    if (Object.keys(error).length !== 0) {
      setFormErrors(error);
      console.log("Error Found");
      return false;
    }
    console.log("No Validation Error Found");
    setIsSubmit(true);
    try {
      var myHeaders = new Headers();

      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(formValues);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendor/Vendor-Password-Otp-Verify`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log("apiresult", result);
          if (result.status === 200) {
            // setFormValues("");
            // //alert(result.message);
            // localStorage.setItem("token", result.data.token);
            // localStorage.setItem("token", JSON.stringify(result.data));
            // localStorage.setItem(
            //   "userinfo",
            //   JSON.stringify(result.data.user_info)
            // );
            toast("OTP Verified Successfully");
            window.location = "/change-password";
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
    dispatch(login({ formValues, loggedIn: true }));
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    const otp_regex = /^[0-9]{1,6}/;

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    if (!otp_regex.test(values["otp"])) {
      errors.otp = "Otp not matched Please try again";
    }
    console.log(errors);
    return errors;
  };

  return (
    <div className="login-wrapper">
      <div className="login-form ">
        <h2>Enter Verification OTP</h2>
        <p className="ftext">
          Enter OTP here to reset your password and click on verify it
        </p>
        <div className="formline">&nbsp;</div>
        <form
          className="loginform"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <div className="login-form-field">
            <MdPassword className="form-icon" />
            <input
              type="otp"
              name="otp"
              placeholder="Enter 6 Digit OTP"
              minLength="6"
              value={formValues.otp}
              onChange={handleChange}
              required
            ></input>
          </div>
          <p className="err-message">{formErrors.otp}</p>
          <Link to="/change-password">
            <button className="btn" onClick={handleSubmit}>
              Verify OTP
            </button>
          </Link>
          {status === STATUS.STARTED ? (
            <p className="fpass">
              Resend OTP in {twoDigits(minutesToDisplay)}:
              {twoDigits(secondsToDisplay)}
            </p>
          ) : (
            status
          )}
        </form>
        <Link to="/login">
          <p className="fpass">Click here to login</p>
        </Link>
      </div>
    </div>
  );
};

export default OtpForm;
