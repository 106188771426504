import { createSlice } from "@reduxjs/toolkit";


export const ProposalList = createSlice({
  name: "Proposal List",
  initialState: {
 proposalDetails:{}
  }, 
  reducers: {
    updateProposalList: (state, action) => {
      // console.log("Proposal information in the store",action)
      state.proposalDetails = action.payload;
    }
  },
});

export const {  updateProposalList} = ProposalList.actions;

export default  ProposalList.reducer;