import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "./Customer.css";
import Topbar from "./Topbar";
import { BaseUrl } from "../../Api/api.js";
import DataTable from "react-data-table-component";
import { transformProjectTableData } from "../../Utils/CustomerProjectList";
import EyeIcon from "../../assets/images/Eye-icon.png";
import { updateProjectInformation } from "../../../src/Store/Reducers/CustomerProjectInfoReducer";
import { updateProposalList } from "../../../src/Store/Reducers/CustomerProposalListReducer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomerProjectInfo from "./CustomerProjectInfo";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import { transformProposalTableData } from "../../Utils/CustomerProjectList";
import { Loader } from "rsuite";
import PageLoader from "../../components/PageLoader/PageLoader";

const Projectlist = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { projectDetails } = useSelector((state) => state.customerProjectInfo);

  const { proposalDetails } = useSelector(
    (state) => state.customerProposalList
  );
  const [data, setData] = useState([]);
  const [proposalData, setProposalData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [type, setType] = useState("All");
  const [startedDate, setStartDate] = useState("");
  const [endedDate, setEndDate] = useState("");
  const [sortedArray, setSortedArray] = useState([]);
  const [selectedHeaders, setSelectedHeaders] = useState([
    {
      id: "Date",
      name: "Date",
      selector: (result) =>
        result.created_at.split("T")[0].split("-").reverse().join("-"),
      // sortable: true,
    },

    {
      id: "Project Name",
      name: "Project",
      selector: (result) => result.project_name,
      // sortable:true,
      grow: 1,
    },

    {
      id: "Category",
      name: "Category",
      selector: (result) => result.solution_name,
      // sortable: true,
      grow: 1,
    },
    {
      id: "Budget",
      name: "Budget",
      // selector: (result) => result.project_budget,
      cell: (result) => (
        <div className="project-table-budget">
          <p className="project-table-budget">{result.project_budget}</p>
        </div>
      ),
      // style: { color: "#304FFE" },
      // sortable: true,
    },

    {
      id: "Status",
      name: "Status",
      // selector: (result) => result.project_status,
      cell: (result) => (
        <div
          className={
            result.project_status === "Completed"
              ? "table-status-Completed"
              : result.project_status === "In Progress"
              ? "table-status-Progress"
              : "table-status-Applying"
          }
          onClick={() => {
            dispatch(updateProjectInformation(result));
            setShowPopup(true);
          }}
        >
          {" "}
          {result.project_status}
        </div>
      ),
    },
    {
      id: "Expiry",
      name: "Expiry",
      selector: (result) => result.project_expiry_datetime,
    },
    {
      id: "Applied",
      name: "Vendor",
      selector: (result) => result.vendor_apply_count,
      style: {
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "30px",
      },
    },
    {
      id: "Action",
      name: "Action",
      cell: (result) => (
        <div
          className="project-list-eye-icon"
          onClick={() => {
            dispatch(updateProjectInformation(result));
            setShowPopup(true);
          }}
        >
          <img className="eye-icon" src={EyeIcon} alt=""></img>
        </div>
      ),
    },
  ]);

  const [showCalender, setShowCalender] = useState(false);
  const handleTypeSelect = (selectedKey) => setType(selectedKey);
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#304FFE",
        color: "white",
        fontSize: "15px",
        width: "100%",
        fontWeight: "500",
        fontfamily: "Poppins",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "16px",
        color: "black",
        fontWeight: "500",
        letterSpacing: "0.5px",
        whiteSpace: "nowrap",
        marginTop: "5px",
        marginBottom: "5px",
        fontfamily: "Poppins",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };

  const selectedHeadersInMobileView = [
    {
      id: "Project Name",
      name: "Project",
      selector: (result) => result.project_name,
    },
    {
      id: "Applied",
      name: "Vendor",
      selector: (result) => result.vendor_apply_count,
      style: {
        alignItems: "center",
        justifyContent: "center",
        paddingRight: "30px",
      },
    },
    {
      id: "Action",
      name: "Action",
      cell: (result) => (
        <div
          className="project-list-eye-icon"
          onClick={() => {
            dispatch(updateProjectInformation(result));
            setShowPopup(true);
          }}
        >
          <img className="eye-icon" src={EyeIcon} alt=""></img>
        </div>
      ),
    },
  ];

  // const onChangeStart = (e) => {
  //   setStartDate(e.target.value);
  // };
  // const onChangeEnd = (e) => {
  //   setEndDate(e.target.value);
  // };
  // console.log(startedDate, endedDate, "startedDate, endedDate button clicked");
  const results = transformProjectTableData(data);

  const onClickView = () => {
    if (startedDate && endedDate) {
      const result = results.filter((e) => {
        if (
          new Date(e.created_at) >= new Date(JSON.parse(startedDate)) &&
          new Date(e.created_at) <= new Date(JSON.parse(endedDate))
        ) {
          return true;
        } else {
          return false;
        }
      });
      setSortedArray(result);
    }
  };

  const userToken = localStorage.getItem("token");
  //console.log(data);
  const id =
    projectDetails.announcement_id && projectDetails.announcement_id.toString();

  useEffect(() => {
    setPageLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/announcement/User-Announcement-List`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // console.log("result", result.data);

          setData(result.data);
          setType(type);
          setSortedArray(transformProjectTableData(result.data));
          setPageLoading(false);
          localStorage.setItem(
            "vendor_apply_count",
            JSON.stringify(result.data)
          );
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const userToken = localStorage.getItem("token");
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        announcement_id: id,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
      };

      fetch(
        `${BaseUrl}/announcement/Get-Vendor-List-apply-Announcment`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log("proposal data", result.data);
          setProposalData(result.data);
          dispatch(updateProposalList(transformProposalTableData(result.data)));
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, [showPopup]);

  const onClickStatus = (type) => {
    setType(type);
    if (type === "All") {
      setSortedArray(results);
    } else if (type === "Expired") {
      setSortedArray(
        results.filter((e) => {
          return new Date(e.project_expire) <= new Date();
        })
      );
    } else {
      setSortedArray(
        results.filter((e) => {
          return e.project_status === type;
        })
      );
    }
  };

  function getDaysBetweenDates(date1, date2) {
    var date1InMs = date1.getTime();
    var date2InMs = date2.getTime();
    var differenceInMs = date2InMs - date1InMs;
    return differenceInMs / (1000 * 3600 * 24);
  }
  var date1 = new Date(projectDetails.created_at);
  var date2 = new Date(projectDetails.project_expire);
  const timelinedays = getDaysBetweenDates(date1, date2);
  const onRowClicked = (row) => {
    // console.log(row, "row clicked");
    dispatch(updateProjectInformation(row));
    setShowPopup(true);
  };
  // console.log(projectDetails.project_status, "projectDetails.project_status");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 500) {
        setShowCalender(true);
      } else {
        setShowCalender(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
    {pageLoading && (
       <PageLoader/>
      )}
      <CustomerProjectInfo
        isShow={showPopup}
        projectName={projectDetails.project_name}
        category={projectDetails.solution_name}
        budget={projectDetails.project_budget}
        city={projectDetails.city}
        country={projectDetails.country}
        descripiton={projectDetails.project_description}
        attachName={projectDetails.attachment_name}
        attachUrl={projectDetails.attachment_url}
        proposalCount={projectDetails.vendor_apply_count}
        projectStatus={projectDetails.project_status}
        timelinedays={timelinedays | 0}
        onRowClicked={() => {
          setShowPopup(false);
        }}
      />

      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          <div className="my-projects-main-div">
            <h1 className="projects-list-header">My Projects</h1>
            {/* <center>
              {" "}
              {pageLoading && <Loader size="lg" speed="normal" />}
            </center> */}
            {/* <h3 className="projects-list-sub-header">
            {sortedArray.length} orders found
          </h3> */}
            <div className="projects-all-orders-and-date">
              <div className="projects-all-orders">
                <h3
                  className={
                    type === "All"
                      ? "projects-status"
                      : "projects-status-in-active"
                  }
                  eventKey="All"
                  onClick={() => {
                    onClickStatus("All");
                  }}
                >
                  All
                </h3>
                {/* <h3
                className={
                  type === "Applying"
                    ? "projects-status"
                    : "projects-status-in-active"
                }
                eventKey="Applying"
                onClick={() => {
                  onClickStatus("Applying");
                }}
              >
                Applying
              </h3> */}
                <h3
                  className={
                    type === "In Progress"
                      ? "projects-status"
                      : "projects-status-in-active"
                  }
                  eventKey="In Progress"
                  onClick={() => {
                    onClickStatus("In Progress");
                  }}
                >
                  In progress
                </h3>
                <h3
                  className={
                    type === "Completed"
                      ? "projects-status"
                      : "projects-status-in-active"
                  }
                  eventKey="Completed"
                  onClick={() => {
                    onClickStatus("Completed");
                  }}
                >
                  Completed
                </h3>
                <h3
                  className={
                    type === "Expired"
                      ? "projects-status"
                      : "projects-status-in-active"
                  }
                  eventKey="Expired"
                  onClick={() => {
                    onClickStatus("Expired");
                  }}
                >
                  Expired
                </h3>
              </div>

              <div className="project-start-end-dates">
                <DateRangePicker
                  //  cleanable={false}
                  appearance="default"
                  placeholder="Date Range"
                  style={{ width: 250 }}
                  format={"dd-MM-yyyy"}
                  onChange={(value) => {
                    if (value && value.length) {
                      setStartDate(JSON.stringify(value[0]));
                      setEndDate(JSON.stringify(value[1]));
                    }
                  }}
                  onClose={() => {
                    onClickView();
                  }}
                  onClean={() => {
                    // console.log("on clean triggered");
                    window.location.reload();
                  }}
                  showOneCalendar={showCalender ? true : false}

                  // onOk={() => {
                  //   onClickView();

                  // }}
                />
                {/* below code is without library(package) date sorting  */}

                {/* <div>
                <p className="projects-start-date">Start Date</p>
                <input
                  type="date"
                  className="date-input"
                  onChange={onChangeStart}
                />
              </div>
              <div>
                <p className="projects-end-date">End Date</p>
                <input
                  type="date"
                  className="date-input"
                  onChange={onChangeEnd}
                  min={startedDate}
                />
                <button className="start-end-date-btn" onClick={onClickView}>
                  View
                </button>
              </div> */}
              </div>
            </div>
            <div className="customer-project-table">
              <DataTable
                data={sortedArray}
                columns={selectedHeaders}
                customStyles={customStyles}
                onRowClicked={onRowClicked}
                pagination={sortedArray && sortedArray.length > 10}
                paginationRowsPerPageOptions={[5, 10]}
                //  fixedHeader
                // fixedHeaderScrollHeight="300px"
              />
            </div>
            <div className="customer-project-table-in-mobile-view">
              <DataTable
                data={sortedArray}
                columns={selectedHeadersInMobileView}
                customStyles={customStyles}
                onRowClicked={onRowClicked}
                pagination={sortedArray && sortedArray.length > 10}
                paginationRowsPerPageOptions={[5, 10]}
                fixedHeader
                fixedHeaderScrollHeight="420px"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projectlist;
