import React from "react";
import Layout from "../../components/Layout";
import { useEffect } from "react";
const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <div className="termsconditions">
        <h2>REFUND</h2>

        <div>
          <h3>Refund of booking fee</h3>
        </div>
        <div>
          <p>
            A booking fee excluding other applicable taxes may be collected by
            ConQt for the transactions booked through the Platform. If at any
            time, the Users are not satisfied with the quality of the Service
            Provider, User may call Service provider care toll free number of
            this Platform wherein the complaint will be noted for review. ConQt
            holds the authority to decide if the booking fee should be refund
            for this compliant. Further, User will not have any right to claim
            or allege anything against ConQt in respect of services
            provided/money collected by the Service Provider from the User
            beyond the booking fee amount.
          </p>
        </div>
        <div>
          <p>
            In any case should you in any manner wrongfully or illegally detain
            any director, employee, affiliate, agent, representative or
            subcontractor of ConQt, we shall be constrained to take all such
            actions including cancellation of the refund process. Any refund
            will stand forfeited without any further reference to you. This
            shall be in addition to and without prejudice to all our further
            remedies at law.
          </p>
        </div>
        <div>
          <h3>CANCELLATION</h3>
          <p>
            User shall directly contact ConQt for processing any requests
            pertaining to cancellation of bookings. <br />
            On receiving cancellation request, the refund shall be governed by
            the Refund terms mentioned in the Agreement. The cancellation
            request should be raised before a minimum of 24 hours before the
            scheduled time for performance of service. For any requests that are
            not raised before 24 hours, the booking fee is non-refundable.
          </p>
        </div>

        <div>
          <h2>REFUND OF TRANSACTION PAYMENT - SERVICE PROVIDER </h2>
          <h4>ConQt refund policy: </h4>
          <p>
            ConQt is not obligated to refund to you any amounts or fees paid by
            you while placing the order, except solely in the event you rescind
            or terminate your subscription within the ‘seven days from the date
            of activation of campaign following your agreement to purchase a
            service package. You authorize ConQt to charge you for any sales or
            similar taxes that may be imposed on your payments, lead fees, or
            any other fees charged by ConQt.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default TermsConditions;
