import React, { useState, useEffect } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import GoogleMapReact from 'google-map-react';
import './nbcd.css';


const GoogleMap = (props) => {

        const [mapCenter, setMapCenter] = useState({ lat: null, lng: null });
        const [showingInfoWindow, setShowingInfoWindow] = useState(true);
        const [selectedPlace, setSelectedPlace] = useState("");

        // const markers = apiKey;

        useEffect(() => {
                const geocoder = new props.google.maps.Geocoder();
                geocoder.geocode({ address: props.address }, (results, status) => {
                        if (status === "OK") {
                                setMapCenter(results[0].geometry.location);
                                setSelectedPlace(results[0].formatted_address);
                        } else {
                                alert("Geocode was not successful for the following reason: " + status);
                        }
                });
        }, [props.address, props.google.maps.Geocoder]);


        const defaultProps = {
                center: {
                        lat: 37.7749,
                        lng: -122.4194
                },
                zoom: 11
        };
        return (
                <>
                        <div className=''  >
                                <Map google={props.google} zoom={14} center={mapCenter} style={{ width: "300px", height: "300px" }}>
                                        <Marker name={selectedPlace} position={mapCenter} />
                                        <InfoWindow visible={showingInfoWindow} marker={mapCenter}>
                                                <div>
                                                        <h3>{selectedPlace}</h3>
                                                </div>
                                        </InfoWindow>
                                </Map>

                        </div>

                </>
        )
}

export default GoogleApiWrapper({
        apiKey: "AIzaSyCak3s7IJ6sr8bXVXF8f2U_KZbSUMxMaOk"
})(GoogleMap);
