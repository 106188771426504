import React, { useEffect, useState } from "react";
import "../Customer/Customer.css";
import Sidebar from "./Sidebar";
import ProjectList from "./Project-list";
import Topbar from "./Topbar";
import ComingSoon from "../../assets/images/coming-soon.png";

const AddServices = () => {
  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />
        <div className="comingsoon">
          <img src={ComingSoon} alt="coming-soon" />
        </div>
      </div>
    </div>
  );
};

export default AddServices;
