import React, { useState } from "react";
import star from "../../../assets/images/star.svg";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";

const starratings = ({ stars }) => {
  const ratingStar = Array.from({ length: stars }, (elem, index) => {
    return (
      <span key={index}>{stars == index ? <AiOutlineStar /> : <FaStar />}</span>
    );
  });

  return (
    <div className="star-rating">
      <ul>{ratingStar}</ul>
    </div>
  );
};

export default starratings;
