import React from "react";
import Faq from "react-faq-component";
import conqt_home_phone from "../../assets/conqt_guidelines/conqt_home_phone.png";
import conqt_home from "../../assets/conqt_guidelines/conqt_home.png";
import conqt_home_register from "../../assets/conqt_guidelines/conqt_home_register.png";
import home_page from "../../assets/conqt_guidelines/home_page.png";
import iamvendor from "../../assets/conqt_guidelines/iamvendor.png";
import iamvendorpc from "../../assets/conqt_guidelines/iamvendorpc.png";
import registerform from "../../assets/conqt_guidelines/registerform.png";
import registerpc from "../../assets/conqt_guidelines/registerpc.png";
import login from "../../assets/conqt_guidelines/login.png";
import loginpc from "../../assets/conqt_guidelines/loginpc.png";
import dashboard from "../../assets/conqt_guidelines/dashboard.png";
import dashboardpc from "../../assets/conqt_guidelines/dashboardpc.png";
import "./steps.css";

const data = {
  title: "How to become a partner ?",
  rows: [
    {
      title: (
        <div className="step-heading">
          <h4>Step 1: Home Page</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={home_page} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={conqt_home} alt="" />
          </span>

          <div className="steps-para">
            <p>Visit to 'www.conqt.com' and click on the register button. </p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 2: Registration</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={conqt_home_phone} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={conqt_home_register} alt="" />
          </span>

          <div className="steps-para">
            <p> 1. Click on the Register button</p>
            <p> 2. If already Register, click on Login</p>
            <p>
              {" "}
              3. Once you click on the register button, you will redirect to a
              selection page.{" "}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 3: I am a Partner</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={iamvendor} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={iamvendorpc} alt="" />
          </span>

          <div className="steps-para">
            <p>
              Now you must select yourself as a Partner and move ahead for
              further process.{" "}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 4: Registration Form</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <div className="formimage">
              <img src={registerform} alt="On Phone" />
            </div>
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={registerpc} alt="On Phone" />
          </span>

          <div className="steps-para">
            <p>1. Fill all the details on the registration form,</p>
            <p>2. select the check box</p>
            <p>3. and click on the Register Button.</p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 5: Login</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={login} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={loginpc} alt="" />
          </span>

          <div className="steps-para">
            <p>1. After completing the Registration form, Select Partner. </p>
            <p>2. Fill the Username</p>
            <p>3. Type the Password</p>
            <p>4. Click on Login</p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 6: Partner Dashboard</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={dashboard} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={dashboardpc} alt="" />
          </span>

          <div className="steps-para">
            <p>
              After completing the Registration and Login process you will
              redirect to the dashboard, Now you can apply the projects and view
              the projects.
            </p>
          </div>
        </div>
      ),
    },
  ],
};

export default function faq() {
  return (
    <div>
      <Faq data={data} />
    </div>
  );
}
