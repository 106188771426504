import React, { useState, useEffect } from "react";
import "../../Vendor/vendorProfile/page.css";
import DragDrop from "./dragandrop";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { BsInfoCircle } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { FcCameraIdentification } from "react-icons/fc";
import "react-phone-number-input/style.css";
import { } from "react-circular-progressbar";
import { BaseUrl } from "../../../Api/api";
import Viewer from "react-viewer";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader";

const CompanyProfile = () => {
        const [data, setData] = useState([]);
        const [clickImage, setClickImage] = useState(null);
        const [currentIndex, setCurrentIndex] = useState(null);
        const [pageLoading, setPageLoading] = useState(false);
        const [showBar, setShowBar] = useState("0");
        const [visible, setVisible] = useState(false);
        const [activeImageUrl, setActiveImageUrl] = useState("");

        const userToken = localStorage.getItem("token");
        const userinfo = JSON.parse(localStorage.getItem("userInfo"));
        console.log(userinfo.user_info, "userdetails");
        let navigate = useNavigate();
        useEffect(() => {
                setPageLoading(true);
                try {
                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${userToken}`);
                        myHeaders.append("Content-Type", "application/json");

                        var requestOptions = {
                                method: "GET",
                                headers: myHeaders,
                                redirect: "follow",
                        };

                        fetch(`${BaseUrl}/user/Get-User-Agency-Profile`, requestOptions)
                                .then((response) => response.json())
                                .then((result) => {
                                        console.log("result", result.data);

                                        if (result.data.agencyData.length) {
                                                setData(result.data.agencyData);
                                                setPageLoading(false)
                                                console.log("data", data);
                                        } else {
                                                navigate("/company-profile/edit");
                                        }
                                        localStorage.setItem(
                                                "profile_complete_status",
                                                result.data.profile_complete_status
                                        );
                                })
                                .catch((error) => console.log("error", error));
                } catch (err) {
                        console.log(err);
                }
        }, []);

        const handleImageClick = (item, index) => {
                setCurrentIndex(index);
                setClickImage(item.link);
        };

        const handleNext = (e) => {
                window.location = "/customer-documents";
        };

        const handleSkip = (e) => {
                window.location = "/customer-documents";
        };

        return (
                <>
                        {pageLoading && (
                                <PageLoader />
                        )}
                        <div className="dashboard-container">
                                <Sidebar />
                                <div className="main-dashboard-container">
                                        <Topbar
                                                isShow={true}
                                                // value={
                                                //   data.length && data[0].profile_complete_status
                                                //     ? data[0].profile_complete_status
                                                //     : 0}
                                                value={localStorage.getItem("profile_complete_status")}
                                        />
                                        <main className="maincontent">
                                                <div>
                                                        <section>
                                                                <div className="basic-info">
                                                                        <h3>Agency Profile</h3>
                                                                        <Link to="/company-profile/edit">
                                                                                <h4>
                                                                                        Edit
                                                                                        <MdModeEdit />
                                                                                </h4>
                                                                        </Link>
                                                                </div>
                                                        </section>
                                                        {data.map((item) => {
                                                                return (
                                                                        <div className="profileform" key={item}>
                                                                                <section>
                                                                                        <div className="profile-info">
                                                                                                <h3>
                                                                                                        Company Logo<span className="star">*</span>
                                                                                                </h3>
                                                                                        </div>
                                                                                        <div className="division">
                                                                                                <div className="upload-file">
                                                                                                        <div className="profilepicupload">
                                                                                                                <img src={item.logo_url} alt="" />
                                                                                                                {/*<FcCameraIdentification />
                          {/* <input
                            type="file"
                            name="myfile"
                            onChange={handleChange}
                          /> */}
                                                                                                        </div>
                                                                                                        {/*<p className="profilepicdes">
                          300 x 300px recommended. JPGs, JPEGs, and PNGs
                          supported.
                        </p>*/}
                                                                                                </div>
                                                                                                {/*<div className="progressbar">
                        <div className="bar-profile">
                          <h4>Completion Progress</h4>
                           <img src={barr} alt="" /> */}
                                                                                                {/* <div className="circle-container">
                            <CircularProgressbar
                              value={showBar}
                              text={showBar + "%"}
                            />
                            <p>Please Complete Your Profile</p>
                          </div>
                        </div>
                      </div> */}
                                                                                        </div>
                                                                                        {/* <p className="para-profile2">
                  100 x 100px recommended. JPGs, JPEGs, and PNGs supported.
                </p> */}
                                                                                </section>

                                                                                <section className="pro-info">
                                                                                        <div className="proformfield">
                                                                                                <label>
                                                                                                        Company Name <span className="star">*</span>
                                                                                                </label>
                                                                                                <p className="profields">
                                                                                                        {userinfo.user_info?.company_name || item.agency_name}
                                                                                                </p>
                                                                                                <div className="protip">
                                                                                                        <BsInfoCircle />
                                                                                                        <p>Set at once and can’t be change</p>
                                                                                                </div>
                                                                                        </div>

                                                                                        <div className="proformfield">
                                                                                                <label>
                                                                                                        Company Email <span className="star">*</span>
                                                                                                </label>
                                                                                                <p className="profields">{item.agency_email}</p>
                                                                                                <div className="protip">
                                                                                                        <BsInfoCircle />
                                                                                                        <p>Set at once and can’t be change</p>
                                                                                                </div>
                                                                                        </div>
                                                                                </section>

                                                                                <section className="details-info">
                                                                                        <div className="details-about">
                                                                                                <label>
                                                                                                        About Company<span className="star">*</span>
                                                                                                </label>
                                                                                                <p className="profields">{item.about_agency}</p>
                                                                                                <div className="profile-info svg"></div>
                                                                                        </div>

                                                                                        <div className="proformfield">
                                                                                                <label>
                                                                                                        Company Type
                                                                                                        <span className="star">*</span>
                                                                                                </label>

                                                                                                <p className="profields">{item.agency_type}</p>
                                                                                                <div className="profile-info svg"></div>
                                                                                        </div>

                                                                                        <label>
                                                                                                Contact Number<span className="star">*</span>
                                                                                        </label>
                                                                                        <p className="profields">{item.agency_phone_no}</p>
                                                                                        <div className="profile-info svg"></div>

                                                                                        <section className="pro-info">
                                                                                                <div className="proformfield">
                                                                                                        <div className="profile-address">
                                                                                                                <label>
                                                                                                                        Address<span className="star">*</span>
                                                                                                                </label>
                                                                                                                <p className="profields">
                                                                                                                        {item?.agency_address || userinfo.user_info?.address}
                                                                                                                </p>
                                                                                                        </div>
                                                                                                </div>
                                                                                                <div className="first-last-names">
                                                                                                        <div className="proformfield">
                                                                                                                <label>
                                                                                                                        Country <span className="star">*</span>
                                                                                                                </label>
                                                                                                                <p className="profields">
                                                                                                                        {item.agency_country || userinfo.user_info?.country}
                                                                                                                </p>
                                                                                                        </div>
                                                                                                        <div className="proformfield profile-width">
                                                                                                                <label>
                                                                                                                        City <span className="star">*</span>
                                                                                                                </label>
                                                                                                                <p className="profields">
                                                                                                                        {item.agency_city || userinfo.user_info?.city}
                                                                                                                </p>
                                                                                                        </div>
                                                                                                </div>
                                                                                                <div className="first-last-names">
                                                                                                        <div className="proformfield">
                                                                                                                <label>
                                                                                                                        State / Province <span className="star">*</span>
                                                                                                                </label>
                                                                                                                <p className="profields">
                                                                                                                        {item.agency_state ||
                                                                                                                                userinfo.user_info?.state ||
                                                                                                                                "State/Province"}
                                                                                                                </p>
                                                                                                        </div>
                                                                                                        <div className="proformfield profile-width">
                                                                                                                <label>
                                                                                                                        Zip Code <span className="star">*</span>
                                                                                                                </label>
                                                                                                                <p className="profields">
                                                                                                                        {item.agency_zipcode || userinfo.user_info?.zipcode}
                                                                                                                </p>
                                                                                                        </div>
                                                                                                </div>
                                                                                        </section>

                                                                                        <div className="proformfield">
                                                                                                <label>Website Link</label>
                                                                                                <p className="profields">{item.website_link}</p>

                                                                                                <div className="profile-info svg"></div>

                                                                                                <label>Number of Employee</label>
                                                                                                <p className="profields">{item.no_of_employee}</p>
                                                                                                <div className="profile-info svg"></div>
                                                                                        </div>

                                                                                        <div className="proformfield">
                                                                                                <label>Social Proof</label>

                                                                                                <div className="details-social">
                                                                                                        <span>
                                                                                                                <div className="checkboxlist">
                                                                                                                        {/* <input
                              type="checkbox"
                              id="linkendin"
                              name="linkendinlink"
                              value="linkendin"
                            /> */}
                                                                                                                        <label>Linkedin</label>
                                                                                                                </div>

                                                                                                                {/* <input 
                                                     type="checkbox"/> */}
                                                                                                                <p className="profields">
                                                                                                                        {item.linkedin_url == ""
                                                                                                                                ? "LinkedIn Page Link"
                                                                                                                                : item.linkedin_url}
                                                                                                                </p>
                                                                                                        </span>

                                                                                                        <span>
                                                                                                                <div className="checkboxlist">
                                                                                                                        {/* <input
                              type="checkbox"
                              id="linkendin"
                              name="linkendinlink"
                              value="linkendin"
                            /> */}
                                                                                                                        <label>Twitter</label>
                                                                                                                </div>

                                                                                                                {/* <input 
                                                     type="checkbox"/> */}
                                                                                                                <p className="profields">
                                                                                                                        {/* {item.twitter_url} */}
                                                                                                                        {item.twitter_url == ""
                                                                                                                                ? "Twitter Profile Link"
                                                                                                                                : item.twitter_url}
                                                                                                                </p>
                                                                                                        </span>
                                                                                                </div>

                                                                                                <div className="details-social">
                                                                                                        <span>
                                                                                                                <div className="checkboxlist">
                                                                                                                        {/* <input
                              type="checkbox"
                              id="linkendin"
                              name="linkendinlink"
                              value="linkendin"
                            /> */}
                                                                                                                        <label>Instagram</label>
                                                                                                                </div>

                                                                                                                {/* <input 
                                                     type="checkbox"/> */}
                                                                                                                <p className="profields">
                                                                                                                        {item.instagram_url == ""
                                                                                                                                ? "Instagram Profile Link"
                                                                                                                                : item.instagram_url}
                                                                                                                </p>
                                                                                                        </span>

                                                                                                        <span>
                                                                                                                <div className="checkboxlist">
                                                                                                                        {/* <input
                              type="checkbox"
                              id="linkendin"
                              name="linkendinlink"
                              value="linkendin"
                            /> */}
                                                                                                                        <label>Facebook</label>
                                                                                                                </div>

                                                                                                                {/* <input 
                                                     type="checkbox"/> */}
                                                                                                                <p className="profields">
                                                                                                                        {item.facebook_url == ""
                                                                                                                                ? "Facebook Page Link"
                                                                                                                                : item.facebook_url}
                                                                                                                </p>
                                                                                                        </span>
                                                                                                </div>

                                                                                                <div className="profile-info svg"></div>

                                                                                                {/* <div className="galleryList">
                        {item.galleryList.map((key, count) => {
                          return (
                            <img
                              src={key.img_url}
                              alt={key.img_name}
                              onClick={() => {
                                setVisible(true);
                                setActiveImageUrl(key.img_url);
                              }}
                              // onClick={() => handleImageClick(item, index)}
                            />
                          );
                        })}
                      </div>
                      <Viewer
                        visible={visible}
                        onClose={() => {
                          setVisible(false);
                        }}
                        zoomSpeed={0.2}
                        images={[{ src: activeImageUrl, alt: activeImageUrl }]}
                      /> */}
                                                                                        </div>
                                                                                </section>

                                                                                {/* <div className="gallery">
                <GrFormUpload />
                <input
                  type="file"
                  placeholder="Upload to see preview"
                  {...register("gallery", {
                    required: false,
                  })}
                />
                
              </div> */}
                                                                                <div className="formbuton">

                                                                                        <input
                                                                                                type="submit"
                                                                                                className="save-button"
                                                                                                value="Skip"
                                                                                                onClick={handleSkip}
                                                                                        />

                                                                                        <input
                                                                                                type="submit"
                                                                                                className="save-button"
                                                                                                value="Next"
                                                                                                onClick={handleNext}
                                                                                        />
                                                                                </div>
                                                                        </div>
                                                                );
                                                        })}
                                                </div>
                                        </main>
                                </div>
                        </div>
                </>
        );
};
export default CompanyProfile;
