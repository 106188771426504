import React from "react";
import "./Project.css";
import Search from "./Search.js";
import Projectlist from "./Project-list";
import Pagination from "./Pagination";
import Layout from "../../components/Layout";

const Project = () => {
  return (
    <Layout>
      <div>
        <Projectlist />
      </div>
    </Layout>
  );
};

export default Project;
