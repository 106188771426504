import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import logo from "../../assets/images/conqt-logo.png";
import "./Customer.css";
import "./Receipt.css";
import { Link, useParams } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";
import { useSelector } from "react-redux";
import Pdf from "react-to-pdf";
import DataTable from "react-data-table-component";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import { Loader } from "rsuite";
import PageLoader from './../../components/PageLoader/PageLoader';

const Receipt = () => {
  const ref = React.createRef();
  const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
  const userData = JSON.parse(localStorage.getItem("user"));
  const id = useParams().milestone_id;
  const p_id = useParams().proposal_id;
  const userToken = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const priceRegex = /\d{1,3}(?:[.,]\d{3})*(?:[.,]\d{2})/g;
  const ind = useParams().index;

  useEffect(() => {
    setPageLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        milestone_id: id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Get-Customer-Milestone-invoice`,
        requestOptions
      )
        .then((response) => response.json())
        // .then((result) => console.log("2", result))
        .then((result) => {
          setPageLoading(false);
          console.log("proposal", result.data);
          setData(result.data);
        })

        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  console.log(id);

  // console.log(user, "user  from local storage");
  // console.log(userData, "user data from local storage");
  let scaleSize = 0.75;
  let xaxis = 20;

  if (window.innerWidth > 1366 && window.innerWidth < 1300) {
    scaleSize = 0.75;
    xaxis = 20;
  } else if (window.innerWidth < 2000 && window.innerWidth > 1566) {
    scaleSize = 0.45;
    xaxis = 10;
  } else if (window.innerWidth > 1290 && window.innerWidth < 1366) {
    scaleSize = 0.68;
    xaxis = 23;
  } else if (window.innerWidth > 1500 && window.innerWidth < 1560) {
    scaleSize = 0.63;
    xaxis = 7;
  } else {
    scaleSize = 0.63;
  }

  console.log(scaleSize, "scaleSize", xaxis, "xaxis");

  let date = data.created_at;
  // console.log(date && date.split(" ")[0], "date");

  let priceString = data.paid_amount;
  let priceNumber = parseFloat(priceString);
  let priceFormatted = priceNumber.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return (
    <>
    {pageLoading && (
       <PageLoader/>
      )}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          {/* <center> {pageLoading && <Loader size="lg" speed="normal" />}</center> */}
          <div className="receipt-container">
            <div className="receipt-header-div">
              <h3 className="receipt-header">Thanks for Payment</h3>
            </div>

            <p className="receipt-person-name">Hi {data.name},</p>
            <p className="receipt-delighted-msg">
              We are delighted that you have found something you like! As soon
              your Project is on its way, you will receive a payment
              confirmation from us by email
            </p>
            <div ref={ref}>
              <div className="cs-rs-main">
                <div className="cs-rs-header">
                  <img className="conqt-logo" src={logo}></img>
                  <p className="receipt-invoice cs-invoice-number">
                    Date :
                    <span className="receipt-invoice-number">
                      &nbsp; {date && date.split(" ")[0]}
                    </span>{" "}
                  </p>
                  <p className="receipt-invoice cs-invoice-number">
                    Invoice #:
                    <span className="receipt-invoice-number">
                      &nbsp; {data.milestone_id}
                    </span>{" "}
                  </p>
                  <p
                    className="receipt-invoice"
                    style={{ marginBottom: "10px" }}
                  >
                    Receipt ID #:
                    <span
                      className="receipt-invoice-number"
                      style={{ marginTop: "0px" }}
                    >
                      &nbsp; {data.transaction_id}
                    </span>
                  </p>{" "}
                </div>
                <div className="cs-billed-details-div">
                  <div className="cs-billed-details">
                    <p className="cs-receipt-header">Billed By</p>
                    <p className="receipt-invoice">ConQt Technology</p>
                    <p className="address">Pte 11 Collyer Quay #17-00,</p>
                    <p className="address"> The Arcade</p>
                    <p className="address">Singapore – 049317</p>
                    <p className="address">Phone: (+91) 941565-0637</p>
                    <p className="address">Lut NO: AD090422023238A</p>
                    <p className="address">IEC Code: AAECF8340F</p>
                  </div>
                  <div className="cs-billed-details">
                    <p className="cs-receipt-header">Billed To</p>
                    <p className="receipt-invoice">
                      {user.user_info.company_name}
                    </p>
                    <p className="address">
                      {user.user_info.address} , {user.user_info.state}
                    </p>
                    <p className="address">
                      {" "}
                      {user.user_info.zipcode} - {user.user_info.country}
                    </p>
                    <p className="address">
                      Phone: {user.user_info.phone_number}
                    </p>
                    {/* <p className="address">Lut NO: AD090422023238A</p>
                <p className="address">IEC Code: AAECF8340F</p> */}
                  </div>
                </div>
                <table className="cs-rs-table">
                  <tr className="cs-rs-table-header">
                    <th>S.No</th>
                    <th className="cs-rs-unit">Description</th>
                    <th>Unit</th>
                    <th className="cs-rs-total">Unit Price</th>
                    <th className="cs-rs-total">Total Price</th>
                  </tr>
                  <tr className="cs-rs-table-details">
                    <td className="cs-rs-desc">{data.count}</td>
                    <td className="cs-rs-count" style={{width:"40%"}}>
                      {data.project_description &&
                        parse(data.project_description)}
                    </td>
                    <td className="cs-rs-desc">{data.count}</td>
                    <td
                      className="cs-rs-total"
                      style={{
                        whiteSpace: "nowrap",
                        borderBottom: "0px",
                        borderTop: "0px",
                      }}
                    >
                      {" "}
                      {priceFormatted} {data.currency_code}
                    </td>
                    <td className="cs-rs-amt">
                      {" "}
                      {priceFormatted} {data.currency_code}
                    </td>
                  </tr>
                  {/* <tr className="cs-rs-table-details">
                    <td></td>
                    <td className="cs-rs-unit"></td>
                    <td></td>
                    <td className="cs-rs-total"></td>
                  </tr> */}
                  <tr className="cs-rs-table-details">
                    <td style={{ borderTop: "0px" }}></td>
                    <td
                      style={{
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    ></td>
                    <td style={{ borderTop: "0px" }}></td>
                    <td style={{ borderTop: "0px", borderLeft: "0px" }}></td>
                    <td
                      style={{ borderTop: "0px" }}
                      className="cs-rs-total"
                    ></td>
                  </tr>
                  <tr className="cs-rs-table-footer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#304ffe", borderTop: "0px" }}
                      className="cs-rs-table-sub"
                    >
                      SubTotal
                    </td>
                    <td
                      className="cs-rs-table-sub cs-rs-total"
                      style={{ borderTop: "0px" }}
                    >
                      {" "}
                      {priceFormatted} {data.currency_code}
                    </td>
                  </tr>
                  <tr className="cs-rs-table-footer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#304ffe", borderTop: "0px" }}
                      className="cs-rs-table-sub"
                    >
                      Tax
                    </td>
                    <td
                      className="cs-rs-table-sub cs-rs-total"
                      style={{ borderTop: "0px" }}
                    >
                      0.00
                    </td>
                  </tr>
                  <tr className="cs-rs-table-footer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#304ffe", borderTop: "0px" }}
                      className="cs-rs-table-sub"
                    >
                      Total
                    </td>
                    <td
                      className="cs-rs-table-sub cs-rs-total"
                      style={{ borderTop: "0px", fontWeight: "700" }}
                    >
                      {" "}
                      {priceFormatted} {data.currency_code}
                    </td>
                  </tr>
                </table>

                <div className="ms-details-in-mobile-view">
                  <div className="ms-details">
                    <div className="cs-ms-details-in-mb">
                      <p className="cs-rs-details-header">Description</p>
                      <p className="cs-rs-details-value">
                        {" "}
                        {data.project_description &&
                          parse(data.project_description)}
                      </p>
                    </div>
                    <div className="cs-ms-details-in-mb">
                      <p className="cs-rs-details-header">Unit</p>
                      <p className="cs-rs-details-value">{data.count}</p>
                    </div>
                    <div className="cs-ms-details-in-mb">
                      <p className="cs-rs-details-header cs-ms-desc-header">
                        Unit Price
                      </p>
                      <p className="cs-rs-details-value cs-ms-desc-value">
                        {priceFormatted} {data.currency_code}
                      </p>
                    </div>
                    <div className="cs-ms-details-in-mb">
                      <p className="cs-rs-details-header">Total Price</p>
                      <p className="cs-rs-details-value">
                        {priceFormatted} {data.currency_code}
                      </p>
                    </div>
                    <div className="cs-ms-details-in-mb">
                      <p className="cs-rs-details-header">Sub Total</p>
                      <p className="cs-rs-details-value">
                        {priceFormatted} {data.currency_code}
                      </p>
                    </div>
                    <div className="cs-ms-details-in-mb">
                      <p className="cs-rs-details-header">Tax</p>
                      <p className="cs-rs-details-value">0.00</p>
                    </div>
                    <div className="cs-ms-details-in-mb">
                      <p className="cs-rs-details-header">Total</p>
                      <p
                        className="cs-rs-details-value"
                        style={{ fontWeight: "600" }}
                      >
                        {priceFormatted} {data.currency_code}
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <h5 className="cs-rs-bank-header">Bank Details</h5>
                  <p className="cs-rs-bank-details">
                    Account Name: Fyoocher Private Limited
                  </p>
                  <p className="cs-rs-bank-details">Bank Name: HDFC Bank</p>
                  <p className="cs-rs-bank-details">
                    Account No.: 50200061928274
                  </p>
                  <p className="cs-rs-bank-details">Bank Code: 2624</p>
                  <p className="cs-rs-bank-details">Swift Code: HDFCINBB</p>
                  <p className="cs-rs-bank-details">IFSC Code: HDFC0002624</p>
                  <p className="cs-rs-bank-details">Bank Branch: Mahoba</p>
                </div> */}
                <div className="cs-rs-terms-div">
                  <h5 className="cs-rs-bank-terms">Terms and Conditions</h5>
                  <ol className="cs-rs-bank-details">
                    <li>
                      {" "}
                      {" "}1. Please pay within 15 days from the date of invoice,
                      overdue interest @18% will be charged on delayed payments
                    </li>
                    <li>
                      {" "}
                      2. Please quote proforma invoice number wheile requesting
                      for the final tax invoice.
                    </li>
                    <li>
                      {" "}
                      3. This invoice is raised for the efforts of the resources
                      outsourced for the month of November 2022.
                    </li>
                    <li>
                      {" "}
                      4. Computer generated invoice, therefore not require
                      signature.
                    </li>
                  </ol>
                </div>
                {/* <div className="cs-billed-details-div">
                  <div>
                    <hr className="cs-rs-hr"></hr>
                    <p className="cs-rs-bank-details">Authorized Signature </p>
                    <p className="cs-rs-bank-details">(ConQT)</p>
                  </div>

                  <div className="cs-rs-received">
                    <hr className="cs-rs-hr"></hr>
                    <p className="cs-rs-bank-details">Received by </p>
                    <p className="cs-rs-bank-details">Name :</p>
                    <p className="cs-rs-bank-details">Company Stamp</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="receipt-btn-div">
            <Pdf
              targetRef={ref}
              filename={"ConQt-Invoice-" + data.milestone_id}
              x={xaxis}
              y={1.5}
              scale={scaleSize}
            >
              {({ toPdf }) => (
                <button className="receipt-print-btn" onClick={toPdf}>
                  Download & Print
                </button>
              )}
            </Pdf>
            <Link to={`/customer-milestone-list`}>
              <button className="receipt-back-btn"> Back</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Receipt;
