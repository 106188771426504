import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import "../Customer/Customer.css";
import "react-phone-number-input/style.css";
import MyProfile from "./vendorProfile/MyProfile";
import PageLoader from "../../components/PageLoader/PageLoader";

const Profile = () => {
  const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
  const [pageLoading, setPageLoading] = useState(false);
  const [value, setValue] = useState();
  const [formValues, setFormValues] = useState();
  const [statusBarvalue,setStatusBar] = useState(0);
  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
const updatePageloaderState = (state) => {
  setPageLoading(state)
}
  //console.log(user.user_info);
  return (
    <>
    {pageLoading && (
       <PageLoader/>
      )}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar isShow = {true} value={statusBarvalue} />
          <main className="maincontent">
            <MyProfile  updatePageloaderState={updatePageloaderState} onChangeStatusValue={(value)=>{
                  setStatusBar(value)
                 
            }}/>
          </main>

          {/* <section className="project-list">
            <div className="profile-card">
              <div className="procardhead">
                <div>
                  <img className="contact-icon" src={img2} alt="logo" />
                </div>
                <div>
                  <h2>COMPANY</h2>
                  <h3>INFORMATION</h3>
                </div>
              </div>
              <div className="">
                <div className="procarddetails">
                  <div className="card-information">
                    <h5>Company Name</h5>
                    <p>{user.user_info.company_name}</p>
                  </div>
                  <div className="card-information">
                    <h5>type of solutions</h5>
                    <p>{user.user_info.solutions}</p>
                  </div>

                  <div className="card-information">
                    <h5>Account Status</h5>
                    {user.user_info.activation_status === 0 ? (
                      <p>
                        <span className="status-indicator status-indicator--inactive"></span>
                        InActive
                      </p>
                    ) : (
                      <p>
                        <span className="status-indicator status-indicator--active"></span>
                        Active
                      </p>
                    )}
                  </div>
                </div>
                <div className="procarddetails-2">
                  <div className="card-information">
                    <h5>Address</h5>
                    <p>{user.user_info.address}</p>
                  </div>
                  <div className="card-information">
                    <h5>Location</h5>
                    <p>
                      {user.user_info.city} {user.user_info.country}
                      {user.user_info.zipcode}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="project-list">
            <div className="profile-card">
              <div className="procardhead">
                <div>
                  <img className="contact-icon" src={img3} alt="logo" />
                </div>
                <div>
                  <h2>CONTACT</h2>
                  <h3>INFORMATION</h3>
                </div>
              </div>
              <div className="procarddetails">
                <div className="card-information">
                  <h5>Phone NO.</h5>
                  <p>{user.user_info.phone_no}</p>
                </div>
                <div className="card-information">
                  <h5>Email</h5>
                  <p>{user.user_info.user_name}</p>
                </div>
              </div>
            </div>
          </section>

          <section className="project-list">
            <div className="profile-card">
              <div className="procardhead">
                <div>
                  <img className="contact-icon" src={img1} alt="logo" />
                </div>
                <div>
                  <h2>CONTACT PERSON</h2>
                  <h3>INFORMATION</h3>
                </div>
              </div>
              <div className="procarddetails">
                <div className="card-information">
                  <h5>Name</h5>
                  <p></p>
                </div>
                <div className="card-information">
                  <h5>Phone NO.</h5>
                  <p></p>
                </div>
                <div className="card-information">
                  <h5>Email</h5>
                  <p></p>
                </div>
              </div>
            </div>
          </section> */}
        </div>
      </div>
    </>
  );
};

export default Profile;
