import React, { useState, useEffect } from "react";
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
import "./bcds.css";
import suc from "../../../assets/images/suc.svg";
import { useNavigate } from "react-router";


const SuccessModal = ({
        open,
        handleClose,
        announcement_id,
}) => {

        const [delay, setDelay] = useState(200);
        const [close, setClose] = useState(handleClose);
        const navigate = useNavigate();
        console.log("announcement_id for modal", announcement_id);

        const handleOpp = () => {
                navigate(`/new-buy-customer-details-page/${announcement_id}`);
                setClose(handleClose(false))
        }

        return (
                <>
                        <Modal open={open} handleClose={handleClose}
                                style={{ paddingTop: "40px", }}>
                                <div className="rs-m">
                                        <Modal.Body >
                                                <div className="d-flex align-items-center justify-content-center flex-column">
                                                        <div className="suc-bg">
                                                                <div className="d-flex align-items-center justify-content-center flex-column">
                                                                        <h3 className="cong">Congratulations!</h3>
                                                                        <img src={suc} alt="" style={{ width: "100px" }} />
                                                                </div>
                                                        </div>
                                                        <div className="suc-btm-bg">
                                                                <div className="d-flex align-items-center justify-content-center flex-column">
                                                                        <div className="text-suc">
                                                                                You have successfully purchased <strong>POS Software Project </strong>
                                                                                Opportunity
                                                                        </div>
                                                                        <button className="view-opp mt-2 px-4"
                                                                                onClick={handleOpp}
                                                                        >
                                                                                <h3 className="view-opp-text">VIEW Opportunity</h3>
                                                                        </button>
                                                                </div>

                                                        </div>
                                                </div>
                                        </Modal.Body>
                                </div>
                        </Modal>
                </>
        );
};

export default SuccessModal;
