import React, { useEffect, useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductListTableData,
  UpdateSelectedProductId,
  updateSelectedSingleProductData,
} from "../../../Store/Reducers/VendorProductListReducer";
import { transformProductData } from "../../../Utils/CustomerProjectList";
import "./VendorProductList.css";
import deleteIcon from "../../../assets/images/delete.png";
import greenTick from "../../../assets/images/green-tick.png";
import whitePlus from "../../../assets/images/white-plus.png";
import editIcon from "../../../assets/images/edit-icon.png";
import ProductDelete from "../../../components/Modal/ProductDeleteModal/ProductDelete";
// import AddNewProducts from "../AddNewProducts/AddNewProducts";
// import VendorEditProduct from "../EditProduct/VendorEditProduct";

const VendorProductListTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productList, loading, selectedProductId } = useSelector(
    (state) => state.productList
  );
  // console.log(productList, "productList");
  // console.log(selectedProductId, "selectedProductId");
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [open, setOpen] = useState(false);
  const [singleProductData, setSingleProductData] = useState({});
  const columns = [
    {
      id: "Name",
      name: "Name",
      selector: (result) => <div>{result.product_name} </div>,
      width: "20%",
    },
    {
      id: "BrandName",
      name: "Brand Name",
      selector: (result) => <div>{result.company_name} </div>,
      width: "15%",
    },
    {
      id: "verified",
      name: "Status",
      selector: (result) => (
        <div>
          {result.product_status == 0 ? (
            <span style={{ color: "orange" }}> Under Review</span>
          ) : result.product_status == 1 ? (
            <div className="d-flex">
              {result.verified == 1 && (
                <img
                  src={greenTick}
                  style={{ width: "20px", height: "20px" }}
                  alt=""
                />
              )}
              <span style={{ color: "#304ffe" }}> Approved</span>
            </div>
          ) : (
            <span style={{ color: "red" }}> Rejected</span>
          )}
        </div>
      ),
      width: "10%",
    },
    {
      id: "Category",
      name: "Category",
      selector: (result) => <div>{result.category} </div>,
      width: "10%",
    },
    {
      id: "Added On",
      name: "Added On",
      selector: (result) => <div>{result.added_on} </div>,
      width: "15%",
    },
    {
      id: "Price",
      name: "Price",
      selector: (result) => <div>{result.price_currency + result.price} </div>,
      width: "10%",
    },
    {
      id: "Price Type",
      name: "Price Type",
      selector: (result) => <div>{result.is_price} </div>,
      width: "10%",
    },
    {
      id: "Edit",
      selector: (result) => (
        <div style={{ width: "50px" }}>
          <button
            onClick={() => onEditClicked(result)}
            style={{ padding: "0px" }}
          >
            <img src={editIcon} alt="" />
          </button>
        </div>
      ),
      width: "4%",
    },
    {
      id: "Delete",
      selector: (result) => (
        <div style={{ width: "50px" }}>
          {" "}
          <button
            onClick={() => onClickDelete(result)}
            style={{ padding: "0px" }}
          >
            <img src={deleteIcon} alt="" />
          </button>
        </div>
      ),
      // width: "5%",
    },
  ];
  const columnsInMobileView = [
    {
      id: "Name",
      name: "Name",
      selector: (result) => <div>{result.product_name} </div>,
      width: "45%",
    },
    {
      id: "verified",
      name: "Status",
      selector: (result) => (
        <div>
          {result.product_status == 0 ? (
            <span style={{ color: "orange" }}> Under Review</span>
          ) : result.product_status == 1 ? (
            <div className="d-flex">
              {result.verified == 1 && (
                <img
                  src={greenTick}
                  style={{ width: "20px", height: "20px" }}
                  alt=""
                />
              )}
              <span style={{ color: "#304ffe" }}> Approved</span>
            </div>
          ) : (
            <span style={{ color: "red" }}> Rejected</span>
          )}
        </div>
      ),
      width: "24%",
    },
    {
      id: "Edit",
      selector: (result) => (
        <div style={{ width: "50px" }}>
          <button
            onClick={() => onEditClicked(result)}
            style={{ padding: "0px" }}
          >
            <img src={editIcon} alt="" />
          </button>
        </div>
      ),
      width: "16%",
    },
    {
      id: "Delete",
      selector: (result) => (
        <div style={{ width: "50px" }}>
          {" "}
          <button
            onClick={() => onClickDelete(result)}
            style={{ padding: "0px" }}
          >
            <img src={deleteIcon} alt="" />
          </button>
        </div>
      ),
      width: "15%",
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#304FFE",
        color: "white",
        fontSize: "14px",
        width: "150px",
        fontWeight: "400",
        fontfamily: "Poppins",
        overflowWrap: "break-word",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "14px",
        color: "black",
        fontWeight: "400",
        letterSpacing: "0.5px",
        whiteSpace: "nowrap",
        marginTop: "10px",
        marginBottom: "10px",
        fontfamily: "Poppins",
        overflowWrap: "break-word",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };

  const customStylesInMobileView = {
    headCells: {
      style: {
        backgroundColor: "#304FFE",
        color: "white",
        fontSize: "10px",
        width: "100%",
        fontWeight: "400",
        fontfamily: "Poppins",
        overflowWrap: "break-word",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "12px",
        color: "black",
        fontWeight: "400",
        letterSpacing: "0.5px",
        whiteSpace: "nowrap",
        marginTop: "10px",
        marginBottom: "10px",
        fontfamily: "Poppins",
        overflowWrap: "break-word",
      },
    },
    cells: {
      style: {
        fontSize: "12px",
      },
    },
  };

  const onClickAddNew = () => {
    navigate("/add-new-products");
  };
  const onClickDelete = (row) => {
    // console.log("onClickDelete", row);
    // setShowSuccessPopup(true);
    setSingleProductData(row);
    setOpen(!open);
  };
  const onEditClicked = (row) => {
    dispatch(updateSelectedSingleProductData(row));
    navigate("/edit-product");
  };

  const result = transformProductData(productList);
  const results =
    result &&
    result.filter(
      (item) =>
        (item.product_name &&
          item.product_name.toLowerCase().includes(filterText.toLowerCase())) ||
        item.company_name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.category.toLowerCase().includes(filterText.toLowerCase()) ||
        item.is_price.toLowerCase().includes(filterText.toLowerCase())
    );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <>
        <div className="table-header-product">
          <div className="">
            <p className="product-list-table-head">Product List</p>
          </div>
          <div className="product-table-filter-div">
            <input
              id="search"
              type="text"
              className={
                filterText.length != 0
                  ? "product-filter"
                  : "product-filter-with-search"
              }
              placeholder={"       Search Category"}
              aria-label="Search Input"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
            />
            <button className="add-btn-product-list" onClick={onClickAddNew}>
              {" "}
              <img
                style={{ width: "12px", height: "12px" }}
                src={whitePlus}
                alt=""
              />
              Add New Product
            </button>
          </div>
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle]);
  useEffect(() => {
    dispatch(getProductListTableData());
  }, []);

  return (
    <>
      <div className="vendor-product-table">
        <DataTable
          columns={columns}
          data={results}
          customStyles={customStyles}
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          pagination={results && results.length > 10}
          paginationRowsPerPageOptions={[5, 10]}
          customer-proposal-table
          onRowClicked={onEditClicked}
        />
      </div>
      <div className="vendor-product-table-in-mobile-view">
        <DataTable
          columns={columnsInMobileView}
          data={results}
          customStyles={customStylesInMobileView}
          paginationResetDefaultPage={resetPaginationToggle}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          pagination={results && results.length > 10}
          paginationRowsPerPageOptions={[5, 10]}
          onRowClicked={onEditClicked}
          fixedHeader
          fixedHeaderScrollHeight="420px"
        />
      </div>

      {/* {showSuccessPopup && ( */}
      <ProductDelete
        open={open}
        setOpen={setOpen}
        handleClose={() => {
          setOpen(!open);
        }}
        singleProductData={singleProductData}
      />
      {/* )} */}
    </>
  );
};

export default VendorProductListTable;
