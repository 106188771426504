import React from "react";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import VendorProductListTable from "./VendorProductListTable";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { useSelector } from "react-redux";

const VendorProductList = () => {
  const { loading } = useSelector((state) => state.productList);
  return (
    <>
      {loading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          <section className="add-new-product-main">
            <div className="add-product-border">
              <h3 className="add-product">Projects Status</h3>
            </div>
            <VendorProductListTable />
          </section>
        </div>
      </div>
    </>
  );
};

export default VendorProductList;
