import React, { useState, useEffect, useRef } from "react";
import Layout from "../../components/Layout";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
// import { useDropzone } from "react-dropzone";
import "../Products/AddProducts/addproduct.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BaseUrl } from "../../Api/api";

const EditProduct = () => {
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem("token");
  const id = useParams().product_id;
  const name = useParams().product_name;

  const initialValues = {
    product_name: "",
    category_id: "",
    product_description: "",
    product_information: "",
    features: "",
    specifications: "",
    compatibility: "",
    video_link: "",
    video_description: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const fileInput = useRef(null);

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    // var ImageData = JSON.stringify(e.target.files[0]);
    // console.log(ImageData, "Category Image");
    // localStorage.setItem("CategoryImage", ImageData);
    console.log(e.target.files[0]);
  };

  const handleChangeText = (e) => {
    console.log("handleChangeText", e);
    setFormValues((prevState) => ({
      ...prevState,
      ["product_description"]: e,
    }));
  };

  const handleChangeProductInformation = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["product_information"]: e,
    }));
  };

  const handleChangeFeatures = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["features"]: e,
    }));
  };

  const handleChangeSpecifications = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["specifications"]: e,
    }));
  };

  const handleChangeCompatibility = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["compatibility"]: e,
    }));
  };

  // const handleChangeVideolink = (e) => {
  //   setFormValues((prevState) => ({
  //     ...prevState,
  //     ["video_link"]: e,
  //   }));
  // };

  const handleChangeVideoDescription = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["video_description"]: e,
    }));
  };

  console.log(formValues);

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}/product/Get-Product/${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let finalresult = JSON.parse(result);
        console.log("results", finalresult.data);
        setData(finalresult.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    //get category

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}/product/Get-Parent-Category`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let finalresult = JSON.parse(result);
        console.log("results", finalresult.data);
        setData(finalresult.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const handleSubmit = async (e) => {
    console.log(setSelectedFile, "select file");
    e.preventDefault();
    let error = validate(formValues);
    setFormErrors(validate(formValues));
    console.log(formValues);
    // if (Object.keys(error).length !== 0) {
    //   setFormErrors(error);
    //   console.log("Error Found");
    //   return false;
    // }
    // console.log("No Validation Error Found");
    setIsSubmit(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);

      var formdata = new FormData();

      formdata.append("image", selectedFile);
      formdata.append("product_name", formValues.product_name);
      formdata.append("category_id", formValues.category_id);
      formdata.append("product_description", formValues.product_description);
      formdata.append("product_information", formValues.product_information);
      formdata.append("features", formValues.features);
      formdata.append("specifications", formValues.specifications);
      formdata.append("compatibility", formValues.compatibility);
      formdata.append("video_link", formValues.video_link);
      formdata.append("video_description", formValues.video_description);

      for (var pair of formdata.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      // var formdata = JSON.stringify(formValues);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/product/${id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          if (result.status === 200) {
            setFormValues("");
            //alert(result.message);
            toast("Product Publish Successfully");
            window.location = "/vendor-dashboard";
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const uploadImg = (events) => {
    console.log(events.target.files);
  };

  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });

    console.log(errors);
    return errors;
  };

  return (
    <Layout>
      <div className="product-container">
        <div className="container">
          <div className="register-toggle">
            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="Project-form"
            >
              <div className="registerhead">
                <h1>Edit Products/Services for Marketplace</h1>
              </div>
              <div className="categorydetails">
                <h3>Select Category Name</h3>

                <select
                  name="category_id"
                  className="categorylist"
                  onChange={handleChange}
                  // value={formValues.category_type}
                >
                  <option value="">Select</option>
                  {data.map((item, announcement_id) => {
                    return (
                      <option value={item.category_id}>
                        {item.category_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form-group">
                <label>Products/Services Name</label>
                <input
                  type="text"
                  name="product_name"
                  value={formValues.product_name}
                  onChange={handleChange}
                />
              </div>
              <p className="err-message">{formErrors.product_name}</p>

              <div className="form-group">
                <label>Products/Services Image</label>
                <input
                  type="file"
                  // value={selectedFile}
                  onChange={handleFileInput}
                />
                {/* <section className="draganddrop">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                  </div>
                  <aside>
                    <h4>Files</h4>
                    <ul>{files}</ul>
                  </aside>
                </section> */}
              </div>
              <p className="videotip">Upload image Size - 500px x 550px</p>
              <p className="err-message"></p>

              <div className="form-group">
                <label>Products/Services Short Description</label>
                {/* <textarea
                  type="texteditor textarea"
                  name="product_description"
                  value={formValues.product_description}
                  onChange={handleChange}
                ></textarea> */}

                <CKEditor
                  type="texteditor textarea"
                  name="product_description"
                  placeholder="Description"
                  editor={ClassicEditor}
                  data="<p>Insert the Product Description Here.</p>"
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChangeText(data);
                    console.log({ event, editor, data });
                  }}
                  value={formValues.product_description}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <p className="err-message">{formErrors.product_description}</p>

              <div className="form-group">
                <label>Products/Services Long Description</label>
                {/* <textarea
                  type="texteditor textarea"
                  name="product_information"
                  value={formValues.product_information}
                  onChange={handleChange}
                ></textarea> */}
                <CKEditor
                  type="texteditor textarea"
                  name="product_information"
                  placeholder="Product Information"
                  editor={ClassicEditor}
                  data="<p>Insert the Product Information Here. Eg. What is this Products ?</p>"
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChangeProductInformation(data);
                    console.log({ event, editor, data });
                  }}
                  value={formValues.product_information}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <p className="err-message">{formErrors.product_information}</p>

              <div className="form-group">
                <label>Features</label>
                {/* <textarea
                  type="texteditor textarea"
                  name="features"
                  value={formValues.features}
                  onChange={handleChange}
                ></textarea> */}

                <CKEditor
                  type="texteditor textarea"
                  name="features"
                  placeholder="Insert the Product Features Here."
                  editor={ClassicEditor}
                  data="<p>Insert the Product Features Here.</p>"
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChangeFeatures(data);
                    console.log({ event, editor, data });
                  }}
                  value={formValues.features}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <p className="err-message">{formErrors.features}</p>
              <div className="form-group">
                <label>Specifications</label>
                {/* <textarea
                  type="texteditor textarea"
                  name="specifications"
                  value={formValues.specifications}
                  onChange={handleChange}
                ></textarea> */}
                <CKEditor
                  type="texteditor textarea"
                  name="specifications"
                  placeholder="Insert the Product Specifications Here."
                  editor={ClassicEditor}
                  data="<p>Insert the Product Specifications Here.</p>"
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChangeSpecifications(data);
                    console.log({ event, editor, data });
                  }}
                  value={formValues.specifications}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <p className="err-message">{formErrors.specifications}</p>
              <div className="form-group">
                <label>Compatibility</label>

                {/* <textarea
                  type="texteditor textarea"
                  name="compatibility"
                  value={formValues.compatibility}
                  onChange={handleChange}
                ></textarea> */}
                <CKEditor
                  type="texteditor textarea"
                  name="compatibility"
                  placeholder="Insert the Product compatibility Here."
                  editor={ClassicEditor}
                  data="<p>Insert the Product compatibility Here.</p>"
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChangeCompatibility(data);
                    console.log({ event, editor, data });
                  }}
                  value={formValues.compatibility}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
              <p className="err-message">{formErrors.compatibility}</p>
              <div className="form-group">
                <label>Video Details</label>
                {/* <textarea
                  type="texteditor textarea"
                  name="video_description"
                  value={formValues.video_description}
                  onChange={handleChange}
                ></textarea> */}
                <CKEditor
                  type="texteditor textarea"
                  name="video_description"
                  placeholder="Insert the Video Description Here."
                  editor={ClassicEditor}
                  data="<p>Insert the Video description Here.</p>"
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleChangeVideoDescription(data);
                    console.log({ event, editor, data });
                  }}
                  value={formValues.video_description}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />

                <label>Video Link</label>

                <input
                  type="text"
                  name="video_link"
                  value={formValues.video_link}
                  onChange={handleChange}
                  Placeholder="enter video url"
                />

                <p className="videotip">
                  Enter the Embed Video URL here. Eg.
                  https://www.youtube.com/embed/bu_0cX7gTOY. <br />
                  Go to Youtube, Click on share button, Select embed option and
                  copy the video link.
                </p>
              </div>
              <p className="err-message">
                {formErrors.video_description} {formErrors.video_link}
              </p>

              <button className="tog-reg-btn">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditProduct;
