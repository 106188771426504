// console.log(window.location.hostname, "Hostnames");

var BaseUrl = "https://api.conqt.com/api";
var RazorPaymentkey = "rzp_live_reXY4cDkDErPkr";
var LogoutUrl = "https://www.dev.conqt.com";
if (
  window.location.hostname == "localhost" ||
  window.location.hostname == "www.dev.conqt.com"
) {
  BaseUrl = "https://dev-api.conqt.com/api";
  RazorPaymentkey = "rzp_test_iUrCPa1I5y4zaL";
  if (window.location.hostname == "localhost") {
    LogoutUrl = "http://localhost:3002";
  } else {
    LogoutUrl = "https://dev2.conqt.com"
  }
} else {
  BaseUrl = "https://api.conqt.com/api";
  RazorPaymentkey = "rzp_live_reXY4cDkDErPkr";
  LogoutUrl = "https://www.conqt.com"

}

module.exports = {
  BaseUrl,
  RazorPaymentkey,
  LogoutUrl
};
