import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import "./Vendor.css";
import { Link, useParams } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const VendorViewMilestone = () => {
  const initialValues = {
    milestone_id: "",
    vendor_remark: "",
  };

  const dispatch = useDispatch();
  const userToken = localStorage.getItem("token");
  const ID = localStorage.getItem("announcement_id");
  const proposal_id = useParams().proposal_id;

  const [rejected, setRejected] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [data, setData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    const logintype = localStorage.getItem("logintype");
    if (logintype == 1) {
      errors["project_name"] =
        "You cannot create the Remark Due to Login type is Different";
    }
    console.log(errors);
    return errors;
  };

  const handleChangeText = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["vendor_remark"]: e,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = validate(formValues);
    if (Object.keys(error).length !== 0) {
      setFormErrors(error);
      console.log("Error Found");
      return false;
    }
    console.log("No Validation Error Found");
    // ... submit to API or something
    setIsSubmit(true);
    try {
      var myHeaders = new Headers();

      //myHeaders.append("Authorization", `Bearer ${userToken})`);
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(formValues);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendordashboard/Milestone-Update-Complete`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          if (result.status === 200) {
            setFormValues("");

            toast("Remark Sent successfully");
            window.location = `/vendor-milestone/${proposal_id}`;
            //dispatch(project({ result }));
            dispatch({ type: "ADD_REMARK", payload: result.data });
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendordashboard/Vendor-Final-Project-List`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          console.log("result", result.data);
          setData(result.data);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  console.log(data);

  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          {data.map((item) => {
            {
              return (
                <div className="timeline">
                  {item.milestonelist.map((key, index) => {
                    return (
                      <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                        <div className="timeline__event__icon">
                          <i className="lni-burger"></i>
                          <div className="timeline__event__date">
                            Project Milestone {index + 1}
                          </div>
                        </div>
                        <div className="timeline__event__content">
                          <div className="milestone">
                            <div>
                              <p>{key.milestone_description}</p>
                              <h3>
                                Invoice Amount - {key.vendor_currecy}{" "}
                                {key.milestone_amount}/-
                              </h3>
                            </div>
                            {/* <div>
                               <button className="btn btn-project">
                                <Link to="/invoice">Pay Now</Link>
                              </button>
                              <button className="btn btn-project">
                                <Link to="/invoice">Download Invoice</Link>
                              </button>
                            </div> */}
                            {(function (rows, status) {
                              if (status == 0) {
                                if (index == 0) {
                                  rows
                                    .push
                                    // <div className="upload-btn-wrapper">
                                    // <button className="btn-invoice">Upload a file</button>
                                    // <input type="file" name="myfile" />
                                    // </div>
                                    ();
                                } else if (index - 1 >= 0) {
                                  if (
                                    item.milestonelist[index - 1]
                                      .customer_status == 0 ||
                                    2
                                  ) {
                                    rows.push(
                                      <div>
                                        <button className="btn-signup" disabled>
                                          Mark as Complete
                                        </button>
                                      </div>
                                    );
                                  }
                                } else {
                                  rows.push(
                                    <div>
                                      <Popup
                                        trigger={
                                          <button className="btn-signup">
                                            Mark as Complete
                                          </button>
                                        }
                                        modal
                                        nested
                                      >
                                        {(close) => (
                                          <div className="popup">
                                            <div className="popup-content">
                                              <button
                                                className="close-btn"
                                                onClick={close}
                                              >
                                                &times;
                                              </button>
                                              <div>
                                                <h3 className="addproject">
                                                  Send Remark
                                                </h3>
                                                <form
                                                  onSubmit={handleSubmit}
                                                  noValidate
                                                  autoComplete="off"
                                                  className="Project-form"
                                                >
                                                  <div className="form-group">
                                                    <label>Remark</label>

                                                    <CKEditor
                                                      type="texteditor textarea"
                                                      name="project_description"
                                                      placeholder="Description"
                                                      editor={ClassicEditor}
                                                      onReady={(editor) => {
                                                        // You can store the "editor" and use when it is needed.
                                                      }}
                                                      onChange={(
                                                        event,
                                                        editor
                                                      ) => {
                                                        const data = editor.getData();
                                                        handleChangeText(data);
                                                      }}
                                                      value={
                                                        formValues.vendor_remark
                                                      }
                                                      onBlur={(
                                                        event,
                                                        editor
                                                      ) => {}}
                                                      onFocus={(
                                                        event,
                                                        editor
                                                      ) => {}}
                                                    />
                                                  </div>
                                                  <p className="err-message"></p>

                                                  <br />
                                                  <div className="form-group">
                                                    <button
                                                      onClick={() => {
                                                        formValues.milestone_id =
                                                          key.milestone_id;
                                                      }}
                                                      type="submit"
                                                      className="btn btn-primary"
                                                    >
                                                      <i className="far fa-paper-plane"></i>
                                                      Submit Remark
                                                    </button>
                                                  </div>
                                                </form>
                                              </div>

                                              <div className="popup-footer">
                                                <button
                                                  className="btn-signup"
                                                  onClick={() => {
                                                    setRejected(0);
                                                    close();
                                                  }}
                                                >
                                                  Close
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Popup>
                                    </div>
                                  );
                                }
                              } else if (status == 1) {
                                rows.push(
                                  <div>
                                    <button
                                      className="btn-signup"
                                      disabled
                                      style={{
                                        backgroundColor: "green",
                                        color: "white",
                                      }}
                                    >
                                      Approved
                                    </button>
                                  </div>
                                );
                              } else if (status == 2) {
                                rows.push(
                                  <div>
                                    <Popup
                                      trigger={
                                        <>
                                          <rs className="got-rejected">
                                            Rejected by Customer
                                          </rs>
                                          <button className="btn-signup">
                                            Resend
                                          </button>
                                        </>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="popup">
                                          <div className="popup-content">
                                            <button
                                              className="close-btn"
                                              onClick={close}
                                            >
                                              &times;
                                            </button>
                                            <div>
                                              <h3 className="addproject">
                                                Send Remark
                                              </h3>
                                              <form
                                                onSubmit={handleSubmit}
                                                noValidate
                                                autoComplete="off"
                                                className="Project-form"
                                              >
                                                <div className="form-group">
                                                  <label>Remark</label>

                                                  <CKEditor
                                                    type="texteditor textarea"
                                                    name="project_description"
                                                    placeholder="Description"
                                                    editor={ClassicEditor}
                                                    onReady={(editor) => {
                                                      // You can store the "editor" and use when it is needed.
                                                    }}
                                                    onChange={(
                                                      event,
                                                      editor
                                                    ) => {
                                                      const data = editor.getData();
                                                      handleChangeText(data);
                                                    }}
                                                    value={
                                                      formValues.vendor_remark
                                                    }
                                                    onBlur={(
                                                      event,
                                                      editor
                                                    ) => {}}
                                                    onFocus={(
                                                      event,
                                                      editor
                                                    ) => {}}
                                                  />
                                                </div>
                                                <p className="err-message"></p>

                                                <br />
                                                <div className="form-group">
                                                  <button
                                                    onClick={() => {
                                                      formValues.milestone_id =
                                                        key.milestone_id;
                                                    }}
                                                    type="submit"
                                                    className="btn btn-primary"
                                                  >
                                                    <i className="far fa-paper-plane"></i>
                                                    Submit Remark
                                                  </button>
                                                </div>
                                              </form>
                                            </div>

                                            <div className="popup-footer">
                                              <button
                                                className="btn-signup"
                                                onClick={() => {
                                                  setRejected(0);
                                                  close();
                                                }}
                                              >
                                                Close
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </div>
                                );
                              }

                              return rows;
                            })([], key.customer_status)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default VendorViewMilestone;
