import React, { useState, useEffect } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import "./Header.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, logout } from "../../Store/Reducers/userSlice.js";
import { FaUserCircle } from "react-icons/fa";

const Header = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const logintype = localStorage.getItem("logintype");

  const handleCustMessage = () => {
    toast.error("Partner is not eligible to Post Requirements");
  };

  const handleVendMessage = () => {
    toast.error("Customer is not eligible to Apply Projects");
  };

  const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
  const dispatch = useDispatch();
  // const [user, setLoginUser] = useState(useSelector(selectUser));
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   setLoginUser(localStorage.getItem("user"));
  // });

  // const updateUser = (user) => {
  //   localStorage.setItem("user", JSON(user));
  //   setLoginUser(user);
  // };

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout(user));
    localStorage.clear();
    window.location = "/";
  };

  return (
    <div className="navbar">
      <div className="navbar-links">
        <div className="navbar-links_logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>

        <div className="navbar-links_container">
          {logintype == 1 ? (
            <p className="applybtn">
              <Link to="/customer-dashboard">
                <button className="button-42" role="button">
                  Post Requirements
                </button>
              </Link>
            </p>
          ) : logintype == 2 ? (
            <p className="applybtn">
              <button className="button-42" role="button">
                <span onClick={handleCustMessage}>Post Requirements</span>
              </button>
            </p>
          ) : (
            <p className="applybtn">
              <Link to="/login">
                <button className="button-42" role="button">
                  Post Requirements
                </button>
              </Link>
            </p>
          )}

          {logintype == 2 ? (
            <p className="applybtn">
              <Link to="/vendor-project-list">
                <button className="button-43" role="button">
                  Get Projects
                </button>
              </Link>
            </p>
          ) : logintype == 1 ? (
            <p className="applybtn">
              <button className="button-43" role="button">
                <span onClick={handleVendMessage}>Get Projects</span>
              </button>
            </p>
          ) : (
            <p className="applybtn">
              <Link to="/login">
                <button className="button-43" role="button">
                  Get Projects
                </button>
              </Link>
            </p>
          )}
          {/* <p>
            <Link to="/" className="active">
              Home
            </Link>
          </p>
          <p>
            <Link to="/about">About</Link>
          </p>
          <p>
            <Link to="/project">Announcement</Link>
          </p>
          <p>
            <Link to="/contact">Contact</Link>
          </p> */}
        </div>
      </div>
      <div className="navbar-sign">
        {logintype == 2 ? (
          <Link to="/vendor-dashboard" className="profileTab">
            <FaUserCircle className="profile-icon" />
            <p>View Profile</p>
          </Link>
        ) : logintype == 1 ? (
          <Link to="/customer-dashboard" className="profileTab">
            <FaUserCircle className="profile-icon" />
            <p>View Profile</p>
          </Link>
        ) : (
          <>
            <p>
              <Link to="/login">Log In</Link>
            </p>
            <Link to="/register">
              <button className="btn">Register</button>
            </Link>
          </>
        )}
        {/* {logintype == 2 ? (
          <>
            <Link to="/vendor-dashboard" className="profileTab">
              <FaUserCircle className="profile-icon" />
              <p>View Profile</p>
            </Link>
         
          </>
        ) : (
          <>
            <p>
              <Link to="/login">Log In</Link>
            </p>
            <Link to="/register">
              <button className="btn">Register</button>
            </Link>
          </>
        )} */}
      </div>
      <div className="navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#000"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#000"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center">
            <div className="navbar-menu_container-links">
              <p>
                <Link to="/" className="active">
                  Home
                </Link>
              </p>
              <p>
                <Link to="/about">About</Link>
              </p>
              <p>
                <Link to="/project">Announcement</Link>
              </p>
              <p>
                <Link to="/contact">Contact</Link>
              </p>
            </div>

            <div className="navbar-menu_container-links-sign">
              {logintype == 2 ? (
                <Link to="/vendor-dashboard" className="profileTab">
                  <FaUserCircle className="profile-icon" />
                  <p>View Profile</p>
                </Link>
              ) : logintype == 1 ? (
                <Link to="/customer-dashboard" className="profileTab">
                  <FaUserCircle className="profile-icon" />
                  <p>View Profile</p>
                </Link>
              ) : (
                <>
                  <p>
                    <Link to="/login">Log In</Link>
                  </p>
                  <Link to="/register">
                    <button className="btn">Register</button>
                  </Link>
                </>
              )}
            </div>
            <p className="applybtn">
              {logintype == 1 ? (
                <p className="applybtn">
                  <Link to="/customer-dashboard">
                    <button className="button-42" role="button">
                      Post Requirements
                    </button>
                  </Link>
                </p>
              ) : logintype == 2 ? (
                <p className="applybtn">
                  <button className="button-42" role="button">
                    <span onClick={handleCustMessage}>Post Requirements</span>
                  </button>
                </p>
              ) : (
                <p className="applybtn">
                  <Link to="/login">
                    <button className="button-42" role="button">
                      Post Requirements
                    </button>
                  </Link>
                </p>
              )}

              {logintype == 2 ? (
                <p className="applybtn">
                  <Link to="/vendor-project-list">
                    <button className="button-43" role="button">
                      Get Projects
                    </button>
                  </Link>
                </p>
              ) : logintype == 1 ? (
                <p className="applybtn">
                  <button className="button-43" role="button">
                    <span onClick={handleVendMessage}>Get Projects</span>
                  </button>
                </p>
              ) : (
                <p className="applybtn">
                  <Link to="/login">
                    <button className="button-43" role="button">
                      Get Projects
                    </button>
                  </Link>
                </p>
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
