import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo-white.svg";

export default function Footer() {
  return (
    <footer className="Footer">
      <div className="companyinfo">
        <Link to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <p>
          ConQt is World's First AI driven B2B & B2C Business Matching
          marketplace that facilitates the exchange of services between Partners
          and customers
        </p>
      </div>
      <div className="mainpages">
        <h4>Main Pages</h4>
        <ul>
          <li>
            <a href="https://www.conqt.com/">Home</a>
          </li>
          <li>
            <a href="https://www.conqt.com/about-us">About</a>
          </li>
          <li>
            <a href="https://www.conqt.com/view-all-product">Products</a>
          </li>
          <li>
            <a href="/project">Announcements</a>
          </li>
          <li>
            <a href="https://www.conqt.com/contact-us">Contact</a>
          </li>
          <li>
            <a href="/conqt-university">ConQt University</a>
          </li>
        </ul>
      </div>
      <div className="category">
        <h4>Category</h4>
        <ul>
          <li>
            <Link to="/category/14/Enterprise%20Resource%20Planning%20(ERP)">
              Enterprise Resource Planning (ERP)
            </Link>
          </li>
          <li>
            <Link to="/category/15/Customer%20Relationship%20Management%20(CRM)">
              Customer Relationship Management (CRM)
            </Link>
          </li>
          <li>
            <Link to="/category/16/Vendor%20Management%20System%20(VMS)">
              Vendor Management System (VMS)
            </Link>
          </li>
          <li>
            <Link to="/category/24/Engineering%20Software">
              Engineering Software
            </Link>
          </li>
          <li>
            <Link to="/category">View All Categories</Link>
          </li>
        </ul>
      </div>
      <div className="Policies">
        <h4>Policy</h4>
        <ul>
          <li>
            <a href="/terms-and-conditions">Terms of Service</a>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/refund-policy">Refund Policy</Link>
          </li>
          <li>
            <Link to="/shipping-policy">Shipping Policy</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
