import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import "./Customer.css";
import "./view.css";
import { Link, useParams } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import { FaDownload } from "react-icons/fa";

const ViewCustomerMilestone = () => {
  const initialValues = {
    milestone_id: "",
    customer_remark: "",
    status: "",
  };

  const dispatch = useDispatch();
  const userToken = localStorage.getItem("token");
  const ID = localStorage.getItem("announcement_id");
  const proposal_id = useParams().proposal_id;

  const [rejected, setRejected] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [data, setData] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    const logintype = localStorage.getItem("logintype");
    if (logintype == 2) {
      errors["project_name"] =
        "You cannot create the Remark Due to Login type is Different";
    }
    console.log(errors);
    return errors;
  };

  const handleChangeText = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["customer_remark"]: e,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = validate(formValues);
    if (Object.keys(error).length !== 0) {
      setFormErrors(error);
      console.log("Error Found");
      return false;
    }
    console.log("No Validation Error Found");
    // ... submit to API or something
    setIsSubmit(true);
    try {
      var myHeaders = new Headers();

      //myHeaders.append("Authorization", `Bearer ${userToken})`);
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(formValues);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Customer-Approve-Decline-Milestone`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          if (result.status === 200) {
            setFormValues("");

            toast("Remark Sent successfully");
            window.location = `/ViewCustomerMilestone/${proposal_id}`;
            //dispatch(project({ result }));
            dispatch({ type: "ADD_REMARK", payload: result.data });
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  console.log(formValues);
  console.log(formErrors);

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Customer-Final-Project-List`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(
            result.data.filter((item) => item.proposal_id == proposal_id)
          );
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  console.log(data);

  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          {data.map((item) => {
            {
              return (
                <div className="timeline">
                  {item.milestonelist.map((key, index) => {
                    return (
                      <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                        <div className="timeline__event__icon">
                          <i className="lni-burger"></i>
                          <div className="timeline__event__date">
                            Project Milestone {index + 1}
                          </div>
                        </div>
                        <div className="timeline__event__content">
                          <div className="milestone">
                            <div>
                              <p>{key.milestone_description}</p>
                              <h3>
                                Invoice Amount - {key.user_currency}
                                {key.user_milestone_amount}/-
                              </h3>

                              {key.milestone_start_close_status == "1" ? (
                                <p className="milestonestart">
                                  Your Project is started by Vendor on &nbsp;
                                  {key.customer_status_update_time}.
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            {(function (rows, status, index_number) {
                              if (status == 0) {
                                if (
                                  index == 0 ||
                                  (item.milestonelist[index - 1]
                                    .vendor_mark_as_complete_status == 1 &&
                                    key.customer_status == 1)
                                ) {
                                  rows.push(
                                    <div className="paystatus">
                                      <span className="milestonestatus"></span>
                                      <Link
                                        to={`/checkout/${item.proposal_id}/${index}/${key.milestone_id}`}
                                      >
                                        <button className="btn btn-project">
                                          Pay Now
                                        </button>
                                      </Link>
                                    </div>
                                  );
                                } else if (index - 1 >= 0) {
                                  if (
                                    item.milestonelist[index - 1]
                                      .user_transaction_status == 1 &&
                                    key.customer_status == 1
                                  ) {
                                    rows
                                      .push
                                      // <div>
                                      //   <Link
                                      //     to={`/receipt/${key.milestone_id}/${
                                      //       index + 0
                                      //     }/${proposal_id}`}
                                      //   >
                                      //     <FaDownload />
                                      //     Download Invoice
                                      //   </Link>
                                      // </div>
                                      ();
                                  } else {
                                    console.log(
                                      item.milestonelist[index - 1]
                                        .user_transaction_status
                                    );
                                    rows.push(
                                      <div className="paystatus">
                                        {key.milestone_start_close_status ==
                                        "0" ? (
                                          <>
                                            <span className="milestonestatus">
                                              Milestone In not started yet
                                            </span>
                                            {/* <button
                                              onClick={() => {
                                                toast.error(
                                                  `Please Pay Previous Milestone First!`
                                                );
                                              }}
                                              className="btn btn-project"
                                              disabled
                                            >
                                              <b> Start Milestone </b>
                                            </button> */}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    );
                                  }
                                }
                              } else if (status == 1) {
                                rows.push(
                                  <div>
                                    {/* <button className="btn btn-project"> */}
                                    <Link
                                      to={`/receipt/${key.milestone_id}/${
                                        index + 0
                                      }/${proposal_id}`}
                                      className="downloadlink"
                                    >
                                      <FaDownload />
                                      Download Invoice
                                    </Link>
                                    {/* </button> */}
                                    {key.milestone_start_close_status == "0" ? (
                                      <p className="invdwnmessage">
                                        Payment Successfully Done! Vendor start,
                                        This Project Start Soon
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                );
                              }

                              return rows;
                            })([], key.user_transaction_status, index)}

                            {(function (rows) {
                              if (key.customer_status == 1) {
                                console.log(key.milestone_description);
                                rows.push(
                                  <div>
                                    <p className="waitingmessage">
                                      This milestone is finished now.
                                    </p>
                                  </div>
                                );
                              } else if (key.customer_status != 1) {
                                rows.push(
                                  <>
                                    {key.vendor_mark_as_complete_status == 1 ? (
                                      <div>
                                        <p className="cmilestonemessage">
                                          Partner Marked it as complete, If it's
                                          not then click on Reject
                                        </p>

                                        <Popup
                                          trigger={
                                            <button className="btn markascomplete">
                                              Click here to Approve/Reject
                                            </button>
                                          }
                                          modal
                                          nested
                                        >
                                          {(close) => (
                                            <div className="popup">
                                              <div className="popup-content">
                                                <button
                                                  className="close-btn popupclose"
                                                  onClick={close}
                                                >
                                                  &times;
                                                </button>
                                                <div>
                                                  {rejected == 0 ? (
                                                    <div className="popuptable">
                                                      <h3 className="addproject">
                                                        View Remark
                                                      </h3>
                                                      <table className="rwd-table">
                                                        <tbody>
                                                          <tr>
                                                            <td>*</td>
                                                            <td>Response</td>
                                                          </tr>
                                                          <tr>
                                                            <td>
                                                              Partner's Remark
                                                            </td>
                                                            <td>
                                                              {parse(
                                                                key.vendor_remark
                                                              )}
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td>
                                                              Remark Updated
                                                              Date
                                                            </td>
                                                            <td>
                                                              {
                                                                key.vendor_status_update_time
                                                              }
                                                            </td>
                                                          </tr>
                                                          {/* <tr>
                                                            <td></td>
                                                            <td></td>
                                                          </tr> */}
                                                        </tbody>
                                                      </table>
                                                      <div className="popup-footer">
                                                        <button
                                                          onClick={() => {
                                                            formValues.milestone_id =
                                                              key.milestone_id;
                                                            formValues.status = 1;
                                                            setRejected(1);
                                                          }}
                                                          className="btn-approve-ms"
                                                        >
                                                          Approve
                                                        </button>
                                                        <button
                                                          onClick={() => {
                                                            formValues.milestone_id =
                                                              key.milestone_id;
                                                            formValues.status = 2;
                                                            setRejected(1);
                                                          }}
                                                          className="btn-reject-ms"
                                                        >
                                                          Reject
                                                        </button>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  {rejected == 1 ? (
                                                    <>
                                                      {" "}
                                                      <h3 className="addproject">
                                                        Send Remark
                                                      </h3>
                                                      <form
                                                        onSubmit={handleSubmit}
                                                        noValidate
                                                        autoComplete="off"
                                                        className="Project-form"
                                                      >
                                                        <div className="form-group">
                                                          <label>Remark</label>

                                                          <CKEditor
                                                            type="texteditor textarea"
                                                            name="project_description"
                                                            placeholder="Description"
                                                            editor={
                                                              ClassicEditor
                                                            }
                                                            onReady={(
                                                              editor
                                                            ) => {
                                                              // You can store the "editor" and use when it is needed.
                                                            }}
                                                            onChange={(
                                                              event,
                                                              editor
                                                            ) => {
                                                              const data = editor.getData();
                                                              handleChangeText(
                                                                data
                                                              );
                                                            }}
                                                            value={
                                                              formValues.customer_remark
                                                            }
                                                            onBlur={(
                                                              event,
                                                              editor
                                                            ) => {}}
                                                            onFocus={(
                                                              event,
                                                              editor
                                                            ) => {}}
                                                          />
                                                        </div>
                                                        <p className="err-message"></p>

                                                        <br />
                                                        <div className="form-group">
                                                          <button
                                                            type="submit"
                                                            className="btn markascomplete"
                                                          >
                                                            <i className="far fa-paper-plane"></i>
                                                            Submit Remark
                                                          </button>
                                                        </div>
                                                      </form>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </div>

                                                {/* <div className="popup-footer">
                                                  <button
                                                    className="btn-signup"
                                                    onClick={() => {
                                                      setRejected(0);
                                                      close();
                                                    }}
                                                  >
                                                    Close
                                                  </button>
                                                </div> */}
                                              </div>
                                            </div>
                                          )}
                                        </Popup>
                                      </div>
                                    ) : (
                                      <div className="milestoneremark">
                                        {key.customer_status == 2 ? (
                                          <p style={{ color: "red" }}>
                                            This milestone is rejected due to
                                            {parse(key.customer_remark)}
                                          </p>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    )}
                                  </>
                                );
                              }
                              return rows;
                            })([])}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
                    <div className="timeline__event__icon">
                      <i className="lni-slim"></i>
                      <div className="timeline__event__date">
                        Project Milestone 2
                      </div>
                    </div>
                    <div className="timeline__event__content">
                      <div className="milestone">
                        <div>
                          <p>
                            In this milestone we delivered the UI Design,
                            Frontend Setup and Backend Setup, Server Sertup
                          </p>
                          <h3>Invoice Amount - INR 150,000/-</h3>
                        </div>
                        <button className="btn btn-project" disabled>
                          <Link to="/invoice">Start Project</Link>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="timeline__event animated fadeInUp timeline__event--type4">
                    <div className="timeline__event__icon">
                      <div className="timeline__event__date">
                        Project Milestone 3
                      </div>
                    </div>
                    <div className="timeline__event__content">
                      <div className="milestone">
                        <div>
                          <p>
                            In this milestone we delivered the UI Design,
                            Frontend Setup and Backend Setup, Server Sertup
                          </p>
                          <h3>Invoice Amount - INR 150,000/-</h3>
                        </div>
                        <button className="btn btn-project" disabled>
                          <Link to="/invoice">Start Project</Link>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="timeline__event animated fadeInUp timeline__event--type2">
                    <div className="timeline__event__icon">
                      <div className="timeline__event__date">
                        Project Milestone 3
                      </div>
                    </div>
                    <div className="timeline__event__content">
                      <div className="milestone">
                        <div>
                          <p>
                            In this milestone we delivered the UI Design,
                            Frontend Setup and Backend Setup, Server Sertup
                          </p>
                          <h3>Invoice Amount - INR 150,000/-</h3>
                        </div>
                        <button className="btn btn-project" disabled>
                          <Link to="/invoice">Start Project</Link>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="timeline__event animated fadeInUp timeline__event--type3">
                    <div className="timeline__event__icon">
                      <div className="timeline__event__date">
                        Project Milestone 3
                      </div>
                    </div>
                    <div className="timeline__event__content">
                      <div className="milestone">
                        <div>
                          <p>
                            In this milestone we delivered the UI Design,
                            Frontend Setup and Backend Setup, Server Sertup
                          </p>
                          <h3>Invoice Amount - INR 150,000/-</h3>
                        </div>
                        <button className="btn btn-project" disabled>
                          <Link to="/invoice">Start Project</Link>
                        </button>
                      </div>
                    </div>
                  </div> */}
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default ViewCustomerMilestone;
