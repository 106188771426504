import React, { useState, useEffect } from "react";
import "../Vendor/view.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Popup from "reactjs-popup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useForm } from "react-hook-form";

const Milestone = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [item, setitem] = useState([]);
  const [rejected, setRejected] = useState(0);
  const [formValues, setFormValues] = useState([]);
  const userToken = localStorage.getItem("token");
  const ID = useParams().milestone_id;
  console.log(ID);

  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

   const handleChangeText = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["customer_remark"]: e,
    }));
  };


  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendordashboard/Milestone-Update-Complete`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          console.log("result", result.data);
          setData(result.data);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  data.map((item) => {
    item.milestonelist.map((key) => {
      console.log("milestone details", key.milestone_description);
    });
  });

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />

        <main className="maincontent">
          <div>
            <section>
              <div className="background2">
                <div className="VMheading1">
                  <h1>Project Milestone 1</h1>
                </div>
                <div className="VMheading2">
                  <h2>D2</h2>
                </div>
                <div className="VMheading3">
                  <b>Invoice Amount - THB 239/-</b>
                </div>
                <div className="VMmile">
                  <p>Payment is completed Successfully for Milestone 1</p>
                  <p>You have only 5 days to complete your Milestone.</p>
                  <p>Good Luck !</p>
                </div>
                <div>
                 <Popup
                  trigger={
                 <button className="apply_milestone">Mark AS Complete</button>
                    }
                                       modal
                                      nested
                                      >
                                        {(close) => (
                                          <div className="popup-milestone">
                                            <div className="popup-content">
                                             <button
                                              className="close-button"
                                                 onClick={close}
                                              >
                                                 &times;
                                             </button> 
                                               <div>
                                                 {rejected == 0 ? (
                                                   <div>
                                                    <div className="popupheading"> View Milestone 1 Achievement</div>
                                                     <h3 className="addproject">
                                                     Remark
                                                    </h3>
                                                    <div className="popupremark">
                                                    <form
                                                      onSubmit={handleSubmit}
                                                       noValidate
                                                       autoComplete="off"
                                                       className="Project-form"
                                                     >
                                                       <div className="form-group">
                                                         <label>Remark</label>

                                                         <CKEditor
                                                           type="texteditor textarea"
                                                           name="project_description"
                                                           placeholder="Description"
                                                          editor={
                                                             ClassicEditor
                                                           }
                                                          onReady={(
                                                            editor
                                                           ) => {
                                                            // You can store the "editor" and use when it is needed.
                                                           }}
                                                           onChange={(
                                                        editor
                                                           ) => {
                                                             const data = editor.getData();
                                                             handleChangeText(
                                                              data
                                                             );
                                                          }}
                                                           value={
                                                          formValues.customer_remark
                                                          }
                                                           onBlur={(
                                                            event,
                                                            editor
                                                          ) => {}}
                                                          onFocus={(
                                                             event,
                                                             editor
                                                          ) => {}}
                                                         />
                                                   </div>
                                                    <p className="err-message"></p>

                                                      <br />
                                                      <div className="form-group">
                                                      <button
                                                          type="submit"
                                                          className="button-submit"
                                                         >
                                                           <i className="far fa-paper-plane"></i>
                                                           Submit Remark
                                                         </button>
                                                       </div>
                                                    </form>
</div>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )}

                                                {rejected == 1 ? (
                                                  <>
                                                    {" "}
                                                    <h3 className="addproject">
                                                      Send Remark
                                                    </h3>
                                                     <form
                                                      onSubmit={handleSubmit}
                                                       noValidate
                                                       autoComplete="off"
                                                       className="Project-form"
                                                     >

                                                      <br />
                                                      <div className="form-group">
                                                      <button
                                                          type="submit"
                                                          className="btn btn-primary"
                                                         >
                                                           <i className="far fa-paper-plane"></i>
                                                           Submit Remark
                                                         </button>
                                                       </div>
                                                    </form>
                                                 </>
                                                ) : (
                                                   <></>
                                                 )}
                                               </div>
                                             </div>
                                           </div>
                                           
                                        )}
                                       </Popup>
            </div>
              </div>
            </section>
            <section>
              <div className="background1">
                <div className="VMheading1">
                  <h1>Project Milestone 2</h1>
                </div>
                <div className="milestone-heading2">
                  <h2>D2</h2>
                  <div className="pay-details">
                    <p className="pay-done">Payment Done</p>
                    <p className="pay-id">EkID5398UI</p>
                  </div>
                </div>
                <div className="heading3">
                  <b>Invoice Amount - THB 239/-</b>
                </div>
                <div className="mile">Milestone 1 have successfully</div>
                <button className="Startmilestones">Mark as Complete</button>
              </div>
            </section>
            <section>
              <div className="background1">
                <div className="VMheading1">
                  <h1>Project Milestone 3</h1>
                </div>
                <div className="VMheading2">
                  <h2>D2</h2>
                </div>
                <div className="VMheading3">
                  <b>Invoice Amount - THB 239/-</b>
                </div>
                <button className="VMstart-mile">View Milestone</button>
              </div>
            </section>
            <section>
              <div className="background1">
                <div className="VMheading1">
                  <h1>Project Milestone 4</h1>
                </div>
                <div className="VMheading2">
                  <h2>D2</h2>
                </div>
                <div className="VMheading3">
                  <b>Invoice Amount - THB 239/-</b>
                </div>
                <button className="VMstart-mile">View Milestone</button>
              </div>
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Milestone;

                                     