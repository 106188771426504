// actions.js
export const updateFormValue = (formData) => {
  console.log("updateFormValue", formData);
  return {
    type: "UPDATE_FORM_VALUE",
    payload: formData,
  };
};

export const clearFormValues = () => {
  return {
    type: "CLEAR_FORM_VALUES",
  };
};

// reducers.js
const initialState = {
  formValues: {
    project_name: "",
    project_description: "",
    solution_name: "",
    country: "",
    city: "",
    project_expiry_datetime: new Date(),
    project_budget: "Budget Not Set",
  },
};

const addProjectsReducer = (state = initialState, action) => {
  console.log("addProjectsReducer", state, action);
  switch (action.type) {
    case "UPDATE_FORM_VALUE": // Corrected action type
      return {
        ...state,
        formValues: {
          ...state.formValues,
          ...action.payload, // Spread the payload object to update the form values
        },
      };
    case "CLEAR_FORM_VALUES":
      return {
        ...state,
        formValues: { ...initialState.formValues }, // Reset formValues to initial state
      };
    default:
      return state;
  }
};

export default addProjectsReducer;
