import React from "react";
import "./Home.css";
import Layout from "../../components/Layout";
import Banner from "../../assets/images/team.svg";
import ptt from "../../assets/images/client-ptt.svg";
import scg from "../../assets/images/client-scg.svg";
import Silapakorn from "../../assets/images/Silapakorn.png";
import Mitsubishi from "../../assets/images/Mitsubishi.png";
import KMIT from "../../assets/images/KMIT.png";
import KMUTT from "../../assets/images/KMUTT.png";
import rtn from "../../assets/images/rtn.png";
import sea from "../../assets/images/sea.png";
import marsun from "../../assets/images/Marsun.png";
import About from "../../assets/images/about.jpg";
import { FiArrowRight } from "react-icons/fi";
import { MdStars } from "react-icons/md";
import { AiFillDollarCircle } from "react-icons/ai";
import { MdSecurity } from "react-icons/md";
import ai from "../../assets/images/ai-search.png";
import match from "../../assets/images/matchable.png";
import solutions from "../../assets/images/solutions.png";
import { Link } from "react-router-dom";
import { BiTimer } from "react-icons/bi";
import { GoLink } from "react-icons/go";
import { BiCertification } from "react-icons/bi";
import { GoDatabase } from "react-icons/go";
import { AiOutlineFileSearch } from "react-icons/ai";
//import Menu from "./Menu";
const Home = () => {
  const logintype = localStorage.getItem("logintype");
  return (
    <Layout>
      <div className="main-banner">
        <div className="main-head">
          <h1>
            {/* Find Right Partner. <br />
            Easy Announce <br />
            Your Requirements */}
            AI Driven Business Matching Platform
          </h1>

          <p>No. 1 Market place for Businesses</p>
          {/* <button className="btn">View Projects</button> */}

          <span className="trust">Trusted By</span>
          <div className="client-img">
            <article>
              <img src={ptt} alt="ptt" />
            </article>
            <article>
              <img src={scg} alt="scg" />
            </article>
            <article>
              <img src={Mitsubishi} alt="Mitsubishi" />
            </article>
            <article>
              <img src={KMIT} alt="thaisia" />
            </article>
            <article>
              <img src={Silapakorn} alt="thaisia" />
            </article>
            <article>
              <img src={KMUTT} alt="thaisia" />
            </article>
            <article>
              <img src={rtn} alt="thaisia" />
            </article>
            <article>
              <img src={sea} alt="thaisia" />
            </article>
            <article>
              <img src={marsun} alt="thaisia" />
            </article>
          </div>
        </div>
        <div className="banner-image">
          <div className="bannerimg">
            <img src={Banner} alt="main-banner" />
          </div>
        </div>
      </div>

      {/*************** Home Banner end - About Connect Start **************/}

      <section id="about">
        <div className="about__container">
          <div className="about__me">
            <img src={About} alt="" className="about__me-image" />
          </div>

          <div className="about__content">
            {/* <h3>Tinder for Business</h3> */}
            <h2>One Stop Market for the Business</h2>
            <p>Easily ConQt with Vendors (Partners) just in few clicks.</p>
          </div>
        </div>
      </section>
      {/***************Apply Buttons**************/}
      <section id="post">
        <div className="post_apply_container">
          <article className="post-tab">
            <h3>Post Job's and Hire Vendors</h3>
            <span className="post-view">
              <p className="post-type">
                <Link to="/add-projects" className="post-link">
                  Post Project
                </Link>
              </p>
              <Link to="/add-projects">
                <FiArrowRight className="post-click-icon post-link" />
              </Link>
            </span>
          </article>
          <article className="post-tab">
            <h3>Get Projects in seconds</h3>
            <span className="post-view">
              <Link to="/project" className="post-link">
                Browse Project
              </Link>
              <Link to="/project">
                <FiArrowRight className="post-click-icon post-link" />
              </Link>
            </span>
          </article>
          <article className="post-tab">
            <h3>Hire Right Vendor</h3>
            {logintype == 2 ? (
              <span className="post-view">
                <Link to="/vendor-dashboard" className="post-link">
                  <p className="post-type">View Projects</p>
                </Link>
                <Link to="/vendor-dashboard">
                  <FiArrowRight style={{ color: "white" }} />
                  <br />
                </Link>
              </span>
            ) : (
              <span className="post-view">
                <Link to="/login" className="post-link">
                  <p className="post-type">View Projects</p>
                </Link>
                <Link to="/login">
                  <FiArrowRight style={{ color: "white" }} />
                  <br />
                </Link>
              </span>
            )}
          </article>
        </div>
      </section>
      {/***************Why Connect**************/}
      <section id="whyconnect">
        <div className="connect_container">
          <div className="connect_details">
            <h4>Why ConQt?</h4>
            {/* <article className="connect-list">
              <MdStars className="connect-icons" />
              <div className="connect-content">
                <h5>Proof of Quality</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </article>
            <article className="connect-list">
              <AiFillDollarCircle className="connect-icons" />
              <div className="connect-content">
                <h5>No hidden Cost</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </article>
            <article className="connect-list">
              <MdSecurity className="connect-icons" />
              <div className="connect-content">
                <h5>Safe and Secure</h5>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>
            </article> */}
            {/* <ul>
              <li className="whylist">
                <BiTimer className="listicon" />
                &nbsp; Reduce your sourcing time
              </li>
              <li className="whylist">
                <GoLink className="listicon" />
                &nbsp; Connect with million Partners just in sec.
              </li>
              <li className="whylist">
                <BiCertification className="listicon" />
                &nbsp;Partners are certified by ConQt.
              </li>
              <li className="whylist">
                <GoDatabase className="listicon" />
                &nbsp;Big Database of Partner and Purchaser.
              </li>
              <li className="whylist">
                <AiOutlineFileSearch className="listicon" />
                &nbsp;AI Driven search engine
              </li>
            </ul> */}
            <p>
              "Find the right vendors, hire the right talent for getting your
              work done. It’s easy with ConQt ConQt is the #1 global online
              marketplace to find right assets to ensure your projects are
              always executed on time. It’s where online business owners find
              trade assets, for building Software businesses, (mention 6
              categories of services). Our mission is to organise business
              requirements and leverage technology to eliminate barriers between
              businesses and their customers. ConQt is a business matching
              portal where businesses can list their requirements so that the
              vendors can apply for the right business as per their needs
              conveniently and quickly."
            </p>
          </div>

          <div className="hashconnect">
            <h4>#ConQT</h4>
            <p>
              The only marketplace for Partners and purchasers on this planet.
            </p>
          </div>
        </div>
      </section>
      {/***************Apply Buttons**************/}
      <section id="flow">
        <div className="flow-container">
          <div className="flow-content">
            <h1 className="flow-steps-head">
              It takes a community to build a business
            </h1>
            <h3 className="flow-steps-sub-head">Try it today</h3>

            <p className="flow-steps-description">
              You can find experienced Partners and promote your business
              online, build your business communities through a global network.
            </p>
            {/* <h3 className="flow-steps-sub-head">ConQT - Tinder for Business</h3>
            <button className="btn btn-register">
              <Link to="Partner-dashboard">Register Now</Link>
            </button> */}
          </div>
          <div className="flow-steps">
            <div className="flow-step-card flow-step-card-1">
              <img src={ai} alt="AI-Search" className="flow-img" />
              <div className="flow-step-content">
                <h2>AI Search</h2>
                <p>Let our AI Search find Partners for your requirements.</p>
              </div>
            </div>
            <div className="flow-step-card flow-step-card-2">
              <div className="flow-step-content">
                <h2>Matchable</h2>
                <p>Get your business matched.</p>
              </div>
              <img src={match} alt="matchable" className="flow-img" />
            </div>
            <div className="flow-step-card flow-step-card-3">
              <img src={solutions} alt="solutions" className="flow-img" />
              <div className="flow-step-content">
                <h2>Solutions</h2>
                <p>ConQT offering more than 300 solutions.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/***************Categories**************/}
      <section id="category">
        <div className="category_container">
          <article className="category-part">
            <h4 className="category-heading">
              For Multiple Categories, Partners Available
            </h4>
          </article>
          <article className="category-list">
            <ul>
              <li> Retailer </li>
              <li> Health Care </li>
              <li> Distributor (Finished Goods)</li>
              <li> Food & Beverage </li>
              <li> Education Management </li>
              <li> Oil & Gas</li>
              <li> Hospitability Information</li>
              <li> Information Technology & Services</li>
              <li> Insurance</li>
              <li> Internet</li>
            </ul>
          </article>
          <article className="category-list">
            <ul>
              <li> Manufacturer </li>
              <li> Business Services </li>
              <li> Investor</li>
              <li> Energy </li>
              <li> Government </li>
              <li> Ship Building </li>
              <li> Jewelry</li>
              <li> Electric Appliances</li>
              <li> Automobiles</li>
              <li> Defense</li>
              <li> Banks</li>
            </ul>
          </article>
          <article className="category-list">
            <ul>
              <li> Accounting </li>
              <li> Computer Software </li>
              <li> Computers and parts</li>
              <li> Education</li>
              <li> Automotive </li>
              <li> Manufacturing </li>
              <li> Renewable Energy </li>
              <li> Petrochemicals</li>
              <li> Tourism</li>
              <li> Airforce</li>
              <li> Navy</li>
              <li> Cement</li>
            </ul>
          </article>
        </div>
      </section>

      {/***************Get Started**************/}
      {/* <div className="getstarted">
        <h4>Get Started Today!</h4>
        <Link to="/register">
          <button className="btn">Register</button>
        </Link>
      </div> */}
    </Layout>
  );
};

export default Home;
