import { createSlice } from "@reduxjs/toolkit";

export const ProjectInfo = createSlice({
  name: "Project Information",
  initialState: {
 projectDetails:{}
  }, 
  reducers: {
    updateProjectInformation: (state, action) => {
      // console.log("Project information in the store",action)
      state.projectDetails = action.payload;
    }
  },
});

export const {  updateProjectInformation} = ProjectInfo.actions;

export default ProjectInfo.reducer;