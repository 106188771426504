import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import "./Vendor.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { toast } from "react-toastify";
import { BaseUrl ,RazorPaymentkey } from "../../Api/api.js";

const VendorPayment = () => {
  const user = JSON.parse(localStorage.getItem("userinfo"));

  console.log(user);
  var country = user.country;
  console.log(country, "this is user country");
  if (country.toLowerCase() == "india") {
    var showAmount = "INR 1180";
    var amount = 118000;
    var currency = "INR";
  } else if (country.toLowerCase() == "thailand") {
    var showAmount = "THB 1000";
    var amount = 100000;
    var currency = "THB";
  } else {
    var showAmount = "$20 USD";
    var amount = 2000;
    var currency = "USD";
  }

  const registrationCharges = "20.0";
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const loadScript = (src) => {
    return new Promise((resovle) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resovle(true);
      };

      script.onerror = () => {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }

    // console.log(user);
    // var country = user.country;
    // if (country.toLowerCase() == "india") {
    //   var amount = 50000;
    //   var currency = "INR";
    // } else {
    //   var amount = 2000;
    //   var currency = "USD";
    // }

    console.log("this is amount", amount);

    const options = {
      key: RazorPaymentkey,
      currency: currency,
      //order_id: "743941",
      receipt: "receipt_order_74394",
      amount: amount,
      name: "Razor Payment",
      description: "Thanks for purchasing",
      image:
        "https://mern-blog-akky.herokuapp.com/static/media/logo.8c649bfa.png",

      handler: function (response) {
        console.log(response);

        let responseData = {
          transactions_id: response.razorpay_payment_id,
          currency: options.currency,
          amount: options.amount / 100,
          transaction_status: "COMPLETED",
        };
        console.log(responseData);

        //POST API For Transactions

        try {
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
          );
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify(responseData);

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };
          console.log("Going to payment update", requestOptions);
          fetch(`${BaseUrl}/vendordashboard/Payment-Update-Rzp`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
              result = JSON.parse(result);
              console.log(result);
              if (result.status === 200) {
                requestOptions.method = "GET";
                delete requestOptions["body"];
                console.log("Going to vendor profile api", requestOptions);
                fetch(`${BaseUrl}/vendor/Vendor-Details`, requestOptions)
                  .then((response) => response.text())
                  .then((result) => {
                    //console.log("Get vendor details", result);
                    result = JSON.parse(result);
                    if (result.status === 200) {
                      localStorage.setItem(
                        "userinfo",
                        JSON.stringify(result.data)
                      );

                      toast("Payment updated successfully");
                      window.location = "/vendor-project-list";
                    }
                  })
                  .catch((error) => console.log("error", error));
              } else {
                toast(result.message);
              }
            })
            .catch((error) => console.log("error", error));
        } catch (err) {
          console.log(err);
        }

        toast(response.razorpay_payment_id);
        toast("Payment Successfully");
      },
      prefill: {
        name: "Enter Your Name",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />
        <br />
        <div className="payment-cart">
          <div className="cart-window">
            <h4 className="cartHeading">
              To Apply for Project, <br />
              Pay one time registration fees
            </h4>
            <h2>{showAmount}</h2>
            <div
              className="paymentbtn"
              onClick={() => displayRazorpay(registrationCharges)}
            >
              Pay Now
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorPayment;
