import React, { useState } from 'react';
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const ShowText = ({ text, maxLength }) => {

        const [showAll, setShowAll] = useState(false);

        if (text.length <= maxLength || showAll) {
                return <p>{parse(text)}</p>;
        }
        if (showAll) {
                return (
                        <>
                                <p>{parse(text)}</p>
                                <div onClick={() => setShowAll(false)}>
                                        <p style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}>Show Less</p>
                                </div>
                        </>
                );
        }
        const trimmedText = text.substring(0, maxLength);

        return (
                <>
                        <p>{parse(trimmedText)}</p>
                        <div onClick={() => setShowAll(true)}>
                                <p style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}>Show More</p>
                        </div>
                </>
        );
}

export default ShowText