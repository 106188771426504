import React, { useState, useEffect } from "react";
import "./page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { BsUpload } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../../Api/api";
import Viewer from "react-viewer";
import nodoc from "../../../assets/images/nodoc.jpg";
import { AiFillFileImage } from "react-icons/ai";
import PageLoader from "../../../components/PageLoader/PageLoader";
import PDF from "../../../assets/images/pdfIcon.png";
import Upload from "../../../assets/images/uploadIcon.png";

const CompanyDocuments = () => {
  const [data, setData] = useState([]);
  const [showBar, setShowBar] = useState("0");
  const [visible, setVisible] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const userToken = localStorage.getItem("token");

  useEffect(() => {
    setPageLoading(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);

      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendor/Get-Vendor-Company-Document-Profile`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(result.data.document_list);
          setPageLoading(false);

          localStorage.setItem(
            "profile_complete_status",
            result.data.profile_complete_status.profile_complete_status
          );
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleNext = (e) => {
    window.location = "/bank-details";
  };

  const handleSkip = (e) => {
    window.location = "/bank-details";
  };

  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar
            isShow={true}
            value={
              // data.length > 0 &&
              // data[0].profile_complete_status &&
              // data[0].profile_complete_status
              localStorage.getItem("profile_complete_status")
            }
          />
          <main className="maincontent">
            <div>
              <section>
                <div className="basic-info">
                  <h3>Company Documents</h3>
                  {data.length != 0 ? (
                    <Link to="/documents/edit">
                      <img src={Upload} alt="" />
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </section>

              <div className="companydoctext">
                {data.length != 0 ? (
                  data.map((item) => {
                    return (
                      // <table className="rwd-table">
                      //   <tbody>
                      //     {/* <tr class="tabletopbar">
                      //       <th>Document Name</th>
                      //       <th className="text-center">View</th>
                      //     </tr> */}

                      //     <>
                      //       <tr>
                      //         <td data-th="Document Name">
                      //           <AiFillFileImage
                      //             title={item.company_doc_name}
                      //             style={{ color: "black" }}
                      //           />
                      //         </td>

                      //         <td data-th="View" className="cell doclist">
                      //           {/* <Viewer
                      //             visible={visible}
                      //             onClose={() => {
                      //               setVisible(false);
                      //             }}
                      //             zoomSpeed={0.2}
                      //             images={[{ src: item.img_url }]}
                      //           /> */}
                      //           {/* <img
                      //           src={item.company_doc_url}
                      //           alt={item.company_doc_url}
                      //           style={{ width: 200 }}
                      //         /> */}
                      //           <a
                      //             className="link-file"
                      //             href={item.company_doc_url}
                      //             target={"_blank"}
                      //           >
                      //             view
                      //           </a>
                      //         </td>
                      //       </tr>
                      //     </>
                      //   </tbody>
                      // </table>

                      <div className="mb-6 pt-4">
                        <div className="formbold-file-list formbold-mb-5">
                          <div className="formbold-file-item">
                            <span className="formbold-file-name">
                              <img src={PDF} alt="" />
                            </span>
                            <a
                              className="link-file"
                              href={item.company_doc_url}
                              target={"_blank"}
                            >
                              view
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="noreviews">
                    <div className="noreviewcont">
                      <h3>No Document Found</h3>

                      <Link to="/documents/edit">
                        <button>Add Document</button>
                      </Link>
                    </div>

                    <img src={nodoc} alt="noreview" />
                  </div>
                )}
              </div>
            </div>

            <div className="formbuton">
              <input
                type="submit"
                className="save-button"
                value="Skip"
                onClick={handleNext}
              />
              <input
                type="submit"
                className="save-button"
                value="Next"
                onClick={handleSkip}
              />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default CompanyDocuments;
