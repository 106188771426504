import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import "./Vendor.css";
import { Link, useParams } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";
import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import UploadInvoice from "./uploadinvoice/uploadinvoice";
import { FcDownload } from "react-icons/fc";

const ViewVendorMilestone = (props) => {
  const proposal_id = useParams().proposal_id;
  const milestone_id = useParams().milestone_id;

  const initialValues = {
    milestone_id: "",
    proposal_id: proposal_id,
    vendor_remark: "",
  };

  const dispatch = useDispatch();
  const userToken = localStorage.getItem("token");
  const ID = localStorage.getItem("announcement_id");

  const [rejected, setRejected] = useState(0);
  const [formValues, setFormValues] = useState(initialValues);
  const [data, setData] = useState([]);
  // const [startMilestone, setStartMilestone] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    const logintype = localStorage.getItem("logintype");
    if (logintype == 1) {
      errors["project_name"] =
        "You cannot create the Remark Due to Login type is Different";
    }
    console.log(errors);
    return errors;
  };

  const handleChangeText = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      ["vendor_remark"]: e,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = validate(formValues);
    if (Object.keys(error).length !== 0) {
      setFormErrors(error);
      console.log("Error Found");
      return false;
    }
    console.log("No Validation Error Found");
    // ... submit to API or something
    setIsSubmit(true);
    try {
      var myHeaders = new Headers();

      //myHeaders.append("Authorization", `Bearer ${userToken})`);
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");
      console.log("Submit Remark", formValues);
      var raw = JSON.stringify(formValues);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendordashboard/Milestone-Update-Complete`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          if (result.status === 200) {
            setFormValues("");

            toast("Remark Sent successfully");
            window.location.reload(true);
            //dispatch(project({ result }));
            dispatch({ type: "ADD_REMARK", payload: result.data });
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));

      // fetch(
      //   `${BaseUrl}/vendordashboard/Milestone-Update-Complete`,
      //   requestOptions
      // )
      //   .then((response) => response.text())
      //   .then((result) => {
      //     result = JSON.parse(result);
      //     console.log(result);
      //     if (result.status === 200) {
      //       setFormValues("");

      //       toast("Remark Sent successfully");
      //       window.location = `/UploadInvoice/${proposal_id}/${milestone_id}`;
      //       //dispatch(project({ result }));
      //       dispatch({ type: "ADD_REMARK", payload: result.data });
      //     } else {
      //       toast(result.message);
      //     }
      //   })
      //   .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  const handleStartMilestone = async (mid) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Content-Type", "application/json");

    console.log("Milestone Id", mid);

    let result = await milestoneStart(mid, myHeaders);
    console.log(result, "result of mid");
  };

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendordashboard/Vendor-Final-Project-List`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          console.log("result", result.data);
          setData(result.data);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  console.log(data);

  const openInNewTab = (url) => {
    console.log(url, "invoice url");
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          {data.map((item) => {
            if (item.proposal_id == proposal_id) {
              return (
                <div className="timeline">
                  {item.milestonelist.map((key, index) => {
                    return (
                      <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
                        <div className="timeline__event__icon">
                          <i className="lni-burger"></i>
                          <div className="timeline__event__date">
                            Project Milestone {index + 1}
                          </div>
                        </div>
                        <div className="timeline__event__content">
                          <div className="milestone">
                            <div>
                              <p>{key.milestone_description}</p>
                              <h3>
                                Invoice Amount - {key.vendor_currecy}
                                {key.milestone_amount} /-THB
                              </h3>
                              {key.cont_to_v_transaction_status != 1 ? (
                                <p className="miletimeline">
                                  Last Date of complete this milestone is &nbsp;
                                  {key.milstone_timeline}. Kindly upload invoice
                                  here.
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                            {key.invoice_rul != 0 &&
                            (key.count == "1" || key.customer_status == "1") ? (
                              <>
                                <button
                                  onClick={() => openInNewTab(key.invoice_rul)}
                                  className="downloadinvoice"
                                >
                                  <FcDownload />
                                  {key.cont_to_v_transaction_status != 1 ? (
                                    <p className="invoiceuploaded">
                                      View Invoice <br /> Invoice Uploaded &
                                      Awaiting for Payment Update
                                    </p>
                                  ) : (
                                    <p className="invoiceuploaded">
                                      Download Invoice
                                    </p>
                                  )}
                                </button>
                              </>
                            ) : key.count == "1" ||
                              key.customer_status == "1" ? (
                              <>
                                <UploadInvoice
                                  item={key.milestone_id}
                                  items={proposal_id}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            <div>
                              {key.count == "1" &&
                              key.cont_to_v_transaction_status == "1" &&
                              key.milestone_start_close_status == 0 ? (
                                <button
                                  className="btn markascomplete"
                                  onClick={() =>
                                    handleStartMilestone(key.milestone_id)
                                  }
                                >
                                  Start Milestone
                                </button>
                              ) : key.count != "1" &&
                                index != 0 &&
                                item.milestonelist[index - 1]
                                  .milestone_start_close_status == 2 &&
                                key.milestone_start_close_status == 0 ? (
                                <button
                                  className="btn markascomplete"
                                  onClick={() =>
                                    handleStartMilestone(key.milestone_id)
                                  }
                                >
                                  Start Milestone
                                </button>
                              ) : (
                                ""
                              )}

                              {key.customer_status == 2 &&
                              key.customer_remark != "" &&
                              key.vendor_mark_as_complete_status == 0 ? (
                                <p className="invoiceuploaded">
                                  Customer Reject this milestone -
                                  {parse(key.customer_remark)}
                                </p>
                              ) : (
                                ""
                              )}

                              {(key.milestone_start_close_status == 1 &&
                                key.vendor_mark_as_complete_status == 0) ||
                              key.vendor_mark_as_complete_status == 2 ? (
                                <Popup
                                  trigger={
                                    <div className="milestoneremark">
                                      {(key.milestone_start_close_status == 1 &&
                                        key.vendor_mark_as_complete_status ==
                                          0 &&
                                        key.customer_status == 1) ||
                                      key.vendor_mark_as_complete_status ==
                                        2 ? (
                                        <p style={{ color: "red" }}>
                                          Your milestone is rejected by client.
                                          {parse(key.customer_remark)}
                                          Kindly resubmit the milestone.
                                        </p>
                                      ) : (
                                        ""
                                      )}

                                      <button className="btn markascomplete">
                                        Mark as Complete
                                      </button>
                                    </div>
                                  }
                                  modal
                                  nested
                                >
                                  {(close) => (
                                    <div className="popup">
                                      <div className="popup-content">
                                        <button
                                          className="close-btn popupclose"
                                          onClick={close}
                                        >
                                          &times;
                                        </button>
                                        <div>
                                          <h3 className="addproject">
                                            Send Remark
                                          </h3>
                                          <form
                                            onSubmit={handleSubmit}
                                            noValidate
                                            autoComplete="off"
                                            className="Project-form"
                                          >
                                            <div className="form-group">
                                              <label>
                                                Remark -
                                                <p className="remarknote">
                                                  Add remark regarding the
                                                  milestone and click on submit
                                                  remark button
                                                </p>
                                              </label>

                                              <CKEditor
                                                type="texteditor textarea"
                                                name="project_description"
                                                placeholder="Description"
                                                editor={ClassicEditor}
                                                onReady={(editor) => {
                                                  // You can store the "editor" and use when it is needed.
                                                }}
                                                onChange={(event, editor) => {
                                                  const data = editor.getData();
                                                  handleChangeText(data);
                                                }}
                                                value={formValues.vendor_remark}
                                                onBlur={(event, editor) => {}}
                                                onFocus={(event, editor) => {}}
                                              />
                                            </div>
                                            <p className="err-message"></p>

                                            <br />
                                            <div className="form-group">
                                              <button
                                                onClick={() => {
                                                  formValues.milestone_id =
                                                    key.milestone_id;
                                                }}
                                                type="submit"
                                                className="btn markascomplete"
                                              >
                                                <i className="far fa-paper-plane"></i>
                                                Submit Remark
                                              </button>
                                            </div>
                                          </form>
                                        </div>

                                        {/* <div className="popup-footer">
                                          <button
                                            className="btn-signup"
                                            onClick={() => {
                                              setRejected(0);
                                              close();
                                            }}
                                          >
                                            Close
                                          </button>
                                        </div> */}
                                      </div>
                                    </div>
                                  )}
                                </Popup>
                              ) : key.milestone_start_close_status == 1 &&
                                key.vendor_mark_as_complete_status == 1 ? (
                                <p className="waitingmessage">
                                  Waiting for customer feedback
                                </p>
                              ) : (
                                ""
                              )}
                              <p className="waitingmessage">
                                {key.customer_status == 1 &&
                                key.milestone_start_close_status == 2 ? (
                                  <p className="waitingmessage">
                                    Milestone Approved by Customer. This
                                    milestone is complete now. <br />
                                    <div className="milestoneremark">
                                      {parse(key.customer_remark)}
                                    </div>
                                  </p>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>

                          {/******Meru Code****/}
                          {/* <div>
                            {(function (rows, status) {
                              if (status == 0) {
                                if (index == 0) {
                                  rows.push();
                                } else if (index - 1 >= 0) {
                                  if (
                                    item.milestonelist[index - 1]
                                      .customer_status == 0 ||
                                    2
                                  ) {
                                    rows.push(
                                      <div>
                                       <button className="btn-signup" disabled>
                                          Mark as Complete
                                        </button> 
                                      </div>
                                    );
                                  }
                                } else {
                                  rows.push(
                                    <div>
                                      <Popup
                                        trigger={
                                          <button className="btn-signup">
                                            Mark as Complete
                                          </button>
                                        }
                                        modal
                                        nested
                                      >
                                        {(close) => (
                                          <div className="popup">
                                            <div className="popup-content">
                                              <button
                                                className="close-btn"
                                                onClick={close}
                                              >
                                                &times;
                                              </button>
                                              <div>
                                                <h3 className="addproject">
                                                  Send Remark
                                                </h3>
                                                <form
                                                  onSubmit={handleSubmit}
                                                  noValidate
                                                  autoComplete="off"
                                                  className="Project-form"
                                                >
                                                  <div className="form-group">
                                                    <label>Remark</label>

                                                    <CKEditor
                                                      type="texteditor textarea"
                                                      name="project_description"
                                                      placeholder="Description"
                                                      editor={ClassicEditor}
                                                      onReady={(editor) => {
                                                        // You can store the "editor" and use when it is needed.
                                                      }}
                                                      onChange={(
                                                        event,
                                                        editor
                                                      ) => {
                                                        const data = editor.getData();
                                                        handleChangeText(data);
                                                      }}
                                                      value={
                                                        formValues.vendor_remark
                                                      }
                                                      onBlur={(
                                                        event,
                                                        editor
                                                      ) => {}}
                                                      onFocus={(
                                                        event,
                                                        editor
                                                      ) => {}}
                                                    />
                                                  </div>
                                                  <p className="err-message"></p>

                                                  <br />
                                                  <div className="form-group">
                                                    <button
                                                      onClick={() => {
                                                        formValues.milestone_id =
                                                          key.milestone_id;
                                                      }}
                                                      type="submit"
                                                      className="btn btn-primary"
                                                    >
                                                      <i className="far fa-paper-plane"></i>
                                                      Submit Remark
                                                    </button>
                                                  </div>
                                                </form>
                                              </div>

                                              <div className="popup-footer">
                                                <button
                                                  className="btn-signup"
                                                  onClick={() => {
                                                    setRejected(0);
                                                    close();
                                                  }}
                                                >
                                                  Close
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Popup>
                                    </div>
                                  );
                                }
                              } else if (status == 1) {
                                rows.push(
                                  <div>
                                    <button
                                      className="btn-signup"
                                      disabled
                                      style={{
                                        backgroundColor: "green",
                                        color: "white",
                                      }}
                                    >
                                      Approved
                                    </button>
                                  </div>
                                );
                              } else if (status == 2) {
                                rows.push(
                                  <div>
                                    <Popup
                                      trigger={
                                        <>
                                          <rs className="got-rejected">
                                            Rejected by Customer
                                          </rs>
                                          <button className="btn-signup">
                                            Resend
                                          </button>
                                        </>
                                      }
                                      modal
                                      nested
                                    >
                                      {(close) => (
                                        <div className="popup">
                                          <div className="popup-content">
                                            <button
                                              className="close-btn"
                                              onClick={close}
                                            >
                                              &times;
                                            </button>
                                            <div>
                                              <h3 className="addproject">
                                                Send Remark
                                              </h3>
                                              <form
                                                onSubmit={handleSubmit}
                                                noValidate
                                                autoComplete="off"
                                                className="Project-form"
                                              >
                                                <div className="form-group">
                                                  <label>Remark</label>

                                                  <CKEditor
                                                    type="texteditor textarea"
                                                    name="project_description"
                                                    placeholder="Description"
                                                    editor={ClassicEditor}
                                                    onReady={(editor) => {
                                                      // You can store the "editor" and use when it is needed.
                                                    }}
                                                    onChange={(
                                                      event,
                                                      editor
                                                    ) => {
                                                      const data = editor.getData();
                                                      handleChangeText(data);
                                                    }}
                                                    value={
                                                      formValues.vendor_remark
                                                    }
                                                    onBlur={(
                                                      event,
                                                      editor
                                                    ) => {}}
                                                    onFocus={(
                                                      event,
                                                      editor
                                                    ) => {}}
                                                  />
                                                </div>
                                                <p className="err-message"></p>

                                                <br />
                                                <div className="form-group">
                                                  <button
                                                    onClick={() => {
                                                      formValues.milestone_id =
                                                        key.milestone_id;
                                                    }}
                                                    type="submit"
                                                    className="btn btn-primary"
                                                  >
                                                    <i className="far fa-paper-plane"></i>
                                                    Submit Remark
                                                  </button>
                                                </div>
                                              </form>
                                            </div>

                                            <div className="popup-footer">
                                              <button
                                                className="btn-signup"
                                                onClick={() => {
                                                  setRejected(0);
                                                  close();
                                                }}
                                              >
                                                Close
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Popup>
                                  </div>
                                );
                              }

                              return rows;
                            })([], key.customer_status)}
                          </div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

const milestoneStart = async (mid, myHeaders) => {
  var raw = {
    milestone_id: mid,
    type: "S",
  };

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow",
  };

  await fetch(
    `${BaseUrl}/vendordashboard/Start-Project-Milestone-Wise`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      console.log(result);
      if (result.status == 200) {
        toast("Milestone Start successfully");
        window.location.reload(true);
      } else {
        toast("Milestone Cannot Start");
      }
    })
    .catch((error) => console.log("error", error));
};

export default ViewVendorMilestone;
