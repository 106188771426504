import React from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import logo from "../../assets/images/conqt-logo.png";
import Pdf from "react-to-pdf";
import DataTable from "react-data-table-component";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const CustomerInvoice = (props) => {
  //   console.log(props.data, "data from checkout");
  //   console.log(props ,"props")
  //   console.log(props.milestoneId, "milestone");
  //   console.log(props.data[0].milestonelist,"milestone list");

  const ref = React.createRef();
  const user = JSON.parse(localStorage.getItem("user"));

  let selectedMilestone;
  if (props.data[0].milestonelist) {
    selectedMilestone = props.data[0].milestonelist[props.milestoneId];
    // console.log(selectedMilestone, "selectedMilestone");
  }

  const onClickBack = () => {
    window.location.reload();
  };

  let scaleSize = 0.75;
  let xaxis = 16;

  if (window.innerWidth < 1366 && window.innerWidth > 1290) {
    scaleSize = 0.75;
    xaxis = 16;
  } else if (window.innerWidth < 2000 && window.innerWidth > 1566) {
    scaleSize = 0.45;
    xaxis = 8;
  } else if (window.innerWidth < 1290) {
    scaleSize = 0.68;
    xaxis = 11;
  } else if (window.innerWidth > 1500 && window.innerWidth < 1560) {
    scaleSize = 0.63;
    xaxis = 4;
  } else {
    scaleSize = 0.63;
  }
  console.log(scaleSize, "scaleSize", xaxis, "xaxis");

  let priceString = selectedMilestone.user_milestone_amount;
  let priceNumber = parseFloat(priceString);
  let priceFormatted = priceNumber.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <div>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          <p className="receipt-person-name" style={{ marginLeft: "22px" }}>
            Hi {props.user} ,
          </p>
          <p className="receipt-delighted-msg" style={{ marginLeft: "22px" }}>
            This Invoice is generated for {props.data[0].project_name} and
            Milestone No.{selectedMilestone.milestone_id}
          </p>

          <div ref={ref} style={{ padding: "20px" }}>
            <div className="cs-rs-main">
              <div className="cs-rs-header">
                <img className="conqt-logo" src={logo}></img>

                <p className="receipt-invoice cs-invoice-number">
                  Invoice #:
                  <span className="receipt-invoice-number">
                    &nbsp; {selectedMilestone.milestone_id}
                  </span>{" "}
                </p>
              </div>
              <div className="cs-billed-details-div">
                <div className="cs-billed-details">
                  <p className="cs-receipt-header">Billed By</p>
                  <p className="receipt-invoice">ConQt Technology</p>
                  <p className="address">Pte 11 Collyer Quay #17-00,</p>
                  <p className="address"> The Arcade</p>
                  <p className="address">Singapore – 049317</p>
                  <p className="address">Phone: (+91) 941565-0637</p>
                  <p className="address">Lut NO: AD090422023238A</p>
                  <p className="address">IEC Code: AAECF8340F</p>
                </div>
                <div className="cs-billed-details">
                  <p className="cs-receipt-header">Billed To</p>
                  <p className="receipt-invoice">
                    {user.user_info.company_name}
                  </p>
                  <p className="address">
                    {user.user_info.address} , {user.user_info.state}
                  </p>
                  <p className="address">
                    {" "}
                    {user.user_info.zipcode} - {user.user_info.country}
                  </p>
                  <p className="address">
                    Phone: {user.user_info.phone_number}
                  </p>
                </div>
              </div>
              {/* <table className="cs-rs-table">
                <tr className="cs-rs-table-header">
                  <th>Description</th>
                  <th className="cs-rs-unit">Unit</th>
                  <th>Unit Price</th>
                  <th className="cs-rs-total">Total Price</th>
                </tr>
                <tr className="cs-rs-table-details">
                  <td className="cs-rs-desc">
                    {selectedMilestone.milestone_description &&
                      parse(selectedMilestone.milestone_description)}
                  </td>
                  <td className="cs-rs-count">{selectedMilestone.count}</td>
                  <td className="cs-rs-desc">
                    {priceFormatted} {selectedMilestone.user_currency}
                  </td>
                  <td className="cs-rs-amt">
                    {priceFormatted} {selectedMilestone.user_currency}
                  </td>
                </tr>
              
                <tr className="cs-rs-table-details">
                  <td style={{ borderTop: "0px" }}></td>
                  <td style={{ borderTop: "0px" }} className="cs-rs-unit"></td>
                  <td style={{ borderTop: "0px" }}>--</td>
                  <td style={{ borderTop: "0px" }} className="cs-rs-total">
                    --
                  </td>
                </tr>
                <tr className="cs-rs-table-footer">
                  <td></td>
                  <td></td>
                  <td
                    style={{ color: "#304ffe", borderTop: "0px" }}
                    className="cs-rs-table-sub"
                  >
                    SubTotal
                  </td>
                  <td
                    className="cs-rs-table-sub cs-rs-total"
                    style={{ borderTop: "0px" }}
                  >
                    {priceFormatted} {selectedMilestone.user_currency}
                  </td>
                </tr>
                <tr className="cs-rs-table-footer">
                  <td></td>
                  <td></td>
                  <td
                    style={{ color: "#304ffe", borderTop: "0px" }}
                    className="cs-rs-table-sub"
                  >
                    Tax
                  </td>
                  <td
                    className="cs-rs-table-sub cs-rs-total"
                    style={{ borderTop: "0px" }}
                  >
                    0.00
                  </td>
                </tr>
                <tr className="cs-rs-table-footer">
                  <td></td>
                  <td></td>
                  <td
                    style={{
                      color: "#304ffe",
                      borderTop: "0px",
                      fontWeight: "700",
                    }}
                    className="cs-rs-table-sub"
                  >
                    Total
                  </td>
                  <td
                    className="cs-rs-table-sub cs-rs-total"
                    style={{ borderTop: "0px" }}
                  >
                    {priceFormatted} {selectedMilestone.user_currency}
                  </td>
                </tr>
              </table> */}
              <table className="cs-rs-table">
                  <tr className="cs-rs-table-header">
                    <th>S.No</th>
                    <th className="cs-rs-unit">Description</th>
                    <th>Unit</th>
                    <th className="cs-rs-total">Unit Price</th>
                    <th className="cs-rs-total">Total Price</th>
                  </tr>
                  <tr className="cs-rs-table-details">
                    <td className="cs-rs-desc">{selectedMilestone.count}</td>
                    <td className="cs-rs-count" style={{width:"40%"}}>
                      {selectedMilestone.milestone_description &&
                        parse(selectedMilestone.milestone_description)}
                    </td>
                    <td className="cs-rs-desc">{selectedMilestone.count}</td>
                    <td
                      className="cs-rs-total"
                      style={{
                        whiteSpace: "nowrap",
                        borderBottom: "0px",
                        borderTop: "0px",
                      }}
                    >
                      {" "}
                      {priceFormatted} {selectedMilestone.user_currency}
                    </td>
                    <td className="cs-rs-amt">
                      {" "}
                      {priceFormatted} {selectedMilestone.user_currency}
                    </td>
                  </tr>
                
                  <tr className="cs-rs-table-details">
                    <td style={{ borderTop: "0px" }}></td>
                    <td
                      style={{
                        borderTop: "0px",
                        borderLeft: "0px",
                        borderRight: "0px",
                      }}
                    ></td>
                    <td style={{ borderTop: "0px" }}></td>
                    <td style={{ borderTop: "0px", borderLeft: "0px" }}></td>
                    <td
                      style={{ borderTop: "0px" }}
                      className="cs-rs-total"
                    ></td>
                  </tr>
                  <tr className="cs-rs-table-footer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#304ffe", borderTop: "0px" }}
                      className="cs-rs-table-sub"
                    >
                      SubTotal
                    </td>
                    <td
                      className="cs-rs-table-sub cs-rs-total"
                      style={{ borderTop: "0px" }}
                    >
                      {" "}
                      {priceFormatted} {selectedMilestone.user_currency}
                    </td>
                  </tr>
                  <tr className="cs-rs-table-footer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#304ffe", borderTop: "0px" }}
                      className="cs-rs-table-sub"
                    >
                      Tax
                    </td>
                    <td
                      className="cs-rs-table-sub cs-rs-total"
                      style={{ borderTop: "0px" }}
                    >
                      0.00
                    </td>
                  </tr>
                  <tr className="cs-rs-table-footer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td
                      style={{ color: "#304ffe", borderTop: "0px" }}
                      className="cs-rs-table-sub"
                    >
                      Total
                    </td>
                    <td
                      className="cs-rs-table-sub cs-rs-total"
                      style={{ borderTop: "0px", fontWeight: "700" }}
                    >
                      {" "}
                      {priceFormatted} {selectedMilestone.user_currency}
                    </td>
                  </tr>
                </table>
              <div className="ms-details-in-mobile-view">
                <div className="ms-details">
                  <div className="cs-ms-details-in-mb">
                    <p className="cs-rs-details-header">Description</p>
                    <p className="cs-rs-details-value">
                      {" "}
                      {selectedMilestone.milestone_description &&
                        parse(selectedMilestone.milestone_description)}
                    </p>
                  </div>
                  <div className="cs-ms-details-in-mb">
                    <p className="cs-rs-details-header">Unit</p>
                    <p className="cs-rs-details-value">
                      {selectedMilestone.count}
                    </p>
                  </div>
                  <div className="cs-ms-details-in-mb">
                    <p className="cs-rs-details-header cs-ms-desc-header">
                      Unit Price
                    </p>
                    <p className="cs-rs-details-value cs-ms-desc-value">
                      {priceFormatted} {selectedMilestone.user_currency}
                    </p>
                  </div>
                  <div className="cs-ms-details-in-mb">
                    <p className="cs-rs-details-header">Total Price</p>
                    <p className="cs-rs-details-value">
                      {priceFormatted} {selectedMilestone.user_currency}
                    </p>
                  </div>
                  <div className="cs-ms-details-in-mb">
                    <p className="cs-rs-details-header">Sub Total</p>
                    <p className="cs-rs-details-value">
                      {priceFormatted} {selectedMilestone.user_currency}
                    </p>
                  </div>
                  <div className="cs-ms-details-in-mb">
                    <p className="cs-rs-details-header">Tax</p>
                    <p className="cs-rs-details-value">0.00</p>
                  </div>
                  <div className="cs-ms-details-in-mb">
                    <p className="cs-rs-details-header">Total</p>
                    <p
                      className="cs-rs-details-value"
                      style={{ fontWeight: "600" }}
                    >
                      {priceFormatted} {selectedMilestone.user_currency}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div>
                <h5 className="cs-rs-bank-header">Bank Details</h5>
                <p className="cs-rs-bank-details">
                  Account Name: Fyoocher Private Limited
                </p>
                <p className="cs-rs-bank-details">Bank Name: HDFC Bank</p>
                <p className="cs-rs-bank-details">
                  Account No.: 50200061928274
                </p>
                <p className="cs-rs-bank-details">Bank Code: 2624</p>
                <p className="cs-rs-bank-details">Swift Code: HDFCINBB</p>
                <p className="cs-rs-bank-details">IFSC Code: HDFC0002624</p>
                <p className="cs-rs-bank-details">Bank Branch: Mahoba</p>
              </div> */}
              <div className="cs-rs-terms-div">
                <h5 className="cs-rs-bank-terms">Terms and Conditions</h5>
                <ol className="cs-rs-bank-details">
                  <li>
                    {" "}
                    1. Please pay within 15 days from the date of invoice,
                    overdue interest @18% will be charged on delayed payments
                  </li>
                  <li>
                    {" "}
                    2. Please quote proforma invoice number wheile requesting
                    for the final tax invoice.
                  </li>
                  <li>
                    {" "}
                    3. This invoice is raised for the efforts of the resources
                    outsourced for the month of November 2022
                  </li>
                  <li>
                    {" "}
                    4. Computer generated invoice, therefore not require
                    signature.
                  </li>
                </ol>
              </div>
              {/* <div className="cs-billed-details-div">
                <div>
                  <hr className="cs-rs-hr"></hr>
                  <p className="cs-rs-bank-details">Authorized Signature </p>
                  <p className="cs-rs-bank-details">(ConQT)</p>
                </div>

                <div className="cs-rs-received">
                  <hr className="cs-rs-hr"></hr>
                  <p className="cs-rs-bank-details">Received by </p>
                  <p className="cs-rs-bank-details">Name :</p>
                  <p className="cs-rs-bank-details">Company Stamp</p>
                </div>
              </div> */}
            </div>
          </div>

          <div className="receipt-btn-div">
            <Pdf
              targetRef={ref}
              filename={"ConQt-Invoice-" + selectedMilestone.milestone_id}
              x={xaxis}
              y={1.5}
              scale={scaleSize}
            >
              {({ toPdf }) => (
                <button className="receipt-print-btn" onClick={toPdf}>
                  Download Invoice
                </button>
              )}
            </Pdf>

            <button onClick={onClickBack} className="receipt-back-btn">
              {" "}
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerInvoice;
