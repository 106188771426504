import React from "react";
import { Loader, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
import "./PageLoader.css";
import Logo from "../../assets/images/logo.svg";

const PageLoader = () => {
  return (
    <>
      <div className="loader-div">
        <span className="loader-text" >Connecting...</span>

        <div className="page-loader">
          <Loader size="md" speed="normal" center />
        </div>
      </div>
    </>
  );
};

export default PageLoader;
