import React, { useEffect, useState } from "react";
import "./AddNewProducts.css";
import { useDispatch, useSelector } from "react-redux";
import { getIndustryListTableData } from "../../../Store/Reducers/IndustryListReducer";
import { getDepartmentListTableData } from "../../../Store/Reducers/DepartmentListReducer";
import { getCategoryListTableData } from "../../../Store/Reducers/CategoryListReducer";
import Multiselect from "multiselect-react-dropdown";

const ProductFilter = ({ register, errors, setValue, data }) => {
  const dispatch = useDispatch();
  const { industryList } = useSelector((state) => state.industryList);
  const { categoryList } = useSelector((state) => state.categoryList);
  const { departmentList } = useSelector((state) => state.departmentList);
  // console.log(categoryList, "category");
  //   console.log(departmentList, "department");
  // console.log(industryList, "industry");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);


  const str = data && data.industry_name;
  const preselectedOptions = [];
  const depStr = data && data.department_name;
  const preSelcetedDepOptions = [];

  if (str && str.length > 0) {
    const arr = str.split(",");

    if (arr.length > 1) {
      for (let i = 0; i < arr.length; i++) {
        const key = "key" + (i + 1);
        const obj = { industry_name: arr[i] };
        preselectedOptions.push(obj);
      }
    } else if (arr.length === 1) {
      const obj = { industry_name: arr[0] };
      preselectedOptions.push(obj);
    } else {
      const obj = { industry_name: str };
      preselectedOptions.push(obj);
    }
  } else {
    console.log("Input string is undefined or null.");
  }

  if (depStr && depStr.length > 0) {
    const arr = depStr.split(",");

    if (arr.length > 1) {
      for (let i = 0; i < arr.length; i++) {
        const key = "key" + (i + 1);
        const obj = { department_name: arr[i] };
        preSelcetedDepOptions.push(obj);
      }
    } else if (arr.length === 1) {
      const obj = { department_name: arr[0] };
      preSelcetedDepOptions.push(obj);
    } else {
      const obj = { department_name: str };
      preSelcetedDepOptions.push(obj);
    }
  } else {
    console.log("Input string is undefined or null.");
  }
  // console.log("preselectedOptions", preselectedOptions);
  const onChangeCategory = (e) => {
    // console.log(e.target.value , "eeeeeee")
    const category = categoryList.find(
      (category) => category.category_id == e.target.value
      //   console.log(e.target.value === category.category_name)
    );
    console.log(category, "category");
    setSelectedCategory(category.sub_categories);
    setSelectedSubcategory(null);
  };

  const handleSubcategoryChange = (e) => {
    const subcategory = selectedCategory.find(
      (subcategory) => subcategory.category_id === e.target.value
    );

    setSelectedSubcategory(subcategory);
  };
  useEffect(() => {
    dispatch(getIndustryListTableData());
    dispatch(getDepartmentListTableData());
    dispatch(getCategoryListTableData());
  }, []);



  const onSelectIndustry = (selectedIndustry) => {
    setValue("industry_name", selectedIndustry);
  };
  const onSelectDepartment = (selectedDepartment) => {
    setValue("department_name", selectedDepartment);
  };
  return (
    <>
      <div className="product-border">
        <h3 className="addproduct">Filter</h3>
      </div>
      <div className="ap-filter-div">
        <div className="anp-filter">
          <label className="anp-label pb-2">Category</label>
          {!data && (
            <select
              className="anp-input"
              {...register("main_category", {
                required: true,
                onChange: onChangeCategory,
              })}
            >
              {" "}
              <option value="" hidden>
                Select
              </option>
              {categoryList &&
                categoryList.slice(0, 2).map((item) => {
                  return (
                    <option key={item.category_name} value={item.category_id}>
                      {item.category_name}
                    </option>
                  );
                })}
            </select>
          )}
          {data && (
            <input
              type="text"
              className="anp-input"
              disabled
              value={data.parent_id == 1 ? "Software" : "IT Hardware"}
            />
          )}

          {errors.main_category && (
            <span className="error-msg">Please select the category</span>
          )}
        </div>

        {data && (
          <div className="anp-filter">
            <label className="anp-label pb-2">Sub Category</label>
            <input
              type="text"
              className="anp-input"
              disabled
              {...register("category_name", {})}
            />
          </div>
        )}
        {!data && selectedCategory && (
          <div className="anp-filter">
            <label className="anp-label pb-2">Sub Category</label>
            <select
              className="anp-input"
              {...register("category_name", {
                required: true,
                onChange: handleSubcategoryChange,
                value: data && data.category_name,
              })}
            >
              {" "}
              <option value="" hidden>
                Select
              </option>
              {selectedCategory.length &&
                selectedCategory.map((subcategory) => (
                  <option
                    key={subcategory.category_id}
                    value={`${subcategory.category_id}_${subcategory.category_name}`}
                  >
                    {subcategory.category_name}
                  </option>
                ))}
            </select>
            {errors.category_name && (
              <span className="error-msg" style={{ whiteSpace: "nowrap" }}>
                Please select the sub category
              </span>
            )}
          </div>
        )}
        <div className="anp-filter">
          <label className="anp-label pb-2">Industry</label>

          <Multiselect
            options={industryList}
            displayValue="industry_name"
            onSelect={onSelectIndustry}
            onRemove={onSelectIndustry}
            selectedValues={
              preselectedOptions.length > 0 ? preselectedOptions : null
            }
          />
          <input
            {...register("industry_name", {
              required: true,
            })}
            type="hidden"
          />
          {errors.industry_name && (
            <span className="error-msg">Please select the industry</span>
          )}
        </div>
        <div className="anp-filter">
          <label className="anp-label pb-2">Department</label>

          <Multiselect
            options={departmentList}
            onSelect={onSelectDepartment}
            onRemove={onSelectDepartment}
            displayValue="department_name"
            selectedValues={preSelcetedDepOptions}
          />
          <input
            {...register("department_name", {
              required: true,
            })}
            type="hidden"
          />
          {errors.department_name && (
            <span className="error-msg">Please select the department</span>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductFilter;
