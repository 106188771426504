import React, { useState, useEffect } from "react";
import "./page.css";
import { BaseUrl } from "../../../Api/api";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import DragDrop from "./dragandrop";
import Viewer from "react-viewer";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./util.js";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CommonApi from "../../../Api/commonApi";
import Popup from "reactjs-popup";
import closeButton from "./images/closeButton.png";
const BankDetails = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [error, showerror] = useState(false);
  const [showBar, setShowBar] = useState("0");
  const [selectedFilesNames, setSelectedFileNames] = useState([]);
  const [userFile, setUserFile] = useState({});
  const [visible, setVisible] = useState(false);
  const [activeImageUrl, setActiveImageUrl] = useState("");
  const [overAllProgressValue, setOverAllProgressValue] = useState(0);
  const [popup, setPopup] = useState(true);

  let navigate = useNavigate();
  const userToken = localStorage.getItem("token");

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const watchAllFields = watch();

  const dragFiles = (files) => {
    let temp = [];
    Object.values(files).forEach((key) => {
      temp.push(key.name);
    });
    setSelectedFileNames(temp);
    setUserFile(files);
  };

  useEffect(() => {
    try {
      const userToken = localStorage.getItem("token");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      CommonApi.ProfileApi().then((vsdata) => {
        console.log("ApiRes", vsdata);
        if (vsdata.status === 200) {
          setOverAllProgressValue(vsdata.data.profile_complete_status);
        }
      });

      fetch(`${BaseUrl}/vendor/Get-Vendor-Bank-Details-Profile`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          if (result.data.length > 0) {
            setData(result.data);
          } else {
            navigate("/bank-details/edit");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);
  useEffect(() => {
    if (data[0]) {
      Object.entries(data[0]).forEach(([name, value]) => {
        if (name === "bank_account_no") {
          setValue("bank_account_number", value);
        } else if (name === "account_name") {
          setValue("account_holder_name", value);
        } else {
          setValue(name, value);
        }
      });
    }
  }, [data[0]]);
  const onSubmit = (data) => {
    console.log(data, "*********submit*********");
    const Token = localStorage.getItem("token");
    const toGetCurrencySymbol = localStorage.getItem("user");
    const currencySymbol = JSON.parse(toGetCurrencySymbol).user_info.symbol;
    data = { ...data };

    dispatch(updateStatusValue(progressBarValidation(7)));
    const formdata = new FormData();
    // formdata.append("bank_name", data.bank_name);
    // formdata.append("bank_account_number", data.bank_account_number);
    // formdata.append("bank_address", data.bank_address);
    // formdata.append("account_holder_name", data.account_holder_name);
    // formdata.append("account_type", data.account_type);
    // formdata.append("ifsc_code", data.ifsc_code);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      bank_name: data.bank_name,
      bank_account_number: data.bank_account_number,
      bank_address: data.bank_address,
      account_holder_name: data.account_holder_name,
      account_type: data.account_type,
      ifsc_code: data.ifsc_code,
    });
    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${BaseUrl}/vendor/Vendor-Bank-Details-Profile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          toast("Your Profile Saved Successfully");

          navigate("/bank-details");
          showerror(false);
        } else {
          showerror(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    const afterRemovingTheDuplicates = Object.values(watchAllFields).filter(
      (value) => value.length > 0
    );
    const percentage = (afterRemovingTheDuplicates.length / 4) * 100;
    setShowBar("" + percentage);
  }, [watchAllFields]);

  const handleNext = (e) => {
    window.location = "/customer-reviews";
  };

  const handleConfirm = (e) => {
    toast.success("Thank you for confirm bank details");
    setPopup(false);
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar isShow={true} value={overAllProgressValue} />
        <main className="maincontent">
          <div>
            <div className="companydoctext">
              <div class="wrapper">
                <h2>Add Your Bank Details</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div class="input-box">
                    <input
                      type="text"
                      placeholder="Enter Your Bank Name"
                      {...register("bank_name", {
                        required: true,
                      })}
                    />
                    {errors.bank_name && (
                      <span className="error-msg">Please enter the value</span>
                    )}
                  </div>

                  <div class="input-box">
                    <input
                      type="text"
                      placeholder="Enter Account Person Name"
                      {...register("account_holder_name", {
                        required: true,
                      })}
                    />
                    {errors.account_holder_name && (
                      <span className="error-msg">Please enter the value</span>
                    )}
                  </div>

                  <div class="input-box">
                    <input
                      type="text"
                      placeholder="Enter Account Type"
                      {...register("account_type", {
                        required: true,
                      })}
                    />
                    {errors.account_type && (
                      <span className="error-msg">Please enter the value</span>
                    )}
                  </div>
                  <div class="input-box">
                    <input
                      type="text"
                      placeholder="Enter Bank Account No."
                      {...register("bank_account_number", {
                        required: true,
                      })}
                    />
                    {errors.bank_account_number && (
                      <span className="error-msg">Please enter the value</span>
                    )}
                  </div>
                  <div class="input-box">
                    <input
                      type="text"
                      placeholder="Enter Bank IFSC Code"
                      {...register("ifsc_code", {
                        required: true,
                      })}
                    />
                    {errors.ifsc_code && (
                      <span className="error-msg" style={{ margin: "5px 0px" }}>
                        Please enter the value
                      </span>
                    )}
                  </div>

                  <div class="input-box">
                    <input
                      type="text"
                      placeholder="Enter Bank Address"
                      {...register("bank_address", {
                        required: true,
                      })}
                    />
                    {errors.bank_address && (
                      <span className="error-msg">Please enter the value</span>
                    )}
                  </div>

                  {/* <h4>Screenshots</h4>

                  <DragDrop onChange={dragFiles} />
                  <h6 className="last-heading">
                    300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
                  </h6> */}

                  {/* {selectedFilesNames.map((value) => {
                    return <p>{value}</p>;
                  })} */}

                  {Object.values(userFile).map((value) => {
                    return (
                      <>
                        <div className="portfolio-screenshots">
                          <img
                            src={URL.createObjectURL(value)}
                            alt=""
                            onClick={() => {
                              setVisible(true);
                              setActiveImageUrl(URL.createObjectURL(value));
                            }}
                          />
                        </div>
                      </>
                    );
                  })}
                  <Viewer
                    visible={visible}
                    onClose={() => {
                      setVisible(false);
                    }}
                    zoomSpeed={0.2}
                    images={[{ src: activeImageUrl, alt: activeImageUrl }]}
                  />
                  <div className="bank-check">
                    <input
                      type="checkbox"
                      {...register("checkbox", {
                        required: true,
                      })}
                    />
                    <label>
                      I/We hereby confirm that the information provided herein
                      is accurate, correct and complete with bank details are
                      genuine.{" "}
                    </label>
                  </div>
                  {errors.checkbox && (
                    <span className="error-msg">
                      Please select the check box
                    </span>
                  )}
                  <Popup
                    open={popup && watch("checkbox")}
                    modal
                    position="left center"
                    overlayStyle={{
                      backgroundColor: "#999999A1",
                    }}
                  >
                    <div className="bankpopup">
                      <button
                        onClick={() => {
                          setPopup(false);
                        }}
                        className="areyousure-close-button"
                        //  onClick={close}
                      >
                        <img src={closeButton} alt="" />
                      </button>

                      <div className="companydoctext">
                        <table className="rwd-table">
                          <tbody>
                            <tr class="tabletopbar">
                              <th>Bank Details Fields</th>
                              <th className="text-center">Details</th>
                            </tr>
                            <tr>
                              <td data-th="Document Name">Bank Name</td>
                              <td data-th="View" className="cell doclist">
                                {watch("bank_name")}
                              </td>
                            </tr>
                            <tr>
                              <td data-th="Document Name">Account</td>
                              <td data-th="View" className="cell doclist">
                                {watch("account_holder_name")}
                              </td>
                            </tr>
                            <tr>
                              <td data-th="Document Name">Account Type</td>
                              <td data-th="View" className="cell doclist">
                                {watch("account_type")}
                              </td>
                            </tr>
                            <tr>
                              <td data-th="Document Name">Bank Account No</td>
                              <td data-th="View" className="cell doclist">
                                {watch("bank_account_number")}
                              </td>
                            </tr>
                            <tr>
                              <td data-th="Document Name">IFSC Code</td>
                              <td data-th="View" className="cell doclist">
                                {watch("ifsc_code")}
                              </td>
                            </tr>
                            <tr>
                              <td data-th="Document Name">
                                Bank Address Details
                              </td>
                              <td data-th="View" className="cell doclist">
                                {watch("bank_address")}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="popupconfirmbtn">
                          <button onClick={handleConfirm}>Confirm</button>
                        </div>
                      </div>
                      {/* 
                      <h1 className="bank-popup-text">Please Check Your Bank Details</h1>
                      <h2>Bank name : {watch("bank_name")}</h2>
                      <h2>
                        Account Person Name : {watch("account_holder_name")}
                      </h2>
                      <h2>Account Type : {watch("account_type")}</h2>
                      <h2>Bank Account No.: {watch("bank_account_number")}</h2>
                      <h2>Bank IFSC Code : {watch("ifsc_code")}</h2>
                      <h2>Bank Address : {watch("bank_address")}</h2>
                    </div>
                    <div class="input-box button"></div> */}
                    </div>
                  </Popup>
                  <div class="input-box button">
                    {/* <div onClick={onClickSave}>save</div> */}
                    <input type="Submit" value="Bank Details Submit Now" />
                  </div>
                </form>
              </div>
            </div>

            <div className="formbuton">
              {/* <Link to={`/AgencyProfile`}> */}
              <input
                type="submit"
                className="save-button"
                value="Next"
                onClick={handleNext}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default BankDetails;
