import React from "react";
import Faq from "react-faq-component";
import projectapply from "../../assets/conqt_guidelines/projectapply.png";
import projectapplyphone from "../../assets/conqt_guidelines/projectapplyphone.png";
import applied from "../../assets/conqt_guidelines/applied.png";
import applied_pc from "../../assets/conqt_guidelines/applied_pc.png";
import proposal from "../../assets/conqt_guidelines/proposal.png";
import proposalphone from "../../assets/conqt_guidelines/proposalphone.png";
import "./steps.css";

const data = {
  title: (
    <div className="step-heading">
      <h4>How to Submit a proposal ?</h4>
    </div>
  ),
  rows: [
    {
      title: (
        <div className="step-heading">
          <h4>Step 1: Build a Proposal</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={projectapplyphone} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={projectapply} alt="" />
          </span>

          <div className="steps-para">
            <p>
              1. After clicking on Apply for Project, you can build a proposal.{" "}
            </p>
            <p>2. Don’t forget to accept the terms and conditions. </p>
            <p>
              3. After completing the proposal details, submit the proposal.{" "}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 2: Successful submission of Proposal</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={applied} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={applied_pc} alt="" />
          </span>
          <p>
            After submitting the proposal, you will redirect again to the
            project-list page, where you can check your already applied
            projects.{" "}
          </p>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 3: My Proposal</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={proposalphone} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={proposal} alt="" />
          </span>
          <p>
            Click on Proposal and select My Proposal, where you can see all your
            applied Projects or the status of Your projects.{" "}
          </p>
        </div>
      ),
    },
  ],
};

export default function faq() {
  return (
    <div>
      <Faq data={data} />
    </div>
  );
}
