import React, { useState } from "react";
import VendorIcon from "../../assets/images/vendor-icon.png";
import AmountTag from "../../assets/images/amount-tag-icon.png";
import ClockIcon from "../../assets/images/project-date-icon.png";
import Descripiton from "../../assets/images/descripiton-icon.png";
import ProjectIcon from "../../assets/images/project-name-icon.png";
import ResourcesIcon from "../../assets/images/resources-icon.png";
import SupportIcon from "../../assets/images/support-icon.png";
import ShowMore from "../../assets/images/show-more-icon.png";
import Showless from "../../assets/images/show-less-icon.png";
import Close from "../../assets/images/close-info.png";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import "./CustomerSelectedProposal.css";

const CustomerSelectedProposal = (props) => {
  const [showDetails, setShowDetails] = useState({
    descripiton: false,
    resources: false,
    support: false,
    work: false,
    conditions: false,
  });
  const [showDesc, setShowDesc] = useState([]);
  //console.log(props.proposalDetails, "proposalDetails from props");
  //console.log(props.milestoneDetails, "milestoneDetails from props");
  const onClickShowMore = (e, str) => {
    let temp = showDetails;
    temp = { ...temp, [str]: !temp[str] };
    // console.log(temp, "buttonClickShowMore");
    setShowDetails(temp);
  };

  const onClickShowMoreMilestoneDescription = (e, index) => {
    let arr = showDesc;
    arr[index] = !arr[index];
    setShowDesc([...arr]);
    //console.log(arr,index,"after update")
  };

  return (
    <div>
      {props.isShow && (
        <div className="cs-proposal-details-popup">
          <div className="customer-popup-proposal">
            <div className="popup-main">
              <div className="cs-proposal-popup-close">
                <img onClick={props.onClickOnClose} src={Close} alt=""></img>
              </div>
              <div className="cs-proposal-info-header-div">
                <h3 className="cs-proposal-header">Proposal Detail</h3>
              </div>
              <div className="project-name-category-div">
                <div>
                  <h3 className="customer-proposal-label">
                    <img className="project-icon" src={VendorIcon} alt=""></img>
                    Vendor name
                  </h3>
                  <div className="customer-project-text customer-project-timeline">
                    {props.proposalDetails.vendor_name}
                  </div>
                </div>
                <div>
                  <h3
                    style={{ gap: "10px" }}
                    className="customer-proposal-label"
                  >
                    <img className="project-icon" src={AmountTag} alt=""></img>
                    Proposal Amount
                  </h3>
                  <div className="customer-project-text customer-project-timeline">
                    {props.proposalDetails.vendor_currency}{" "}
                    {props.proposalDetails.customer_amount}
                  </div>
                </div>
                <div>
                  <h3 className="customer-proposal-label">
                    <img
                      className="project-timeline-icon"
                      src={ClockIcon}
                      alt=""
                    />
                    Timeline
                  </h3>
                  <div className="customer-project-text customer-project-timeline">
                    {props.proposalDetails.timeline}{" "}
                    {props.proposalDetails.timeline_unit}
                  </div>
                </div>
              </div>
              <div className="project-name-category-div">
                <div>
                  <h3 className="customer-proposal-label">
                    <img
                      className="project-icon"
                      src={Descripiton}
                      alt=""
                    ></img>
                    Description
                  </h3>
                  <div className="customer-project-text project-text-description">
                    {showDetails.descripiton
                      ? parse(props.proposalDetails.perposal_description)
                      : parse(
                          props.proposalDetails.perposal_description.substring(
                            0,
                            100
                          )
                        )}

                    {props.proposalDetails.perposal_description.length >
                      100 && (
                      <button
                        className="proposal-show-more-button"
                        onClick={(e) => {
                          onClickShowMore(e, "descripiton");
                        }}
                      >
                        {showDetails.descripiton ? "Show Less" : "Show More"}
                        {showDetails.descripiton ? (
                          <img className="project-icon" src={ShowMore} alt="" />
                        ) : (
                          <img className="project-icon" src={Showless} alt="" />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="project-name-category-div">
                <div>
                  <h3 className="customer-proposal-label" style={{gap:"5px"}}>
                    <img
                      className="project-icon"
                      src={ResourcesIcon}
                      alt=""
                    ></img>
                    Project Resources
                  </h3>
                  <div className="customer-project-text project-text-description">
                    {showDetails.resources
                      ? parse(props.proposalDetails.project_resources)
                      : parse(
                          props.proposalDetails.project_resources.substring(
                            0,
                            100
                          )
                        )}

                    {props.proposalDetails.project_resources.length > 100 && (
                      <button
                        className="proposal-show-more-button"
                        onClick={(e) => {
                          onClickShowMore(e, "resources");
                        }}
                      >
                        {showDetails.resources ? "Show Less" : "Show More"}
                        {showDetails.resources ? (
                          <img className="project-icon" src={ShowMore} alt="" />
                        ) : (
                          <img className="project-icon" src={Showless} alt="" />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="project-name-category-div">
                <div>
                  <h3 className="customer-proposal-label">
                    <img
                      className="project-icon"
                      src={SupportIcon}
                      alt=""
                    ></img>
                    Project Support
                  </h3>
                  <div className="customer-project-text project-text-description">
                    {showDetails.support
                      ? parse(props.proposalDetails.project_support)
                      : parse(
                          props.proposalDetails.project_support.substring(
                            0,
                            100
                          )
                        )}

                    {props.proposalDetails.project_support.length > 100 && (
                      <button
                        className="proposal-show-more-button"
                        onClick={(e) => {
                          onClickShowMore(e, "support");
                        }}
                      >
                        {showDetails.support ? "Show Less" : "Show More"}
                        {showDetails.support ? (
                          <img className="project-icon" src={ShowMore} alt="" />
                        ) : (
                          <img className="project-icon" src={Showless} alt="" />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="project-name-category-div">
                <div>
                  <h3 className="customer-proposal-label">
                    <img
                      className="project-icon"
                      src={ProjectIcon}
                      alt=""
                    ></img>
                    Scope of Work
                  </h3>
                  <div className="customer-project-text project-text-description">
                    {showDetails.work
                      ? parse(props.proposalDetails.out_of_scope)
                      : parse(
                          props.proposalDetails.out_of_scope.substring(0, 100)
                        )}

                    {props.proposalDetails.out_of_scope.length > 100 && (
                      <button
                        className="proposal-show-more-button"
                        onClick={(e) => {
                          onClickShowMore(e, "work");
                        }}
                      >
                        {showDetails.work ? "Show Less" : "Show More"}
                        {showDetails.work ? (
                          <img className="project-icon" src={ShowMore} alt="" />
                        ) : (
                          <img className="project-icon" src={Showless} alt="" />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="project-name-category-div">
                <div>
                  <h3 className="customer-proposal-label">
                    <img
                      className="project-icon"
                      src={Descripiton}
                      alt=""
                    ></img>
                    Terms & Conditions
                  </h3>
                  <div className="customer-project-text project-text-description">
                    {showDetails.conditions
                      ? parse(props.proposalDetails.terms_conditions)
                      : parse(
                          props.proposalDetails.terms_conditions.substring(
                            0,
                            100
                          )
                        )}

                    {props.proposalDetails.terms_conditions.length > 100 && (
                      <button
                        className="proposal-show-more-button"
                        onClick={(e) => {
                          onClickShowMore(e, "conditions");
                        }}
                      >
                        {showDetails.conditions ? "Show Less" : "Show More"}
                        {showDetails.conditions ? (
                          <img className="project-icon" src={ShowMore} alt="" />
                        ) : (
                          <img className="project-icon" src={Showless} alt="" />
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="">
                <h3 className="cs-proposal-header">Milestone</h3>
              </div>
              <table className="cs-milestone-table">
                <tr className="cs-ms-table">
                  <th>S.No.</th>
                  <th>Description</th>
                  <th>Percent</th>
                  <th>Amount</th>
                  <th>Days</th>
                </tr>
                {props.milestoneDetails.map((e, index) => {
                  return (
                    <tr className="cs-ms-table-data">
                      <td>
                        <div>{e.count}</div>
                      </td>
                      <td className="ms-table-desc">
                        <div>
                          {" "}
                          {!showDesc[index]
                            ? e.milestone_description.replace(
                                /^(.{50}[^\s]*).*/,
                                "$1"
                              )
                            : e.milestone_description}
                          {e.milestone_description.length > 50 && (
                            <button
                              className="proposal-show-more-button"
                              onClick={(e) => {
                                onClickShowMoreMilestoneDescription(e, index);
                              }}
                            >
                              {!showDesc[index] ? "Show More" : "Show Less"}
                            </button>
                          )}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div>{e.user_milestone_percent}%</div>
                      </td>
                      <td>
                        {" "}
                        <div>
                          {" "}
                          {e.user_currency} {e.user_milestone_amount}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div>
                          {new Date(e.milstone_timeline)
                            .toLocaleDateString("en-GB")
                            .replace(/\//g, "-")}
                        </div>{" "}
                      </td>
                    </tr>
                  );
                })}
              </table>
              <div className="ms-details-in-mobile-view">
                {props.milestoneDetails.map((e, index) => {
                  return (
                    <div className="ms-details">
                      <div className="cs-ms-details-in-mb">
                        <p className="cs-ms-details-header">S.No</p>
                        <p className="cs-ms-details-value">{e.count}</p>
                      </div>
                      <div className="cs-ms-details-in-mb">
                        <p className="cs-ms-details-header">Percent</p>
                        <p className="cs-ms-details-value">
                          {e.user_milestone_percent}%
                        </p>
                      </div>
                      <div className="cs-ms-details-in-mb">
                        <p className="cs-ms-details-header cs-ms-desc-header">
                          Description
                        </p>
                        <p className="cs-ms-details-value cs-ms-desc-value">
                          {!showDesc[index]
                            ? e.milestone_description.replace(
                                /^(.{50}[^\s]*).*/,
                                "$1"
                              )
                            : e.milestone_description}
                          {e.milestone_description.length > 50 && (
                            <button
                              className="proposal-show-more-button"
                              onClick={(e) => {
                                onClickShowMoreMilestoneDescription(e, index);
                              }}
                            >
                              {!showDesc[index] ? "Show More" : "Show Less"}
                            </button>
                          )}
                        </p>
                      </div>
                      <div className="cs-ms-details-in-mb">
                        <p className="cs-ms-details-header">Amount</p>
                        <p className="cs-ms-details-value">
                          {" "}
                          {e.user_currency} {e.user_milestone_amount}
                        </p>
                      </div>
                      <div className="cs-ms-details-in-mb">
                        <p className="cs-ms-details-header">Days</p>
                        <p className="cs-ms-details-value">
                          {" "}
                          {new Date(e.milstone_timeline)
                            .toLocaleDateString("en-GB")
                            .replace(/\//g, "-")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerSelectedProposal;
