import React, { useState, useEffect } from "react";
import "../Vendor/Vendor.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import Project from "../../assets/images/project.png";
import ApplyProject from "../../assets/images/ApplyProject.png";
import ProcessPro from "../../assets/images/processpro.png";
import CompProject from "../../assets/images/on-time.png";
import { Link, useNavigate } from "react-router-dom";
import RecentAnnouncement from "./RecentAnnouncement";
import { BaseUrl } from "../../Api/api.js";
import PageLoader from "../../components/PageLoader/PageLoader";

const Customer = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem("token");
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/user/Get-Customer-Project-Count`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("count", result.data);
          setData(result.data);
          setPageLoading(false);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const myProject = data[0]?.MyProject;
  const partnerView = data[0]?.VendorView;
  const Quotation = data[0]?.QuotationSubmitted;
  const projectCompleted = data[0]?.ProposalFinished;
  const handleMyProject = () => {
    if (myProject > 0) {
      navigate("/projectlist");
    }
  };
  const handleQuotation = () => {
    if (Quotation > 0) {
      navigate("/projectlist");
    }
  };

  const handlePartner = () => {
    if (partnerView > 0) {
      navigate("/projectlist");
    }
  };

  const handleProjectCompleted = () => {
    if (projectCompleted > 0) {
      navigate("/projectlist");
    }
  };

  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />

          <div className="vendor-dashboard">
            <div className="maindashbar">
              <h2>No.1 Business Matching Platform</h2>
              <Link to="/new-add-project">
                <button className="explore-btn">
                  <span>Add Projects </span>
                </button>
              </Link>
            </div>
            {data.map((key, announcement_id) => {
              return (
                <div className="topprobar">
                  {/* <Link to="/projectlist"> */}
                  <div className="topprodcard" onClick={handleMyProject}>
                    <div className="procard">
                      <h5>My Projects</h5>
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>{key.MyProject}</h4>
                        <img src={Project} className="topproicon" />
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                  {/* <Link to="/projectlist"> */}
                  <div className="topprodcard" onClick={handlePartner}>
                    <div className="procard">
                      <h5>Partner View</h5>
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>{key.VendorView}</h4>
                        <img src={ApplyProject} className="topproicon" />
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                  {/* <Link to="/projectlist"> */}
                  <div className="topprodcard" onClick={handleQuotation}>
                    <div className="procard">
                      <h5>Quotation Accepted</h5>
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>{key.QuotationSubmitted}</h4>
                        <img src={ProcessPro} className="topproicon" />
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}

                  {/* <Link to="/projectlist"> */}
                  <div className="topprodcard" onClick={handleProjectCompleted}>
                    <div className="procard">
                      <h5>Project Completed</h5>

                      <div className="d-flex align-items-center justify-content-between">
                        <h4>{key.ProposalFinished}</h4>
                        <img src={CompProject} className="topproicon" />
                      </div>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              );
            })}
          </div>

          <div className="dashboard-body">
            <div className="bodytable">
              <div className="projecttable">
                <div className="bodytitle">
                  <h2>Recent Announcement</h2>
                  <RecentAnnouncement />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
