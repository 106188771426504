import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Api/api";

export const getCategoryListTableData = createAsyncThunk("categoryList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}/product/Get-Parent-Category`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
        // console.log(result.data)
        return result.data
    })
    .catch((error) => console.log("error", error));
});



export const myReducer = createSlice({
  name: "categoryList",
  initialState: {
    loading: false,
    selectedCategoryId: "",
    categoryList: [],
    selectedSingleCategoryData:[],
  },
  reducers: {
    UpdateSelectedCategoryId: (state, action) => {
      state.selectedCategoryId = action.payload;
    },
    updateSelectedSingleCategoryData : (state, action) => {
       state.selectedSingleCategoryData = action.payload;
    },
    
  },
  extraReducers: {
    [getCategoryListTableData.pending]: (state) => {
      state.loading = true;
    },
    [getCategoryListTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.categoryList = payload;
      // console.log(payload,"payload from reducer") 
    },
   

  },


})

export const {
  UpdateSelectedCategoryId,
  updateSelectedSingleCategoryData,
} = myReducer.actions;

export default myReducer.reducer;
