import React from "react";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import "./nbcd.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const NewTermsAndConditions = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar isShow={false} />
          <div className="container mb-4 mt-2">
            <div className="top-unlock-component py-3">
              <div className="container">
                <BiArrowBack
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div className="container">
                <h3 className="terms-conditions-head py-3">
                  Purchase Terms and Conditions{" "}
                </h3>
                <p className="terms-conditions">
                  Thank you for your interest in purchasing an opportunity from
                  us. Before proceeding with your purchase, please read the
                  following terms and conditions carefully:{" "}
                </p>
                <div className="px-5  terms-conditions">
                  <p>
                    1. <strong>Definition:</strong> In these terms and
                    conditions, "we", "us" or "our" refers to the seller of the
                    opportunity, and "you" or "your" refers to the purchaser.{" "}
                  </p>
                  <p>
                    2. <strong>Payment: </strong>The purchase price for the
                    opportunity must be paid in full at the time of purchase,
                    unless otherwise agreed upon in writing by both parties.{" "}
                  </p>
                  <p>
                    3. <strong>Delivery: </strong>Upon receipt of payment, we
                    will deliver the opportunity to you within a reasonable time
                    frame.{" "}
                  </p>
                  <p>
                    4. <strong> Refunds: </strong> We do not offer refunds for
                    opportunities purchased. All sales are final.{" "}
                  </p>
                  <p>
                    5. <strong> Non-transferable: </strong>The opportunity is
                    non-transferable and can only be used by the purchaser.{" "}
                  </p>
                  <p>
                    6. <strong>Disclaimer: </strong>We make no guarantees or
                    representations regarding the outcome or success of the
                    opportunity. The purchaser assumes all risks and
                    responsibilities associated with the opportunity.{" "}
                  </p>
                  <p>
                    7. <strong>Confidentiality: </strong>The purchaser agrees to
                    keep all information related to the opportunity confidential
                    and not to disclose such information to any third party.
                  </p>
                  <p>
                    8. <strong>Governing Law: </strong>These terms and
                    conditions shall be governed by and construed in accordance
                    with the laws of the jurisdiction in which we are located.{" "}
                  </p>
                  <p>
                    9. <strong>Entire Agreement: </strong>These terms and
                    conditions constitute the entire agreement between us and
                    you with respect to the opportunity and supersede all prior
                    or contemporaneous communications and proposals, whether
                    oral or written.{" "}
                  </p>
                  <p>
                    10. <strong>Modification: </strong>We reserve the right to
                    modify these terms and conditions at any time without prior
                    notice. Any such modifications will be effective immediately
                    upon posting on our website or other notice to you.{" "}
                  </p>
                </div>
                <div className="terms-conditions">
                  By purchasing the opportunity, you agree to be bound by these
                  terms and conditions. If you do not agree to these terms and
                  conditions, please do not purchase the opportunity.
                </div>
              </div>

              <div className="container">
                <h3 className="terms-conditions-head py-3">Refund Policy </h3>
                <p className="terms-conditions">
                  Thank you for providing the specific details for your refund
                  policy. Please find below a sample 60-day refund policy based
                  on your requirements:{" "}
                </p>
                <div className="terms-conditions">
                  <p>
                    We understand that circumstances may change after purchasing
                    an opportunity, and we want to ensure that our customers are
                    satisfied with their purchases. If for any reason you are
                    not satisfied with the opportunity you purchased from us, we
                    offer a 60-day period during which you can request a
                    replacement opportunity.{" "}
                  </p>
                  <p>
                    In the event that you have not received a response from the
                    customer after purchasing the opportunity, our team will
                    provide you with another opportunity. However, we do not
                    offer refunds for purchases.{" "}
                  </p>
                  <p>
                    Before we provide you with a replacement opportunity, we
                    will request full information about your efforts to contact
                    the customer, including the emails you have sent and any
                    other relevant information. We will use this information to
                    validate your request for a replacement opportunity and to
                    ensure that the customer is provided with the best possible
                    experience.{" "}
                  </p>
                  <p>
                    Please note that we reserve the right to refuse a
                    replacement opportunity if we believe that the information
                    provided is inaccurate or if we suspect that the opportunity
                    has been abused or misused.{" "}
                  </p>
                  <p>
                    If you have any questions or concerns about our refund
                    policy, please do not hesitate to contact us. We are
                    committed to providing our customers with the highest level
                    of service and support.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewTermsAndConditions;
