import React, { useState, useEffect } from "react";
import "./page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { BsInfoCircle } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { FcCameraIdentification } from "react-icons/fc";
import "react-phone-number-input/style.css";
import PhoneInput, {
        isPossiblePhoneNumber,
        isValidPhoneNumber,
} from "react-phone-number-input";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./util.js";
import Spinner from "../../../components/Header/Spinner";
import { toast } from "react-toastify";
import { BaseUrl } from "../../../Api/api.js";
import CommonApi from "../../../Api/commonApi";
import Select from 'react-select';
import axios from 'axios';

const MyProfileEdit = () => {
        let navigate = useNavigate();
        const dispatch = useDispatch();
        const [error, showerror] = useState(false);
        const [phone, setPhone] = useState("");
        const [showBar, setShowBar] = useState("0");
        const [picture, setPicture] = useState("");
        const [pictureUrl, setPictureUrl] = useState("");
        const [userData, setUserData] = useState({});
        const [pageLoading, setPageLoading] = useState(false);
        const [profile, setProfile] = useState(false);
        // const [countryList, setCountryList] = useState([]);

        const userToken = localStorage.getItem("token");
        const userinfo = JSON.parse(localStorage.getItem("userinfo"));


        const {
                register,
                handleSubmit,
                watch,
                reset,
                setValue,
                formState: { errors },
        } = useForm();
        const watchAllFields = watch();
        // const handleChange = (e) => {
        //   setPicture(e.target.files[0]);
        //   let url = URL.createObjectURL(e.target.files[0]);
        //   setProfile(true);
        //   setPictureUrl(url);
        // };
        const [countryList, setCountryList] = useState(null)
        const [selectedCountry, setSelectedCountry] = useState(null);
        const [states, setStates] = useState([]);
        const [selectedState, setSelectedState] = useState(null);
        const [cities, setCities] = useState([]);
        const [selectedCity, setSelectedCity] = useState(null);
        const [city, setCity] = useState()
        const [state, setState] = useState()



        // Countries options for countries to pass this data into the Selector components.
        const countries = countryList?.map((e) => ({
                value: e.country,
                label: e.country,
        }));
        // State options for all the states to pass this data into the Selector components.
        const statesOptions = states?.map((state) => ({
                value: state,
                label: state
        }))
        // Cities options for all the cities to pass this data into the Selector components.
        const citiesOptions = cities?.map((city) => ({
                value: city,
                label: city
        }));

        useEffect(() => {
                axios
                        .get(`${BaseUrl}/common/Get-All-Country`)
                        .then((response) => {
                                if (response.data.status === 2000) {
                                        setCountryList(response.data.data.country_list);
                                }
                                else if (response.data.status === 401) {
                                        console.log("Unauthorized")
                                }
                        })
                        .catch((error) => {
                                console.log("state catch error", error)
                        })
        }, [])

        // calling the API for states based on the selectedCountry
        useEffect(() => {
                if (selectedCountry) {
                        axios
                                .get(`${BaseUrl}/universal/getAllStates/${selectedCountry?.value}`)
                                .then((res) => {
                                        if (res.data.status === 200) {
                                                setStates(res.data.data)
                                        }
                                        else if (res.data.status === 401) {
                                                console.log("state error")
                                        }
                                })
                                .catch((err) => {
                                        console.log("state catch error", err)
                                })
                }
        }, [selectedCountry]);
        // calling the API for cities based on the selectedCountry and selectedState as well
        useEffect(() => {
                if (selectedCountry && selectedState) {
                        axios
                                .get(`${BaseUrl}/universal//getAllCities/${selectedCountry?.value}/${selectedState?.value}`)
                                .then((res) => {
                                        if (res.data.status === 200) {
                                                setCities(res.data.data)
                                        }
                                        else if (res.data.status === 401) {
                                                console.log("city error")
                                        }
                                })
                                .catch((err) => {
                                        console.log("city catch error", err)
                                })
                }
        }, [selectedCountry && selectedState]);

        const handleCountryChange = (selectedOption) => {
                setSelectedCountry(selectedOption);
                setSelectedState(null)
                setSelectedCity(null);
               

        };
        const handleStateChange = (selectedOption) => {
                setSelectedState(selectedOption);
                setSelectedCity(null);

        }

        const handleCityChange = (selectedOption) => {
                setSelectedCity(selectedOption);

        }

        useEffect(() => {
                // Replace this with your actual logic to set the initial value based on edit data
                const editData = userData?.city // Assuming the selected city is City 2
                setSelectedCity(editData);
        }, []);

        const country = selectedCountry && selectedCountry?.value

        useEffect(() => {
                if (selectedState) {
                        setState(selectedState?.value)
                } else if (selectedState === null || selectedState === undefined) {
                        setState("")
                        // toast.warning("Please select")
                }
        }, [selectedState])

        useEffect(() => {
                if (selectedCity) {
                        setCity(selectedCity?.value)
                } else if (selectedCity === null || selectedCity === undefined) {
                        setCity("")
                }
        }, [selectedCity])




        const handleChange = (e) => {
                const file = e.target.files[0];

                // Allowed file types
                const allowedTypes = ["image/jpeg", "image/png"];

                // Check if the selected file is allowed
                if (file && allowedTypes.includes(file.type)) {
                        setPicture(file);
                        let url = URL.createObjectURL(file);
                        setProfile(true);
                        setPictureUrl(url);
                        toast.success("Your Profile Uploaded Successfully")
                } else {
                        toast.warning("Invalid file type. Please select a .jpg or .png file.")
                        e.target.value = "";
                }
        };

        const onSubmit = async (data) => {
                data = { ...data, phone: phone };
                if (isValidPhoneNumber(phone)) {
                        dispatch(updateStatusValue(progressBarValidation(1)));

                        const formdata = new FormData();
                        console.log("formdata", formdata);
                        formdata.append(
                                "company_name",
                                data.company_name == undefined
                                        ? userData.company_name
                                        : data.company_name
                        );
                        formdata.append("first_name", data.first_name);
                        formdata.append("last_name", data.last_name);
                        //formdata.append("email", data.email);
                        formdata.append("contact_number", data.phone);
                        if (pictureUrl.includes("blob")) {
                                formdata.append("files", picture, pictureUrl);
                        }
                        formdata.append("position", data.position);
                        formdata.append("department", data.department);
                        formdata.append("address", data.address);
                        formdata.append("city", city);
                        formdata.append("state", state);
                        formdata.append("country", country);
                        formdata.append("zipcode", data.zipcode);

                        // console.log("==", data.selectedCountry?.value)

                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${userToken}`);
                        var requestOptions = {
                                method: "POST",
                                body: formdata,
                                headers: myHeaders,
                                redirect: "follow",
                        };
                        fetch(`${BaseUrl}/vendor/Vendor-Update-Profile`, requestOptions)
                                .then((response) => response.json())
                                .then((result) => {
                                        if (result.status === 200) {

                                                toast("Your Profile Saved Successfully");
                                                CommonApi.ProfileApi().then((mpdata) => {
                                                        if (mpdata.status === 200) {
                                                                localStorage.setItem("userinfo", JSON.stringify(mpdata.data));
                                                                navigate("/vendor-profile");
                                                        }
                                                });

                                                showerror(false);
                                        } else {
                                                showerror(true);
                                                if (selectedCity === null || selectedCity === undefined) {
                                                        toast.warning("Please select a city")
                                                }
                                                if (selectedState === null || selectedState === undefined) {
                                                        toast.warning("Please select a state")
                                                }
                                        }
                                })
                                .catch((error) => {
                                if (selectedCity === null || selectedCity === undefined) {
                                        toast.warning("Please select a city")
                                }
                                        if (selectedState === null || selectedState === undefined) {
                                                toast.warning("Please select a state")
                                        }
                                        console.log("error", error)});
                }
        };


        useEffect(() => {
                setPageLoading(true);
                const Token = localStorage.getItem("token");
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${Token}`);
                var requestOptions = {
                        method: "GET",
                        headers: myHeaders,
                        redirect: "follow",
                };
                let data;

                fetch(`${BaseUrl}/vendor/Get-Vendor-My-Profile`, requestOptions)
                        .then((response) => response.json())
                        .then((result) => setUserData(result.data[0]))
                        .catch((error) => console.log("error", error));
                setPageLoading(false);
        }, []);

        useEffect(() => {
                setValue("city", userinfo?.city);
                setValue("country", userinfo?.country);
                setValue("zipcode", userinfo.zipcode);
                setValue("state", userinfo?.state);

                if (userData) {
                        Object.entries(userData).forEach(([name, value]) =>
                                setValue(name, value)
                        );
                        setPictureUrl(userData.profile_img_url);
                        setPhone(userData.phone_no);

                        // const getBlobFromUrl = (userData) => {
                        //   return new Promise((resolve, reject) => {
                        //     let request = new XMLHttpRequest();
                        //     request.open("GET", userData.profile_img_url, true);
                        //     request.responseType = "image/png";
                        //     request.onload = () => {
                        //       resolve(request.response);
                        //     };
                        //     request.onerror = reject;
                        //     request.send();
                        //   });
                        // };
                        // getBlobFromUrl(userData).then((val) => {
                        //   setPicture(val);
                        // });
                }

                // CommonApi.allCountryList().then((csresponse) => {
                //         if (csresponse.status === 200 || csresponse.status === 2000) {
                //                 setCountryList(csresponse.data.country_list);
                //         }
                // });
        }, [userData]);

        useEffect(() => {
                const afterRemovingTheDuplicates = Object.values(watchAllFields).filter(
                        (value) => value && value.length > 0
                );
                const percentage = (afterRemovingTheDuplicates.length / 12) * 100;
                if (percentage > 100) {
                        setShowBar("" + 100);
                } else {
                        setShowBar("" + percentage);
                }
        }, [watchAllFields]);

        return (
                <div className="dashboard-container">
                        <Sidebar />
                        <div className="main-dashboard-container">
                                <Topbar
                                        isShow={true}
                                        value={
                                                userData && userData.profile_complete_status
                                                        ? userData.profile_complete_status
                                                        : 0
                                        }
                                />
                                <main className="maincontent">
                                        <div className="mainpro">
                                                {/* <Topbar /> */}
                                                {pageLoading && <Spinner />}
                                                <section>
                                                        <div className="basic-info">
                                                                <h3>My Profile</h3>
                                                                {/* <Link to="/vendor-profile/edit">
                  <h4>
                    Edit
                    <MdModeEdit />
                  </h4>
                </Link> */}
                                                        </div>
                                                </section>

                                                <form onSubmit={handleSubmit(onSubmit)} className="profileform">
                                                        <section>
                                                                {/* <div className="profile-info"></div> */}
                                                                <div className="division">
                                                                        <div className="upload-file">
                                                                                <h3>
                                                                                        Profile Image<span className="star">*</span>
                                                                                </h3>
                                                                                <div className="profilepicupload">
                                                                                        <img src={pictureUrl} alt="" />
                                                                                        <div className="uploadImageProfile">
                                                                                                <FcCameraIdentification />
                                                                                                <input
                                                                                                        type="file"
                                                                                                        name="myfile"
                                                                                                        onChange={handleChange}
                                                                                                />
                                                                                        </div>
                                                                                </div>
                                                                                {/* {profile && <p>Your Profile Uploaded Successfully</p>} */}
                                                                                <p className="profilepicdes">
                                                                                        300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
                                                                                </p>
                                                                        </div>
                                                                        <div className="progressbar">
                                                                                <div className="bar-profile">
                                                                                        <h4>Completion Progress</h4>
                                                                                        {/* <img src={barr} alt="" /> */}
                                                                                        <div className="circle-container">
                                                                                                <CircularProgressbar
                                                                                                        value={showBar}
                                                                                                        text={Math.round(showBar) + "%"}
                                                                                                        styles={buildStyles({
                                                                                                                pathColor: "#1fc94f",
                                                                                                                textColor: "#001488",
                                                                                                                backgroundColor: "#3e98c7",
                                                                                                        })}
                                                                                                />
                                                                                                <p>
                                                                                                        {showBar == 100 ? "" : "Please Complete Your Profile"}
                                                                                                </p>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </section>

                                                        <section className="pro-info">
                                                                <div className="first-last-names">

                                                                        <div className="d-flex flex-column col-12 col-md-6" style={{ marginRight: "10px" }}>
                                                                                <label htmlFor="" className='dep-label py-1'>First Name</label>
                                                                                <input
                                                                                        type="text"
                                                                                        placeholder="Add your name here"
                                                                                        {...register("first_name", {
                                                                                                required: "First name is required",
                                                                                                minLength: {
                                                                                                        value: 3,
                                                                                                        message: "First name must be at least 3 characters long",
                                                                                                },
                                                                                                maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "First name cannot exceed 20 characters",
                                                                                                },
                                                                                                pattern: {
                                                                                                        value: /^[A-Za-z]+$/,
                                                                                                        message: "Only letters are allowed in the first name",
                                                                                                },
                                                                                        })}
                                                                                />
                                                                                {errors.first_name && <span className="error-msg">
                                                                                        {errors.first_name.message}</span>}
                                                                        </div>
                                                                        <div className="d-flex flex-column col-12 col-md-6">
                                                                                <label htmlFor="" className='dep-label py-1'>Last Name</label>
                                                                                <input
                                                                                        type="text"
                                                                                        placeholder="Add your name here"
                                                                                        {...register("last_name", {
                                                                                                required: "Last name is required",
                                                                                                minLength: {
                                                                                                        value: 3,
                                                                                                        message: "Last name must be at least 3 characters long",
                                                                                                },
                                                                                                maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Last name cannot exceed 20 characters",
                                                                                                },
                                                                                                pattern: {
                                                                                                        value: /^[A-Za-z]+$/,
                                                                                                        message: "Only letters are allowed in the Last name",
                                                                                                },
                                                                                        })}
                                                                                />
                                                                                {errors.last_name && <span className="error-msg">
                                                                                        {errors.last_name.message}</span>}
                                                                        </div>


                                                                </div>
                                                                <div className="proformfield">
                                                                        <label>
                                                                                Email<span className="star">*</span>
                                                                        </label>
                                                                        <input
                                                                                type="email"
                                                                                placeholder="Add your email here"
                                                                                {...register("email", {
                                                                                        disabled: true,
                                                                                })}
                                                                                readonly
                                                                        />
                                                                        {errors.email && (
                                                                                <span className="error-msg">
                                                                                        Please enter the email address
                                                                                </span>
                                                                        )}
                                                                        <div className="protip">
                                                                                <BsInfoCircle />
                                                                                <p>It already set, you can’t change it</p>
                                                                        </div>
                                                                </div>
                                                        </section>

                                                        <section className="details-info">
                                                                <div className="proformfield">
                                                                        <label>
                                                                                Contact Number<span className="star">*</span>
                                                                        </label>

                                                                        <PhoneInput
                                                                                country="india"
                                                                                placeholder="6547894388"
                                                                                name="contact_number"
                                                                                international
                                                                                value={phone}
                                                                                onChange={(e) => {
                                                                                        setPhone(e);
                                                                                }}
                                                                        />
                                                                        {phone && !isValidPhoneNumber(phone) && (
                                                                                <span className="error-msg">
                                                                                        Please enter the valid phone number
                                                                                </span>
                                                                        )}
                                                                        {!phone && error && (
                                                                                <span className="error-msg">
                                                                                        Please enter the phone number
                                                                                </span>
                                                                        )}
                                                                        <div className="profile-info svg"></div>
                                                                </div>
                                                                <div className="profile-address">
                                                                        <label>
                                                                                Company Name<span className="star">*</span>
                                                                        </label>
                                                                        <input
                                                                                type="text"
                                                                                placeholder="Add address here"
                                                                                {...register("company_name", {
                                                                                        disabled: true,
                                                                                })}
                                                                        />
                                                                        {errors.company_name && (
                                                                                <span className="error-msg">
                                                                                        Please enter the company name
                                                                                </span>
                                                                        )}
                                                                </div>
                                                                <div className="proformfield" style={{ marginTop: 20 }}>
                                                                        <label>
                                                                                Position<span className="star">*</span>
                                                                        </label>

                                                                        <input
                                                                                type="text"
                                                                                name="category_id"
                                                                                className="selectlist"
                                                                                // onChange={handleChange}
                                                                                placeholder="Enter Your Position"
                                                                                {...register("position", {
                                                                                        required: true,
                                                                                        maxLength: 80,
                                                                                })}
                                                                        ></input>
                                                                        {errors.position && (
                                                                                <span className="error-msg">
                                                                                        Please select the position
                                                                                </span>
                                                                        )}

                                                                        <div className="profile-info svg"></div>
                                                                </div>

                                                                <div className="proformfield">
                                                                        <label>
                                                                                Department<span className="star">*</span>
                                                                        </label>
                                                                        <input
                                                                                // name="category_id"
                                                                                className="selectlist"
                                                                                // onChange={handleChange}
                                                                                type="text"
                                                                                placeholder="Enter Department"
                                                                                {...register("department", {
                                                                                        required: true,
                                                                                })}
                                                                        ></input>
                                                                        {errors.department && (
                                                                                <span className="error-msg">
                                                                                        Please select the department
                                                                                </span>
                                                                        )}

                                                                        <div className="profile-info svg"></div>
                                                                </div>
                                                                {/* <div className="proformfield">
                  <div className="profile-address">
                    <label>
                      Address<span className="star">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Add address here"
                      {...register("address", {
                        required: true,
                      })}
                    />
                    {errors.address && (
                      <span className="error-msg">
                        Please enter the address
                      </span>
                    )}
                  </div>
                </div> */}
                                                        </section>

                                                        <section className="details-info">
                                                                <div className="proformfield pt-20">
                                                                        <div className="profile-address">
                                                                                <label>
                                                                                        Location / Address<span className="star">*</span>
                                                                                </label>

                                                                                <input
                                                                                        type="text"
                                                                                        placeholder="Add address here"
                                                                                        //value={address}
                                                                                        {...register("address", {
                                                                                                required: true,
                                                                                        })}
                                                                                />
                                                                                {errors.agency_address && (
                                                                                        <span className="error-msg">
                                                                                                Please enter the address
                                                                                        </span>
                                                                                )}
                                                                        </div>
                                                                </div>

                                                                <div className="first-last-names pt-20">
                                                                        <div className="proformfield">
                                                                                <label>
                                                                                        County <span className="star">*</span>
                                                                                </label>
                                                                                {/* <input
                        type="text"
                        placeholder="Select Country"
                        {...register("country", {
                          required: true,
                          minLength: 3,
                          maxLength: 50,
                        })}
                      /> */}
                                                                                <Select
                                                                                        name="country"
                                                                                        options={countries}
                                                                                        onChange={handleCountryChange}
                                                                                        value={selectedCountry}
                                                                                        placeholder="Select Country"
                                                                                        className="custom-select-control"
                                                                                        styles={{
                                                                                                indicatorsContainer: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        display: 'none',
                                                                                                }),
                                                                                                control: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        height: '40px',
                                                                                                        backgroundColor: '#f8f8f8',
                                                                                                }),
                                                                                                placeholder: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        marginLeft: '5px',
                                                                                                        marginBottom: '10px',
                                                                                                }),
                                                                                                singleValue: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        marginBottom: "8px",
                                                                                                }),
                                                                                                input: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        margin: "0 px",
                                                                                                        paddingBottom: "0px",
                                                                                                        paddingTop: "0px",
                                                                                                })
                                                                                        }}
                                                                                />
                                                                                {errors.country && (
                                                                                        <span className="error-msg">
                                                                                                Please select the country
                                                                                        </span>
                                                                                )}
                                                                        </div>

                                                                        <div className="proformfield profile-width">
                                                                                <label>
                                                                                        City<span className="star">*</span>
                                                                                </label>
                                                                                <Select
                                                                                        name="city"
                                                                                        options={citiesOptions}
                                                                                        onChange={handleCityChange}
                                                                                        value={selectedCity}
                                                                                        placeholder="Select city"
                                                                                        className="custom-select-control"
                                                                                        styles={{
                                                                                                indicatorsContainer: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        display: 'none',
                                                                                                }),
                                                                                                control: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        height: '40px',
                                                                                                        backgroundColor: '#f8f8f8',
                                                                                                }),
                                                                                                placeholder: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        marginLeft: '5px',
                                                                                                        marginBottom: '10px',
                                                                                                }),
                                                                                                singleValue: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        marginBottom: "8px",
                                                                                                }),
                                                                                                input: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        margin: "0 px",
                                                                                                        paddingBottom: "0px",
                                                                                                        paddingTop: "0px",
                                                                                                })
                                                                                        }}
                                                                                />
                                                                                {errors.city && (
                                                                                        <span className="error-msg">Please enter the city</span>
                                                                                )}
                                                                        </div>
                                                                </div>

                                                                <div className="first-last-names pt-20">
                                                                        <div className="proformfield">
                                                                                <label>
                                                                                        State/Province <span className="star">*</span>
                                                                                </label>
                                                                                <Select
                                                                                        name="state"
                                                                                        options={statesOptions}
                                                                                        onChange={handleStateChange}
                                                                                        value={selectedState}
                                                                                        placeholder="Select State"
                                                                                        className="custom-select-control"

                                                                                        styles={{
                                                                                                indicatorsContainer: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        display: 'none',
                                                                                                }),
                                                                                                control: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        height: '40px',
                                                                                                        backgroundColor: '#f8f8f8',
                                                                                                }),
                                                                                                placeholder: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        marginLeft: '5px',
                                                                                                        marginBottom: '10px',
                                                                                                }),
                                                                                                singleValue: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        marginBottom: "8px",
                                                                                                }),
                                                                                                input: (provided, state) => ({
                                                                                                        ...provided,
                                                                                                        margin: "0 px",
                                                                                                        paddingBottom: "0px",
                                                                                                        paddingTop: "0px",
                                                                                                })
                                                                                        }}
                                                                                />
                                                                                {errors.state && (
                                                                                        <span className="error-msg">
                                                                                                {errors.state.message}
                                                                                        </span>
                                                                                )}
                                                                        </div>

                                                                        <div className="proformfield profile-width">
                                                                                <label>
                                                                                        Pin Code<span className="star">*</span>
                                                                                </label>
                                                                                <input
                                                                                        type="number"
                                                                                        placeholder="Add Pin Code here"
                                                                                        //value={userinfo.zipcode}
                                                                                        {...register("zipcode", {
                                                                                                required: true,
                                                                                                minLength: 4,
                                                                                                maxLength: 8,
                                                                                        })}
                                                                                />
                                                                                {errors.zipcode && (
                                                                                        <span className="error-msg">
                                                                                                Please enter the Pin Code
                                                                                        </span>
                                                                                )}
                                                                        </div>
                                                                </div>
                                                        </section>

                                                        <div className="formbuton">
                                                                {/* <Link to={`/AgencyProfile`}> */}
                                                                <input type="submit" className="save-button" value="Save" />
                                                                {/*<input
                  type="submit"
                  className="save-button"
                  value="Skip"
                  onClick={handleSkip}
                />
                 </Link> */}

                                                                {/* <Link to={`/AgencyProfile`}>
                <button className="save-button">Skip</button>
              </Link> */}
                                                        </div>
                                                </form>
                                        </div>
                                </main>
                        </div>
                </div>
        );
};
export default MyProfileEdit;
