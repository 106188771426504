import { BaseUrl } from "./api";

async function ProfileApi() {
  const userToken = localStorage.getItem("token");

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const response = await fetch(
    `${BaseUrl}/vendor/Vendor-Details`,
    requestOptions
  );
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
}

async function myProfileApi() {
  const userToken = localStorage.getItem("token");

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const response = await fetch(
    `${BaseUrl}/vendor/Get-Vendor-My-Profile`,
    requestOptions
  );
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
}

async function allCountryList() {
  var requestOptions = {
    method: "GET",
  };

  const response = await fetch(
    `${BaseUrl}/common/Get-All-Country`,
    requestOptions
  );
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
}

//Customer API

async function CustomerProfileApi() {
  const userToken = localStorage.getItem("token");

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const response = await fetch(
    `${BaseUrl}/user/Get-User-My-Profile`,
    requestOptions
  );
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
}

async function CustomerUserDetails() {
  const userToken = localStorage.getItem("token");

  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
  };

  const response = await fetch(`${BaseUrl}/user/User-Details`, requestOptions);
  if (!response.ok) {
    throw new Error("Data coud not be fetched!");
  } else {
    return response.json();
  }
}

async function CommonApiData() {
  return true;
}

export default {
  CommonApiData,
  ProfileApi,
  myProfileApi,
  allCountryList,
  CustomerProfileApi,
  CustomerUserDetails,
};
