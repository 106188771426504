import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../Store/Reducers/userSlice";
import projectReducer from "./Reducers/ProjectReducer";
import myProfileReducer from "./Reducers/myProfileReducer";
import CustomerProjectInfoReducer from "./Reducers/CustomerProjectInfoReducer";
import CustomerProposalList from "./Reducers/CustomerProposalListReducer";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import BrandListReducer from "./Reducers/BrandListReducer";
import CategoryListReducer from "./Reducers/CategoryListReducer";
import IndustryListReducer from "./Reducers/IndustryListReducer";
import DepartmentListReducer from "./Reducers/DepartmentListReducer";
import CountryList from "./Reducers/CountryList";
import ProductList from "./Reducers/VendorProductListReducer";
import addProjectsReducer from "./Reducers/AddProjectsReducer";

const persistConfig = {
  key: "counter",
  storage,
};
const reducers = combineReducers({
  user: userReducer,
  project: projectReducer,
  profile: myProfileReducer,
  customerProjectInfo: CustomerProjectInfoReducer,
  customerProposalList: CustomerProposalList,
  brandList: BrandListReducer,
  categoryList: CategoryListReducer,
  departmentList: DepartmentListReducer,
  industryList: IndustryListReducer,
  countryList: CountryList,
  productList: ProductList,
  addProjectsReducer: addProjectsReducer,
});
const persistedReducer = persistReducer(persistConfig, reducers);
export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  //  devTools:false,
  devTools: process.env.NODE_ENV !== "production",
});
