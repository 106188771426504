import React, { useState, useEffect } from "react";
import "./Register.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import registerbanner from "../../assets/images/registerback.png";
import product from "../../assets/images/product-icon.png";
import inventory from "../../assets/images/inventory-icon.png";
import customer from "../../assets/images/customer-icon.png";
import diagram from "../../assets/images/diagram.png";
import correct from "../../assets/images/correct.png";
import monitor from "../../assets/images/monitor.png";
import { BaseUrl } from "../../Api/api.js";
import { ButtonToolbar } from "rsuite";
import "rsuite/dist/rsuite.css";
import OtpVerificationModal from "../../components/Modal/OtpVerificationModal/OtpVerificationModal";

const ClientRegister = () => {
  const initialValues = {
    title: "",
    firstname: "",
    lastname: "",
    user_name: "",
    password: "",
    confirm_password: "",
    companyname: "",
    department_name: "",
    position: "",
    phonenumber: "",
    address: "",
    city: "",
    state: "",
    country: "",
    currency: "",
    currency_code: "",
    symbol: "",
    zipcode: "",
    checkboxemail: "",
    checkboxagree: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  // const [agree, setAgree] = useState(false);
  // const [agreebox, setAgreebox] = useState(false);
  const [error, setError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [dataValidated, setDataValidated] = useState(false);
  const [open, setOpen] = useState({ isHide: false });
  const [details, setDetails] = useState({});
  const [showResend, setShowResend] = useState(false);

  useEffect(() => {
    try {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(`${BaseUrl}/common/Get-All-Country`, requestOptions)
        .then(function (response) {
          return response.json();
        })
        .then(function (result) {
          setCountryList(result.data.country_list);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    } catch {
      console.log("error");
    }
  }, []);

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePassword = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (e.target.value.length < 8) {
      setError("Password must be at least 8 characters long");
    } else if (!/\d/.test(e.target.value)) {
      setError("Password must contain at least one number");
    } else if (!/[a-z]/.test(e.target.value)) {
      setError("Password must contain at least one lowercase letter");
    } else if (!/[A-Z]/.test(e.target.value)) {
      setError("Password must contain at least one uppercase letter");
    } else if (!/[!@#$%^&*]/.test(e.target.value)) {
      setError(
        "Password must contain at least one special character  @, #, $, %, &, or !"
      );
    } else {
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currencyData = countryList.filter(
      (item) => item.country == formValues.country
    );
    currencyData.map((key) => {
      formValues.currency = key.currency;
      formValues.currency_code = key.code;
      formValues.symbol = key.symbol;
    });

    // setFormErrors(validate(formValues));
    let error = validate({ ...formValues });

    if (Object.keys(error).length !== 0) {
      setFormErrors(error);
      console.log("Error Found");
      return false;
    }

    setIsSubmit(true);
    setDataValidated(!dataValidated);

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        user_name: formValues.user_name && formValues.user_name,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/user/Otp-Send`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          let temp = { ...open };
          // console.log(result);
          if (result.status === 200) {
            setOpen({ ...temp, isHide: true });
            toast("OTP Successfully Send To Your Registar Mail ID");
            setOpen({ ...temp, isHide: true });
          } else if (result.message === "Email Id already Register with us.") {
            setOpen({ ...temp, isHide: false });
            toast(result.message);
          } else if (result.status === 401) {
            setOpen({ ...temp, isHide: true });
            toast(result.message);
            setShowResend(true);
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  const tnccheck = (e) => {
    if (e.target.checked) {
      e.target.value = 1;
    } else {
      e.target.value = "";
    }
  };

  // const checkboxHandler = () => {
  //   // if agree === true, it will be set to false
  //   // if agree === false, it will be set to true
  //   setAgree(!agree);
  //   setAgreebox(!agreebox);
  //   // Don't miss the exclamation mark
  // };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    console.log("validate", values);
    const customerDetails = values;
    setDetails(customerDetails);
    const errors = {};

    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
    const phone_regex =
      /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
    //const zip_regex = /\(?([0-9])\)/;
    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    if (!email_regex.test(values["user_name"])) {
      errors.user_name = "Enter valid email id.";
    } else if (!PWD_REGEX.test(values["password"])) {
      errors.password =
        "Password Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character";
    } else if (!PWD_REGEX.test(values["confirm_password"])) {
      errors.confirm_password =
        "Password Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character";
    } else if (values.password !== values.confirm_password) {
      toast("Passwords don't match");
    } else if (!phone_regex.test(values["phonenumber"])) {
      errors.phonenumber = "This is not valid phone number. ";
      // } else if (!zip_regex.test(values["zipcode"])) {
      //   errors.zipcode = "This is not valid Zip number";
    }
    console.log(errors);
    return errors;
  };

  console.log(formValues);

  console.log(countryList.filter((item) => item.country == formValues.country));

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div className="register-toggle">
      <div className="registerfeatures">
        <img src={registerbanner} alt="" className="registerbanner" />
        <h3>
         Connect with vendors, Find product <br />
          and services for your businesses in minutes.
        </h3>
        <div className="regAdvantages">
          <div>
            <img src={customer} alt="product" className="regicons" />
            <h4>
              <span>50K+</span> <br />
              Buyer
            </h4>
          </div>
          <div>
            <img src={inventory} alt="inventory" className="regicons" />
            <h4>
              <span>100K+</span> <br />
              Supplier
            </h4>
          </div>
          <div>
            <img src={product} alt="customer" className="regicons" />
            <h4>
              <span>5K+</span> <br />
              Products & Services
            </h4>
          </div>
        </div>
        <div className="register-window mt-20">
          <h3>100% Verified Vendors</h3>
          <div className="sellFeature">
            <div className="sellconqt">
              <img src={diagram} alt="" />
              <div className="sellfeatures">
                <h4>Reduce IT costs</h4>
                <p>Compare prices and services from multiple vendors across the globe</p>
              </div>
            </div>
            <div className="sellconqt">
              <img src={correct} alt="" />
              <div className="sellfeatures">
                <h4>Save time and resources</h4>
                <p>Let our database ease your vendor research and sourcing</p>
              </div>
            </div>
            <div className="sellconqt">
              <img src={monitor} alt="" />
              <div className="sellfeatures">
                <h4>Choose right solutions </h4>
                <p>Get tailormade solutions for your specific IT needs</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="register-window">
        <div className="registerhead">
          <h1>Customer Signup</h1>
        </div>

        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          {/*{Object.keys(formErrors).length === 0 && isSubmit ? (
              <div className="ui message success">Signed in successfully</div>
            ) : (
              <pre>{JSON.stringify(formValues, undefined, 2)}</pre>
            )}*/}
          <div className="name-field">
            <select className="nametag" name="title" onChange={handleChange}>
              <option value="Select">Select*</option>
              <option value="Mr">Mr.</option>
              <option value="Miss">Miss.</option>
              <option value="Mrs">Mrs.</option>
              
            </select>
            <input
              type="text"
              name="firstname"
              placeholder="First Name*"
              value={formValues.firstname}
              onChange={handleChange}
            />
            <input
              type="text"
              name="lastname"
              placeholder="Last Name*"
              value={formValues.lastname}
              onChange={handleChange}
              required
            />
          </div>
          <p className="err-message">{formErrors.title}</p>
          {formErrors.firstname && (
            <p className="err-message"> First Name Field is required </p>
          )}
          {formErrors.lastname && (
            <p className="err-message"> Last Name Field is required </p>
          )}
          <div className="form-field">
            <input
              type="email"
              name="user_name"
              placeholder="Enter Email ID*"
              value={formValues.user_name}
              onChange={handleChange}
              autoComplete="off"
            />
          </div>
          {formErrors.user_name && (
            <p className="err-message"> Email Field is required</p>
          )}
          <div className="form-field">
            <input
              type={passwordShown ? "text" : "password"}
              name="password"
              placeholder="Enter your Password*"
              value={formValues.password}
              onChange={handleChangePassword}
              autoComplete="off"
            />
          </div>
          <div className="show-password">
            <input
              className="validation-password"
              type="checkbox"
              onClick={togglePassword}
            />
            Show Password
          </div>
          {error && (
            <p className="validation-password" style={{ color: "red" }}>
              {error}
            </p>
          )}

          {/* <p className="err-message">{formErrors.password}</p> */}
          <div className="form-field">
            <input
              type="password"
              name="confirm_password"
              placeholder="Enter your Confirm Password*"
              value={formValues.confirm_password}
              onChange={handleChange}
              autoComplete="off"
            />
          </div>
          {formErrors.confirm_password && (
            <p className="err-message">Confirm Password Field is Required</p>
          )}
          <div className="form-field">
            <input
              type="text"
              name="companyname"
              placeholder="Enter Your Company Name*"
              value={formValues.companyname}
              onChange={handleChange}
            />
          </div>
          {formErrors.companyname && (
            <p className="err-message"> Company Name Field is Required</p>
          )}
          <div className="form-field">
            <input
              type="text"
              name="department_name"
              placeholder="Select department Name*"
              value={formValues.department_name}
              onChange={handleChange}
            />
          </div>
          {formErrors.department_name && (
            <p className="err-message"> Department Field is Required</p>
          )}
          <div className="form-field">
            <input
              type="text"
              name="position"
              placeholder="Select Positions*"
              value={formValues.position}
              onChange={handleChange}
            />
          </div>
          <p className="err-message">{formErrors.position}</p>
          <div className="form-field">
            <input
              name="phonenumber"
              placeholder="Enter Your Phone Number*"
              value={formValues.phonenumber}
              onChange={handleChange}
            />
          </div>
          {formErrors.phonenumber && (
            <p className="err-message"> Phone Number Field is Required</p>
          )}
          <div className="form-field">
            <input
              type="text"
              name="address"
              placeholder="Enter your Address*"
              value={formValues.address}
              onChange={handleChange}
            />
          </div>
          <p className="err-message">{formErrors.address}</p>
          <div className="form-field">
            <input
              type="text"
              name="city"
              placeholder="Enter your City Name*"
              value={formValues.city}
              onChange={handleChange}
            />
          </div>
          <p className="err-message">{formErrors.city}</p>
          <div className="form-field">
            <input
              type="text"
              name="state"
              placeholder="Enter Your State Name*"
              value={formValues.state}
              onChange={handleChange}
            />
          </div>
          <p className="err-message">{formErrors.state}</p>
          <div className="form-field">
            <select
              type="text"
              name="country"
              placeholder="Select Country*"
              value={formValues.country}
              onChange={handleChange}
              className="selectcountry"
            >
              {countryList.map((el) => {
                return (
                  <>
                    <option value={el.country}>{el.country}</option>
                  </>
                );
              })}
            </select>
          </div>
          <p className="err-message">{formErrors.country}</p>
          <div className="form-field">
            <input
              type="number"
              name="zipcode"
              placeholder="Enter Your Zip Code*"
              value={formValues.zipcode}
              onChange={handleChange}
            />
          </div>
          <p className="err-message">{formErrors.zipcode}</p>
          <div className="form-checkbox">
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
            <input
              type="checkbox"
              name="checkboxemail"
              className="form-input"
              onChange={handleChange}
              onClick={tnccheck}
            />
            <label>
              Send me emails with tips on how to find talent that fits my needs.
            </label>
          </div>
          <p className="err-message">{formErrors.checkboxemail}</p>
          <div className="form-checkbox">
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
            <input
              type="checkbox"
              name="checkboxagree"
              className="form-input"
              onChange={handleChange}
              onClick={tnccheck}
            />
            <label>
              Yes, I understand and agree to the ConQt
              <span > <a href="https://login.conqt.com/terms-and-conditions" style={{color:"blue"}}>Terms of Services</a></span>, including the
              <span> User Agreement</span> and <span > <a href="https://login.conqt.com/privacy-policy" style={{color:"blue"}}>Privacy Policy</a></span>.
            </label>
          </div>
          <p className="err-message">{formErrors.checkboxagree}</p>
          <ButtonToolbar>
            <button
              className="tog-reg-btn"
              onClick={handleSubmit}
              // disabled={
              //   !(
              //     formValues.checkboxagree === "1" &&
              //     formValues.checkboxemail === "1"
              //   )
              // }
            >
              Submit
            </button>
          </ButtonToolbar>
        </form>
        <div className="login-account">
          <p>
            Already have an account ? <Link to="/login">Log In </Link>
          </p>
        </div>
      </div>
      {dataValidated && (
        <OtpVerificationModal
          open={open.isHide}
          handleClose={() => {
            setOpen(!open.isHide);
          }}
          customerMail={details.user_name}
          customerDetails={formValues}
          showingResend={showResend}
        />
      )}
    </div>
  );
};

export default ClientRegister;
