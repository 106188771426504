import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Api/api";

export const getAllCountryList = createAsyncThunk("countryList", () => {
 

  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };

  return fetch(`${BaseUrl}/common/Get-All-Country`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
    //    console.log(result.data.country_list, "country api data");
      return result.data.country_list


    })
    .catch((error) => console.log("error", error));
});



export const myReducer = createSlice({
  name: "countryList",
  initialState: {
    loading: false,
    countryList: [],
    

  },
  reducers: {
    updateSelectcountryList: (state, action) => {
   
      state.selectedCountryId = action.payload;

    },
  },
  extraReducers: {
    [getAllCountryList.pending]: (state) => {
      state.loading = true;
    },
    [getAllCountryList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.countryList = payload;

      // console.log(payload,"payload from reducer") 
    },
   

  },


})

export const {
    updateSelectcountryList
} = myReducer.actions;

export default myReducer.reducer;
