import React, { useState, useEffect } from "react";
import "../../Vendor/vendorProfile/page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import profile from "../../../assets/images/profile.png";
import { BsInfoCircle } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { FcCameraIdentification } from "react-icons/fc";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch } from "react-redux";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./utils";
import Spinner from "../../../components/Header/Spinner";
import { BaseUrl } from "../../../Api/api";

const MyProfile = (props) => {
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [showBar, setShowBar] = useState("0");

  const userToken = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    props.loaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/user/Get-User-My-Profile`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(
            "customer profile",
            result.data[0].profile_complete_status
          );
          setData(result.data);
          props.loaderState(false);

          if (result.data.length > 0) {
            props.onChangeStatusValue(result.data[0].profile_complete_status);
          }
          localStorage.setItem(
            "profile_complete_status",
            result.data[0].profile_complete_status
          );
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleNext = (e) => {
    window.location = "/company-profile";
  };

  const handleSkip = (e) => {
    window.location = "/company-profile";
  };

  return (
    <div className="mainpro">
      {/* <Topbar /> */}
      <section>
        <div className="basic-info">
          <h3>My Profile</h3>
          <Link to="/profile-edit">
            <h4>
              Edit
              <MdModeEdit />
            </h4>
          </Link>
        </div>
      </section>
      {data.map((item) => {
        console.log(item, "data from item");
        return (
          <div className="profileform" key={item}>
            <section>
              {/* <div className="profile-info"></div> */}
              <div className="division">
                <div className="upload-file">
                  <h3>
                    Profile Image<span className="star">*</span>
                  </h3>
                  <div className="profilepicupload">
                    <img
                      src={item.profile_img_url}
                      alt="{item.profile_img_name}"
                    />
                    {/* <FcCameraIdentification />
                    <input type="file" name="myfile" /> */}
                  </div>
                  {/* <p className="profilepicdes">
                    300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
                  </p> */}
                </div>
                {/* <div className="progressbar">
                  <div className="bar-profile">
                    <h4>Completion Progress</h4>
                    {/* <img src={barr} alt="" /> */}
              </div>
            </section>

            <section className="pro-info">
              <div className="first-last-names">
                <div className="proformfield">
                  <label>
                    Your Name <span className="star">*</span>
                  </label>
                  <p className="profields">
                    {item.first_name
                      ? item.first_name
                      : "Please Enter First Name"}
                  </p>

                  {/* <div className="protip">
                    <BsInfoCircle />
                    <p>Set at once and can’t be change</p>
                  </div> */}
                </div>
                <div className="proformfield profile-width">
                  <label>
                    Your Last Name <span className="star">*</span>
                  </label>
                  <p className="profields">
                    {item.last_name ? item.last_name : "Please Enter Last Name"}
                  </p>

                  {/* <div className="protip">
                    <BsInfoCircle />
                    <p>Set at once and can’t be change</p>
                  </div> */}
                </div>
              </div>
              <div className="proformfield">
                <label>
                  Email<span className="star">*</span>
                </label>
                <p className="profields">
                  {item.email ? item.email : "Please Enter Customer Email"}
                </p>

                <div className="protip">
                  <BsInfoCircle />
                  <p>Set at once and can’t be change</p>
                </div>
              </div>
            </section>

            <section className="details-info">
              <div className="proformfield">
                <label>
                  Contact Number<span className="star">*</span>
                </label>

                <p className="profields">
                  {item.phone_number
                    ? item.phone_number
                    : "Please Enter Contact Number"}
                </p>
                <div className="profile-info svg"></div>
              </div>
              <div className="proformfield">
                <div className="profile-address">
                  <label>
                    Company Name<span className="star">*</span>
                  </label>
                  <p className="profields">
                    {item.company_name
                      ? item.company_name
                      : "Please Enter Company Name"}
                  </p>
                </div>
              </div>
              <div className="proformfield">
                <label>
                  Position<span className="star">*</span>
                </label>

                <p className="profields" title="Position">
                  {item.position ? item.position : "Please Enter a position"}
                </p>

                <div className="profile-info svg"></div>
              </div>

              <div className="proformfield">
                <label>
                  Department<span className="star">*</span>
                </label>
                <p className="profields">
                  {item.department_name
                    ? item.department_name
                    : "Please Enter Department Name"}
                </p>

                <div className="profile-info svg"></div>
              </div>
            </section>

            <section className="pro-info">
              <div className="proformfield">
                <div className="profile-address">
                  <label>
                    Address<span className="star">*</span>
                  </label>
                  <p className="profields">
                    {" "}
                    {item.address ? item.address : "Please Enter Address"}
                  </p>
                </div>
              </div>
              <div className="first-last-names">
                <div className="proformfield">
                  <label>
                    Country <span className="star">*</span>
                  </label>
                  <p className="profields">
                    {item.country ? item.country : "Please Select a Country"}
                  </p>
                </div>
                <div className="proformfield profile-width">
                  <label>
                    City <span className="star">*</span>
                  </label>
                  <p className="profields">
                    {item.city ? item.city : "Please Enter City Name"}
                  </p>
                </div>
              </div>
              <div className="first-last-names">
                <div className="proformfield">
                  <label>
                    State / Province <span className="star">*</span>
                  </label>
                  <p className="profields">
                    {item.state
                      ? item.state
                      : "Please Enter State/Province Name"}
                  </p>
                </div>
                <div className="proformfield profile-width">
                  <label>
                    Pin Code <span className="star">*</span>
                  </label>
                  <p className="profields">
                    {item.zipcode ? item.zipcode : "Please Enter Zipcode"}
                  </p>
                </div>
              </div>
            </section>

            <div className="formbuton">
              <input
                type="submit"
                className="save-button"
                value="Skip"
                onClick={handleSkip}
              />

              <input
                type="submit"
                className="save-button"
                value="Next"
                onClick={handleNext}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default MyProfile;
