import React from "react";
import "./nbcd.css";

const SmallButton = ({ color, backgroundColor, buttonName, image, border }) => {
  return (
    <>
      <button
        style={{
          color: `${color}`,
          backgroundColor: `${backgroundColor}`,
          border: `${border}`,
          padding: "8px 15px",
          borderRadius: "5px",
          width: "100%",
        }}
      >
        <div className="d-flex align-items-center justify-content-center">
          <img src={image} alt="" style={{ width: "23px" }} />
          <h5 className="px-3"> {buttonName}</h5>
        </div>
      </button>
    </>
  );
};

export default SmallButton;
