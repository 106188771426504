import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "rsuite/dist/rsuite.css";
import closeIcon from "../../../assets/images/red-close-icon.png";
import "./ProjectRestricted.css";
import mainIcon from "../../../assets/images/restricted-popup-icon.png";

const ProjectRestricted = ({ showPopup, setShowPopup }) => {
  const handleClose = () => {
    setShowPopup(false);
  };
  return (
    <div>
      <Modal
        isOpen={showPopup}
        onRequestClose={handleClose}
        className="project-restrict-popup"
        overlayClassName="pr-modal-overlay"
        shouldCloseOnOverlayClick={false}
      >
        <div className="d-flex align-items-center justify-content-end">
          <img
            src={closeIcon}
            alt=""
            style={{ width: "20px", height: "20px", cursor: "pointer" }}
            onClick={handleClose}
          />
        </div>
        {/* <Modal.Body className="pr-modal-body"> */}
        <div className="pr-image-container">
          <img src={mainIcon} alt="" />
        </div>
        <div className="mt-3" style={{ textAlign: "center" }}>
          <h2 className="pr-title">This opportunity is for Premium Sellers.</h2>
          <p className="pr-text">
            Please contact sales team at <br />
          </p>
          <span style={{ color: "#304ffe" }} className="pr-mail-id">
            sales@conqt.com.
          </span>
        </div>
        {/* </Modal.Body> */}
      </Modal>
    </div>
  );
};

export default ProjectRestricted;
