import React, { useState, useEffect } from "react";
import "../../Vendor/vendorProfile/page.css";
import { BsUpload } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../../Api/api";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch } from "react-redux";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./utils";
import Spinner from "../../../components/Header/Spinner";
import { toast } from "react-toastify";
import CommonApi from "../../../Api/commonApi";
import { IoMdArrowDropup } from "react-icons/io";
import { Rating } from "react-simple-star-rating";

const Customerreviewsadd = () => {
        const [filteredData, setFilteredData] = useState([]);
        const [rating, setRating] = useState(0);
        const [data, setData] = useState([]);
        const [active, setActive] = useState(10);
        const [showBar, setShowBar] = useState("0");
        const [userData, setUserData] = useState({});
        const [pageLoading, setPageLoading] = useState(false);
        const [addReview, setAddReview] = useState(false);
        const [projectSelect, setProjectSelect] = useState("");
        const [error, showerror] = useState(false);
        const dispatch = useDispatch();
        let navigate = useNavigate();
        const userToken = localStorage.getItem("token");

        const initialValues = {
                vendor_id: "",
                announcement_id: "",
                proposal_id: "",
                project_delivery_stars: "",
                project_support_stars: "",
                timeline_quality_stars: "",
                money_value_stars: "",
                project_likely_number: "",
                reviews: "",
        };

        const [formValues, setFormValues] = useState(initialValues);

        const handleRatingDelivery = (rate) => {
                console.log("handleRating", rate);
                setRating(rate);
                // setFormValues((formValues.Project_Delivery = rate));
                // other logic

                setFormValues((prevState) => ({
                        ...prevState,
                        ["project_delivery_stars"]: rate.toString(),
                }));
        };

        const handleRatingReview = (rate) => {
                console.log("handleRating", rate);
                setRating(rate);
                // other logic
                setFormValues((prevState) => ({
                        ...prevState,
                        ["project_support_stars"]: rate.toString(),
                }));
        };

        const handleRatingQuality = (rate) => {
                console.log("handleRating", rate);
                setRating(rate);
                // other logic
                setFormValues((prevState) => ({
                        ...prevState,
                        ["timeline_quality_stars"]: rate.toString(),
                }));
        };

        const handleRatingMoney = (rate) => {
                console.log("handleRating", rate);
                setRating(rate);
                // other logic
                setFormValues((prevState) => ({
                        ...prevState,
                        ["money_value_stars"]: rate.toString(),
                }));
        };

        const handleChangeText = (e) => {
                setData((data) => ({
                        ...data,
                        ["project_name"]: e,
                }));
                console.log(data);
        };

        const handleLink = (e) => {
                console.log(e.target, "likes");
                setFormValues((prevState) => ({
                        ...prevState,
                        ["project_likely_number"]: e.target.name,
                }));
                setActive(e.target.name);
                console.log("Active", active);
                console.log("e value", e.target.name);
        };

        const handleReview = (e) => {
                setFormValues((prevState) => ({
                        ...prevState,
                        ["reviews"]: e.target.value,
                }));
        };

        // const onPointerEnter = () => console.log("Enter");
        // const onPointerLeave = () => console.log("Leave");
        // const onPointerMove = (value: number, index: number) =>
        //   console.log(value, index);

        const {
                register,
                handleSubmit,
                watch,
                reset,
                setValue,
                formState: { errors },
        } = useForm();
        console.log(userData, "userdata");
        const watchAllFields = watch();
        const handleChange = (e) => {
                setAddReview(true);
        };

        const handleSubmitReview = async () => {
                dispatch(updateStatusValue(progressBarValidation(1)));
                console.log("Submit Form", formValues);
                // var myHeaders = new Headers();
                // myHeaders.append("Authorization", `Bearer ${userToken}`);
                // var requestOptions = {
                //   method: "POST",
                //   body: JSON.stringify(formValues),
                //   headers: myHeaders,
                //   redirect: "follow",
                // };

                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${userToken}`);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify(formValues);

                var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                };

                fetch(`${BaseUrl}/user/User-Project-Reviews`, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                                console.log("Review Result", result);
                                if (result.status === 200) {
                                        toast("Your Review Upload Successfully");
                                        CommonApi.CustomerProfileApi().then((mcpdata) => {
                                                if (mcpdata.status === 200) {
                                                        localStorage.setItem("userInfo", JSON.stringify(mcpdata.data));
                                                        navigate("/customer-reviews-list");
                                                }
                                        });
                                        showerror(false);
                                } else {
                                        showerror(true);
                                }
                        })
                        .catch((error) => console.log("error", error));
        };

        useEffect(() => {
                try {
                        setPageLoading(true);
                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${userToken}`);
                        myHeaders.append("Content-Type", "application/json");

                        var requestOptions = {
                                method: "GET",
                                headers: myHeaders,
                                redirect: "follow",
                        };

                        fetch(
                                `${BaseUrl}/announcement/Customer-Final-Project-List`,
                                requestOptions
                        )
                                .then((response) => response.json())
                                .then((result) => {
                                        console.log("projectlist", result.data);
                                        setData(result.data);
                                })
                                .catch((error) => console.log("error", error));
                } catch (err) {
                        console.log(err);
                }
        }, []);


        const announcementid = (announcement_id) => {
                console.log(announcement_id);
                localStorage.setItem("announcement_id", announcement_id);
        };

        const handleFilter = (event) => {
                const searchWord = event.target.value;
                console.log("searchword", searchWord);
                setProjectSelect(searchWord);
                const newFilter = data.filter((item) => {
                        return item.project_name.toLowerCase().includes(searchWord.toLowerCase());
                });

                if (searchWord === "") {
                        setFilteredData(data);
                } else {
                        setFilteredData(newFilter);
                }
        };

        useEffect(() => {
                if (userData) {
                        Object.entries(userData).forEach(([name, value]) =>
                                setValue(name, value)
                        );
                }
        });

        useEffect(() => {
                const afterRemovingTheDuplicates = Object.values(watchAllFields).filter(
                        (value) => value && value.length > 0
                );
                const percentage = (afterRemovingTheDuplicates.length / 12) * 100;
                if (percentage > 100) {
                        setShowBar("" + 100);
                } else {
                        setShowBar("" + percentage);
                }
        }, [watchAllFields]);

        const handleSelectProject = (e) => {
                console.log("vendor id", e.target.name);
                console.log("proposal id", e.target.getAttribute("data-proposal_id"));
                console.log(
                        "announcement id",
                        e.target.getAttribute("data-announcement_id")
                );
                console.log("project_name", e.target.getAttribute("data-project_name"));
                setProjectSelect(e.target.getAttribute("data-project_name"));
                setFormValues((prevState) => ({
                        ...prevState,
                        ["vendor_id"]: e.target.name,
                        ["proposal_id"]: e.target.getAttribute("data-proposal_id"),
                        ["announcement_id"]: e.target.getAttribute("data-announcement_id"),
                }));

                setFilteredData([]);
        };

        const handleNext = (e) => {
                window.location = "/customer-transaction-list";
        };

        const handleSkip = (e) => {
                window.location = "/customer-transaction-list";
        };


        return (
                <div className="dashboard-container">
                        <Sidebar />
                        <div className="main-dashboard-container">
                                <Topbar
                                        isShow={true}
                                        // value={
                                        //   data.length > 0 &&
                                        //   data[0].profile_complete_status &&
                                        //   data[0].profile_complete_status
                                        // }
                                        value={localStorage.getItem("profile_complete_status")}
                                />
                                <main className="maincontent">
                                        <div>
                                                <section>
                                                        <div className="basic-info">
                                                                <h3>Add Reviews Here</h3>
                                                        </div>
                                                </section>

                                                <div className="back-review">
                                                        <div className="review-content">
                                                                <h2>Select Project to Add your Review</h2>

                                                                <div className="select-details">
                                                                        <div className="icon-in">
                                                                                <input
                                                                                        type="text"
                                                                                        placeholder="Search Project"
                                                                                        onChange={handleFilter}
                                                                                        value={projectSelect}
                                                                                // onClick={handleFilter}
                                                                                />
                                                                                <IoMdArrowDropup />
                                                                        </div>

                                                                        {filteredData.length != 0 && (
                                                                                <div className="content-data">
                                                                                        {/* <h1>Popular Projects</h1> */}
                                                                                        {filteredData.map((item, announcement_id) => {
                                                                                                return (
                                                                                                        <div className="image-para">
                                                                                                                <div className="allpara">
                                                                                                                        <p>{item.project_name}</p>
                                                                                                                </div>
                                                                                                                <input
                                                                                                                        type="button"
                                                                                                                        name={item.vendor_id}
                                                                                                                        value="Select"
                                                                                                                        data-proposal_id={item.proposal_id}
                                                                                                                        data-announcement_id={item.announcement_id}
                                                                                                                        data-project_name={item.project_name}
                                                                                                                        onClick={handleSelectProject}
                                                                                                                        class="selectbtnlist"
                                                                                                                />
                                                                                                        </div>
                                                                                                );
                                                                                        })}
                                                                                </div>
                                                                        )}
                                                                </div>

                                                                <div className="border"></div>
                                                        </div>

                                                        <div className="start-rating">
                                                                <div className="app-division">
                                                                        <div className="App-rating">
                                                                                <p>Project Delivery</p>
                                                                                <Rating
                                                                                        onClick={handleRatingDelivery}
                                                                                        value={formValues.project_delivery_stars}
                                                                                // onPointerEnter={onPointerEnter}
                                                                                // onPointerLeave={onPointerLeave}
                                                                                // onPointerMove={onPointerMove}
                                                                                />
                                                                        </div>

                                                                        <div className="App-rating">
                                                                                <p>Project Review</p>
                                                                                <Rating
                                                                                        onClick={handleRatingReview}
                                                                                        value={formValues.project_support_stars}
                                                                                // onPointerEnter={onPointerEnter}
                                                                                // onPointerLeave={onPointerLeave}
                                                                                // onPointerMove={onPointerMove}
                                                                                />
                                                                        </div>
                                                                </div>

                                                                <div className="app-division">
                                                                        <div className="App-rating">
                                                                                <p>Timeline Quality</p>
                                                                                <Rating
                                                                                        onClick={handleRatingQuality}
                                                                                        value={formValues.timeline_quality_stars}
                                                                                // onPointerEnter={onPointerEnter}
                                                                                // onPointerLeave={onPointerLeave}
                                                                                // onPointerMove={onPointerMove}
                                                                                />
                                                                        </div>

                                                                        <div className="App-rating">
                                                                                <p>Value for money</p>
                                                                                <Rating
                                                                                        onClick={handleRatingMoney}
                                                                                        value={formValues.money_value_stars}
                                                                                // onPointerEnter={onPointerEnter}
                                                                                // onPointerLeave={onPointerLeave}
                                                                                // onPointerMove={onPointerMove}
                                                                                />
                                                                        </div>
                                                                </div>

                                                                <div className="single-review">
                                                                        <h5>
                                                                                How likely are you to recommend “Microsoft Office 365
                                                                                Business Basic” to others ?
                                                                        </h5>
                                                                        <div class="btn-group">
                                                                                <button
                                                                                        name="1"
                                                                                        type="button"
                                                                                        value="1"
                                                                                        className={
                                                                                                "oreviewrating" +
                                                                                                (active == 1 ? "oreviewrating-close" : "")
                                                                                        }
                                                                                        onClick={handleLink}
                                                                                >
                                                                                        {active ? "1" : "1"}
                                                                                </button>
                                                                                <button
                                                                                        name="2"
                                                                                        type="button"
                                                                                        value="2"
                                                                                        className={
                                                                                                "oreviewrating" +
                                                                                                (active == 2 ? "oreviewrating-close" : "")
                                                                                        }
                                                                                        onClick={handleLink}
                                                                                >
                                                                                        {active ? "2" : "2"}
                                                                                </button>
                                                                                <button
                                                                                        name="3"
                                                                                        type="button"
                                                                                        value="3"
                                                                                        className={
                                                                                                "oreviewrating" +
                                                                                                (active == 3 ? "oreviewrating-close" : "")
                                                                                        }
                                                                                        onClick={handleLink}
                                                                                >
                                                                                        {active ? "3" : "3"}
                                                                                </button>
                                                                                <button
                                                                                        name="4"
                                                                                        type="button"
                                                                                        value="4"
                                                                                        className={
                                                                                                "oreviewrating" +
                                                                                                (active == 4 ? "oreviewrating-close" : "")
                                                                                        }
                                                                                        onClick={handleLink}
                                                                                >
                                                                                        {active ? "4" : "4"}
                                                                                </button>
                                                                                <button
                                                                                        name="5"
                                                                                        type="button"
                                                                                        value="5"
                                                                                        className={
                                                                                                "oreviewrating" +
                                                                                                (active == 5 ? "oreviewrating-close" : "")
                                                                                        }
                                                                                        onClick={handleLink}
                                                                                >
                                                                                        {active ? "5" : "5"}
                                                                                </button>
                                                                                <button
                                                                                        name="6"
                                                                                        type="button"
                                                                                        value="6"
                                                                                        className={
                                                                                                "oreviewrating" +
                                                                                                (active == 6 ? "oreviewrating-close" : "")
                                                                                        }
                                                                                        onClick={handleLink}
                                                                                >
                                                                                        {active ? "6" : "6"}
                                                                                </button>
                                                                                <button
                                                                                        name="7"
                                                                                        type="button"
                                                                                        value="7"
                                                                                        className={
                                                                                                "oreviewrating" +
                                                                                                (active == 7 ? "oreviewrating-close" : "")
                                                                                        }
                                                                                        onClick={handleLink}
                                                                                >
                                                                                        {active ? "7" : "7"}
                                                                                </button>
                                                                                <button
                                                                                        name="8"
                                                                                        type="button"
                                                                                        value="8"
                                                                                        className={
                                                                                                "oreviewrating" +
                                                                                                (active == 8 ? "oreviewrating-close" : "")
                                                                                        }
                                                                                        onClick={handleLink}
                                                                                >
                                                                                        {active ? "8" : "8"}
                                                                                </button>
                                                                                <button
                                                                                        name="9"
                                                                                        type="button"
                                                                                        value="9"
                                                                                        className={
                                                                                                "oreviewrating" +
                                                                                                (active == 9 ? "oreviewrating-close" : "")
                                                                                        }
                                                                                        onClick={handleLink}
                                                                                >
                                                                                        {active ? "9" : "9"}
                                                                                </button>
                                                                                <button
                                                                                        name="10"
                                                                                        type="button"
                                                                                        value="10"
                                                                                        className={
                                                                                                "oreviewrating" +
                                                                                                (active == 10 ? "oreviewrating-close" : "")
                                                                                        }
                                                                                        onClick={handleLink}
                                                                                >
                                                                                        {active ? "10" : "10"}
                                                                                </button>
                                                                                <input
                                                                                        type="hidden"
                                                                                        class="start_rating_input"
                                                                                        name="star_rating"
                                                                                        id="star_rating"
                                                                                        value=""
                                                                                />
                                                                        </div>
                                                                        <div class="review_hint">
                                                                                <h4>Not likely</h4>
                                                                                <h4>Highly Recommend</h4>
                                                                        </div>
                                                                </div>

                                                                <div className="border"></div>
                                                        </div>

                                                        <div className="overall">
                                                                <p>Overall Review</p>
                                                                <textarea
                                                                        type="textarea"
                                                                        rows="4"
                                                                        placeholder="Please share your thoughts here."
                                                                        value={formValues.reviews}
                                                                        onChange={handleReview}
                                                                />
                                                        </div>
                                                </div>

                                                {formValues?.reviews?.length > 0 ? (
                                                        <div className="formbuton">
                                                                <input
                                                                        type="submit"
                                                                        className="save-button"
                                                                        value="Save"
                                                                        onClick={handleSubmitReview}
                                                                />
                                                        </div>) : (
                                                        <div className="formbuton">
                                                                <input
                                                                        type="submit"
                                                                        className="save-button-notwork"
                                                                        value="Save"
                                                                />
                                                        </div>
                                                )}

                                        </div>
                                </main>
                        </div>
                </div>
        );
};
export default Customerreviewsadd;
