import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BudgetIcon from "../../assets/images/budget-icon.png";
import Close from "../../assets/images/close-info.png";
import ProjectIcon from "../../assets/images/project-name-icon.png";
import CategoryIcon from "../../assets/images/category-icon.png";
import ClockIcon from "../../assets/images/project-date-icon.png";
import Descripiton from "../../assets/images/descripiton-icon.png";
import Country from "../../assets/images/country-icon.png";
import Attach from "../../assets/images/attach-icon.png";
import DocIcon from "../../assets/images/doc-icon.png";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import { useNavigate } from "react-router-dom";

const CustomerProjectInfo = (props) => {
  const navigate = useNavigate();
  // const { proposalDetails } = useSelector(
  //   (state) => state.customerProposalList
  // );
  // const { projectDetails } = useSelector((state) => state.customerProjectInfo);
  const [showMore, setShowMore] = useState(false);

  const onClickViewProposal = () => {
    navigate("/customer-proposal-list");
  };
  const onClickProjectProgressReport = () => {
    navigate("/customer-milestone-list");
  };

  return (
    <>
      {props.isShow && (
        <div className="project-details-popup">
          <div className="customer-popup-project">
            <div className="popup-main">
              <div className="customer-popup-close cs-popup-close-in-mb">
                <img className="project-icon" onClick={props.onRowClicked} src={Close} alt=""></img>
                <p className="proposal-count-in-mb">Proposals <span className="v-count-in-mb">{props.proposalCount}</span></p>
              </div>
              <div className="project-info-header-div">
                <h3 className="project-info-header">Project Information</h3>
                <div>
                  {props.proposalCount > 0 && (
                    <button
                      onClick={
                        props.projectStatus === "Applied"
                          ? onClickViewProposal
                          : onClickProjectProgressReport
                      }
                      className="view-all-proposal-btn"
                    >
                      {props.projectStatus === "Applied" ? (
                        <p className="cs-view-proposal-count">
                          <span className="cs-v-count">
                            {props.proposalCount}
                          </span>{" "}
                          View Proposals
                        </p>
                      ) : props.projectStatus === "In Progress" ? (
                        "Project  Progress Report"
                      ) : (
                        "Project Report"
                      )}
                    </button>
                  )}
                  <p className="cs-vendor-count">
                    {props.proposalCount} proposal received
                  </p>
                </div>
              </div>
              <div className="project-name-category-div">
                <div style={{marginRight:"10px" }} className="wid-half">
                  <h3 className="customer-project-label">
                    <img
                      className="project-icon"
                      src={ProjectIcon}
                      alt=""
                    ></img>
                    Project name
                  </h3>
                  <div className="customer-project-text">
                    {props.projectName}
                  </div>
                </div>
                <div className="wid-half">
                  <h3 className="customer-project-label">
                    <img className="project-icon" src={CategoryIcon} alt="" />
                    Category
                  </h3>
                  <div className="customer-project-text">{props.category}</div>
                </div>
              </div>
              <div className="project-name-category-div">
                <div className="wid-thirty">
                  <h3 className="customer-project-label">
                    <img className="project-icon" src={ClockIcon} alt=""></img>
                    Project Timeline
                  </h3>
                  <div className="customer-project-text customer-project-timeline">
                    {props.timelinedays} Days
                  </div>
                </div>
                <div className="wid-thirty">
                  <h3
                    style={{ gap: "10px" }}
                    className="customer-project-label"
                  >
                    <img className="project-icon" src={BudgetIcon} alt=""></img>
                    Project budget
                  </h3>
                  <div className="customer-project-text customer-project-timeline">
                    {props.budget}
                  </div>
                </div>
                <div className="wid-thirty">
                  <h3 className="customer-project-label">
                    <img className="project-icon" src={Country} alt="" />
                    Country
                  </h3>
                  <div className="customer-project-text customer-project-timeline">
                    {props.country}
                  </div>
                </div>
              </div>
              <div className="project-name-category-div">
                <div>
                  <h3 className="customer-project-label">
                    <img
                      className="project-icon"
                      src={Descripiton}
                      alt=""
                    ></img>
                    Description
                  </h3>
                  <div className="customer-project-text project-text-description">
                    {!showMore
                      ? parse(
                        props.descripiton.replace(/^(.{100}[^\s]*).*/, "$1")
                      )
                      : parse(props.descripiton)}

                    {props.descripiton.length > 50 && (
                      <button
                        className="proposal-show-more-button"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {!showMore ? "Show More" : "Show Less"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="project-name-category-div">
                {props.attachName && (
                  <div>
                    <h3 className="customer-project-label">
                      <img className="project-icon" src={Attach} alt=""></img>
                      Attachment
                    </h3>
                    <div className="customer-attach-box" x>
                      <img className="project-icon" src={DocIcon} alt=""></img>
                      <a
                        href={props.attachUrl}
                        target={"_blank"}
                        className="customer-attach-name"
                      >
                        {props.attachName}
                      </a>
                    </div>
                  </div>
                )}
                {/* <div>
                  {props.proposalCount > 0 && (
                    <button
                      onClick={onClickViewProposal}
                      className="view-all-proposal-btn"
                    >
                      View All Proposal
                    </button>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerProjectInfo;
