import React, { useState, useEffect } from "react";
import "./Register.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import registerbanner from "../../assets/images/registerback.png";
import product from "../../assets/images/product-icon.png";
import inventory from "../../assets/images/inventory-icon.png";
import customer from "../../assets/images/customer-icon.png";
import diagram from "../../assets/images/diagram.png";
import correct from "../../assets/images/correct.png";
import monitor from "../../assets/images/monitor.png";
import { BaseUrl } from "../../Api/api.js";
import CommonApi from "../../Api/commonApi";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { ButtonToolbar } from "rsuite";
import "rsuite/dist/rsuite.css";
import OtpVerificationModal from "../../components/Modal/OtpVerificationModal/OtpVerificationModal";

const VendorRegister = () => {
  const initialValues = {
    title: "",
    first_name: "",
    last_name: "",
    company_name: "",
    user_name: "",
    password: "",
    confirm_password: "",
    country: "",
    currency: "",
    currency_code: "",
    symbol: "",
    city: "",
    state: "",
    zipcode: "",
    address: "",
    solutions: "",
    phone_no: "",
    checkboxemail: "",
    checkboxagree: "",
  };

  const [countryList, setCountryList] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [solutions, setSolutions] = useState([]);
  const [phone_no, setPhone_No] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState("");
  const [open, setOpen] = useState({ isHide: false });
  const [dataValidated, setDataValidated] = useState(false);
  const [details, setDetails] = useState({});
  const [showResend, setShowResend] = useState(false);
  useEffect(() => {
    try {
      CommonApi.allCountryList().then((csresponse) => {
        if (csresponse.status === 200 || csresponse.status === 2000) {
          setCountryList(csresponse.data.country_list);
        }
      });
    } catch {
      console.log("error");
    }
  }, []);

  // const onChange = (phone_no) => {
  //   console.log("phoneno", phone_no);
  //   setFormValues(phone_no);
  //   console.log(initialValues);
  // };
  // console.log(details, "vendor details");
  const validate = (values) => {
    // console.log("validate", values);
    const vendorDetails = values;
    setDetails(vendorDetails);

    const errors = {};

    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&$%]).{8,24}$/;
    //const phone_regex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
    //const zip_regex = /\(?([0-9]{6})\)/;
    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    if (!email_regex.test(values["user_name"])) {
      errors.user_name = "This is not valid email format";
    } else if (!PWD_REGEX.test(values["password"])) {
      errors.password =
        "Password Length Needed  Minimum eight  at least one uppercase letter, one lowercase letter, one number and one special character example:Conqt@123";
    } else if (!PWD_REGEX.test(values["confirm_password"])) {
      errors.confirm_password =
        "Password Length Needed Minimum eight  at least one uppercase letter, one lowercase letter, one number and one special character example:Conqt@123";
    } else if (values.password !== values.confirm_password) {
      toast("Passwords don't match");
      // } else if (!zip_regex.test(values["zipcode"])) {
      //   errors.zipcode = "This is not valid Zip number";
    }
    // } else if (!phone_regex.test(values["phonenumber"])) {
    //   errors.phonenumber =
    //     "This is not valid phone number. Format - (123) 456 7899";
    // }
    console.log(errors);
    return errors;
  };
  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleChangePassword = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (e.target.value.length < 8) {
      setError("Password must be at least 8 characters long");
    } else if (!/\d/.test(e.target.value)) {
      setError("Password must contain at least one number");
    } else if (!/[a-z]/.test(e.target.value)) {
      setError("Password must contain at least one lowercase letter");
    } else if (!/[A-Z]/.test(e.target.value)) {
      setError("Password must contain at least one uppercase letter");
    } else if (!/[!@#$%^&*]/.test(e.target.value)) {
      setError(
        "Password must contain at least one special character  @, #, $, %, &, or !"
      );
    } else {
      setError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(isValidPhoneNumber(phone_no),"@@@@")
    const currencyData = countryList.filter(
      (item) => item.country == formValues.country
    );
    currencyData.map((key) => {
      formValues.currency = key.currency;
      formValues.currency_code = key.code;
      formValues.symbol = key.symbol;
    });

    let error = validate({ ...formValues, phone_no: phone_no });
    // console.log(formValues, "formValues");

    if (
      Object.keys(error).length !== 0 ||
      !isValidPhoneNumber(phone_no ? phone_no : "")
    ) {
      setFormErrors(error);
      console.log("Error Found");
      return false;
    }
    console.log("No Validation Error Found");
    setDataValidated(!dataValidated);

    delete formValues["checkboxemail"];
    delete formValues["checkboxagree"];

    //setIsSubmit(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        user_name: formValues.user_name && formValues.user_name,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(`${BaseUrl}/vendor/Otp-Send`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          let temp = { ...open };

          // console.log(open, "popup");
          // setOpen(true)
          // setOpen( (prevState)=>{
          //     console.log(prevState ,"prevstate")
          //     return true
          // });

          if (result.status === 200) {
            setOpen({ ...temp, isHide: true });
            toast("OTP Successfully Send To Your Registar Mail ID");
            // console.log("result:", result);
            setOpen({ ...temp, isHide: true });
          } else if (result.message === "Email Id already register with us.") {
            setOpen({ ...temp, isHide: false });
            toast(result.message);
          } else if (result.status === 401) {
            setOpen({ ...temp, isHide: true });
            toast(result.message);
            setShowResend(true);
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  const tnccheck = (e) => {
    if (e.target.checked) {
      e.target.value = 1;
    } else {
      e.target.value = "";
    }
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0) {
      console.log(formValues);
    }
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(`${BaseUrl}/vendor/Get-Solutions`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log("Get Solutions", JSON.parse(result).data);
        setSolutions(JSON.parse(result).data);
      })
      .catch((error) => console.log("error", error));
  }, [formErrors]);

  // console.log(phone_no, "from state value");

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="register-toggle">
      <div className="registerfeatures">
        <img src={registerbanner} alt="" className="registerbanner" />
        <h3>
          Connect with customers, market your <br />products/services, upscale your business globally
        </h3>
        <div className="regAdvantages">
          <div>
            <img src={customer} alt="product" className="regicons" />
            <h4>
              <span>50K+</span> <br />
              Buyer
            </h4>
          </div>
          <div>
            <img src={inventory} alt="inventory" className="regicons" />
            <h4>
              <span>100K+</span> <br />
              Supplier
            </h4>
          </div>
          <div>
            <img src={product} alt="customer" className="regicons" />
            <h4>
              <span>5K+</span> <br />
              Products & Services
            </h4>
          </div>
        </div>
        <div className="register-window mt-20">
          <h3>Sell On ConQt</h3>
          <div className="sellFeature">
            <div className="sellconqt">
              <img src={diagram} alt="" />
              <div className="sellfeatures">
                <h4>Grow your Business</h4>
                <p>Sell to buyers anytime, anywhere</p>
              </div>
            </div>
            <div className="sellconqt">
              <img src={correct} alt="" />
              <div className="sellfeatures">
                <h4>Verified Client</h4>
                <p>100% Verified Vendors/Clients</p>
              </div>
            </div>
            <div className="sellconqt">
              <img src={monitor} alt="" />
              <div className="sellfeatures">
                <h4>Manage your Business Better</h4>
                <p>Sell to buyers anytime, anywhere</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="register-window">
        <div className="registerhead">
          <h1>Partner Registration</h1>
        </div>
        <form
          className="register-form"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <div className="name-field">
            <select className="nametag" name="title" onChange={handleChange}>
              <option>Select*</option>
              <option value="Mr">Mr.</option>
              <option value="Miss">Miss.</option>
              <option value="Mrs">Mrs.</option>
        
            </select>
            <input
              type="text"
              name="first_name"
              placeholder="First Name*"
              value={formValues.first_name}
              onChange={handleChange}
            />
            <input
              type="text"
              name="last_name"
              placeholder="Last Name*"
              value={formValues.last_name}
              onChange={handleChange}
              required
            />
          </div>
          <p className="err-message">{formErrors.title}</p>
          {formErrors.first_name && (
            <p className="err-message"> First Name Field is required</p>
          )}
          {formErrors.last_name && (
            <p className="err-message"> Last Name Field is required</p>
          )}
          <div className="form-field">
            <input
              type="text"
              name="company_name"
              value={formValues.company_name}
              placeholder="Enter Your Company Name*"
              onChange={handleChange}
            />
          </div>
          {formErrors.company_name && (
            <p className="err-message"> Company Name Field is required</p>
          )}
          <div className="form-field">
            <input
              type="email"
              name="user_name"
              value={formValues.user_name}
              placeholder="Enter Email ID*"
              onChange={handleChange}
            />
          </div>
          {formErrors.user_name && (
            <p className="err-message"> Email Field is required </p>
          )}
          <div className="form-field">
            <input
              type={passwordShown ? "text" : "password"}
              name="password"
              value={formValues.password}
              placeholder="Enter your Password*"
              onChange={handleChangePassword}
            />
          </div>
          <div className="show-password">
            <input
              className="validation-password"
              type="checkbox"
              onClick={togglePassword}
            />
            Show Password
          </div>
          {error && (
            <p className="validation-password" style={{ color: "red" }}>
              {error}
            </p>
          )}
          {/* <p className="err-message">{formErrors.password}</p> */}
          <div className="form-field">
            <input
              type="password"
              name="confirm_password"
              value={formValues.confirm_password}
              placeholder="Enter your Confirm Password*"
              onChange={handleChangePassword}
            />
          </div>

          {formErrors.confirm_password && (
            <p className="err-message"> Confirm Password Field is required</p>
          )}
          <div className="form-field">
            {/* <input
              type="text"
              name="phone_no"
              value={formValues.phone_no}
              placeholder="Office Phone Number"
              onChange={handleChange}
            /> */}

            <PhoneInput
              country="india"
              placeholder="+919876543210"
              name="phone_no"
              international
              value={formValues.phone_no.length ? formValues.phone_no : ""}
              defaultCountry="IN"
              onChange={(e) => {
                setPhone_No(e);
              }}
            />
          </div>
          {phone_no && !isValidPhoneNumber(phone_no) && (
            <span className="error-msg-phone">
              Please enter the valid phone number
            </span>
          )}
          <p className="err-message">{formErrors.phone_no}</p>
          <select
            className="selectdropdown"
            name="solutions"
            value={formValues.solutions_name}
            // placeholder="Enter the solutions"
            onChange={handleChange}
          >
            <option>Select Solutions</option>
            {solutions.map((solutions) => (
              <option key={solutions.id} value={solutions.solutions_name}>
                {solutions.solutions_name}
              </option>
            ))}
          </select>
          <p className="err-message">{formErrors.solutions}</p>
          <div className="form-field">
            <input
              type="text"
              name="address"
              value={formValues.address}
              placeholder="Select Company Address*"
              onChange={handleChange}
            />
          </div>
          <p className="err-message">{formErrors.address}</p>
          <div className="form-field">
            <select
              type="text"
              name="country"
              placeholder="Select Country*"
              value={formValues.country}
              onChange={handleChange}
              className="selectcountry"
            >
              {countryList.map((el) => {
                return (
                  <>
                    <option value={el.country}>{el.country}</option>
                  </>
                );
              })}
            </select>
          </div>
          <p className="err-message">{formErrors.country}</p>
          <div className="form-field">
            <input
              type="text"
              name="city"
              value={formValues.city}
              placeholder="Enter Your City*"
              onChange={handleChange}
            />
          </div>
          <p className="err-message">{formErrors.city}</p>
          <div className="form-field">
            <input
              type="text"
              name="state"
              value={formValues.state}
              placeholder="Enter Your State*"
              onChange={handleChange}
            />
          </div>
          <p className="err-message">{formErrors.state}</p>
          <div className="form-field">
            <input
              type="number"
              name="zipcode"
              value={formValues.zipcode}
              placeholder="Enter Your Zip Code*"
              onChange={handleChange}
            />
          </div>
          <p className="err-message">{formErrors.zipcode}</p>
          <div className="form-checkbox">
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
            <input
              type="checkbox"
              name="checkboxemail"
              className="form-input"
              onChange={handleChange}
              onClick={tnccheck}
            />

            <label>
              Send me emails with tips on how to find talent that fits my needs.
            </label>
          </div>
          <p className="err-message">
            {formErrors.checkboxemail && "Please Select Check Box"}
          </p>
          {/* <p className="err-message">{formValues.checkboxemail != "1" && "Please select check box"}</p> */}

          <div className="form-checkbox">
            <span style={{ color: "red", fontSize: "20px" }}>*</span>
            <input
              type="checkbox"
              name="checkboxagree"
              className="form-input"
              onChange={handleChange}
              onClick={tnccheck}
            />

            <label>
              Yes, I understand and agree to the ConQt
              <span>
                {" "}
                <a href="https://login.conqt.com/terms-and-conditions"  style={{color:"blue"}}>
                  Terms of Services
                </a>
              </span>
              , including the
              <span> User Agreement</span> and{" "}
              <span>
                {" "}
                <a href="https://login.conqt.com/privacy-policy" style={{color:"blue"}}>
                  Privacy Policy
                </a>
              </span>
              .
            </label>
          </div>
          <p className="err-message">
            {formErrors.checkboxagree && "Please Select Check Box"}
          </p>
          {/* <p className="err-message">{formValues.checkboxagree != "1" && "Please select check box"}</p> */}

          <ButtonToolbar>
            <button
              className="tog-reg-btn btn"
              // disabled={
              //   !(
              //     formValues.checkboxagree === "1" &&
              //     formValues.checkboxemail === "1"
              //   )
              // }
            >
              Submit
            </button>
          </ButtonToolbar>
        </form>
        <div className="login-account">
          <p>
            Already have an account ? <Link to="/login">Log In</Link>
          </p>
        </div>
      </div>
      {dataValidated && (
        <OtpVerificationModal
          open={open.isHide}
          handleClose={() => {
            setOpen(!open.isHide);
          }}
          vendorMail={details.user_name}
          vendorDetails={formValues}
          phone_no={phone_no}
          showingResend={showResend}
        />
      )}
    </div>
  );
};

export default VendorRegister;
