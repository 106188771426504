import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BaseUrl } from "../../Api/api.js";
import { Link } from "react-router-dom";
import "./Customer.css";
import "./OpenModel.css";
import { AiOutlineCloseSquare } from "react-icons/ai";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import { TbHandClick } from "react-icons/tb";
import select from "../../assets/images/select.svg";

const Viewproposal = () => {
  const [data, setData] = useState([]);
  const [vdata, setVData] = useState([]);
  const [openModal, setOpenModal] = useState();
  const ID = useParams().announcement_id;
  //console.log("params", ID);

  const userinfo = JSON.parse(localStorage.getItem("user"));
  const userInfoData = userinfo.user_info;

  const userToken = localStorage.getItem("token");
  const announcementid = localStorage.getItem("announcement_id");
  //console.log("announcementid", announcementid);
  const initialFormData = {
    announcement_id: "",
    proposal_id: "",
    action_type: "",
  };

  const [formValues, setFormValues] = useState(initialFormData);

  const getProposalList = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        announcement_id: announcementid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Get-Vendor-List-apply-Announcment`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("proposal", result.data);
          if (
            result.data.filter((check) => check.proposal_status == 1).length ==
            0
          ) {
            setData(result.data);
          } else {
            setData(result.data.filter((check) => check.proposal_status == 1));
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProposalList();
  }, []);

  console.log(data.filter((check) => check.proposal_status == 1));

  const proposalAcceptedList = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        proposal_id: formValues.proposal_id,
        announcement_id: formValues.announcement_id,
        action_type: formValues.action_type,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Proposal-Acceptance-By-Customer`,
        requestOptions
      )
        .then((response) => response.text())
        // .then((result) => console.log("2", result))
        .then((result) => {
          console.log("2", result);
          getProposalList();
        })

        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  const handleMilestone = () => {
    toast.success("Thank you for select vendor for your announcement");
    // // on confirmation a mouse click:
    window.location.href = "/customer-milestone";
    // // redirec to payment:
    // window.location.replace("/customer-invoice-payment");
  };

  data.map((key) => {
    key.milestonelist.map((item, index) => {
      if (index == 0) {
        console.log("Milestone Price", item);
      }
    });
  });

  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          {openModal && <Modal closeModal={setOpenModal} />}

          <div className="data-card">
            <main className="maincontent">
              <div>
                {data.map((item) => {
                  if (ID == item.announcement_id && item.proposal_status == 0) {
                    return (
                      <div className="projectprolist">
                        <div className="project">
                          <span>
                            <h4>Partner Name -</h4>
                            <p>
                              {item.proposal_status == 0 ? (
                                <></>
                              ) : item.proposal_status == 1 ? (
                                <> {item.vendor_name}</>
                              ) : (
                                <></>
                              )}
                            </p>
                          </span>

                          <Link
                            to={`/projectlist/view/${item.announcement_id}/${item.proposal_id}`}
                          >
                            <button className="partner-btn">
                              <img src={select} alt="selectbtn" />
                              View
                            </button>
                          </Link>
                        </div>

                        <div className="description">
                          <span>
                            <h4>Description :</h4>
                            <p>
                              {parse(
                                item.perposal_description.substring(0, 150)
                              )}
                            </p>
                          </span>

                          <span>
                            <h4>Resources : </h4>
                            <p> {parse(item.project_resources)}</p>
                          </span>
                        </div>

                        <div className="project">
                          <span>
                            <h4>Project Timeline -</h4>
                            <p className="btnhover">
                              {item.timeline} {item.timeline_unit}
                            </p>
                          </span>

                          <span>
                            <h4>Project Price -</h4>
                            <p className="btnhover">
                              {item.customer_amount}{" "}
                              {userInfoData.currency_code}
                            </p>
                          </span>
                        </div>
                      </div>
                    );
                  } else if (item.proposal_status == 1) {
                    return (
                      <div className="projectprolist">
                        <div className="project">
                          <span>
                            <h4>Partner Name -</h4>
                            <p>
                              {item.proposal_status == 0 ? (
                                <></>
                              ) : item.proposal_status == 1 ? (
                                <> {item.vendor_name}</>
                              ) : (
                                <></>
                              )}
                            </p>
                          </span>

                          <Link
                            to={`/projectlist/view/${item.announcement_id}/${item.proposal_id}`}
                          >
                            <button className="partner-btn">
                              <img src={select} alt="selectbtn" />
                              View
                            </button>
                          </Link>
                        </div>

                        <div className="description">
                          <span>
                            <h4>Description :</h4>
                            <p>
                              {parse(
                                item.perposal_description.substring(0, 150)
                              )}
                            </p>
                          </span>

                          <span>
                            <h4>Resources : </h4>
                            <p> {parse(item.project_resources)}</p>
                          </span>
                        </div>

                        <div className="project">
                          <span>
                            <h4>Project Timeline -</h4>
                            <p className="btnhover">
                              {item.timeline} {item.timeline_unit}
                            </p>
                          </span>

                          <span>
                            <h4>Project Price -</h4>
                            <p className="btnhover">
                              {item.customer_amount}{" "}
                              {userInfoData.currency_code}
                            </p>
                          </span>
                        </div>
                      </div>
                    );
                  } else if (item.proposal_status == 2) {
                    return <></>;
                  }
                })}

                <>
                  {/* {data.map((item) => {
                    if (ID == item.announcement_id) {
                      return (
                        <div className="projectprolist">
                          <div className="project">
                            <span>
                              <h4>Partner Name -</h4>
                              <p>
                                {item.proposal_status == 0 ? (
                                  <></>
                                ) : item.proposal_status == 1 ? (
                                  <> {item.vendor_name}</>
                                ) : (
                                  <></>
                                )}
                              </p>
                            </span>

                            <Link
                              to={`/projectlist/view/${item.announcement_id}/${item.proposal_id}`}
                            >
                              <button className="partner-btn">
                                <img src={select} alt="selectbtn" />
                                View
                              </button>
                            </Link>
                          </div>

                          <div className="description">
                            <span>
                              <h4>Description :</h4>
                              <p>
                                {parse(
                                  item.perposal_description.substring(0, 150)
                                )}
                              </p>
                            </span>

                            <span>
                              <h4>Resources : </h4>
                              <p>10 Resources required for project.</p>
                            </span>
                          </div>

                          <div className="project">
                            <span>
                              <h4>Project Timeline -</h4>
                              <p className="btnhover">
                                {item.timeline} {item.timeline_unit}
                              </p>
                            </span>

                            <span>
                              <h4>Project Price -</h4>
                              <p className="btnhover">
                                {item.price} {item.currency}
                              </p>
                            </span>
                          </div>
                        </div>
                      );
                    }
                  })} */}
                  {/* <table className="proposal-table">
                  <tr className="proposal-heading">
                    <th>Partner Name</th> 
                    <th>Description</th> 
                   <th>Project Resources</th>
                     <th>Project Timeline</th>
                    <th>Project Price</th>
                    <th>Select Partner</th>
                  </tr>

                  {data.map((item) => {
                    if (ID == item.announcement_id) {
                      return (                            
                 
                        <tr className="proposal-data">
                         <td data-label="Name" className="cell blurry-text">
                            {parse(item.vendor_name)}
                          </td> 
                         <td data-label="Description">
                            {parse(item.perposal_description)}
                          </td> 
                          <td data-label="Timeline">
                            {item.timeline} {item.timeline_unit}
                          </td>
                          <td data-label="Currency">
                            {item.price} {item.currency}
                          </td> 
                          <td data-label="Resources">
                            {parse(item.project_resources)}
                          </td> 
                          <td className="cell scroll">
                        <Link
                       to={`/projectlist/view/${item.announcement_id}/${item.proposal_id}`}
                        >
                       <TbHandClick />
                       </Link>
                       </td>
                        </tr>
                        
                      );
                    }
                  })}
                </table> */}
                </>
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};

const Modal = ({ closeModal }) => {
  const [vdata, setVData] = useState([]);

  const userToken = localStorage.getItem("token");
  const announcementid = localStorage.getItem("announcement_id");

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        announcement_id: announcementid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Get-Vendor-List-apply-Announcment`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("proposal", result.data);
          setVData(result.data);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <div className="ModelBackground">
      <div className="modalContainer">
        <div class="bg-circles">
          <div id="circle-1"></div>
          <div id="circle-2"></div>
          <div id="circle-3"></div>
        </div>

        <div className="closebtn" onClick={() => closeModal(false)}>
          <AiOutlineCloseSquare className="close" />
        </div>
        <h1 id="title">Congratulations 🎉 </h1>
        {vdata.map((item) => {
          if (item.proposal_status == 1) {
            return (
              <p class="conf-text">
                You have selected to <strong>{item.vendor_name}</strong> for the
                Project <strong>{item.project_name}</strong>
              </p>
            );
          }
        })}
        <button id="btn-continue">
          <Link to="/customer-milestone">Continue</Link>
        </button>
      </div>
    </div>
  );
};

export default Viewproposal;
