import React, { useState, useEffect } from "react";
import "./page.css";
import Sidebar from "../Sidebar";
import Topbar from "./Topbar";
import profile from "../../../assets/images/profile.png";
import { BsInfoCircle } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { FcCameraIdentification } from "react-icons/fc";
import "react-phone-number-input/style.css";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import barr from "./images/barr.png";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch } from "react-redux";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./util.js";
import Spinner from "../../../components/Header/Spinner";
import { BaseUrl } from "../../../Api/api";

const MyProfile = (props) => {
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [showBar, setShowBar] = useState("0");

  const userToken = localStorage.getItem("token");
  const userinfo = JSON.parse(localStorage.getItem("userinfo"));

  useEffect(() => {
    props.updatePageloaderState(true)
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendor/Get-Vendor-My-Profile`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(result.data);
          if (result.data.length > 0) {
            props.updatePageloaderState(false)
            props.onChangeStatusValue(result.data[0].profile_complete_status);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleNext = (e) => {
    window.location = "/agency-profile";
  };

  const handleSkip = (e) => {
    window.location = "/agency-profile";
  };

  return (
    <div className="mainpro">
      {/* <Topbar /> */}
      <section>
        <div className="basic-info">
          <h3>My Profile</h3>
          <Link to="/vendor-profile/edit">
            <h4>
              Edit
              <MdModeEdit />
            </h4>
          </Link>
        </div>
      </section>
      {data.map((item) => {
        console.log(item, "data from item");
        return (
          <div className="profileform" key={item}>
            <section>
              {/* <div className="profile-info"></div> */}
              <div className="division">
                <div className="upload-file">
                  <h3>
                    Profile Image<span className="star">*</span>
                  </h3>
                  <div className="profilepicupload">
                    <img src={item.profile_img_url} alt="{item.name}" />
                    {/* <FcCameraIdentification />
                    <input type="file" name="myfile" /> */}
                  </div>
                  {/* <p className="profilepicdes">
                    300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
                  </p> */}
                </div>
                {/* <div className="progressbar">
                  <div className="bar-profile">
                    <h4>Completion Progress</h4>
                    {/* <img src={barr} alt="" /> */}
              </div>
            </section>

            <section className="pro-info">
              <div className="first-last-names">
                <div className="proformfield">
                  <label>
                    Your Name <span className="star">*</span>
                  </label>
                  <p className="profields">{item.first_name}</p>

                  {/* <div className="protip">
                    <BsInfoCircle />
                    <p>Set at once and can’t be change</p>
                  </div> */}
                </div>
                <div className="proformfield profile-width">
                  <label>
                    Your Last Name <span className="star">*</span>
                  </label>
                  <p className="profields">{item.last_name}</p>

                  {/* <div className="protip">
                    <BsInfoCircle />
                    <p>Set at once and can’t be change</p>
                  </div> */}
                </div>
              </div>
              <div className="proformfield">
                <label>
                  Email<span className="star">*</span>
                </label>
                <p className="profields">{item.email}</p>

                <div className="protip">
                  <BsInfoCircle />
                  <p>Set at once and can’t be change</p>
                </div>
              </div>
            </section>

            <section className="details-info">
              <div className="proformfield">
                <label>
                  Contact Number<span className="star">*</span>
                </label>

                <p className="profields">{item.phone_no}</p>
                <div className="profile-info svg"></div>
              </div>
              <div className="proformfield">
                <div className="profile-address">
                  <label>
                    Company Name<span className="star">*</span>
                  </label>
                  <p className="profields">{item.company_name}</p>
                </div>
              </div>
              <div className="proformfield">
                <label>
                  Position<span className="star">*</span>
                </label>

                <p className="profields" title="Position">
                  {item.position ? item.position : "please select a position"}
                </p>

                <div className="profile-info svg"></div>
              </div>

              <div className="proformfield">
                <label>
                  Department<span className="star">*</span>
                </label>
                <p className="profields">
                  {item.department
                    ? item.department
                    : "please select a department"}
                </p>

                <div className="profile-info svg"></div>
              </div>
            </section>

            <section className="pro-info">
              <div className="proformfield">
                <div className="profile-address">
                  <label>
                    Address<span className="star">*</span>
                  </label>
                  <p className="profields">{item.address}</p>
                </div>
              </div>
              <div className="first-last-names">
                <div className="proformfield">
                  <label>
                    Country <span className="star">*</span>
                  </label>
                  <p className="profields">
                    {item.country || userinfo.country}
                  </p>
                </div>
                <div className="proformfield profile-width">
                  <label>
                    City <span className="star">*</span>
                  </label>
                  <p className="profields">{item.city || userinfo.city}</p>
                </div>
              </div>
              <div className="first-last-names">
                <div className="proformfield">
                  <label>
                    State / Province <span className="star">*</span>
                  </label>
                  <p className="profields">
                    {item.state || userinfo.state || "State/Province"}
                  </p>
                </div>
                <div className="proformfield profile-width">
                  <label>
                    Pin Code <span className="star">*</span>
                  </label>
                  <p className="profields">
                    {item.zipcode || userinfo.zipcode}
                  </p>
                </div>
              </div>
            </section>

            <div className="formbuton">
              {/* <Link to={`/AgencyProfile`}> */}
              <input
                type="submit"
                className="save-button"
                value="Skip"
                onClick={handleSkip}
              />

              <input
                type="submit"
                className="save-button"
                value="Next"
                onClick={handleNext}
              />

              {/*<input
            type="submit"
            className="save-button"
            value="Skip"
            onClick={handleSkip}
          />
           </Link> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default MyProfile;
