import React, { useState, useEffect } from "react";
import "./page.css";

// drag drop file component
export default function DragDropFile(props) {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
      console.log(dragActive, "dragactive true");
    } else if (e.type === "dragleave") {
      setDragActive(false);
      console.log(dragActive, "dragactive false");
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    let temp = [];
    console.log(e.target.files, "on change");
    const file = Object.values(e.target.files).forEach((key) => {
      temp.push(key.name);
    });
    setFiles(temp);
    props.onChange(e.target.files);
    console.log(temp, "files");
    e.preventDefault();

    if (e.target.files && e.target.files[0]) {
      // handleFiles(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
    console.log("button pressed");
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        <div>
          <p>Drag and drop your file here or Upload a file</p>
          {/* <button className="upload-button" onClick={onButtonClick}>Upload a file</button> */}
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}
