import React, { useState, useEffect } from "react";
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
import "./bcds.css";
import fail from "../../../assets/images/cir.svg";
import { useNavigate } from "react-router";


const FailureModal = ({
        open,
        handleClose,
        announcement_id,
}) => {

        // const [delay, setDelay] = useState(200);
        const [close, setClose] = useState(handleClose);
        const navigate = useNavigate();
        console.log("announcement_id for modal", announcement_id);

        const handleOpp = () => {
                navigate(`/new-buy-customer-details-page/${announcement_id}`);
                setClose(handleClose(false))
        }

        return (
                <>
                        <Modal open={open} handleClose={handleClose}
                                style={{ paddingTop: "40px" }}>
                                <div className="rs-m">
                                        <Modal.Body >
                                                <div className="d-flex align-items-center justify-content-center flex-column">
                                                        <div className="">
                                                                <div className="d-flex align-items-center justify-content-center flex-column">
                                                                        <h3 className="fail-text">Payment Failed</h3>
                                                                        <img src={fail} alt="" style={{ width: "100px", paddingTop:"20px" }} />
                                                                        <h3 className="text-fail">Please try again</h3>
                                                                        <button className="modal-fail-btn mt-4" onClick={handleOpp}>
                                                                                <h3 className="modal-fail-btn-text px-3">OK</h3>
                                                                        </button>
                                                                </div>
                                                        </div>
                                                </div>
                                        </Modal.Body>
                                </div>
                        </Modal>
                </>
        );
};

export default FailureModal;
