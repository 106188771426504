import React, { useEffect, useState } from "react";
import "./view.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import trophy from "../../assets/images/trophy.png";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Popup from "reactjs-popup";
import { AiFillEye } from "react-icons/ai";
import PageLoader from "../../components/PageLoader/PageLoader";

const VendorProjectApplyList = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const userToken = localStorage.getItem("token");
  const announcementid = localStorage.getItem("announcement_id");
  console.log(data);

  useEffect(() => {
    setPageLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendordashboard/Vendor-Proposal-List`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(result.data);
          setPageLoading(false);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);
  console.log(data);
  return (
    <>
    {pageLoading && (
      <PageLoader/>
      )}
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />
        <br />

        <div className="container">
          <table className="rwd-table">
            <tbody>
              <tr class="tabletopback">
                <th>Date</th>
                <th>Project Name</th>
                <th>Price</th>
                <th>Days</th>
                <th>Project Status</th>
                <th>View</th>
              </tr>
              {data.map((item, announcement_id) => {
                return (
                  <tr>
                    <td data-th="Date">{item.created_at}</td>
                    <td data-th="Project Name">{item.project_name}</td>
                    <td data-th="Project Price" className="text-center">
                      {item.price.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                      &nbsp;
                      {item.currency}
                    </td>
                    <td data-th="Days" className="text-center">
                      {item.timeline} {item.timeline_unit}
                    </td>
                    <td data-th="Project Status" className="text-center">
                      <span>
                        {item.proposal_status == 2 ? (
                          <p className="selecttags">Rejected</p>
                        ) : item.proposal_status == 1 ? (
                          <p className="text-center awards">
                            <img src={trophy} alt="trophy" />
                          </p>
                        ) : (
                          <li className="reviewprop">Review</li>
                        )}
                      </span>
                    </td>

                    <td data-th="View" className="cell scroll text-center">
                      <Link
                        to={`/vendor-view-project-list/${item.proposal_id}`}
                        className="viewbtn"
                      >
                        {/* <button  */}
                        <AiFillEye />
                        {/* </button> */}
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </>
  );
};
export default VendorProjectApplyList;
