import React, { useEffect, useState } from "react";
import "./Vendor.css";
import Sidebar from "./Sidebar";
import ProjectList from "./Project-list";
import Topbar from "./Topbar";
import { Link } from "react-router-dom";
import office365 from "../../assets/images/office365.jpg";

const AddProject = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModals, setOpenModals] = useState(false);
  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />
        <div className="comingsoon">
          {/* <img src={ComingSoon} alt="coming-soon" /> */}
          <button className="btn btn-primary">
            <Link to="/insert-category">Add Products</Link>
          </button>
          <div className="addproductsscreen">
            <div className="product-list">
              <div className="product-cards">
                <img src={office365} alt="office365" />
                <h3>Office 360 Business</h3>
              </div>
              <div className="product-cards">
                <img src={office365} alt="office365" />
                <h3>Office 360 Business</h3>
              </div>
              <div className="product-cards">
                <img src={office365} alt="office365" />
                <h3>Office 360 Business</h3>
              </div>
              <div className="product-cards">
                <img src={office365} alt="office365" />
                <h3>Office 360 Business</h3>
              </div>
              <div className="product-cards">
                <img src={office365} alt="office365" />
                <h3>Office 360 Business</h3>
              </div>
              {/* <div className="product-cards">
                <img src={office365} alt="office365" />
                <h3>Office 360 Business</h3>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProject;
