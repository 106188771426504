import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Vendor/Vendor.css";
import { AiFillProject, AiOutlineBarChart, AiOutlineShoppingCart } from "react-icons/ai";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { MdPostAdd } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { MdLogout } from "react-icons/md";
import { VscOrganization, VscPreview } from "react-icons/vsc";
import { HiOutlineDocumentText } from "react-icons/hi";
import { GiReceiveMoney } from "react-icons/gi";
import { MdDashboardCustomize } from "react-icons/md";
import { FiImage } from "react-icons/fi";
import { BsGear, BsBank } from "react-icons/bs";
import logo from "../../assets/images/logo-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, logout } from "../../Store/Reducers/userSlice.js";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import { AiFillCheckCircle } from "react-icons/ai";
import { LogoutUrl } from "../../Api/api";

const Sidebar = () => {
        const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
        const navigate = useNavigate()
        const dispatch = useDispatch();
        const [status, setStatus] = useState(false);
        const [proposals, setProposals] = useState(false);
        const [myAccount, SetMyAccount] = useState(false);

        const { pathname } = useLocation();

        const handleLogout = (e) => {
                e.preventDefault();
                dispatch(logout(user));
                localStorage.clear();
                window.location = LogoutUrl
        };

        const handleNavigate = (path) => {
                navigate(path)
        }
        useEffect(() => {
                if (pathname === "/add-new-products" ||
                        pathname === "/vendor-product-lists"
                ) {
                        setStatus(true)
                }
        }, [status])

        useEffect(() => {
                if (pathname === "/Vendor-Project-Apply-List" ||
                        pathname === "/final-proposal-list"
                ) {
                        setProposals(true)
                }
        }, [proposals])

        useEffect(() => {
                if (pathname === "/vendor-profile" ||
                        pathname === "/agency-profile" ||
                        pathname === "/services-and-preference"||
                        pathname === "/portfolio" ||
                        pathname === "/documents" ||
                        pathname === "/bank-details" ||
                        pathname === "/customer-reviews" ||
                        pathname === "/vendor-profile/edit" ||
                        pathname === "/agency-profile/edit" ||
                        pathname === "/services-and-preference/edit" ||
                        pathname === "/portfolio/edit" ||
                        pathname === "/documents/edit" ||
                        pathname === "/bank-details/edit"
                ) {
                        SetMyAccount(true)
                }
        }, [myAccount])



        return (
                <>
                        <div className="dashboard-navigation">
                                <div className="dashboardMenu">
                                        <a href={LogoutUrl}>
                                                <span className="menu-title">
                                                        <span className="menu-title">
                                                                <img src={logo} alt="logo" width="{150}" />
                                                        </span>
                                                </span>
                                        </a>

                                        <div onClick={() => handleNavigate("/vendor-project-list")}
                                                style={{ marginTop: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/vendor-project-list" ? "active-side-link" : ""
                                                        }`}>
                                                <AiFillProject style={{ width: "25px", height: "25px" }} />
                                                Projects
                                        </div>

                                        <div onClick={() => handleNavigate("/customer-contact")}
                                                className={`d-flex align-items-center justify-content-start link-side ${pathname === "/customer-contact" ? "active-side-link" : ""
                                                        }`}>
                                                <AiOutlineBarChart style={{ width: "25px", height: "25px" }} />
                                                Opportunities
                                        </div>

                                        <div onClick={() => setStatus(!status)} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/cut" ? "active-side-link" : ""
                                                }`}>
                                                <AiOutlineShoppingCart style={{ width: "25px", height: "25px" }} />
                                                Solutions
                                                <span style={{ marginLeft: "67px" }}>
                                                        <HiOutlineChevronDoubleDown style={{ width: "18px", height: "18px" }} />
                                                </span>
                                        </div>

                                        {status ? <>
                                                <div onClick={() => handleNavigate("/add-new-products")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/add-new-products" ? "active-side-link" : ""
                                                                }`}>
                                                        <AiOutlineAppstoreAdd style={{ width: "25px", height: "25px" }} />
                                                        Add Product/Services
                                                </div>

                                                <div onClick={() => handleNavigate("/vendor-product-lists")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/vendor-product-lists" ? "active-side-link" : ""
                                                                }`}>
                                                        <MdPostAdd style={{ width: "25px", height: "25px" }} />
                                                        My Product
                                                </div>
                                        </> : null
                                        }

                                        <div onClick={() => setProposals(!proposals)} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/cut" ? "active-side-link" : ""
                                                }`}>
                                                <MdPostAdd style={{ width: "25px", height: "25px" }} />
                                                Proposals
                                                <span style={{ marginLeft: "67px" }}>
                                                        <HiOutlineChevronDoubleDown style={{ width: "18px", height: "18px" }} />
                                                </span>
                                        </div>

                                        {proposals ? <>
                                                <div onClick={() => handleNavigate("/Vendor-Project-Apply-List")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/Vendor-Project-Apply-List" ? "active-side-link" : ""
                                                                }`}>
                                                        <MdPostAdd style={{ width: "25px", height: "25px" }} />
                                                        My Proposals
                                                </div>

                                                <div onClick={() => handleNavigate("/final-proposal-list")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/final-proposal-list" ? "active-side-link" : ""
                                                                }`}>
                                                        <AiFillCheckCircle style={{ width: "25px", height: "25px" }} />
                                                        Final Proposals
                                                </div>
                                        </> : null
                                        }

                                        <div onClick={() => SetMyAccount(!myAccount)} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/cut" ? "active-side-link" : ""
                                                }`}>
                                                <CgProfile style={{ width: "25px", height: "25px" }} />
                                                My Account
                                                <span style={{ marginLeft: "50px" }}>
                                                        <HiOutlineChevronDoubleDown style={{ width: "18px", height: "18px" }} />
                                                </span>
                                        </div>

                                        {myAccount ? <>
                                                <div onClick={() => handleNavigate("/vendor-profile")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/vendor-profile" || pathname === "/vendor-profile/edit" ? "active-side-link" : ""
                                                                }`}>
                                                        <MdPostAdd style={{ width: "25px", height: "25px" }} />
                                                        My Profile
                                                </div>

                                                <div onClick={() => handleNavigate("/agency-profile")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/agency-profile" ||  pathname === "/agency-profile/edit" ? "active-side-link" : ""
                                                                }`}>
                                                        <VscOrganization style={{ width: "25px", height: "25px" }} />
                                                        Agency Profile
                                                </div>

                                                <div onClick={() => handleNavigate("/services-and-preference")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/services-and-preference" ||  pathname === "/services-and-preference/edit" ? "active-side-link" : ""
                                                                }`}>
                                                        <BsGear style={{ width: "25px", height: "25px" }} />
                                                        Services & Preferences
                                                </div>

                                                <div onClick={() => handleNavigate("/portfolio")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/portfolio" || pathname === "/portfolio/edit" ? "active-side-link" : ""
                                                                }`}>
                                                        <FiImage style={{ width: "25px", height: "25px" }} />
                                                        Portfolio
                                                </div>

                                                <div onClick={() => handleNavigate("/documents")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/documents" || pathname === "/documents/edit" ? "active-side-link" : ""
                                                                }`}>
                                                        <HiOutlineDocumentText style={{ width: "25px", height: "25px" }} />
                                                        Company Documents
                                                </div>

                                                <div onClick={() => handleNavigate("/bank-details")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/bank-details" || pathname === "/bank-details/edit" ? "active-side-link" : ""
                                                                }`}>
                                                        <BsBank style={{ width: "25px", height: "25px" }} />
                                                        Bank Details
                                                </div>

                                                <div onClick={() => handleNavigate("/customer-reviews")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/customer-reviews" ? "active-side-link" : ""
                                                                }`}>
                                                        <VscPreview style={{ width: "25px", height: "25px" }} />
                                                        Reviews
                                                </div>
                                        </> : null
                                        }

                                        <div onClick={(e) => handleLogout(e)}
                                                className={`d-flex align-items-center justify-content-start link-side ${pathname === "/ven" ? "active-side-link" : ""
                                                        }`}>
                                                <MdLogout style={{ width: "25px", height: "25px" }} />
                                                Logout
                                        </div>


                                </div>
                        </div>
                </>
        );
};

export default Sidebar;
