import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../../../Api/api";
import { useSelector } from "react-redux";

export default function   Uploadinvoice(props) {
  console.log(props.image, "proposal image");
  console.log("Proposal ID", props);
  const proposal_id = useParams().proposal_id;
  const milestone_id = useParams().milestone_id;

  const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
  const initialFormData = {
    milestone_id: props.item,
    proposal_id: props.items,
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [File, setFile] = useState(null);
  const userToken = localStorage.getItem("token");

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    console.log(setFile.name);
    toast("Invoice Uploaded successfully. Kindly click on Submit Button");
    //var ImageData = JSON.stringify(e.target.files[0]);
    //console.log(ImageData, "Category Image");
    // localStorage.setItem("CategoryImage", ImageData);
  };

  console.log(initialFormData);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = validate(formValues);
    setFormErrors(validate(formValues));

    if (File == null) {
      const error = "File Is Not Uploaded";
      toast.error(error, { theme: "colored" });
    }

    console.log("No Validation Error Found");
    // ... submit to API or something
    setIsSubmit(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);

      var formdata = new FormData();
      formdata.append("image", File);
      formdata.append("milestone_id", formValues.milestone_id);

      for (var pair of formdata.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendordashboard/Add-vendor-Invoice`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          if (result.status === 200) {
            setFormValues("");
            //localStorage.setItem("Project", JSON.stringify(result.data));
            //alert(result.message);
            toast("Invoice Uploaded successfully");
            // window.location = `/ViewVendorMilestone/${props.item}/${props.items}`;
            window.location = `/ViewVendorMilestone/${proposal_id}`;
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("formValues", formValues);
    }
  }, [formErrors]);

  const uploadImg = (events) => {
    console.log(events.target.files);
  };

  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    console.log(errors);
    return errors;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="milestonebtns">
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <div className="upload-btn-wrapper">
          <input
            type="file"
            //value={formErrors.image}
            onChange={handleFileInput}
          />
          <div>
            <button className="mile-btn">Upload Invoice</button>
          </div>
        </div>
        <p>{formErrors.image}</p>
        <div>
          {/* <Link to={`/Milestone/${item.proposal_id}/${item.milestone_id}`}> */}
          <button className="invoice-submit btn">Submit</button>
        </div>
      </form>
    </div>
  );
}
