import React from "react";
import "./Register.css";
import customer from "../../assets/images/customer.svg";
import vendor from "../../assets/images/vendor.svg";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <div className="register-toggle">
      <div className="register-window">
        <div className="registerhead">
          <h1>Join as a customer or Partner</h1>
        </div>
        <div className="register-toggle-form">
          <Link to="/customer-registration">
            <div className="register-field">
              <img src={customer} alt="customer" className="customer" />
              <h4>I am a Customer</h4>
            </div>
          </Link>
          <Link to="/vendor-registration">
            <div className="register-field">
              <img src={vendor} alt="customer" className="vendor" />
              <h4>I am a Partner</h4>
            </div>
          </Link>
          {/*button className="tog-reg-btn">Create Account</button>*/}
        </div>
        <div className="login-account">
          <p>
            Already have an account ? <Link to="/login">Log In</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
