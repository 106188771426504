import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../Vendor/Vendor.css";
import "../../components/Header/Header.css";
import { BsBank } from "react-icons/bs";
import {
  AiFillProject,
  AiOutlineShoppingCart,
  AiOutlineProject,
} from "react-icons/ai";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, logout } from "../../Store/Reducers/userSlice.js";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { MdPostAdd, MdOutlineDoubleArrow, MdDrafts } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { MdLogout } from "react-icons/md";
import { VscOrganization, VscPreview } from "react-icons/vsc";
import { HiOutlineDocumentText } from "react-icons/hi";
import { GiReceiveMoney, GiWallet } from "react-icons/gi";
import { MdDashboardCustomize } from "react-icons/md";
import { FiImage } from "react-icons/fi";
import { BsGear } from "react-icons/bs";
import logo from "../../assets/images/logo-white.svg";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdOutlineNotifications } from "react-icons/md";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
// import ProgressBar from "react-bootstrap/ProgressBar";
import profile from "../../assets/images/profile.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import curbal from "../../assets/images/curbal.png";

const Topbar = (props) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [Collapse, SetCollapse] = useState(false);
  const [myAccount, SetMyAccount] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const userDetails = JSON.parse(localStorage.getItem("userInfo"));
  // console.log("profile_img_url", userDetails[0].profile_img_url);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout(user));
    localStorage.clear();
    window.location = "/";
  };

  const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
  //console.log(user.user_info.company_name);

  const progressBarValue = useSelector(
    // (state) => state.vendorProfile.overAllStatusBarValue
    (state) => state.profile.overAllStatusBarValue
  );
  console.log(progressBarValue, "Value");

  return (
    <div className="topbar">
      <div className="toggle">
        <div className="navbar-menu">
          {toggleMenu ? (
            <RiCloseLine
              color="#000"
              size={27}
              onClick={() => setToggleMenu(false)}
            />
          ) : (
            <RiMenu3Line
              color="#000"
              size={27}
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <div className="mobile-menu-bar">
              <div className="dashboardMenu">
                <a href="/">
                  <span className="menu-title">
                    <span className="menu-title">
                      <img src={logo} alt="logo" width="{150}" />
                    </span>
                  </span>
                </a>
              </div>
              <ul className="MenuBarDashboard">
                {/* <li>
                    <a href="/">
                      <span className="menu-title">
                        <span className="menu-title">
                          <img
                            src={logo}
                            alt="logo"
                            width="{60}"
                            className="logo-mob"
                          />
                        </span>
                      </span>
                    </a>
                  </li> */}
                <li>
                  <a href="/customer-dashboard">
                    <span className="menu-icon">
                      <MdDashboardCustomize className="menu-icons" />
                    </span>
                    <span className="menu-title">Customer Dashboard</span>
                  </a>
                </li>
                {/* <li>
                  <a href="/vendor-project-list">
                    <span className="menu-icon">
                      <AiFillProject />
                    </span>
                    <span className="menu-title">Project</span>
                  </a>
                </li> */}

                <li onClick={() => setStatus(!status)} className="dropdownmenu">
                  <span className="menu-icon">
                    <AiOutlineShoppingCart className="menu-icons" />
                  </span>
                  <span className="menu-titles">My Project</span>
                  <span className="menu-icon-sidebar">
                    <HiOutlineChevronDoubleDown className="menu-icons-down" />
                  </span>
                </li>

                {status ? (
                  <>
                    <li className="sub-menu-list">
                      <a href="/add-projects">
                        <span className="menu-icon">
                          <AiOutlineAppstoreAdd className="submenuicons" />
                        </span>
                        <span className="menu-title">Create Project</span>
                      </a>
                    </li>
                    <li className="sub-menu-list">
                      <a href="/projectlist">
                        <span className="menu-icon">
                          <AiOutlineProject className="submenuicons" />
                        </span>
                        <span className="menu-title">View Projects</span>
                      </a>
                    </li>
                    <li className="sub-menu-list">
                      <a href="/draft-table">
                        <span className="menu-icon">
                          <MdDrafts className="submenuicons" />
                        </span>
                        <span className="menu-title">Draft Project</span>
                      </a>
                    </li>

                                                        
                    {/* <li className="sub-menu-list">
                      <a href="/final-project-list">
                        <span className="menu-icon">
                          <AiFillCheckCircle  className="submenuicons" />
                        </span>
                        <span className="menu-title">My Final Project</span>
                      </a>
                    </li> */}
                  </>
                ) : null}

                <li
                  onClick={() => SetMyAccount(!myAccount)}
                  className="dropdownmenu"
                >
                  <span className="menu-icon">
                    <CgProfile className="menu-icons" />
                  </span>
                  <span className="menu-titles">My Account</span>
                  <span className="menu-icon-sidebar">
                    <HiOutlineChevronDoubleDown className="menu-icons-down" />
                  </span>
                </li>

                {myAccount ? (
                  <>
                    <li className="sub-menu-list">
                      <a href="/profile">
                        <span className="menu-icon">
                          <MdPostAdd className="submenuicons" />
                        </span>
                        <span className="menu-title"> My Profile</span>
                      </a>
                    </li>
                    <li className="sub-menu-list">
                      <a href="/company-profile">
                        <span className="menu-icon">
                          <VscOrganization className="submenuicons" />
                        </span>
                        <span className="menu-title">Company Profile</span>
                      </a>
                    </li>
                    <li className="sub-menu-list">
                      <a href="/customer-documents">
                        <span className="menu-icon">
                          <HiOutlineDocumentText className="submenuicons" />
                        </span>
                        <span className="menu-title">Company Documents</span>
                      </a>
                    </li>
                    <li className="sub-menu-list">
                      <a href="/customer-reviews-list">
                        <span className="menu-icon">
                          <VscPreview className="submenuicons" />
                        </span>
                        <span className="menu-title">Reviews</span>
                      </a>
                    </li>
                    <li className="sub-menu-list">
                      <a href="/customer-product-reviews">
                        <span className="menu-icon">
                          <VscPreview className="submenuicons" />
                        </span>
                        <span className="menu-title">Product Reviews</span>
                      </a>
                    </li>
                    <li className="sub-menu-list">
                      <a href="/customer-bank-details">
                        <span className="menu-icon">
                          <BsBank className="submenuicons" />
                        </span>
                        <span className="menu-title">Bank Details</span>
                      </a>
                    </li>
                    <li className="sub-menu-list">
                      <a href="/customer-transaction-list">
                        <span className="menu-icon">
                          <GiReceiveMoney className="submenuicons" />
                        </span>
                        <span className="menu-title">Transaction List</span>
                      </a>
                    </li>
                  </>
                ) : null}
                {/* <li>
                  <a href="/projectlist">
                    <span className="menu-icon">
                      <AiFillProject className="menu-icons" />
                    </span>
                    <span className="menu-title">My Project</span>
                  </a>
                </li> */}
                {/* <li>
                  <a href="/add-projects">
                    <span className="menu-icon">
                      <AiOutlineAppstoreAdd />
                    </span>
                    <span className="menu-title">Add Project</span>
                  </a>
                </li> */}
                {/* <li>
                  <a href="/profile">
                    <span className="menu-icon">
                      <CgProfile />
                    </span>
                    <span className="menu-title">Profile</span>
                  </a>
                </li> */}
                <li>
                  <a href="#">
                    <span className="menu-icon">
                      <MdLogout />
                    </span>
                    <span
                      className="menu-title"
                      onClick={(e) => handleLogout(e)}
                    >
                      Logout
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          )}
        </div>
        <div className="topbarwelcome">
          <span>Customer Dashboard</span>
          {props.isShow && (
            <div className="topprogressbar">
              <progress
                className="progress-bar"
                value={props.value}
                max="100"
                title={props.value}
              >
                {/* {props.isShow=true} */}
              </progress>
              <p>{Math.round(props.value) + "%"}</p>
            </div>
          )}
        </div>
      </div>
      <div className="welcomeprofile">
        {/* <label>Profile Progress</label> */}

        <div className="sideicons">
          {/* <MdOutlineNotifications />
          <MdOutlineMarkEmailUnread />
          <div className="availablebalance">
            <img src={curbal} alt="currentbalance" />
            <span>
              <strong>{userinfo.available_point}</strong> Coins
            </span>
          </div> */}
          <div className="mainprofileicon">
            <Link to="/profile">
              <img
                src={userInfo.user_info.profile_img_url}
                alt=""
                className=""
              />
              <div className="vprofileDetails">
                <span>
                  {userInfo.user_info.company_name || userDetails.company_name}
                </span>
                <p className="partnerposition">Customer</p>
              </div>
              {/* <RiArrowDropDownLine /> */}
            </Link>
          </div>
        </div>
      </div>
      {/* <Link to="/" className="backtohome">
        <MdOutlineDoubleArrow />
        Back to Home Page
      </Link> */}
      {/* <div>
        <FaUserCircle />
      </div> */}
    </div>
  );
};

export default Topbar;
