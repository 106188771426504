import React, { useEffect, useState } from "react";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import { useForm } from "react-hook-form";
import { BaseUrl } from "../../Api/api.js";
import { toast } from "react-toastify";
import achieve from "../../assets/images/achievement.png";

const CustomerAcceptReject = (props) => {
  const [status, setStatus] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmitCheck = (e, action) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!isChecked) {
      console.log("Checkbox is not checked");
    } else {
      // console.log("Checkbox is checked");
      if (action === "button1") {
        setStatus(1);
      } else if (action === "button2") {
        setStatus(2);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const onSubmit = (data) => {
    // console.log(data, "data from on submit");
    const userToken = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      milestone_id: props.data.id,
      customer_remark: data.customer_remark,
      status: status,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}/announcement/Customer-Approve-Decline-Milestone`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        toast("Your Feedback has been sent successfully");
        if (status === 2) {
          window.location.reload(true);
        }
        setShowPopup(true);
      })
      .catch((error) => console.log("error", error));
  };

  // const onClickApprove = () => {
  //   setStatus(1);
  // };
  const onClickReject = () => {
    setStatus(2);
  };

  const onClickDone = () => {
    window.location.reload();
  };
  return (
    <>
      {props.isShow && (
        <div className="project-details-popup" style={{position:"fixed"}}>
          <div className="milestone-review-popup">
            <div className="ms-popup-main">
              <div className="milestone-popup-btn-div">
                <button
                  className="milestone-popup-close"
                  onClick={props.onClickClose}
                >
                  X
                </button>
              </div>

              <div className="milestone-popup-title">
                Milestone {props.data.count}
              </div>
              {status === 0 && (
                <div>
                  <form>
                    <p className="ms-vendor">Vendor Comment </p>
                    <div className="ms-vendor-remark">
                      {parse(props.data.vendor_remark)}
                    </div>
                    <div className="ms-cs-checkbox">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <label className="cs-ms-label">
                        I have checked the milestone details and approving it
                      </label>
                    </div>
                    {isSubmitted && !isChecked && (
                      <div style={{ color: "red" }}>Please check the box</div>
                    )}
                    <div className="ms-btn-div">
                      <button
                        className="ms-btn-approve"
                        // type="submit"
                        // onClick={onClickApprove}
                        onClick={(event) => onSubmitCheck(event, "button1")}
                      >
                        Approve
                      </button>
                      <button
                        className="ms-btn-reject"
                        // type="submit"
                        onClick={onClickReject}
                        // onClick={(event) => onSubmitCheck(event, "button2")}
                      >
                        Reject
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {status != 0 && (
                <div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <p className="ms-vendor">Feedback </p>
                    <textarea
                      className="ms-customer-feedback"
                      {...register("customer_remark", {
                        required: true,
                      })}
                    ></textarea>
                    {errors.customer_remark && (
                      <span className="error-msg">
                        Please enter the feed back
                      </span>
                    )}
                    <button className="ms-send-btn">Send</button>
                  </form>
                </div>
              )}
            </div>
          </div>{" "}
        </div>
      )}

      {showPopup && status == 1 && (
        <div className="project-details-popup" style={{position:"fixed"}}>
          <div className="milestone-review-popup">
            <div className="ms-popup-main">
              <div className="milestone-popup-btn-div">
                <button
                  className="milestone-popup-close"
                  onClick={props.onClickClose}
                >
                  X
                </button>
              </div>
              <div className="ms-success-popup">
                <div className="achieve-img-div">
                <img className="achieve" src={achieve} alt="" />
                </div>
                <br />
               
                <h3 className="ms-success-text">
                  Milestone {props.data.count} is completed successfully
                </h3>
                <div className="ms-done-btn">
                <button style={{marginBottom:"20px" ,marginTop:"20px"}} className="ms-btn-approve" onClick={onClickDone}>
                  Done
                </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomerAcceptReject;
