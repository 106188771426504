import React from "react";
import Faq from "react-faq-component";
import conqt_home_phone from "../../assets/conqt_guidelines/conqt_home_phone.png";
import conqt_home from "../../assets/conqt_guidelines/conqt_home.png";
import conqt_home_register from "../../assets/conqt_guidelines/conqt_home_register.png";
import home_page from "../../assets/conqt_guidelines/home_page.png";
import iamvendor from "../../assets/conqt_guidelines/iamvendor.png";
import iamvendorpc from "../../assets/conqt_guidelines/iamvendorpc.png";
import registerform from "../../assets/conqt_guidelines/registerform.png";
import registerpc from "../../assets/conqt_guidelines/registerpc.png";
import login from "../../assets/conqt_guidelines/login.png";
import loginpc from "../../assets/conqt_guidelines/loginpc.png";
import dashboard from "../../assets/conqt_guidelines/dashboard.png";
import dashboardpc from "../../assets/conqt_guidelines/dashboardpc.png";
import customeregm from "../../assets/conqt_guidelines/customer-m-1.png";
import customeregd from "../../assets/conqt_guidelines/customer-1.png";
import customeregm1 from "../../assets/conqt_guidelines/customer-m-2.png";
import customeregd1 from "../../assets/conqt_guidelines/customer-2.png";
import customeregm2 from "../../assets/conqt_guidelines/customer-m-3.png";
import customeregd2 from "../../assets/conqt_guidelines/customer-3.png";
import customeregm3 from "../../assets/conqt_guidelines/customer-m-4.png";
import customeregd3 from "../../assets/conqt_guidelines/customer-4.png";
import "./steps.css";

const data = {
  title: "How to become a Customer ?",
  rows: [
    // {
    //   title: (
    //     <div className="step-heading">
    //       <h4>Step 1: Home Page</h4>
    //     </div>
    //   ),
    //   content: (
    //     <div className="steps">
    //       <span>
    //         <h4>On Mobile Screen</h4>
    //         <img src={home_page} alt="On Phone" />
    //       </span>

    //       <span className="conqtimage">
    //         <h4>On Computer Screen</h4>
    //         <img src={conqt_home} alt="" />
    //       </span>

    //       <div className="steps-para">
    //         <p>Visit to 'www.conqt.com' and click on the register button. </p>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: (
        <div className="step-heading">
          <h4>Step 1: Registration</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={customeregm} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={customeregd} alt="" />
          </span>

          <div className="steps-para">
            <p> 1. Visit <a href="https://login.conqt.com/customer-registration">https://login.conqt.com/customer-registration</a></p>
            {/* <p> 2. If already Register, click on Login</p>
            <p> 3. Once you click on the register button, you will redirect to a OTP Ve page. </p> */}
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 2: I am a Customer</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={customeregm1} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={customeregd1} alt="" />
          </span>

          <div className="steps-para">
            <p> Fill out the required information on the registration page, including your first and last name, a password, your company name, your position, your phone number, your company address, city, country, and zip code. Make sure to check both boxes before clicking "submit."</p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 3: OTP Verification</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <div className="formimage">
              <img src={customeregm2} alt="On Phone" />
            </div>
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={customeregd2} alt="On Phone" />
          </span>

          <div className="steps-para">
            <p>An OTP will be sent to the email address you provided. Enter the OTP and click "submit."</p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 4: Customer Dashboard  </h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={customeregm3} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={customeregd3} alt="" />
          </span>

          <div className="steps-para">
            <p>Your account is now set up, and you can access ConQT's project posting, quotation analysis, and freemium features.</p>
          </div>
        </div>
      ),
    },
    
  ],
};

export default function faq() {
  return (
    <div>
      <Faq data={data} />
    </div>
  );
}
