import React, { useState, useEffect } from "react";
import "./page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { MdModeEdit } from "react-icons/md";
import { BsCash } from "react-icons/bs";
import { BsPlusSquareFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import bar40 from "./images/bar40.png";
import Popup from "reactjs-popup";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinFill } from "react-icons/ri";
import { ImLink } from "react-icons/im";
import { CircularProgressbar } from "react-circular-progressbar";
import { BaseUrl } from "../../../Api/api";
import { useNavigate } from "react-router-dom";
import noreviews from "../../../assets/images/noreview.png";
import PageLoader from "../../../components/PageLoader/PageLoader";

const MyProfile = () => {
  const [data, setData] = useState([]);
  const [agencydata, setAgencyData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const userToken = localStorage.getItem("token");
  const [showBar, setShowBar] = useState("0");
  const [userData, setUserData] = useState({});
  let navigate = useNavigate();
  useEffect(() => {
    setPageLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendor/Get-Vendor-Portfolio-Profile`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          if (result.data.length) {
            setPageLoading(false);
            setData(result.data);
          } else {
            setPageLoading(false);
            navigate("/portfolio/");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleNext = (e) => {
    window.location = "/subscription";
  };

  const handleSkip = (e) => {
    window.location = "/subscription";
  };

  return (
    <>
      {pageLoading && (
        <PageLoader />
      )}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar
            isShow={true}
            value={data.length > 0 && data[0].profile_complete_status}
          />
          <main className="maincontent">
            <div>
              <section>
                <div className="basic-info">
                  <h3>Portfolio</h3>
                  {data.length > 0
                    ? data.map((item) => {
                      return (
                        <Link to="/portfolio/edit">
                          <h4>
                            Edit
                            <MdModeEdit />
                          </h4>
                        </Link>
                      );
                    })
                    : ""}
                </div>
              </section>
              <br />
              {data.length > 0 ? (
                data.map((item) => {
                  return (
                    <div className="profileform">
                      <section className="division">
                        <div className="head-para">
                          <h3>{item.companyName}</h3>
                          <p>{item.companyDes}</p>
                        </div>
                        {/*<div className="progressbar">
                       <div className="bar-profile">
                        <h4>Completion Progress</h4>
                        {/* <img src={barr} alt="" /> 
                        <div className="circle-container">
                          <CircularProgressbar
                            value={showBar}
                            text={showBar + "%"}
                          />
                          <p>Please Complete Your Profile</p>
                        </div>
                      </div> 
                    </div>*/}
                      </section>

                      <div className="profile-info svg"></div>

                      <section>
                        <div className="social">
                          <span>
                            <p>Project Price</p>
                            <p className="profields">
                              {item.project_currency} {item.project_price}
                              <BsCash />
                            </p>
                          </span>
                          <span>
                            <p>Project Duration</p>
                            <p className="profields">{item.project_duration}</p>
                          </span>
                        </div>
                      </section>

                      <section>
                        <div className="weblink">
                          <p>Project Website link</p>
                          <div className="projectlink">
                            <ImLink />
                            <span>{item.project_website}</span>
                          </div>
                        </div>
                        <div className="weblink">
                          <p>Social link</p>

                          <div className="projectlink">
                            <ImLink />
                            <span>{item.social_link}</span>
                          </div>
                        </div>
                      </section>
                      <br />
                      <h4>Portfolio Images</h4>

                      <div className="galleryList">
                        {item.portfolioImgList.map((key, count) => {
                          return (
                            <img
                              src={key.img_url}
                              alt={key.img_name}
                            // onClick={() => handleImageClick(item, index)}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="noreviews">
                  <div className="noreviewcont">
                    <h3>No Portfolio Found</h3>

                    <Link to="/portfolio/edit">
                      <button>Add Portfolio</button>
                    </Link>
                  </div>

                  <img src={noreviews} alt="noreview" />
                </div>
              )}
            </div>

            <div className="formbuton">
              {/* <Link to={`/AgencyProfile`}> */}
              <input
                type="submit"
                className="save-button"
                value="Skip"
                onClick={handleSkip}
              />
              <input
                type="submit"
                className="save-button"
                value="Next"
                onClick={handleNext}
              />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default MyProfile;
