import { BaseUrl ,RazorPaymentkey } from "../../Api/api.js";
import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import logo from "../../assets/images/logo.svg";
import "./Customer.css";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Invoice = () => {
  const initialFormData = {
    showAmount: "",
    amount: 0.00,
    currency: "",
    invoice_no : "",
    transactions_id:"",
    amount:"",
    transaction_status: "COMPLETED",
    milestone_id:"",
  };

  const invoice =  Math.random().toString(21).substring(2, 9).toUpperCase();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  const id = useParams().proposal_id;
  const Milestone_No = useParams().index;
  today = dd + "/" + mm + "/" + yyyy;
  const userToken = localStorage.getItem("token");
  const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(initialFormData);
   formValues.milestone_id = useParams().milestone_id

 

 
  console.log();

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Customer-Final-Project-List`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(result.data.filter((item) => item.proposal_id == id));
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  console.log(data);


  const loadScript = (src) => {
    return new Promise((resovle) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resovle(true);
      };

      script.onerror = () => {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
     
   console.log(formValues.showAmount)
   console.log(formValues.amount)
   console.log(formValues.currency)
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }


    console.log("this is amount", formValues.amount);

    const options = {
      key: RazorPaymentkey,
      currency: formValues.currency,
      milestone_id: formValues.milestone_id,
      receipt: "receipt_order_74394",
      amount:formValues.amount*100,
      name: "Milestone Payment",
      description: "Thanks for purchasing",
      image:
        "https://mern-blog-akky.herokuapp.com/static/media/logo.8c649bfa.png",

      handler: function (response) {
        console.log(response);

        let responseData = {
          invoice_no : invoice,
          milestone_id:formValues.milestone_id,
          transactions_id: response.razorpay_payment_id,
          currency: options.currency,
          amount: options.amount / 100,
          transaction_status: "COMPLETED",
        };
        console.log(responseData);

        //POST API For Transactions

        try {
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
          );
          myHeaders.append("Content-Type", "application/json");
          
          var raw = JSON.stringify(responseData);
          
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          
          fetch(`${BaseUrl}/announcement/Milestone-Payment-Update`, requestOptions)
            .then(response => response.text())
            .then((result) => {
              result = JSON.parse(result);
              console.log(result);
            })
            .catch((error) => console.log("error", error));
              }
         catch{
          
        }

        toast(response.razorpay_payment_id);
        toast("Payment Successfully");
        window.location=`/receipt`
      },
      prefill: {
        name: "Enter Your Name",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />

          <div className="cs-container">
            <div className="cs-invoice cs-style1">
              <div className="cs-invoice_in" id="download_section">
                <div className="cs-invoice_head cs-type1 cs-mb25">
                  <div className="cs-invoice_left">
                    <div className="cs-logo cs-mb5">
                      <img src={logo} alt="Logo" style={{ width: 150 }} />
                    </div>
                    <p className="cs-mb0">
                      67 T1 01 SARAFIPURA TIWARIPURA <br />
                      Mahoba, 210427 - India
                    </p>
                    <p className="cs-mb0">Phone: (+91) 941-565-0637</p>
                    <p className="cs-mb0">LUT NO: AD090422023238A</p>
                    <p className="cs-mb0">IEC CODE: AAECF8340F</p>
                  </div>
                  <div className="cs-invoice_right cs-text_right">
                    <h2>INVOICE</h2>
                    <p className="cs-invoice_number cs-primary_color cs-mb5 cs-f16">
                      <b className="cs-primary_color">Date:</b> {today}
                    </p>
                    <p className="cs-invoice_date cs-primary_color cs-m0">
                      <b className="cs-primary_color">Invoice: </b>
                     {invoice}
                    </p>
                  </div>
                </div>
                <div className="billto">Bill To -</div>
                <ul className="cs-grid_row cs-col_3 cs-mb10">
                  <li className="cs-mb20">
                    <b className="cs-primary_color">
                      {user.user_info.name.toUpperCase()}
                    </b>
                    <p className="cs-mb0">
                      {user.user_info.name.toUpperCase()}
                    </p>
                    <p className="cs-mb0">{user.user_info.phone_number}</p>
                    <p className="cs-mb0">{user.user_info.user_name}</p>
                  </li>
                </ul>
              
                {data.map((item) => {
                  {
                    return (
                      <div className="cs-table cs-style2">
                        {item.milestonelist
                          .filter((key, index) => index == Milestone_No)
                          .map((key, index) => {
                            
                            formValues.showAmount = user.user_info.currency_code + " " + (Number(key.user_milestone_amount))
                            formValues.amount = (Number(key.user_milestone_amount));
                              formValues.currency = user.user_info.currency_code
                              
                            return (
                              <div className="cs-round_border">
                                <div className="cs-table_responsive">
                                  <table>
                                    <thead>
                                      <tr className="cs-focus_bg">
                                        <th className="cs-width_6 cs-semi_bold cs-primary_color"></th>
                                        <th className="cs-width_2 cs-semi_bold cs-primary_color">
                                          Qty
                                        </th>
                                        <th className="cs-width_2 cs-semi_bold cs-primary_color">
                                          Rate
                                        </th>
                                        <th className="cs-width_2 cs-semi_bold cs-primary_color">
                                          Amount
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td className="cs-width_6">
                                          {item.project_name}- Milestone{" "}
                                          {Number(Milestone_No) + 1}
                                        </td>
                                        <td className="cs-width_3 text-center">
                                          01{" "}
                                        </td>
                                        <td className="cs-width_3 text-center">
                                          {user.user_info.symbol}{" "}
                                          {key.user_milestone_amount}
                                        </td>
                                        <td className="cs-width_3 text-center">
                                          {user.user_info.symbol}{" "}
                                          {key.user_milestone_amount}
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="cs-width_6">
                                          Sub Total
                                        </td>
                                        <td className="cs-width_3 text-center">
                                          {" "}
                                        </td>
                                        <td className="cs-width_3 text-center"></td>
                                        <td className="cs-width_3 text-center">
                                          {user.user_info.symbol}{" "}
                                          {key.user_milestone_amount}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="cs-width_6">Discount Applied</td>
                                        <td className="cs-width_3 text-center">
                                          {" "}
                                        </td>
                                        <td className="cs-width_3 text-center"></td>
                                        <td className="cs-width_3 text-center">
                                         
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="cs-width_6">Total</td>
                                        <td className="cs-width_3 text-center"></td>
                                        <td className="cs-width_3 text-center"></td>
                                        <td className="cs-width_3 text-center">
                                          {user.user_info.symbol}{" "}
                                          {Number(key.user_milestone_amount)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  }
                })}
                <br />
                <p className="text">
                  Make all checks payable to Fyoocher Private Limited. If you
                  have any questions concerning this invoice, contact Anjali T
                  at <b>anjali@fyoocher.co</b> Thank you for your business!
                </p>
              </div>
            </div>

           
          </div>

          <div className="invoicepayment">
            
              <button id="download_btn" className="cs-invoice_btn cs-color2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ionicon"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                    d="M176 272l80 80 80-80M256 48v288"
                  />
                </svg>
                <span className="Paybtn" onClick={() => displayRazorpay()}>Pay Now</span>
              </button>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;