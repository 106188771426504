import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import GetProject from "./GetProject";
import {Link} from "react-router-dom";
import submit_proposal2 from "../../assets/conqt_guidelines/submit_proposal2.jpg"
import getproject2 from "../../assets/conqt_guidelines/getproject2.jpg"
import team2 from "../../assets/conqt_guidelines/team2.jpg"

const ConqtUniversity = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <section className="">
        <div className="contact-heading conuni">
          <h1>Contact University</h1>
          <h2>ConQt - First Businesse Matching Marketplace</h2>
        </div>
        <div className="universitycontent">
          <GetProject />
        </div>
        <div>
            <div className="ourrecentpost">
                <h2>Other Related Topics</h2>
            </div>
        <div className="universityblog">
        <Link to="/how-to-become-a-partner"><div className="uniblog">
            <div class="box-content recentPost">
             <img src={team2} alt="blogimg" />
              <div className="blogcontent">
                <h1 id="box-head" contenteditable="true">How to become a partner ?</h1>
              </div>
            </div>
          </div>
          </Link>
          <Link to="/how-to-submit-a-proposal"><div className="uniblog">
          <div class="box-content recentPost">
             <img src={submit_proposal2} alt="blogimg" />
              <div className="blogcontent">
              <h1 id="box-head" contenteditable="true">How to Submit a Proposal?</h1>
              </div>
            </div>
          </div></Link>
         
        </div>
        </div>
      </section>
    </Layout>
  );
};

export default ConqtUniversity;
