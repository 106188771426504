import React, { useState, useEffect } from "react";
import "./view.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseUrl } from "../../Api/api.js";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Popup from "reactjs-popup";
import PageLoader from "../../components/PageLoader/PageLoader";

const FinalProposal = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const userToken = localStorage.getItem("token");
  console.log(data);
  console.log(BaseUrl);
  useEffect(() => {
    setPageLoading(true)
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendordashboard/Vendor-Final-Project-List`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          console.log("result", result.data);
          setData(result.data);
          setPageLoading(false);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
     {pageLoading && (
       <PageLoader/>
      )}

    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />
        <br />

        <div class="container">
          <table class="rwd-table">
            {data.length == 0 ? (
              <>
                <tr className="FPPproposaldata tabletopback">
                  <td data-th="Supplier Code">Proposal is not accepted yet.</td>
                </tr>

                <tr className="FPPproposaldata">
                  <td data-th="Supplier Name">
                    <Link to="/vendor-project-list">
                      Click here to apply more proposals
                    </Link>
                  </td>
                </tr>
              </>
            ) : (
              data.map((item, proposal_id) => {
                return (
                  <tbody>
                    <tr className="tabletopback">
                      <th>Date</th>
                      <th>Project Name</th>
                      <th>Price</th>
                      <th>Timeline</th>
                      <th>Project Status</th>
                      <th>View</th>
                    </tr>
                    <tr>
                      <td data-th="Supplier Code">{item.created_at}</td>
                      <td data-th="Supplier Name">{item.project_name}</td>
                      <td data-th="Invoice Number">
                        {item.price.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                        &nbsp;
                        {item.currency}
                      </td>
                      <td data-th="Invoice Date">
                        {item.timeline} {item.timeline_unit}
                      </td>
                      <td data-th="Project Status">
                        <span>
                          {item.proposal_status == 2 ? (
                            <p className="selecttag">Rejected</p>
                          ) : item.proposal_status == 1 ? (
                            <p className="selectaward">Project Awarded</p>
                          ) : (
                            <p className="selecttag">In Review</p>
                          )}
                        </span>
                      </td>
                      {/* <td data-th="Invoice Date">
                      <Popup
                        trigger={
                          <button
                            className="viewmore btn"
                            // style={{
                            //   border: "2px solid #304ffe",
                            //   color: "#304ffe",
                            //   backgroundColor: "transparent",
                            // }}
                          >
                            View
                          </button>
                        }
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="popup">
                            <div className="popup-content">
                              <button className="close-btn" onClick={close}>
                                &times;
                              </button>
                              {/* <div className="popup-header"> Proposal </div>
                                <div className="popup-body"> 
                              <table className="rwd-table">
                                <tbody>
                                  <tr style={{ color: "#2d3650" }}>
                                    <td>Project Name</td>
                                    <td>{parse(item.project_name)}</td>
                                  </tr>

                                  <tr>
                                    <td>Project Resources</td>
                                    <td>{parse(item.proposal_resources)}</td>
                                  </tr>
                                  <tr>
                                    <td>Proposal Description</td>
                                    <td>{parse(item.perposal_description)}</td>
                                  </tr>
                                  <tr>
                                    <td>Price</td>
                                    <td>
                                      {item.price} {item.currency}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Timeline</td>
                                    <td>
                                      {item.timeline} {item.timeline_unit}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Project Support</td>
                                    <td>{parse(item.proposal_support)}</td>
                                  </tr>
                                  <tr>
                                    <td>Proposal Out of Scope Details</td>
                                    <td>{parse(item.out_of_scope)}</td>
                                  </tr>
                                  <tr>
                                    <td>Proposal Terms & Conditions</td>
                                    <td>
                                      {parse(item.proposal_termsconditions)}
                                    </td>
                                  </tr>
                                  {/* <tr>
                                      <td>Proposal Status</td>
                                      <td>
                                        Congratulations, Your Proposal is
                                        finalized for the Project -{" "}
                                        {parse(item.project_name)}
                                      </td>
                                    </tr> 

                                  <tr>
                                    <td className="b">S.No.</td>
                                    <td>
                                      <td
                                        className="b"
                                        style={{ width: "25%" }}
                                      >
                                        Milestone Description{" "}
                                      </td>
                                      <td
                                        className="b"
                                        style={{ width: "42%" }}
                                      >
                                        Milestone Percent
                                      </td>
                                      <td
                                        className="b"
                                        style={{ width: "45%" }}
                                      >
                                        Milestone Amount
                                      </td>
                                      <td className="b">Milestone Timeline</td>
                                      <td></td>
                                    </td>
                                  </tr>
                                  {item.milestonelist.map((key, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          <td style={{ width: "30%" }}>
                                            {key.milestone_description}{" "}
                                          </td>
                                          <td style={{ width: "25%" }}>
                                            {key.milestone_percent}%
                                          </td>
                                          <td style={{ width: "28%" }}>
                                            {item.currency}{" "}
                                            {key.milestone_amount}
                                          </td>
                                          <td>{key.milstone_timeline}</td>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                              {/* </div> 
                              <div className="popup-footer">
                                <button
                                  className="btn-signup"
                                  onClick={() => {
                                    close();
                                  }}
                                >
                                  Close
                                </button>
                                <Link
                                  to={`/vendor-milestone/${item.proposal_id}`}
                                >
                                  <button className="btn-signup">
                                    Proposal Progress
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </td> */}
                      <div className="cell scroll">
                        <Link to={`/vendor-final-proposal/${item.proposal_id}`}>
                          <button className="Viewproposaldetails">View</button>
                        </Link>
                      </div>
                    </tr>
                  </tbody>
                );
              })
            )}
          </table>
        </div>
      </div>
    </div>
    </>
  );
};

export default FinalProposal;
