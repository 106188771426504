import React, { useEffect, useState } from "react";
import "../Login/Login.css";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { BaseUrl } from "../../Api/api.js";

import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import { login } from "../../Store/Reducers/userSlice";

const ResetPass = () => {
  const [Counter, setCounter] = useState(59);
  useEffect(() => {
    const timer =
      Counter > 0 && setInterval(() => setCounter(Counter - 1), 1000);
    return () => clearInterval(timer);
  }, [Counter]);

  const initialValues = {
    user_name: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = validate(formValues);
    //setFormErrors(validate(formValues));
    if (Object.keys(error).length !== 0) {
      setFormErrors(error);
      console.log("Error Found");
      return false;
    }
    console.log("No Validation Error Found");
    setIsSubmit(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(formValues);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendor/Vendor-Forgot-P-I`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          if (result.status === 200) {
            setFormValues("");
            //alert(result.message);
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("user", JSON.stringify(result.data));
            localStorage.setItem(
              "userinfo",
              JSON.stringify(result.data.user_info)
            );
            toast("OTP Send Successfully");
            window.location = "/otp-verification";
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
    dispatch(login({ formValues, loggedIn: true }));
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    if (!email_regex.test(values["user_name"])) {
      errors.user_name = "This is not valid email format";
    }
    console.log(errors);
    return errors;
  };

  return (
    <div className="login-wrapper">
      <div className="login-form ">
        <h2>Forgot Your Password</h2>
        <p className="ftext">
          Enter your email and we will send you a OTP on your email to reset
          your password.
        </p>
        <div className="formline">&nbsp;</div>
        <form
          className="loginform"
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
        >
          <div className="login-form-field">
            <FaUserCircle className="form-icon" />
            <input
              type="email"
              name="user_name"
              value={formValues.user_name}
              placeholder="Email ID"
              required
              onChange={handleChange}
            />
          </div>
          <p className="err-message">{formErrors.user_name}</p>
          <button className="btn" onClick={handleSubmit}>
            Send OTP
          </button>
        </form>
        <Link to="/login">
          <p className="fpass">Click here to login</p>
        </Link>
      </div>
    </div>
  );
};

export default ResetPass;
