import React, { useEffect, useState } from "react";
import "./Project.css";
import { Link } from "react-router-dom";
import CountdownTimer from "../../components/CountdownTimer";
import { BaseUrl } from "../../Api/api.js";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import { toast } from "react-toastify";
import ReadMoreReact from "read-more-react";

const Projectlist = () => {
  const [data, setData] = useState([]);
  let logintype = JSON.parse(localStorage.getItem("logintype"));

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}/announcement/Announcement-List`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("results", result.data);
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const handleCustMessage = () => {
    toast.error("Customer is not eligible to submit the Proposal");
  };

  return (
    <section className="listprojects">
      {/* {data.map((item, announcement_id) => {
        return (
          <div className="prolists">
            <div className="exdate"> */}
      {/* <span>
                  <CountdownTimer
                    targetDate={String(item.project_expiry_datetime)}
                  />
                </span> */}
      {/* </div>
            <div className="prolist">
              <div
                className="list-block block-one wow fadeIn"
                data-wow-duration="2s"
              >
                <h2>{item.project_name}</h2>

                <ReadMoreReact
                  text={item.project_description}
                  min="100"
                  ideal="200"
                  max="5000"
                  readMoreText="read more"
                />

                <p>{parse(item.project_description)}</p>

                {logintype == 2 ? (
                  <button className="btn-read">
                    <Link to="/vendor-project-list">Submit Proposal</Link>
                  </button>
                ) : logintype == 1 ? (
                  <button className="btn-read">
                    <span onClick={handleCustMessage}>Submit Proposal</span>
                  </button>
                ) : (
                  <button className="btn-read">
                    <Link to="/vendor-project-list">Submit Proposal</Link>
                  </button>
                )}
              </div>
            </div>
          </div> 
      ); })}*/}
      {data.map((item, announcement_id) => {
        return (
          <div className="projectcard">
            <div className="exdate"></div>
            <div className="prolist">
              <h2>{item.project_name}</h2>
              <p>{parse(item.project_description)}</p>

              {logintype == 2 ? (
                <button className="btn-read">
                  <Link to="/vendor-project-list">Submit Proposal</Link>
                </button>
              ) : logintype == 1 ? (
                <button className="btn-read">
                  <span onClick={handleCustMessage}>Submit Proposal</span>
                </button>
              ) : (
                <button className="btn-read">
                  <Link to="/vendor-project-list">Submit Proposal</Link>
                </button>
              )}
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Projectlist;
