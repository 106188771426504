import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import {Link} from "react-router-dom";

const ConqtUniversity = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <section className="">
        <div className="contact-heading conuni">
          <h1>ConQt University</h1>
          <h2>ConQt - First Businesse Matching Marketplace</h2>
        </div>
        <div className="universitycontent">
        <h3 className="text-center">ConQt Guideline Articles</h3>
        <br />
          <Link to="/how-to-become-a-partner" className="conqtquest"><div>How to become a partner ?</div></Link>
          <Link to="/how-to-get-a-project" className="conqtquest"><div>How to get a Project ?</div></Link>
          <Link to="/how-to-submit-a-proposal" className="conqtquest"><div>How to Submit a Proposal ?</div></Link>
          <Link to="/how-to-add-products" className="conqtquest"><div>How to Add Products ?</div></Link>
        </div>
     
      </section>
    </Layout>
  );
};

export default ConqtUniversity;
