import React, { useState, useEffect } from "react";
import "./page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import IT from "./images/IT.jpg";
import ui from "./images/ui.jpg";
import hardware from "./images/hardware.jpg";
import office from "./images/office.jpg";
import PM from "./images/PM.jpg";
import { BsPlusSquareFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
// import bar30 from "../NewProfile/images/bar30.png";
import Popup from "reactjs-popup";
import { BaseUrl } from "../../../Api/api";
// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Multiselect } from "multiselect-react-dropdown";
import { useNavigate } from "react-router-dom";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./util.js";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const ServicesandPreference = () => {
  const dispatch = useDispatch();
  const initialValues = {
    solutions: "",
  };
  const [error, showerror] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [solutions, setSolutions] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [copysSelectedValue, setCopySelectedValue] = useState([]);
  const userToken = localStorage.getItem("token");
  const [data, setData] = useState([]);
  let navigate = useNavigate();
  const { control, handleSubmit } = useForm();
  const handleSave = () => {
    {
      try {
        const temp = [];
        selectedValue.forEach((value) => {
          if (!copysSelectedValue.includes(value)) {
            temp.push(value);
          }
        });

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        myHeaders.append("Content-Type", "application/json");
        if (temp.length > 0) {
          var raw = JSON.stringify({
            services_preferences: temp.toString(),
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(
            `${BaseUrl}/vendor/Vendor-Services-Preferences-Profile`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              if (result.status === 200) {
                toast("Your Profile Saved Successfully");
                navigate("/services-and-preference");
                showerror(false);
              } else {
                showerror(true);
              }
            })
            .catch((error) => console.log("error", error));
        } else {
          navigate("/services-and-preference");
          showerror(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const onSubmit = (data) => {
    console.log(data, "onsubmit");
    setSelectedValue(data.rootCauseAnalysisCategory);
    dispatch(updateStatusValue(progressBarValidation(3)));
  };

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0) {
      console.log(formValues);
    }

    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${BaseUrl}/vendor/Get-Solutions`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log("Get Solutions", JSON.parse(result).data);
        setSolutions(JSON.parse(result).data);
      })
      .catch((error) => console.log("error", error));
  }, [formErrors]);

  useEffect(() => {
    try {
      const userToken = localStorage.getItem("token");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendor/Get-Vendor-Service-Preferences-Profile`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(result.data);
          const service_preferencesarray = result.data.map(
            (value) => value.service_preferences
          );
          setSelectedValue(service_preferencesarray);
          setCopySelectedValue(service_preferencesarray);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);
  let rootCauseAnalysisCategorys = [];
  solutions.forEach((solutionsList, index) => {
    // console.log(index, solutionsList["solutions_name"]);
    rootCauseAnalysisCategorys[index] = solutionsList["solutions_name"];
  });

  // console.log("catlist", rootCauseAnalysisCategorys);

  const handleSkip = (e) => {
    window.location = "/portfolio";
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar
          isShow={true}
          value={data.length > 0 && data[0].profile_complete_status}
        />
        <main className="maincontent">
          <div>
            <section>
              <div className="basic-info">
                <h3>Services & Preferences</h3>
                {/* <h4>
                  Edit
                  <MdModeEdit />
                </h4> */}
              </div>
            </section>
            <section className="mainservices">
              <div className="selectservicelist">
                <p>Add Services in Services & Preference List from here</p>

                <div className="servicespreflist">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      control={control}
                      name="rootCauseAnalysisCategory"
                      render={({ field: { value, onChange } }) => (
                        <Multiselect
                          options={rootCauseAnalysisCategorys}
                          isObject={false}
                          showCheckbox={true}
                          hidePlaceholder={true}
                          closeOnSelect={false}
                          onSelect={onChange}
                          onRemove={onChange}
                          selectedValues={
                            selectedValue.length > 0 && selectedValue[0]
                              ? selectedValue
                              : value
                          }
                        />
                      )}
                    />
                    <input type="submit" className="btn selectservice" />
                  </form>
                  {/* <select
                    className="selectdropdown"
                    name="solutions"
                    value={formValues.solutions_name}
                    // placeholder="Enter the solutions"
                    onChange={handleChange}
                  >
                    <option>Select Solutions</option>
                    {solutions.map((solutions) => (
                      <option
                        key={solutions.id}
                        value={solutions.solutions_name}
                      >
                        {solutions.solutions_name}
                      </option>
                    ))}
                  </select>

                  <button>Add Services Category</button> */}
                </div>
              </div>
              <div>
                {selectedValue.length > 0 &&
                  selectedValue[0] &&
                  selectedValue.map((val) => {
                    return (
                      <div className="mainserviceslist">
                        <div className="it">
                          <h3>{val}</h3>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {/* <div className="mainserviceslist">
                <div className="it">
                  <img src={IT} alt="" />
                  <h3>Information Technology</h3>
                </div>

                <div className="it">
                  <img src={IT} alt="" />
                  <h3>Information Technology</h3>
                </div>

                <div className="it">
                  <img src={IT} alt="" />
                  <h3>Information Technology</h3>
                </div>

                <div className="it">
                  <img src={IT} alt="" />
                  <h3>Information Technology</h3>
                </div>

                <div className="it">
                  <img src={IT} alt="" />
                  <h3>Information Technology</h3>
                </div>

                <div className="it">
                  <img src={IT} alt="" />
                  <h3>Information Technology</h3>
                </div>
              </div> */}
            </section>
            {/* <div className="cards">
              <section>
                <div className="it">
                  <img src={IT} alt="" />
                  <h3>Information Technology</h3>
                </div>
              </section>

              <section>
                <div className="it-cards">
                  <img src={ui} alt="" />
                  <h3>UI UX Design</h3>
                </div>
              </section>
            </div>

            <div className="cards">
              <section>
                <div className="it">
                  <img src={hardware} alt="" />
                  <h3>Hardware</h3>
                </div>
              </section>

              <section>
                <div className="it-cards">
                  <img src={office} alt="" />
                  <h3>Office Suite</h3>
                </div>
              </section>
            </div>

            <div className="cards">
              <section>
                <div className="it">
                  <img src={PM} alt="" />
                  <h3>Predictive Maintenanace</h3>
                </div>
              </section>

              <section>
                <div className="add-item">
                  <Popup trigger={<BsPlusSquareFill />} modal nested>
                    {(close) => (
                      <div className="pass-changed">
                        <button className="pass-button" onClick={close}>
                          &times;
                        </button>
                        <h3>Select Services</h3>
                        <select
                          className="selectdropdown"
                          name="solutions"
                          value={formValues.solutions_name}
                          // placeholder="Enter the solutions"
                          onChange={handleChange}
                        >
                          <option>Select Solutions</option>
                          {solutions.map((solutions) => (
                            <option
                              key={solutions.id}
                              value={solutions.solutions_name}
                            >
                              {solutions.solutions_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </Popup>
                  <h3>Add Technology</h3>
                </div>
              </section>
            </div> */}

            <div className="formbuton">
              {/* <Link to={`/AgencyProfile`}> */}

              <input
                type="submit"
                className="save-button"
                onClick={handleSave}
                value="Save"
              />

              {/* </Link> */}

              {/* <Link to={`/AgencyProfile`}>
                <button className="save-button">Skip</button>
              </Link> */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default ServicesandPreference;
