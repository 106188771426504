import React, { useState, useEffect } from "react";
import "./page.css";
import DragDrop from "./dragandrop";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import logo from "./images/logo.png";
import { BsInfoCircle } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { FcCameraIdentification } from "react-icons/fc";
import "react-phone-number-input/style.css";
import bar20 from "./images/bar20.png";
import { GrFormUpload } from "react-icons/gr";
import { useForm } from "react-hook-form";
import PhoneInput, {
        isPossiblePhoneNumber,
        isValidPhoneNumber,
} from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./util.js";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import CommonApi from "../../../Api/commonApi";
import { BaseUrl } from "../../../Api/api";

const MyProfile = () => {
        const dispatch = useDispatch();
        const [error, showerror] = useState(false);
        const [showBar, setShowBar] = useState("0");
        const [phone, setPhone] = useState("");
        const [picture, setPicture] = useState("");
        const [pictureUrl, setPictureUrl] = useState("");
        const [selectedFilesNames, setSelectedFileNames] = useState([]);
        const [userFile, setUserFile] = useState({});
        const [data, setData] = useState([]);
        const [profile, setProfile] = useState(false);
        const [overAllProgressValue, setOverAllProgressValue] = useState(0);
        const [showFileError, setShowFileError] = useState("");
        const [gallery, setGallery] = useState([]);
        const [galleryUrl, setGalleryUrl] = useState([]);
        const [countryList, setCountryList] = useState([]);

        let navigate = useNavigate();
        const userToken = localStorage.getItem("token");
        const userinfo = JSON.parse(localStorage.getItem("userinfo"));

        const {
                register,
                handleSubmit,
                getValues,
                setValue,
                watch,
                formState: { errors },
        } = useForm();
        const handleChange = (e) => {
                setPicture(e.target.files[0]);
                let url = URL.createObjectURL(e.target.files[0]);
                setProfile(true);
                setPictureUrl(url);
        };

        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.(com|co)(?:\.[A-Z|a-z]{2,})?$/;


        const dragFiles = (files) => {
                let temp = [];
                Object.values(files).forEach((key) => {
                        temp.push(key.name);
                });
                setSelectedFileNames(temp);
                setUserFile(files);
                setShowFileError("");
        };
        const onSubmit = (data) => {
                const Token = localStorage.getItem("token");
                const IsImageSelector = () => {
                        return selectedFilesNames.length > 0 ? true : false;
                };

                data = { ...data, phone: phone };
                if (!IsImageSelector()) {
                        setShowFileError("Please Select a file before saving");
                }
                if (isValidPhoneNumber(phone) && IsImageSelector()) {
                        dispatch(updateStatusValue(progressBarValidation(2)));
                        const formdata = new FormData();
                        if (pictureUrl && pictureUrl.includes("blob")) {
                                formdata.append("logo", picture, pictureUrl);
                        }
                        formdata.append("agency_name", data.agency_name);
                        formdata.append("agency_email", data.agency_email);
                        formdata.append("agency_about", data.about_agency);
                        formdata.append("agency_type", data.agency_type);
                        formdata.append("agency_contact_number", phone);
                        formdata.append("agency_address", data.agency_address);
                        formdata.append("agency_city", data.agency_city);
                        formdata.append("agency_country", data.agency_country);
                        formdata.append("agency_state", data.agency_state);
                        formdata.append("agency_zipcode", data.agency_zipcode);
                        formdata.append("agency_website_link", data.website_link);
                        formdata.append("agency_no_of_employee", data.no_of_employee);
                        formdata.append("agency_linkedin_url", data.linkedin_url);
                        formdata.append("agency_twitter_url", data.twitter_url);
                        formdata.append("agency_instagram_url", data.instagram_url);
                        formdata.append("agency_facebook_url", data.facebook_url);
                        Object.values(userFile).forEach((value) => {
                                let url = URL.createObjectURL(value);
                                formdata.append("gallery", value, url);
                        });

                        for (var pair of formdata.entries()) {
                        }
                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${Token}`);
                        var requestOptions = {
                                method: "POST",
                                body: formdata,
                                headers: myHeaders,
                                redirect: "follow",
                        };
                        fetch(`${BaseUrl}/vendor/Vendor-Agency-Profile`, requestOptions)
                                .then((response) => response.json())
                                .then((result) => {
                                        console.log(result, "api result");
                                        if (result.status === 200) {
                                                toast("Your Profile Saved Successfully");
                                                navigate("/agency-profile");
                                                showerror(false);
                                        } else {
                                                showerror(true);
                                        }
                                })
                                .catch((error) => console.log("error", error));
                }
        };

        const watchAllFields = watch();

        useEffect(() => {
                const afterRemovingTheDuplicates = Object.values(watchAllFields).filter(
                        (value) => value && value.length > 0
                );
                const percentage = (afterRemovingTheDuplicates.length / 18) * 100;
                if (percentage > 100) {
                        setShowBar("" + 100);
                } else {
                        setShowBar("" + percentage);
                }
        }, [watchAllFields]);

        // const handleSkip = (e) => {
        //   window.location = "/services-and-preference";
        // };
        useEffect(() => {
                try {
                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${userToken}`);
                        myHeaders.append("Content-Type", "application/json");

                        var requestOptions = {
                                method: "GET",
                                headers: myHeaders,
                                redirect: "follow",
                        };

                        fetch(`${BaseUrl}/vendor/Get-Vendor-Agency-Profile`, requestOptions)
                                .then((response) => response.json())
                                .then((result) => {
                                        setData(result.data[0]);
                                        CommonApi.ProfileApi().then((vsdata) => {
                                                console.log("ApiRes", vsdata);
                                                if (vsdata.status === 200) {
                                                        setOverAllProgressValue(vsdata.data.profile_complete_status);
                                                }
                                        });
                                })
                                .catch((error) => console.log("error", error));
                } catch (err) {
                        console.log(err);
                }
        }, []);
        useEffect(() => {
                setValue("agency_city", userinfo.city);
                setValue("agency_country", userinfo.country);
                setValue("agency_zipcode", userinfo.zipcode);
                setValue("agency_state", userinfo.state);
                setValue("agency_address", userinfo.address);
                if (data) {
                        Object.entries(data).forEach(([name, value]) => {
                                setValue(name, value);
                        });

                        console.log(getValues(), "after setting the values");
                        setPictureUrl(data.logo_url);
                        setPhone(data.agency_phone_no);
                        setPicture(data.logo_name);
                        if (data.galleryList && data.galleryList.length > 0) {
                                setGalleryUrl(data.galleryList);
                                const names = data.galleryList.map((images) => {
                                        return images.img_name;
                                });
                                setSelectedFileNames(names);
                        }
                        const getBlobFromUrl = (data) => {
                                return new Promise((resolve, reject) => {
                                        let request = new XMLHttpRequest();
                                        request.open("GET", data.profile_img_url, true);
                                        request.responseType = "image/png";
                                        request.onload = () => {
                                                resolve(request.response);
                                        };
                                        request.onerror = reject;
                                        request.send();
                                });
                        };
                        getBlobFromUrl(data).then((val) => {
                                setPicture(val);
                        });

                        CommonApi.allCountryList().then((csresponse) => {
                                if (csresponse.status === 200 || csresponse.status === 2000) {
                                        setCountryList(csresponse.data.country_list);
                                }
                        });
                }
        }, [data]);

        return (
                <div className="dashboard-container">
                        <Sidebar />
                        <div className="main-dashboard-container">
                                <Topbar isShow={true} value={overAllProgressValue} />
                                <main className="maincontent">
                                        <div>
                                                <section>
                                                        <div className="basic-info">
                                                                <h3>Agency Profile</h3>
                                                                {/* <h4>
                  Edit
                  <MdModeEdit />
                </h4> */}
                                                        </div>
                                                </section>
                                                {/* onSubmit={handleSubmit(onSubmit)} */}
                                                <form onSubmit={handleSubmit(onSubmit)} className="profileform">
                                                        <section>
                                                                <div className="division">
                                                                        <div className="upload-file">
                                                                                <h3>
                                                                                        Company Logo<span className="star">*</span>
                                                                                </h3>
                                                                                <div className="profilepicupload">
                                                                                        <img src={pictureUrl ? pictureUrl : logo} alt="" />
                                                                                        <div className="uploadImageProfile">
                                                                                                <FcCameraIdentification />
                                                                                                <input
                                                                                                        type="file"
                                                                                                        name="myfile"
                                                                                                        onChange={handleChange}
                                                                                                />
                                                                                        </div>
                                                                                </div>
                                                                                {profile && <p>Your Profile Uploaded Successfully</p>}
                                                                                <p className="profilepicdes">
                                                                                        300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
                                                                                </p>
                                                                        </div>
                                                                        <div className="progressbar">
                                                                                <div className="bar-profile">
                                                                                        <h4>Completion Progress</h4>
                                                                                        {/* <img src={barr} alt="" /> */}
                                                                                        <div className="circle-container">
                                                                                                <CircularProgressbar
                                                                                                        value={showBar}
                                                                                                        text={Math.round(showBar) + "%"}
                                                                                                        styles={buildStyles({
                                                                                                                pathColor: "#1fc94f",
                                                                                                                textColor: "#001488",
                                                                                                                backgroundColor: "#3e98c7",
                                                                                                        })}
                                                                                                />
                                                                                                <p>
                                                                                                        {showBar == 100 ? "" : "Please Complete Your Profile"}
                                                                                                </p>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                {/* <p className="para-profile2">
                  100 x 100px recommended. JPGs, JPEGs, and PNGs supported.
                </p> */}
                                                        </section>

                                                        <section className="pro-info">
                                                                <div className="proformfield pt-20">
                                                                        <label>
                                                                                Agency Name <span className="star">*</span>
                                                                        </label>
                                                                        <input
                                                                                type="text"
                                                                                placeholder="Enter Your Company Name"
                                                                                value={userinfo.company_name}
                                                                                {...register("agency_name", {
                                                                                        required: true,
                                                                                })}
                                                                                // {...register("agency_name", {
                                                                                //   disabled: true,
                                                                                // })}
                                                                                readonly
                                                                        />
                                                                        {errors.agency_name && (
                                                                                <span className="error-msg">Please enter the value</span>
                                                                        )}
                                                                        {/* <div className="protip">
                    <BsInfoCircle />
                    <p>Set at once and can’t be change</p>
                  </div> */}
                                                                </div>

                                                                <div className="proformfield pt-20">
                                                                        <label>
                                                                                Agency Email <span className="star">*</span>
                                                                        </label>
                                                                        <input
                                                                                type="email"
                                                                                placeholder="Add your email here"
                                                                                {...register('agency_email', {
                                                                                        required: 'Email is required',
                                                                                        pattern: {
                                                                                                value: emailRegex,
                                                                                                message: 'Invalid email address'
                                                                                        }
                                                                                })}
                                                                        />
                                                                        {errors.agency_email && (
                                                                                <span className="error-msg">
                                                                                        {errors.agency_email.message}
                                                                                </span>
                                                                        )}
                                                                       
                                                                </div>
                                                        </section>

                                                        <section className="details-info">
                                                                <div className="details-about">
                                                                        <label>
                                                                                About Agency<span className="star">*</span>
                                                                        </label>
                                                                        <textarea
                                                                                type="textarea"
                                                                                placeholder="Type here about agency"
                                                                                {...register("about_agency", {
                                                                                        required: true,
                                                                                })}
                                                                                rows="8"
                                                                        />
                                                                        {errors.about_agency && (
                                                                                <span className="error-msg">Please enter the value</span>
                                                                        )}
                                                                        <div className="profile-info svg"></div>
                                                                </div>

                                                                <div className="proformfield">
                                                                        <label>
                                                                                Agency Type
                                                                                <span className="star">*</span>
                                                                        </label>

                                                                        <select
                                                                                type="text"
                                                                                name="category_id"
                                                                                className="selectlist"
                                                                                // onChange={handleChange}
                                                                                placeholder="Select position"
                                                                                {...register("agency_type", {
                                                                                        required: true,
                                                                                        maxLength: 80,
                                                                                })}
                                                                        >
                                                                                <option value="">Select Position</option>
                                                                                <option value="Private company limited">
                                                                                        Private company limited
                                                                                </option>
                                                                                <option value="Public limited company">
                                                                                        Public limited company
                                                                                </option>
                                                                                <option value="Limited partnership">
                                                                                        Limited partnership
                                                                                </option>
                                                                                <option value="General partnership">
                                                                                        General partnership
                                                                                </option>
                                                                                <option value="Chartered company">Chartered company</option>
                                                                                <option value="Statutory corporation">
                                                                                        Statutory corporation
                                                                                </option>
                                                                                <option value="Company Limited">Company Limited</option>
                                                                                <option value="State-owned enterprise">
                                                                                        State-owned enterprise
                                                                                </option>
                                                                                <option value="Holding company">Holding company</option>
                                                                                <option value="Subsidiary company">
                                                                                        Subsidiary company
                                                                                </option>
                                                                                <option value="Sole proprietorship">
                                                                                        Sole proprietorship
                                                                                </option>
                                                                                <option value="Charitable incorporated organisation">
                                                                                        Charitable incorporated organisation (UK)
                                                                                </option>
                                                                                <option value="Reciprocal inter-insurance exchange">
                                                                                        Reciprocal inter-insurance exchange
                                                                                </option>
                                                                                <option value="Other">Other</option>
                                                                        </select>
                                                                        {errors.agency_type && (
                                                                                <span className="error-msg">
                                                                                        Please select the position
                                                                                </span>
                                                                        )}

                                                                        <div className="profile-info svg"></div>
                                                                </div>

                                                                <label>
                                                                        Contact Number<span className="star">*</span>
                                                                </label>

                                                                <PhoneInput
                                                                        country="india"
                                                                        placeholder="6547894388"
                                                                        name="phoneNumber"
                                                                        international
                                                                        value={phone}
                                                                        onChange={(e) => {
                                                                                setPhone(e);
                                                                        }}
                                                                />
                                                                {phone && !isValidPhoneNumber(phone) && (
                                                                        <span className="error-msg">
                                                                                Please enter the valid phone number
                                                                        </span>
                                                                )}
                                                                {!phone && error && (
                                                                        <span className="error-msg">
                                                                                Please enter the phone number
                                                                        </span>
                                                                )}

                                                                <div className="profile-info svg"></div>

                                                                <section>
                                                                        <div className="proformfield pt-20">
                                                                                <div className="profile-address">
                                                                                        <label>
                                                                                                Location / Address<span className="star">*</span>
                                                                                        </label>

                                                                                        <input
                                                                                                type="text"
                                                                                                placeholder="Add address here"
                                                                                                // value={userinfo.address}
                                                                                                {...register("agency_address", {
                                                                                                        required: true,
                                                                                                })}
                                                                                        />
                                                                                        {errors.agency_address && (
                                                                                                <span className="error-msg">
                                                                                                        Please enter the address
                                                                                                </span>
                                                                                        )}
                                                                                </div>
                                                                        </div>

                                                                        <div className="first-last-names pt-20">
                                                                                <div className="proformfield">
                                                                                        <label>
                                                                                                Country <span className="star">*</span>
                                                                                        </label>
                                                                                        {/* <input
                        type="text"
                        placeholder="Select Country"
                        {...register("country", {
                          required: true,
                          minLength: 3,
                          maxLength: 50,
                        })}
                      /> */}
                                                                                        <select
                                                                                                type="text"
                                                                                                placeholder="Select Country"
                                                                                                //onChange={handleChange}
                                                                                                // value={userinfo.country}
                                                                                                className="selectlist"
                                                                                                {...register("agency_country", {
                                                                                                        required: true,
                                                                                                        maxLength: 80,
                                                                                                })}
                                                                                        >
                                                                                                {countryList.map((el) => {
                                                                                                        return (
                                                                                                                <>
                                                                                                                        <option value={el.country}>{el.country}</option>
                                                                                                                </>
                                                                                                        );
                                                                                                })}
                                                                                        </select>
                                                                                        {errors.country && (
                                                                                                <span className="error-msg">
                                                                                                        Please select the country
                                                                                                </span>
                                                                                        )}
                                                                                </div>

                                                                                <div className="proformfield profile-width">
                                                                                        <label>
                                                                                                City<span className="star">*</span>
                                                                                        </label>
                                                                                        <input
                                                                                                type="text"
                                                                                                //value={userinfo.city}
                                                                                                // placeholder={userinfo.city}
                                                                                                {...register("agency_city", {
                                                                                                        required: true,
                                                                                                        minLength: 3,
                                                                                                        maxLength: 50,
                                                                                                })}
                                                                                        />
                                                                                        {errors.country && (
                                                                                                <span className="error-msg">Please enter the city</span>
                                                                                        )}
                                                                                </div>
                                                                        </div>

                                                                        <div className="first-last-names pt-20">
                                                                                <div className="d-flex flex-column col-12 col-md-6"
                                                                                        style={{ marginRight: "10px" }}>
                                                                                        <label>
                                                                                                State/Province <span className="star">*</span>
                                                                                        </label>
                                                                                        <input
                                                                                                type="text"
                                                                                                placeholder="Select State/Province"
                                                                                                // value={userinfo.state}
                                                                                                {...register("agency_state", {
                                                                                                        required: true,
                                                                                                        minLength: 3,
                                                                                                        maxLength: 50,
                                                                                                })}
                                                                                        />
                                                                                        {errors.state && (
                                                                                                <span className="error-msg">
                                                                                                        Please select the State/Province
                                                                                                </span>
                                                                                        )}
                                                                                </div>

                                                                                <div className="d-flex flex-column col-12 col-md-6">
                                                                                        <label>
                                                                                                Pin Code<span className="star">*</span>
                                                                                        </label>
                                                                                        <input
                                                                                                type="number"
                                                                                                placeholder="Add Pin Code here"
                                                                                                //value={userinfo.zipcode}

                                                                                                {...register("agency_zipcode", {
                                                                                                        required: true,
                                                                                                        minLength: 4,
                                                                                                        maxLength: 8,
                                                                                                })}
                                                                                        />
                                                                                        {errors.zipcode && (
                                                                                                <span className="error-msg">
                                                                                                        Please enter the Pin Code
                                                                                                </span>
                                                                                        )}
                                                                                </div>
                                                                        </div>
                                                                </section>

                                                                <div className="proformfield">
                                                                        <label>
                                                                                Website Link<span className="star">*</span>
                                                                        </label>

                                                                        <div className="projectlink">
                                                                                {/* <span>https://</span> */}
                                                                                <input
                                                                                        type="link"
                                                                                        placeholder="Add website link here"
                                                                                        {...register("website_link", {
                                                                                                required: "Please enter a website link",
                                                                                                pattern: {
                                                                                                        value: urlRegex,
                                                                                                        message: "Invalid URL format. Please enter a valid URL.",
                                                                                                },
                                                                                        })}
                                                                                />
                                                                        </div>

                                                                        {/* <input
                    type="link"
                    placeholder="Add website link here"
                    {...register("website_link", {
                      required: true,
                    })}
                  /> */}
                                                                        {errors.website_link && (
                                                                                <span className="error-msg">
                                                                                        {errors.website_link.message}
                                                                                </span>
                                                                        )}
                                                                        <div className="profile-info svg"></div>

                                                                        <label>
                                                                                Number of Employee<span className="star">*</span>
                                                                        </label>

                                                                        <input
                                                                                type="number"
                                                                                placeholder="Select no. of employee"
                                                                                {...register("no_of_employee", {
                                                                                        required: true,
                                                                                })}
                                                                                min="0"
                                                                                oninput="validity.valid||(value='')"
                                                                        />
                                                                        {errors.no_of_employee && (
                                                                                <span className="error-msg">
                                                                                        Please enter the number of employee
                                                                                </span>
                                                                        )}

                                                                        <div className="profile-info svg"></div>
                                                                </div>

                                                                <div className="proformfield">
                                                                        <label>Social Proof</label>

                                                                        <div className="details-social">
                                                                                <span>
                                                                                        <div className="checkboxlist">
                                                                                                {/* <input
                          type="checkbox"
                          id="linkendin"
                          name="linkendinlink"
                          value="linkendin"
                        /> */}
                                                                                                <label>Linkedin</label>
                                                                                        </div>

                                                                                        {/* <input 
                                                     type="checkbox"/> */}
                                                                                        <input
                                                                                                type="link"
                                                                                                placeholder="Add link here"
                                                                                                {...register("linkedin_url", {})}
                                                                                        />
                                                                                        {errors.linkedin_url && (
                                                                                                <span className="error-msg">
                                                                                                        Please enter the linkedin link
                                                                                                </span>
                                                                                        )}
                                                                                </span>

                                                                                <span>
                                                                                        <div className="checkboxlist">
                                                                                                {/* <input
                          type="checkbox"
                          id="linkendin"
                          name="linkendinlink"
                          value="linkendin"
                        /> */}
                                                                                                <label>Twitter</label>
                                                                                        </div>

                                                                                        {/* <input 
                                                     type="checkbox"/> */}
                                                                                        <input
                                                                                                type="link"
                                                                                                placeholder="Add link here"
                                                                                                {...register("twitter_url", {})}
                                                                                        />
                                                                                        {errors.twitter_url && (
                                                                                                <span className="error-msg">
                                                                                                        Please enter the twitter link
                                                                                                </span>
                                                                                        )}
                                                                                </span>
                                                                        </div>

                                                                        <div className="details-social">
                                                                                <span>
                                                                                        <div className="checkboxlist">
                                                                                                {/* <input
                          type="checkbox"
                          id="linkendin"
                          name="linkendinlink"
                          value="linkendin"
                        /> */}
                                                                                                <label>Instagram</label>
                                                                                        </div>

                                                                                        {/* <input 
                                                     type="checkbox"/> */}
                                                                                        <input
                                                                                                type="link"
                                                                                                placeholder="Add link here"
                                                                                                {...register("instagram_url", {})}
                                                                                        />
                                                                                        {errors.instagram_url && (
                                                                                                <span className="error-msg">
                                                                                                        Please enter the instagram link
                                                                                                </span>
                                                                                        )}
                                                                                </span>

                                                                                <span>
                                                                                        <div className="checkboxlist">
                                                                                                {/* <input
                          type="checkbox"
                          id="linkendin"
                          name="linkendinlink"
                          value="linkendin"
                        /> */}
                                                                                                <label>Facebook</label>
                                                                                        </div>

                                                                                        {/* <input 
                                                     type="checkbox"/> */}
                                                                                        <input
                                                                                                type="link"
                                                                                                placeholder="Add link here"
                                                                                                {...register("facebook_url", {})}
                                                                                        />
                                                                                        {errors.facebook_url && (
                                                                                                <span className="error-msg">
                                                                                                        Please enter the facebook link
                                                                                                </span>
                                                                                        )}
                                                                                </span>
                                                                        </div>

                                                                        <div className="profile-info svg"></div>

                                                                        <label>Gallery(attached file is any)</label>
                                                                </div>
                                                        </section>

                                                        {/* <div className="gallery">
                <GrFormUpload />
                <input
                  type="file"
                  placeholder="Upload to see preview"
                  {...register("gallery", {
                    required: false,
                  })}
                />
                
              </div> */}

                                                        <DragDrop onChange={dragFiles} />
                                                        <h6 className="last-heading">
                                                                300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
                                                        </h6>
                                                        {showFileError && <p className="error-msg">{showFileError}</p>}
                                                        {selectedFilesNames.map((value) => {
                                                                return <p>{value}</p>;
                                                        })}

                                                        {Object.values(userFile).map((value) => {
                                                                return (
                                                                        <>
                                                                                <div className="portfolio-screenshots">
                                                                                        <img src={URL.createObjectURL(value)} alt="" />
                                                                                </div>
                                                                        </>
                                                                );
                                                        })}

                                                        {galleryUrl.length > 0 &&
                                                                galleryUrl.map((value) => {
                                                                        return (
                                                                                <>
                                                                                        <div className="portfolio-screenshots">
                                                                                                <img src={value.img_url} alt="" />
                                                                                        </div>
                                                                                </>
                                                                        );
                                                                })}

                                                        <div className="formbuton">
                                                                {/* <Link to={`/AgencyProfile`}> */}
                                                                <input type="submit" className="save-button" value="Save" />
                                                                {/*<input
                  type="submit"
                  className="save-button"
                  value="Skip"
                  onClick={handleSkip}
                />
                 </Link> */}

                                                                {/* <Link to={`/AgencyProfile`}>
                <button className="save-button">Skip</button>
              </Link> */}
                                                        </div>

                                                        {/* <div>
                                   <Link to={`/ServicesandPreference`}>
                                   <button className="save-button">Skip</button>
                                   </Link>
                                    </div> */}
                                                </form>
                                        </div>
                                </main>
                        </div>
                </div>
        );
};
export default MyProfile;
