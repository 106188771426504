import React, { useState, useEffect } from "react";
import "./Login.css";
import { Link } from "react-router-dom";
import CustLogin from "./custLogin";
import VendorLogin from "./vendLogin";
import PageLoader from "../../components/PageLoader/PageLoader";


const Login = () => {
  const login = ["Customer", "Partner"];
  const [userLogin, setLogin] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const logintype = localStorage.getItem("logintype");
  useEffect(() => {
    setPageLoading(true);
    if (logintype == 1) {
      setPageLoading(false);
      window.location = "/customer-dashboard";
    } else if (logintype == 2) {
      setPageLoading(false);
      window.location = "/vendor-project-list";
    } else {
      setPageLoading(false);
    }
  }, []);

  return (
    <>
      {pageLoading && (
       <PageLoader/>
      )}
      <div className="login-wrapper">
        <div className="login-form">
          <h2>Log in to ConQT</h2>

          <div className="login-btn">
            {login.map((login) => (
              <button
                type="button"
                key={login}
                default="Customer"
                className="btn btn-select center"
                onClick={() => setLogin(login)}
              >
                {login}
              </button>
            ))}
          </div>

          <div>
            {userLogin === "Customer" && <CustLogin />}
            {userLogin === "Partner" && <VendorLogin />}
          </div>

          <div className="formline">&nbsp;</div>
          <p className="notreg">Don't have a ConQT account?</p>
          <Link to="/register">
            <button className="btn-signup">Sign Up</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Login;
