import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import "./Customer.css";
import "./view.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BaseUrl, RazorPaymentkey } from "../../Api/api.js";
import CheckOutIcon from "../../assets/images/checkout-icon.png";
import CustomerInvoice from "./CustomerInvoice";
import "./Checkout.css";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

function Checkout() {
  const navigate = useNavigate();
  const initialFormData = {
    showAmount: "",
    amount: 0.0,
    currency: "",
    invoice_no: "",
    transactions_id: "",
    amount: "",
    transaction_status: "COMPLETED",
    milestone_id: "",
  };

  const invoice = Math.random().toString(21).substring(2, 9).toUpperCase();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  const id = useParams().proposal_id;
  const Milestone_No = useParams().index;
  today = dd + "/" + mm + "/" + yyyy;
  const userToken = localStorage.getItem("token");
  const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
  const [data, setData] = useState([]);
  const [formValues, setFormValues] = useState(initialFormData);
  const [customerInvoice, showCustomerInvoice] = useState(false);
  formValues.milestone_id = useParams().milestone_id;
  const ind = useParams().index;

  // console.log(id, "proposal id", Milestone_No, "milestone number", user);

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Customer-Final-Project-List`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(result.data.filter((item) => item.proposal_id == id));
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  console.log(data);

  const loadScript = (src) => {
    return new Promise((resovle) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resovle(true);
      };

      script.onerror = () => {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async () => {
    console.log(formValues.showAmount);
    console.log(formValues.amount);
    console.log(formValues.currency);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }

    console.log("this is amount", formValues.amount);

    const options = {
      key: RazorPaymentkey,
      currency: formValues.currency,
      milestone_id: formValues.milestone_id,
      receipt: "receipt_order_74394",
      // amount: 100, //formValues.amount*100,
      amount: formValues.amount * 100,
      name: "Milestone Payment",
      description: "Thanks for purchasing",
      image:
        "https://mern-blog-akky.herokuapp.com/static/media/logo.8c649bfa.png",

      handler: function (response) {
        console.log(response);

        let responseData = {
          invoice_no: invoice,
          milestone_id: formValues.milestone_id,
          transactions_id: response.razorpay_payment_id,
          currency: options.currency,
          amount: options.amount / 100,
          transaction_status: "COMPLETED",
        };
        console.log(responseData);

        //POST API For Transactions

        try {
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("token")}`
          );
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify(responseData);

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(
            `${BaseUrl}/announcement/Milestone-Payment-Update`,
            requestOptions
          )
            .then((response) => response.text())
            .then((result) => {
              result = JSON.parse(result);
              // console.log(result,"milestone payment details");
              window.location = `/receipt/${formValues.milestone_id}/${ind}/${id}`;
            })
            .catch((error) => console.log("error", error));
        } catch { }

        toast(response.razorpay_payment_id);
        toast("Payment Successfully");
      },
      prefill: {
        email: user.user_info.user_name,
        contact: user.user_info.phone_number,
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const onClickInvoice = () => {
    showCustomerInvoice(true);
    // navigate("/customer-invoice")
  };
  return (
    <>
      {customerInvoice && (
        <CustomerInvoice
          data={data}
          milestoneId={Milestone_No}
          user={user.user_info.name}
        />
      )}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          {!customerInvoice && (
            <>
              <div className="headerinvoice">
                <h3 className="ch-header">Customer Invoice Payment</h3>
              </div>

              <div className="cs-container checkout-container">
                <div className="cs-invoice cs-style1 cs-checkout-style">
                  <div className="cs-invoice_in" id="download_section">
                    <div className="cs-table cs-style1">
                      <div className="cs-round_border">
                        <div className="cs-table_responsive">
                          {data.map((item) => {
                            {
                              return (
                                <table className="checkout-table">
                                  {console.log(
                                    item.milestonelist,
                                    "item.milestonelist"
                                  )}
                                  {item.milestonelist
                                    .filter(
                                      (key, index) => index == Milestone_No
                                    )
                                    .map((key, index) => {
                                      formValues.showAmount =
                                        user.user_info.currency_code +
                                        " " +
                                        Number(key.user_milestone_amount);
                                      formValues.amount = Number(
                                        key.user_milestone_amount
                                      );
                                      formValues.currency =
                                        user.user_info.currency_code;

                                      return (
                                        <>
                                          <thead>
                                            <tr className="cs-focus_bg cs-checkout">
                                              <th className="cs-width_6 cs-semi_bold  cs-table-header">
                                                Item description
                                              </th>
                                              <th className="cs-width_2 cs-semi_bold cs-table-header">
                                                Milestone
                                              </th>

                                              <th className="cs-width_2 cs-semi_bold cs-table-header">
                                                Amount
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr className="cs-width_6">
                                              <td
                                                data-th="Item Description"
                                                className="cs-width_6"
                                              >
                                                {" "}
                                                {item.project_name}
                                              </td>
                                              <td
                                                data-th="Milestone"
                                                className="cs-width_6"
                                              >
                                                Milestone{" "}
                                                {Number(Milestone_No) + 1}{" "}
                                              </td>

                                              <td
                                                data-th="Price"
                                                className="cs-width_6 text-center"
                                              >
                                                {user.user_info.symbol}{" "}
                                                {key.user_milestone_amount}
                                              </td>
                                            </tr>
                                            <tr className="cs-total">
                                              <td className="cs-width_6"></td>
                                              <td className="cs-width_6">
                                                Total
                                              </td>
                                              <td className="cs-width_6 text-center">
                                                {user.user_info.symbol}
                                                {Number(
                                                  key.user_milestone_amount
                                                )}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </>
                                      );
                                    })}
                                </table>
                              );
                            }
                          })}
                        </div>
                        <div className="invoicepayment checkout-invoice-div-btn">
                          <button
                            className="cs-invoice_btn cs-color2"
                            onClick={onClickInvoice}
                          >
                            {" "}
                            Download Invoice
                          </button>
                          <button
                            onClick={() => displayRazorpay()}
                            id="download_btn"
                            className="cs-invoice_btn cs-color2"
                          >
                            <img src={CheckOutIcon}></img>

                            <span className="Paybtn">Checkout</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Checkout;
