import React, { useState, useEffect } from "react";
import "../Vendor/view.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseUrl } from "../../Api/api.js";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Popup from "reactjs-popup";

const FinalProjectPage = () => {
  const proposal_id = useParams().proposal_id;
  console.log("proposal id", proposal_id);
  const [openModal, setOpenModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem("token");
  console.log(data);

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Customer-Final-Project-List`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(result.data);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const toggle = (i) => {
    if (showMore == i) {
      return setShowMore(null);
    }
    setShowMore(i);
  };

  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />

          <br />
          <h3 className="proposalhead">Project Proposal Data</h3>
          {data.map((item, announcement_id) => {
            let count = item.milestonelist.length + 1;
            localStorage.setItem("ProjectCount", count);
            if (item.proposal_id == proposal_id) {
              return (
                <>
                  <div className="card1">
                    <table className="fppProposal-table">
                      <tbody>
                        <tr>
                          <td className="fppctitle">Project Name</td>
                          <td className="fppcbody">
                            {parse(item.project_name)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fppctitle">Project Description</td>
                          <td className="fppcbody">
                            {/* {parse(item.project_description)} */}
                            {showMore == item ? (
                              <p>{parse(item.project_description)}</p>
                            ) : (
                              <p>
                                {parse(
                                  item.project_description.substring(0, 300)
                                )}
                              </p>
                            )}
                            {item.project_description.length <= 300 ? (
                              ""
                            ) : (
                              <span
                                className="markascomplete"
                                style={{ color: "blue", cursor: "pointer" }}
                                onClick={() => toggle(item)}
                              >
                                {showMore == item ? "Show less" : "Show more"}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="fppctitle">Proposal Resources</td>
                          <td className="fppcbody">
                            {parse(item.proposal_resources)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fppctitle">Proposal Support</td>
                          <td className="fppcbody">
                            {parse(item.proposal_support)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fppctitle">Proposal Description</td>
                          <td className="fppcbody">
                            {parse(item.perposal_description)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fppctitle">Proposal Out of Scope</td>
                          <td className="fppcbody">
                            {parse(item.out_of_scope)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fppctitle">Proposal Price</td>
                          <td className="fppcbody">
                            {item.price} {item.currency}
                          </td>
                        </tr>
                        <tr>
                          <td className="fppctitle">Proposal Timeline</td>
                          <td className="fppcbody">
                            {item.timeline} {item.timeline_unit}
                          </td>
                        </tr>
                        <tr>
                          <td className="fppctitle">
                            Proposal Terms & Conditions
                          </td>
                          <td className="fppcbody">
                            {parse(item.proposal_termsconditions)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {item.milestonelist.length != 0 ? (
                    <div>
                      <h3 className="FPPproposalhead2">Milestone Data</h3>

                      <div className="card2">
                        <div className="container">
                          <table className="rwd-table">
                            <tbody>
                              <tr class="tabletopback">
                                <th>S.No.</th>
                                <th>Description </th>
                                <th>Percent</th>
                                <th>Amount</th>
                                <th>Date</th>
                              </tr>

                              {item.milestonelist.map((key, index) => {
                                return (
                                  <tr>
                                    <td data-th="S.No.">{index + 1}</td>
                                    <td data-th="Description">
                                      {key.milestone_description}{" "}
                                    </td>
                                    <td data-th="Percent">
                                      {key.user_milestone_percent}%
                                    </td>
                                    <td data-th="Price">
                                      {item.currency}{" "}
                                      {key.user_milestone_amount}
                                    </td>
                                    <td data-th="Date">
                                      {key.milstone_timeline}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="popup-footers">
                        <Link to={`/ViewCustomerMilestone/${item.proposal_id}`}>
                          <button className="fpp-progress">
                            Project Progress
                          </button>
                        </Link>
                        <Link to={`/edit-milestone/${item.proposal_id}`}>
                          <button className="fpp-signup">
                            Request Changes in Milestone
                          </button>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default FinalProjectPage;
