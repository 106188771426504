import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";
import Login from "./Pages/Login/Login.js";
import Register from "./Pages/Register/Register.js";
import Home from "./Pages/Home/Home.js";
//import About from "./Pages/About/About.js";
import Contact from "./Pages/ContactUs/ContactUs.js";
import Project from "./Pages/Project/Project.js";
import Customer from "./Pages/Customer/Customer.js";
import CustomerRegister from "./Pages/Register/Client-Register";
import CustRegister from "./Pages/Register/customer-register-steps";
import Vendor from "./Pages/Vendor/Vendor.js";
import VendorRegister from "./Pages/Register/Vendor-Register";
import Missing from "./components/Missing.js";
import SingleProject from "./Pages/Project/SingleProject.js";
import SingleProjects from "./Pages/Customer/SingleProject.js";
import CustomerProfile from "./Pages/Customer/Profile.js";
import CustomerProfileEdit from "./Pages/Customer/profile/myProfileEdit";
import CompanyProfile from "./Pages/Customer/profile/companyProfile";
import CompanyProfileEdit from "./Pages/Customer/profile/companyProfileEdit.js";
import CustomerDocuments from "./Pages/Customer/profile/CustomerDocuments";
import CustomerDocumentsAdd from "./Pages/Customer/profile/CustomerDocumentsAdd";
import CustomerBankDetails from "./Pages/Customer/profile/bankDetails";
import CustomerAddBankDetails from "./Pages/Customer/profile/addBankDetails";
import AddCustomerReview from "./Pages/Customer/profile/addCustomerReview";
import CustomerReviewsList from "./Pages/Customer/profile/CustomerReviewsList";
import ProtectedRoutes from "./ProtectedRoutes";
import Projectlist from "./Pages/Customer/project-list";
import AddProject from "./Pages/Customer/Add-Project";
import EditProject from "./Pages/Customer/Edit-Project";
import EditProduct from "./Pages/Vendor/EditProduct";
import VendorResponse from "./Pages/Customer/VendorResponse";
import VendorProjectList from "./Pages/Vendor/Vendor-project-list";
import TermsConditions from "./Pages/ContactUs/NewTermsConditions";
import Payment from "./Pages/Vendor/RazorPayment";
import VendorProjectApplyList from "./Pages/Vendor/Vendor-Project-Apply-List";
import VendorProfile from "./Pages/Vendor/Profile";
import VendorProfileEdit from "./Pages/Vendor/vendorProfile/myProfileEdit";
import AddProducts from "./Pages/Vendor/AddProject";
import AddServices from "./Pages/Vendor/AddServices";
import Products from "./Pages/Products/Products";
import ProductPage from "./Pages/Products/ProductPage";
// import Mscone from "./Pages/Products/MSC/mscone";
// import MSCcosim from "./Pages/Products/MSC/msccosim";
// import MSCapex from "./Pages/Products/MSC/mscapex";
// import Odyssee from "./Pages/Products/MSC/odyssee";
// import CT from "./Pages/Products/MSC/ct";
// import Digimat from "./Pages/Products/MSC/digimat";
// import Erp from "./Pages/Products/ERP/erp";
// import Sap from "./Pages/Products/ERP/sap";
// import Dynamics from "./Pages/Products/ERP/dynamics.js";
// import Oracle from "./Pages/Products/ERP/oracle.js";
// import Netsuite from "./Pages/Products/ERP/netsuite.js";
// import Actran from "./Pages/Products/MSC/actran.js";
// import Migration from "./Pages/Products/Microsoft/microsoft-office-migration";
// import Workspace from "./Pages/Products/Google/workspace";
// import MicrosoftBusiness from "./Pages/Products/Microsoft/microsoft-business";
// import MicrosoftEnterprise from "./Pages/Products/Microsoft/microsoft-enterprise";
// import MicrosoftTeams from "./Pages/Products/Microsoft/microsoft-team";
// import WorkspaceBussiness from "./Pages/Products/Google/workspace_bussiness";
// import WorkspaceEnterprise from "./Pages/Products/Google/workspace_enterprise";
// import Gmail from "./Pages/Products/Google/gmail";
// import Meet from "./Pages/Products/Google/meet";
// import Adams from "./Pages/Products/MSC/adams";
// import Fatigue from "./Pages/Products/MSC/fatigue";
// import Cradle from "./Pages/Products/MSC/cradle";
// import Easy5 from "./Pages/Products/MSC/easy5";
// import Category from "./Pages/Products/Category/Category";
// import Simufact from "./Pages/Products/MSC/simufact";
// import Marc from "./Pages/Products/MSC/marc";
// import Virtual from "./Pages/Products/MSC/virtual";
// import Dytran from "./Pages/Products/MSC/dytran";
// import Nastran from "./Pages/Products/MSC/nastran";
// import Patran from "./Pages/Products/MSC/patran";
// import Material from "./Pages/Products/MSC/material";
// import Simmanager from "./Pages/Products/MSC/simmanager";
// import InsertProducts from "./Pages/Vendor/addProduct";
// import InsertCategory from "./Pages/Vendor/AddCategory";
// import Workbench from "./Pages/Products/SEEQ/workbench";
// import Organizer from "./Pages/Products/SEEQ/organizer";
// import Datalab from "./Pages/Products/SEEQ/datalab";
// import Cortex from "./Pages/Products/SEEQ/cortex";
// import Archietect from "./Pages/Products/SEEQ/archietect";
// import Professional from "./Pages/Products/SEEQ/professional";
// import Suite from "./Pages/Products/CRM/suite";
// import Assure from "./Pages/Products/CRM/assure";
// import Xpi from "./Pages/Products/Magic/xpi";
// import Xpa from "./Pages/Products/Magic/xpa";
// import Aevai from "./Pages/Products/aevai.js";
import Productslist from "./Pages/Products/ProductsLists";
import PrivacyPolicy from "./Pages/ContactUs/privacypolicy";
import ShippingPolicy from "./Pages/ContactUs/ShippingPolicy";
import RefundPolicy from "./Pages/ContactUs/RefundCancellation";
import SingleProduct from "./Pages/Products/SingleProducts";
import ResetPass from "./Pages/Vendor/ResetPass";
import OtpForm from "./Pages/Vendor/OtpForm";
import ChangePassword from "./Pages/Vendor/ChangePassword";
import DetailedProduct from "./Pages/Vendor/DetailedProduct";
import CustomerOtp from "./Pages/Customer/otp";
import ResetBuyerPassword from "./Pages/Customer/resetPassword";
import ChangeCPassword from "./Pages/Customer/ChangeCPassword";
// import VendorProductList from "./Pages/Vendor/VendorProductsList";
import ApplyForm from "./Pages/Vendor/ApplyForm";
import ViewProposalList from "./Pages/Customer/ViewProposalList";
import CategorySidebar from "./Pages/Vendor/CategorySidebar";
import Invoice from "./Pages/Customer/Invoice";
import VendorViewMilestone from "./Pages/Vendor/VendorViewMilestone";
import ViewCustomerMilestone from "./Pages/Customer/ViewCustomerMilestone";
import Receipt from "./Pages/Customer/Receipt";
import { ToastContainer } from "react-toastify";
import Checkout from "./Pages/Customer/checkout";
import HomeNew from "./Pages/Home/HomeNew";
import FinalProject from "./Pages/Customer/Finaleproject";
import CMilestone from "./Pages/Customer/CMilestone";
import CMarkascomplete from "./Pages/Customer/CMarkascomplete";
import CStartmilestone from "./Pages/Customer/CStartmilestone";
import FinalProposal from "./Pages/Vendor/FinalProposal";
import SingleProjectPage from "./Pages/Vendor/SingleProjectPage";
import ViewProposalListDetails from "./Pages/Customer/ViewProposalListDetails";
import UploadInvoice from "./Pages/Vendor/UploadInvoice";
import FinalProjectPage from "./Pages/Customer/FinalProjectPage";
import Responsive from "./Pages/Customer/responsive";
// import Viewpro from "./Pages/Customer/viewpro";
import EditMilestone from "./Pages/Customer/EditMilestone";
import VendorViewProjectList from "./Pages/Vendor/Vendorviewprojectlist";
import Vendorfinalproposal from "./Pages/Vendor/Vendorfinalproposal";
import ViewVendorMilestone from "./Pages/Vendor/ViewVendorMilestone";
import Milestone from "./Pages/Vendor/Milestone";
import AgencyProfile from "./Pages/Vendor/vendorProfile/AgencyProfile";
import AgencyProfileEdit from "./Pages/Vendor/vendorProfile/AgencyProfileEdit";
import ServicesandPreference from "./Pages/Vendor/vendorProfile/ServicesandPreference";
import ServicesandPreferenceEdit from "./Pages/Vendor/vendorProfile/ServicesandPreferenceEdit";
import Portfolio from "./Pages/Vendor/vendorProfile/Portfolio";
import PortfolioEdit from "./Pages/Vendor/vendorProfile/PortfolioEdit";
import Subscription from "./Pages/Vendor/vendorProfile/Subscription";
import CompanyDocuments from "./Pages/Vendor/vendorProfile/CompanyDocuments";
import CompanyDocumentsEdit from "./Pages/Vendor/vendorProfile/CompanyDocumentsEdit";
import CustomerReviews from "./Pages/Vendor/vendorProfile/customerReviews";
import Partner from "./Pages/Guidelines/BecomePartner";
import BecomeCustomer from "./Pages/Guidelines/BecomeCustomer";
import Proposal from "./Pages/Guidelines/SubmitProposal";
import Getproject from "./Pages/Guidelines/GetProjects";
import HowtoAddProducts from "./Pages/Guidelines/addProducts";
import BankDetails from "./Pages/Vendor/vendorProfile/bankDetails";
import BankDetailsEdit from "./Pages/Vendor/vendorProfile/bankDetailsEdit";
import ConqtUniversity from "./Pages/Guidelines/conqtUniversity";
import TransactionsList from "./Pages/Customer/profile/customerTransaction";
import CustomerProposalList from "./Pages/Customer/CustomerProposalList";
import CustomerMilestone from "./Pages/Customer/CustomerMilestone";
import CustomerInvoice from "./Pages/Customer/CustomerInvoice";
import BuyCustomerDetails from "./Pages/Vendor/BuyCustomerDetails";
import CustomerContact from "./Pages/Vendor/CustomerContact";
// import Stripe from "./Pages/Vendor/Stripe";
// import PaymentButton from "./Pages/Vendor/PaymentButton";
// import Element from "./Pages/Vendor/Element";
// import Practice from "./Pages/Vendor/Practice";
import BuyCustomerDetailsStripe from "./Pages/Vendor/BuyCustomerDetailsStripe";
import AddNewProducts from "./Pages/Vendor/AddNewProducts/AddNewProducts";
// import BuyCustomerDetailsStripe from "./Pages/Vendor/BuyCustomerDetailsStripe";
import NewBusyCustomerDetailsBeforePayment from "./Pages/Vendor/NewBuyCustomerDetails/NBCDBP";
import NewTermsAndConditions from "./Pages/Vendor/NewBuyCustomerDetails/TermaAndConditions";
import VendorProductList from "./Pages/Vendor/ProductList/VendorProductList";
import VendorEditProduct from "./Pages/Vendor/EditProduct/VendorEditProduct";
import CustomerProductReview from "./Pages/Customer/profile/CustomerProductReviews";
import DraftProject from "./Pages/Customer/DraftProject/DraftTable";
import NewAddProject from "./Pages/Customer/NewAddProject";

export default function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        {/**Public Routes**/}
        <Route path="/about" element={<Home />} />
        <Route path="/new-add-project" element={<NewAddProject />} />
        {/* <Route path="/home" element={< />} />
         <Route path="/about" element={<About />} /> */}
        <Route path="/conqt-university" element={<ConqtUniversity />} />
        <Route path="/how-to-submit-a-proposal" element={<Proposal />} />
        <Route path="/how-to-get-a-project" element={<Getproject />} />
        <Route path="/how-to-become-a-partner" element={<Partner />} />
        <Route path="/how-to-become-a-customer" element={<BecomeCustomer />} />
        <Route path="/how-to-add-products" element={<HowtoAddProducts />} />
        {/* <Route path="ProposalList" element={<ProposalList />} />
        <Route path="VendorList" element={<VendorList />} />
        <Route path="ViewProposal" element={<ViewProposal />} />
        <Route path="ViewVendor" element={<ViewVendor />} /> 
         <Route path="office-migration-service" element={<Migration />} />
        */}

        <Route path="login" element={<Login />} />
        <Route path="viewMilestone" element={<viewMilestone />} />
        <Route path="register" element={<Register />} />
        <Route path="cregister" element={<CustRegister />} />
        <Route path="customer-registration" element={<CustomerRegister />} />
        <Route path="vendor-registration" element={<VendorRegister />} />
        <Route path="about" element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="project" element={<Project />} />
        <Route path="single-project" element={<SingleProject />} />
        <Route path="/" element={<Login />} />

        <Route path="ProductPage" element={<ProductPage />} />
        {/* <Route path="Workspace_bussiness" element={<WorkspaceBussiness />} />
        <Route path="Workspace_enterprise" element={<WorkspaceEnterprise />} /> 
         <Route path="Workspace" element={<Workspace />} />
         <Route path="Gmail" element={<Gmail />} />
         <Route path="Meet" element={<Meet />} />
         <Route path="Mscone" element={<Mscone />} />
         <Route path="Msccosim" element={<MSCcosim />} />
         <Route path="Mscapex" element={<MSCapex />} />
         <Route path="Odyssee" element={<Odyssee />} />
         <Route path="CT" element={<CT />} />
         <Route path="Digimat" element={<Digimat />} />
         <Route path="erp" element={<Erp />} />
         <Route path="sap" element={<Sap />} />
         <Route path="dynamics" element={<Dynamics />} />
         <Route path="oracle" element={<Oracle />} />
         <Route path="netsuite" element={<Netsuite />} />
       <Route path="actran" element={<Actran />} />

        <Route path="Products" element={<Products />} /> 
         <Route path="workspace" element={<Workspace />} />
         <Route path="office-migration-service" element={<Migration />} />
         <Route path="productpage" element={<ProductPage />} />
         <Route path="adams" element={<Adams />} />
         <Route path="category" element={<Category />} />
         <Route path="fatigue" element={<Fatigue />} />
         <Route path="cradle" element={<Cradle />} />
         <Route path="easy5" element={<Easy5 />} />
         <Route path="simufact" element={<Simufact />} />
         <Route path="marc" element={<Marc />} />
         <Route path="virtual" element={<Virtual />} />
         <Route path="dytran" element={<Dytran />} />
         <Route path="nastran" element={<Nastran />} />
         <Route path="patran" element={<Patran />} />
         <Route path="material" element={<Material />} />
         <Route path="simmanager" element={<Simmanager />} />
         <Route path="workbench" element={<Workbench />} />
         <Route path="organizer" element={<Organizer />} />
         <Route path="datalab" element={<Datalab />} />
         <Route path="cortex" element={<Cortex />} />
         <Route path="archietect" element={<Archietect />} />
         <Route path="professional" element={<Professional />} />
         <Route path="suite" element={<Suite />} />
         <Route path="assure" element={<Assure />} />
         <Route path="xpi" element={<Xpi />} />
         <Route path="xpa" element={<Xpa />} />
         <Route path="aevai" element={<Aevai />} />
         <Route path="terms-and-conditions" element={<TermsConditions />} /> */}
        <Route path="terms-and-conditions" element={<TermsConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="Vendor-View-Milestone" element={<VendorViewMilestone />} />
        <Route path="ViewVendorMilestone" element={<ViewVendorMilestone />} />
        {/*<Route
           path="google-workspace-business"
           element={<WorkspaceBussiness />}
         />
         <Route
           path="google-workspace-enterprises"
           element={<WorkspaceEnterprise />}
         />
         <Route path="gmail-for-business" element={<Gmail />} />
         <Route path="google-meet" element={<Meet />} />
              <Route
           path="microsoft-office-enterprise"
           element={<MicrosoftEnterprise />}
         />
         <Route path="microsoft-teams" element={<MicrosoftTeams />} />
         <Route
           path="microsoft-office-365-for-business"
           element={<MicrosoftBusiness />}
         />
         <Route path="product-list" element={<Productslist />} />
         <Route path="/:product_id/*" element={<SingleProduct />} />
         <Route
           path="category/:category_id/:category_name/*"
           element={<DetailedProduct />}
         />
         <Route
           path="category/:category_id/:category_name/*"
           element={<CategorySidebar />}
         />
         <Route path="insert-category" element={<InsertCategory />} />
         */}
        <Route
          path="ViewVendorMilestone/:proposal_id/*"
          element={<ViewVendorMilestone />}
        />
        <Route path="otp-verification" element={<OtpForm />} />
        <Route path="change-password" element={<ChangePassword />} />
        <Route path="reset-password" element={<ResetPass />} />

        <Route path="shipping-policy" element={<ShippingPolicy />} />
        <Route path="reset-buyer-otp" element={<CustomerOtp />} />
        <Route path="reset-buyer-password" element={<ResetBuyerPassword />} />
        <Route path="change-buyer-password" element={<ChangeCPassword />} />
        {/**Private Routes**/}
        <Route path="/" element={<ProtectedRoutes />}>
          <Route path="customer-dashboard" element={<Customer />} />
          {/* <Route path="vendor-dashboard" element={<Vendor />} /> */}
          <Route path="profile" element={<CustomerProfile />} />
          <Route path="profile-edit" element={<CustomerProfileEdit />} />
          <Route path="company-profile" element={<CompanyProfile />} />
          <Route path="company-profile/edit" element={<CompanyProfileEdit />} />
          <Route path="customer-documents" element={<CustomerDocuments />} />
          <Route
            path="customer-documents/add"
            element={<CustomerDocumentsAdd />}
          />
          <Route
            path="customer-transaction-list"
            element={<TransactionsList />}
          />
          <Route path="profile-edit" element={<CustomerProfileEdit />} />
          <Route path="projectlist" element={<Projectlist />} />
          <Route
            path="customer-proposal-list"
            element={<CustomerProposalList />}
          />
          <Route
            path="customer-milestone-list"
            element={<CustomerMilestone />}
          />
          <Route path="customer-invoice" element={<CustomerInvoice />} />
          <Route path="single-projects/" element={<SingleProjects />} />
          <Route path="add-projects" element={<AddProject />} />
          <Route path="edit-projects/:id" element={<EditProject />} />
          <Route path="edit-products/:product_id" element={<EditProduct />} />
          <Route path="vendor-response" element={<VendorResponse />} />
          <Route path="vendor-project-list" element={<VendorProjectList />} />
          <Route
            path="vendor-view-project-list"
            element={<VendorViewProjectList />}
          />
          <Route path="payment" element={<Payment />} />
          <Route path="CMilestone" element={<CMilestone />} />
          <Route path="CMilestone/:proposal_id/*" element={<CMilestone />} />
          <Route path="CMarkascomplete" element={<CMarkascomplete />} />
          <Route
            path="CMarkascomplete/:milestone_id/*"
            element={<CMarkascomplete />}
          />
          <Route path="CStartmilestone" element={<CStartmilestone />} />
          <Route
            path="CStartmilestone/:milestone_id/*"
            element={<CStartmilestone />}
          />

          <Route
            path="Vendor-Project-Apply-List"
            element={<VendorProjectApplyList />}
          />
          <Route path="apply-form" element={<ApplyForm />} />
          <Route path="vendor-profile" element={<VendorProfile />} />
          <Route path="vendor-profile/edit" element={<VendorProfileEdit />} />
          <Route path="add-products" element={<AddProducts />} />
          <Route path="add-services" element={<AddServices />} />
          {/* <Route path="insert-products" element={<InsertProducts />} /> */}
          <Route path="add-new-products" element={<AddNewProducts />} />
          {/* <Route path="vendor-product-lists" element={<VendorProductList />} /> */}
          <Route
            path="Vendor-final-proposal"
            element={<Vendorfinalproposal />}
          />
          <Route path="edit-product" element={<VendorEditProduct />} />
          <Route path="vendor-product-lists" element={<VendorProductList />} />
          <Route path="UploadInvoice" element={<UploadInvoice />} />
          <Route path="Milestone" element={<Milestone />} />
          {/* <Route path="GoTriangleLeft" element={<GoTriangleLeft />} /> */}

          <Route
            path="UploadInvoice/:proposal_id/:milestone_id*"
            element={<UploadInvoice />}
          />
          <Route path="Milestone/:proposal_id*" element={<Milestone />} />
          <Route
            path="projectlist/:announcement_id/*"
            element={<ViewProposalList />}
          />
          <Route
            path="viewMilestone/:proposal_id/*"
            element={<ViewProposalList />}
          />
          <Route
            path="vendor-view-project-list/:proposal_id*"
            element={<VendorViewProjectList />}
          />
          <Route
            path="Vendor-final-proposal/:proposal_id*"
            element={<Vendorfinalproposal />}
          />
          <Route
            path="projectlist/view/:announcement_id/:proposal_id*"
            element={<ViewProposalListDetails />}
          />
          <Route
            path="vendor-project-list/:announcement_id/:project_name*"
            element={<ApplyForm />}
          />
          <Route
            path="vendor-projects/:project_name/:index/:announcement_id/*"
            element={<SingleProjectPage />}
          />
          <Route path="invoice" element={<Invoice />} />
          <Route path="vendor-milestone" element={<ViewVendorMilestone />} />
          <Route
            path="vendor-projects/:project_name/:index/:announcement_id/*"
            element={<SingleProjectPage />}
          />
          <Route
            path="checkout/:proposal_id/:index/:milestone_id*"
            element={<Checkout />}
          />
          <Route
            path="Vendor-View-Milestone/:proposal_id/:milestone_id*"
            element={<VendorViewMilestone />}
          />
          <Route
            path="ViewCustomermilestone/:proposal_id*"
            element={<ViewCustomerMilestone />}
          />
          <Route path="checkout" element={<Checkout />} />

          <Route
            path="edit/:id/:product_name/:product_id*"
            element={<EditProduct />}
          />
          <Route
            path="receipt/:milestone_id/:index/:proposal_id*"
            element={<Receipt />}
          />

          <Route
            path="edit-milestone/:proposal_id*"
            element={<EditMilestone />}
          />
          <Route path="final-project-list" element={<FinalProject />} />
          <Route
            path="final-project-list/:proposal_id*"
            element={<FinalProjectPage />}
          />
          <Route path="final-proposal-list" element={<FinalProposal />} />
          <Route
            path="upload-invoice/:milestone_id/:index/:proposal_id*"
            element={<UploadInvoice />}
          />
          <Route path="UploadInvoice" element={<UploadInvoice />} />
          <Route path="agency-profile" element={<AgencyProfile />} />
          <Route path="agency-profile/edit" element={<AgencyProfileEdit />} />
          <Route
            path="services-and-preference"
            element={<ServicesandPreference />}
          />
          <Route
            path="services-and-preference/edit"
            element={<ServicesandPreferenceEdit />}
          />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="portfolio/edit" element={<PortfolioEdit />} />
          <Route path="subscription" element={<Subscription />} />
          <Route path="documents" element={<CompanyDocuments />} />
          <Route path="documents/edit" element={<CompanyDocumentsEdit />} />

          <Route path="bank-details" element={<BankDetails />} />
          <Route path="bank-details/edit" element={<BankDetailsEdit />} />
          <Route
            path="customer-bank-details"
            element={<CustomerBankDetails />}
          />
          <Route
            path="customer-bank-details/add"
            element={<CustomerAddBankDetails />}
          />
          <Route path="customer-reviews" element={<CustomerReviews />} />
          <Route
            path="customer-reviews-list"
            element={<CustomerReviewsList />}
          />
          <Route
            path="customer-product-reviews"
            element={<CustomerProductReview />}
          />
          <Route path="customer-reviews/add" element={<AddCustomerReview />} />
        </Route>
        {/**Catch All**/}
        <Route path="*" element={<Missing />} />
        <Route path="responsive" element={<Responsive />} />
        <Route
          path="buy-customer-details/:announcement_id"
          element={<BuyCustomerDetails />}
        />
        <Route path="customer-contact" element={<CustomerContact />} />
        {/* <Route path="stripe" element={<Stripe />} />
        <Route path="payment-button" element={<PaymentButton />} />
        <Route path="element" element={<Element />} />
        <Route path="pay" element={<Practice />} /> */}
        <Route
          path="buy-customer-details-page/:announcement_id*"
          element={<BuyCustomerDetailsStripe />}
        />
        <Route
          path="new-buy-customer-details-page/:announcement_id"
          element={<NewBusyCustomerDetailsBeforePayment />}
        />
        <Route
          path="new-terms-and-conditions"
          element={<NewTermsAndConditions />}
        />
        <Route path="draft-table" element={<DraftProject />} />
        {/* <Route path="bcd" element={<Bcd />} /> */}
        {/* <Route path="viewpro" element={<Viewpro />} />
        <Route path="MyProfile" element={<MyProfile />} />
      
      
        
        <Route path="Subscription" element={<Subscription />} />
        <Route path="subscription2" element={<Subscription2 />} />
         <Route path="subscription3" element={<Subscription3 />} /> 
        <Route path="subscription4" element={<Subscription4 />} />
        <Route path="subscription5" element={<Subscription5 />} />
        <Route path="subscription6" element={<Subscription6 />} />

        <Route path="Settings" element={<Settings />} />
  
        <Route path="otpform" element={<Otpform />} />
        <Route path="changepass" element={<Changepass />} />
        <Route path="newpass" element={<Newpass />} />
        <Route path="passchanged" element={<Passchanged />} />
        <Route path="profilepic" element={<Profilepic />} />
       <Route path="test" element={<Test />} /> 
        <Route path="MyProfile/*" element={<MyProfile />} />
        // <Route path="AgencyProfile/*" element={<AgencyProfile />} />
        <Route
          path="ServicesandPreference/*"
          element={<ServicesandPreference />}
        />
        <Route path="Portfolio/*" element={<Portfolio />} />
        <Route path="Subscription/*" element={<Subscription />} />
        <Route path="Settings*" element={<Settings />} />
        <Route path="CompanyDocuments/*" element={<CompanyDocuments />} />
        <Route path="otpform/*" element={<otpform />} />
       
        <Route path="test/*" element={<Test />} /> */}
      </Routes>
    </BrowserRouter>
  );
}
