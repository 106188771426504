import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../Api/api";

export const getProductListTableData = createAsyncThunk("ProductList", () => {
  const userToken = localStorage.getItem("token");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${userToken}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${BaseUrl}/product/Vendor-Product-List`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result.data;
    })
    .catch((error) => console.log("error", error));
});

export const myReducer = createSlice({
  name: "ProductList",
  initialState: {
    loading: false,
    selectedProductId: "",
    productList: [],
    selectedSingleProductData: [],
  },
  reducers: {
    UpdateSelectedProductId: (state, action) => {
      state.selectedProductId = action.payload;
    },
    updateSelectedSingleProductData: (state, action) => {
      state.selectedSingleProductData = action.payload;
    },
  },
  extraReducers: {
    [getProductListTableData.pending]: (state) => {
      state.loading = true;
    },
    [getProductListTableData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.productList = payload;
    },
  },
});

export const { UpdateSelectedProductId, updateSelectedSingleProductData } =
  myReducer.actions;

export default myReducer.reducer;
