import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useSelector } from "react-redux";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import "../Customer/Customer.css";
import MyProfile from "./profile/myProfile";
import PageLoader from "../../components/PageLoader/PageLoader";


const Profile = () => {
  const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
  const [value, setValue] = useState();
  const [formValues, setFormValues] = useState();
  const [statusBarvalue, setStatusBar] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const loaderState =(state)=>{
setPageLoading(state)
  }

  //console.log(user.user_info);
  return (
    <>
      {pageLoading && (
       <PageLoader/>
      )}

      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar isShow={true} value={statusBarvalue} />
          <main className="maincontent">
            <MyProfile
              onChangeStatusValue={(value) => {
                setStatusBar(value);
              }}
                loaderState={loaderState}
            />
          </main>
        </div>
      </div>
    </>
  );
};

export default Profile;
