import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Topbar from './Topbar';
import './buyCustomerDetails.css';
import Lock from '../../assets/images/Lock.svg';
import right from '../../assets/images/right.svg';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { BaseUrl,RazorPaymentkey} from '../../Api/api';
import { toast } from "react-toastify";
import axios from 'axios';
import Proposal from "./Proposal";
import Tc from "../../assets/images/T&C.svg";
import SuccessModal from '../../components/Modal/BCDS/SuccessModal';
import FailureModal from '../../components/Modal/BCDS/FailModal';

const BuyCustomerDetailsStripe = () => {

        const navigate = useNavigate();
        let vendorInfo = JSON.parse(localStorage.getItem("userinfo"));
        const [popup, setPopup] = useState(false);
        const [projectApplyUrl, setProjectApplyUrl] = useState("");
        const [openModal, setOpenModal] = useState(false);
        const [show, setShow] = useState(false);
        const [showModal, setShowModal] = useState(false);

        const handleApply = () => {
                navigate('/insert-products');
        }

        const onHide = () => {
                setShow(false);
        }

        const handleClose = () => {
                setShowModal(false);
        }


        const initialFormData = {
                currency: "USD",
                transactions_id: "",
                amount: 10,
                transaction_status: "COMPLETED",
                project_id: "",
        };

        const { announcement_id } = useParams();
        console.log("id==>", announcement_id);

        console.log("All url", useParams());



        const [filterData, setFilterData] = useState([])
        const [isShow, setIsShow] = useState(true);
        const [formValues, setFormValues] = useState(initialFormData)
        const token = localStorage.getItem('token');
        const [customerData, setCustomerData] = useState([]);
        const getData = localStorage.getItem('results');
        const data = JSON.parse(getData);
        console.log("data==>", data);
        console.log("token", token)

        useEffect(() => {
                if (data && announcement_id) {
                        const filterData = data.filter((item) => {
                                return (item.announcement_id == announcement_id)
                        });
                        setFilterData(filterData);
                        window.scrollTo(0, 0);
                }
        }, [announcement_id])

        useEffect(() => {
                axios
                        .get(`${BaseUrl}/vendordashboard/Get-Customer-By-Project?announcement_id=${announcement_id}`, {
                                headers: { Authorization: `Bearer ${token}` },
                        })
                        .then((res) => {
                                console.log("customer data==>", res);
                                setCustomerData(res.data.data[0] ? res.data.data[0] : "");
                        })
                        .catch((err) => {
                                console.log("err==>", err);
                        });
        }, [announcement_id])


        console.log("filterData==>", filterData);

        const announcementid = (announcement_id, project_name) => {
                localStorage.setItem("vendorproject", announcement_id);

                console.log("Vendor Info", vendorInfo);

                if (
                        (vendorInfo.activation_status == 0 ||
                                vendorInfo.activation_status == 1) &&
                        vendorInfo.available_point < 100
                ) {
                        toast.error("To Apply Project, You need to buy coins.");
                        window.location = "/subscription";
                        setOpenModal(false);
                } else {
                        console.log("set Project Appply URL ", popup);
                        setProjectApplyUrl(`vendor-project-list/${announcement_id}/${project_name}`);
                        setPopup(true);
                        // setTimeout(
                        //   () =>
                        //     (window.location = `vendor-project-list/${announcement_id}/${project_name}`),
                        //   10000
                        // );
                }
        };

        const loadScript = (src) => {
                return new Promise((resovle) => {
                        const script = document.createElement("script");
                        script.src = src;

                        script.onload = () => {
                                resovle(true);
                        };

                        script.onerror = () => {
                                resovle(false);
                        };

                        document.body.appendChild(script);
                });
        };

        const displayRazorpay = async () => {
                console.log(formValues.currency);
                const res = await loadScript(
                        "https://checkout.razorpay.com/v1/checkout.js"
                );

                if (!res) {
                        alert("You are offline... Failed to load Razorpay SDK");
                        return;
                }
                const options = {
                        key: RazorPaymentkey,
                        currency: formValues.currency,
                        amount: formValues.amount * 100,
                        image:
                                "https://mern-blog-akky.herokuapp.com/static/media/logo.8c649bfa.png",

                        handler: function (response) {
                                console.log(response);

                                let responseData = {
                                        transactions_id: response.razorpay_payment_id,
                                        currency: "THB",
                                        amount: "200",
                                        transaction_status: "COMPLETED",
                                        project_id: announcement_id
                                };
                                console.log(responseData);

                                //POST API For Transactions

                                try {
                                        var myHeaders = new Headers();
                                        myHeaders.append(
                                                "Authorization",
                                                `Bearer ${localStorage.getItem("token")}`
                                        );
                                        myHeaders.append("Content-Type", "application/json");

                                        var raw = JSON.stringify(responseData);

                                        var requestOptions = {
                                                method: "POST",
                                                headers: myHeaders,
                                                body: raw,
                                                redirect: "follow",
                                        };

                                        fetch(
                                                `${BaseUrl}/vendordashboard/Payment-for-Customer-Details`,
                                                requestOptions
                                        )
                                                .then((response) => response.text())
                                                .then((result) => {
                                                        result = JSON.parse(result);
                                                        toast(response.razorpay_payment_id);
                                                        toast("Payment Successfully");
                                                        window.location.reload();
                                                        window.scrollTo(0.0)
                                                        // console.log(result,"milestone payment details");
                                                        // window.location = `/receipt/${formValues.milestone_id}/${ind}/${id}`;
                                                })
                                                .catch((error) => console.log("error", error));
                                } catch { }

                                //reload the page
                        },
                };

                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
        };

        const srtipeAPI = async () => {
                const data = {

                        project_id: announcement_id,
                        currency: "USD",
                        amount: "10",
                        transactions_id: "123"

                }

                console.log("payment data".data)
                const token = localStorage.getItem('token');
                axios
                        .post(`${BaseUrl}/vendordashboard/Payment-for-Customer-Details-New`, data, {
                                headers: { Authorization: `Bearer ${token}` },
                        })
                        .then((res) => {
                                console.log("payment data", res.data.data.url);
                                window.location = res.data.data.url;
                        })
                        .catch((err) => {
                                console.log("err==>", err);
                        });
        }

        const useQuery = () => new URLSearchParams(useLocation().search);
        const query = useQuery();
        const paymentStatus = query.get('transaction_status');
        useEffect(() => {
                if (paymentStatus == "success") {
                        console.log("success");
                        setShow(true)                      
                }
                else if (paymentStatus == "failed") {
                        setShowModal(true)
                }
        }, [paymentStatus])

        console.log("status", paymentStatus);
        console.log("hightlight  ", announcement_id)
        return (

                <div className="dashboard-container">
                       
                        <Sidebar />
                        <div className="main-dashboard-container">
                                <Topbar isShow={false} />
                                {openModal && <Proposal closeModal={setOpenModal} />}
                                {<SuccessModal open={show} handleClose={onHide}  announcement_id={announcement_id}/>}
                                {<FailureModal open={showModal} handleClose={handleClose}  announcement_id={announcement_id}/>}
                                {/* {<OtpVerificationModal  open={show} handleClose={onHide}/>} */}
                                <div className="customer-details-container">
                                        <div className='container'>
                                                <h5 className='cus-sub-header'>Company Details</h5>
                                                <hr style={{ marginTop: "-1px", border: "1px solid #D9D9D9" }} />

                                                <div className='d-flex align-items-center mbl-res-bcd'>
                                                        <label htmlFor="" className='label-name-cus'>Company Name <span className='px-2'>:</span></label>
                                                        <div type="text" className='ans'>
                                                                {customerData.company_name}
                                                        </div>
                                                </div>
                                                <div className='d-flex align-items-center py-1 mbl-res-bcd'>
                                                        <label htmlFor="" className='label-name-cus'>Address <span className='px-2'>:</span></label>
                                                        <div type="text" className='ans'>
                                                                {customerData.address}
                                                        </div>
                                                </div>
                                                <div className='d-flex align-items-center mbl-res-bcd'>
                                                        <label htmlFor="" className='label-name-cus'>Country <span className='px-2'>:</span></label>
                                                        <div type="text" className='ans'>
                                                                {customerData.country}
                                                        </div>
                                                </div>
                                                <div className='d-flex align-items-center pt-1 mbl-res-bcd'>
                                                        <label htmlFor="" className='label-name-cus'>Contact Number <span className='px-2'>:</span></label>
                                                        <div type="text" className='ans'>
                                                                {customerData.company_phone}
                                                        </div>
                                                </div>
                                        </div>
                                        <div className='pt-2 pb-2 container'>
                                                <h5 className='cus-sub-header'>Contact Details</h5>
                                                <hr style={{ marginTop: "-1px", border: "1px solid #D9D9D9" }} />
                                                <div className='d-flex align-items-center mbl-res-bcd'>
                                                        <label htmlFor="" className='label-name-cus'>Contact Person <span className='px-2'>:</span></label>
                                                        <div type="text" className='ans'>
                                                                {customerData.name}
                                                        </div>
                                                </div>
                                                <div className='d-flex align-items-center py-1 mbl-res-bcd '>
                                                        <label htmlFor="" className='label-name-cus'> Email  <span className='px-2'>:</span></label>
                                                        <div type="text" className='ans'>
                                                                {customerData.contact_person_email == null ? "" : customerData.contact_person_email}
                                                        </div>
                                                </div>
                                                <div className='d-flex align-items-center  mbl-res-bcd '>
                                                        <label htmlFor="" className='label-name-cus'> Phone Number <span className='px-2'>:</span></label>
                                                        <div type="text" className='ans'>
                                                                {customerData.phone_number == null ? "" : customerData.phone_number}
                                                        </div>
                                                </div>
                                                <div className='d-flex align-items-center py-1 mbl-res-bcd '>
                                                        <label htmlFor="" className='label-name-cus'> Department <span className='px-2'>:</span></label>
                                                        <div type="text" className='ans'>
                                                                {customerData.department_name == null ? "" : customerData.department_name}
                                                        </div>
                                                </div>
                                                <div className='d-flex align-items-center  mbl-res-bcd '>
                                                        <label htmlFor="" className='label-name-cus'> Position <span className='px-2'>:</span></label>
                                                        <div type="text" className='ans'>
                                                                {customerData.position == null ? "" : customerData.position}
                                                        </div>
                                                </div>
                                        </div>
                                </div>

                                {Object.keys(customerData).length == 0
                                        && (<div className='popup-det'>
                                                <div className='popup-det-c'>
                                                        <img src={Lock} alt="" style={{ width: "70px" }} />
                                                        <h5 className='py-3'>Unlock Customer Details</h5>
                                                        {/* <button className='unlock-btn my-3' onClick={displayRazorpay}>Unlock $10</button> */}
                                                        {/* <h5 className='pb-3'>unlock following</h5> */}
                                                        {/* <div>
                                                                <div className='d-flex '>
                                                                        <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                        <p className='px-2'>Company Name</p>
                                                                </div>
                                                                <div className='d-flex '>
                                                                        <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                        <p className='px-2'>Address</p>
                                                                </div>
                                                                <div className='d-flex '>
                                                                        <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                        <p className='px-2'>Country</p>
                                                                </div>
                                                                <div className='d-flex '>
                                                                        <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                        <p className='px-2'>Contact Number</p>
                                                                </div>
                                                                <div className='d-flex '>
                                                                        <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                        <p className='px-2'>Contact Person</p>
                                                                </div>
                                                                <div className='d-flex '>
                                                                        <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                        <p className='px-2'>Email</p>
                                                                </div>
                                                                <div className='d-flex '>
                                                                        <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                        <p className='px-2'>Phone Number</p>
                                                                </div>
                                                                <div className='d-flex '>
                                                                        <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                        <p className='px-2'>Department</p>
                                                                </div>
                                                                <div className='d-flex '>
                                                                        <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                        <p className='px-2'>Position</p>
                                                                </div>
                                                        </div> */}
                                                        <div className='d-flex '>
                                                                <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                <p className='px-2 unlock-text'>Acquire the customer's contact details Phone number, email address.</p>
                                                        </div>
                                                        <div className='d-flex '>
                                                                <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                <p className='px-2 unlock-text'>Relevant company information, company name, department etc.</p>
                                                        </div>
                                                        <div className='d-flex ' style={{ width: "100%" }}>
                                                                <img src={right} alt="" style={{ width: "20px", height: "20px" }} />
                                                                <p className='px-2 unlock-text'>Initiate direct communication with the customer.</p>
                                                        </div>
                                                        {/* <div className='unlock-text'>
                                                                Acquire the customer's contact details, including their phone number,
                                                                email address, job title, and any other relevant information, and proceed to negotiate a deal.
                                                        </div> */}
                                                        <img src={Tc} alt="" style={{ width: "68px", paddingTop: "10px" }} />
                                                        <button className='unlock-btn my-3' onClick={srtipeAPI}>Unlock $10</button>
                                                </div>
                                        </div>)}


                                <div className="customer-details-container">

                                        {filterData.map((item) => {
                                                return (
                                                        <div className='container'>
                                                                <h5 className='cus-sub-header'>Project Details</h5>
                                                                <hr style={{ marginTop: "-1px", border: "1px solid #D9D9D9" }} />
                                                                <div className='d-flex align-items-center mbl-res-bcd'>
                                                                        <label htmlFor="" className='label-name-cus'>Project Name <span className='px-2'>:</span></label>
                                                                        <div type="text" className='ans'>
                                                                                {item.project_name}
                                                                        </div>
                                                                </div>
                                                                <div className='d-flex align-items-start py-2 mbl-res-bcd'>
                                                                        <label htmlFor="" className='label-name-cus'>Project Description <span className='px-2'>:</span></label>
                                                                        <div name="" className='des-bcd ans'>
                                                                                {parse(item.project_description)}
                                                                        </div>
                                                                </div>
                                                                {item.attachment_url == null ? "" :
                                                                        <div className='d-flex align-items-start mbl-res-bcd'>
                                                                                <label htmlFor="" className='label-name-cus '
                                                                                >Attachment <span className='px-2'>:</span></label>
                                                                                <div type="text" className='des-bcd ans'>
                                                                                        {item.attachment_url}
                                                                                </div>
                                                                        </div>}
                                                                {/* <div className='label-name-head py-3' style={{ fontSize: "14px" }}>Budget Details</div> */}
                                                                <div className='d-flex align-items-center mbl-res-bcd pt-1 pb-2'>
                                                                        <label htmlFor="" className='label-name-cus '>Amount <span className='px-2'>:</span></label>
                                                                        <div type="text" className='ans'>
                                                                                {item.project_budget}
                                                                        </div>
                                                                </div>
                                                        </div>
                                                )
                                        })}
                                </div>

                                <div className=' container d-flex align-items-center justify-content-end pb-5'>
                                        <div>
                                                {filterData[0] && filterData[0].vendor_id == vendorInfo.vendor_id ?
                                                        (
                                                                <>
                                                                        <button
                                                                                className="apply-btn"
                                                                                style={{ backgroundColor: "gray" }}
                                                                        >
                                                                                Already Applied
                                                                        </button>
                                                                </>

                                                        ) : (
                                                                <>
                                                                        <Link to={`/vendor-project-list/${filterData[0] && filterData[0].announcement_id}/${filterData[0] && filterData[0].project_name}`}>
                                                                                <button
                                                                                        className="apply-btn"
                                                                                        onClick={() => {
                                                                                                setOpenModal(true);
                                                                                                announcementid(
                                                                                                        filterData[0].announcement_id,
                                                                                                        filterData[0].project_name
                                                                                                );
                                                                                        }}
                                                                                >
                                                                                        Apply for project
                                                                                </button>
                                                                        </Link>
                                                                </>
                                                        )}
                                        </div>
                                </div>
                        </div>
                      </div>
        )
}

export default BuyCustomerDetailsStripe


