import React, { useState, useEffect } from "react";
import "./page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { BiFilterAlt } from "react-icons/bi";
import PaymentSubscription from "./subscriptionPayment";
import moment from "moment";
import { useDispatch } from "react-redux";
import CommonApi from "../../../Api/commonApi";
import { BaseUrl } from "../../../Api/api";
import PageLoader from "../../../components/PageLoader/PageLoader";

const Subscription = () => {
  const Token = localStorage.getItem("token");
  const toGetCurrencySymbol = localStorage.getItem("user");
  const currencySymbol = JSON.parse(toGetCurrencySymbol).user_info.symbol;
  const overAllProgressBar = localStorage.getItem("profile_percentage");
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [sortedArray, setSortedArray] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const [overAllProgressValue, setOverAllProgressValue] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);

  const handleSkip = (e) => {
    window.location = "/documents";
  };

  // const onStatusChange = (e) => {
  //   console.log(e, "selected status valus");
  //   if (e.includes("status")) {
  //     const result = arr.sort((status1, status2) => {
  //       return status2.status - status1.status;
  //     });
  //     console.log(result, "sortedArray");
  //     setSortedArray([...result]);
  //     console.log(sortedArray, "updated state");
  //     setShowDropdown(false);
  //   }
  // };

  const onFilterClick = () => {
    setShowDropdown(true);
  };
  const onClickView = () => {
    console.log(dateFrom, dateTo, "view button clicked");
    const result = transactionList.filter((e) => {
      return (
        moment(dateFrom) >= moment(e.created_at.split(" ")[0]) &&
        moment(dateTo) <= moment(e.created_at.split(" ")[0])
      );
    });
    setSortedArray([...result]);
  };
  const onChangeFrom = (e) => {
    setDateFrom(e.target.value);
  };
  const onChangeTo = (e) => {
    setDateTo(e.target.value);
  };

  const onClickSuccessOrFailure = (e, value) => {
    e.preventDefault();
    const output = transactionList.filter((e) => {
      return e.transaction_status === value;
    });
    setSortedArray([...output]);
  };
  useEffect(() => {
    setPageLoading(true);
    const Token = localStorage.getItem("token");
    try {
      var myHeaders = new Headers();
      myHeaders.append("authorization", `Bearer ${Token}`);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        `${BaseUrl}/vendordashboard/Get-Vendor-Subscription-txn-list`,
        requestOptions
      )
        .then((res) => res.json())
        .then((result) => {
          setSortedArray(result.data);
          setTransactionList(result.data);
          CommonApi.ProfileApi().then((vsdata) => {
            console.log("ApiRes", vsdata);
            if (vsdata.status === 200) {
              setOverAllProgressValue(vsdata.data.profile_complete_status);
            }
          });
          setPageLoading(false);
          console.log(result.data, "result");
        });
    } catch (err) {
      console.log(err, "error");
    }
  }, []);

  return (
    <> {pageLoading && (
      <PageLoader/>
     )}
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar isShow={true} value={overAllProgressValue} />
        <main className="maincontent">
          <div>
            <section>
              <div className="basic-info">
                <h3>Subscription</h3>
                {/* <h4>
                  Edit
                  <MdModeEdit />
                </h4> */}
              </div>
            </section>

            <PaymentSubscription />

            <section className="payment">
              <h3>Payment History</h3>
              <div className="history">
                <div className="historyDate">
                  <div className="dateFrom">
                    <p>From</p>
                    <input
                      type="date"
                      onChange={onChangeTo}
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                  <div className="dateFrom">
                    <p>To</p>
                    <input
                      type="date"
                      onChange={onChangeFrom}
                      max={new Date().toISOString().split("T")[0]}
                      min={dateTo}
                    />
                  </div>
                  <div className="mbl-view">
                    <button
                      className="from-to-date-button"
                      onClick={onClickView}
                    >
                      View
                    </button>
                  </div>
                </div>
                <span className="ions">
                  {/* <p onClick={onFilterClick}>Filter</p>
                  <BiFilterAlt />
                  {showDropdown && (
                    <Multiselect
                      options={["status"]}
                      onSelect={onStatusChange}
                      // showCheckbox={true}
                      isObject={false}
                    />
                  )} */}
                </span>
                <span className="filter">
                  <p>Status Filter</p>
                  <BiFilterAlt />
                  <div className="status-filter">
                    <p
                      className="success-failure"
                      onClick={(e) => onClickSuccessOrFailure(e, "COMPLETED")}
                    >
                      Success
                    </p>
                    <hr className="horizontal-line" />
                    <p
                      className="success-failure"
                      onClick={(e) => onClickSuccessOrFailure(e, "ERROR")}
                    >
                      Failure
                    </p>
                  </div>
                </span>
              </div>
            </section>

            <section className="payment-table">
              <table>
                <tr>
                  <th>Date</th>
                  <th>Package</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
                {sortedArray.map((value) => {
                  return (
                    <tr>
                      <td>{value.created_at}</td>
                      <td>{value.subscription_point} Coins</td>
                      <td>${value.transaction_amount}</td>

                      {
                        <td>
                          {value.transaction_status === "COMPLETED" ? (
                            <p className="payment-success">Success</p>
                          ) : (
                            <p className="payment-failed">Failure</p>
                          )}
                        </td>
                      }
                    </tr>
                  );
                })}
                {/* <tr>
                  <td>Sep 20, 2022</td>
                  <td>1000 Coins</td>
                  <td>$99</td>
                  <td className="colour">Successful</td>
                </tr>
                <tr>
                  <td>Sep 20, 2022</td>
                  <td>300 Coins</td>
                  <td>$49</td>
                  <td className="colour">Successful</td>
                </tr>
                <tr>
                  <td>Sep 20, 2022</td>
                  <td>300 Coins</td>
                  <td>$49</td>
                  <td className="color-failed">Failed</td>
                </tr>
                <tr>
                  <td>Sep 20, 2022</td>
                  <td>300 Coins</td>
                  <td>$49</td>
                  <td className="colour">Successful</td>
                </tr> */}
              </table>
            </section>
          </div>

          <div className="formbuton">
            {/* <Link to={`/AgencyProfile`}> */}
            <input
              type="submit"
              className="save-button"
              value="Skip"
              onClick={handleSkip}
            />
            <input
              type="submit"
              className="save-button"
              value="Next"
              onClick={handleSkip}
            />
            {/* </Link> */}

            {/* <Link to={`/AgencyProfile`}>
                <button className="save-button">Skip</button>
              </Link> */}
          </div>
        </main>
      </div>
    </div>
    </>
  );
};
export default Subscription;
