import React, { useState, useEffect } from "react";
import "./Customer.css";
import Sidebar from "./Sidebar";
import Layout from "../../components/Layout";
import { toast } from "react-toastify";
import { BaseUrl } from "../../Api/api.js";

const EditProject = (props) => {
  const userToken = localStorage.getItem("token");

  const initialFormData = {
    announcement_id: "35",
    project_name: "",
    project_description: "",
    country: "",
    city: "",
  };
  const [formValues, setFormValues] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = validate(formValues);

    if (Object.keys(error).length !== 0) {
      setFormErrors(error);
      console.log("Error Found");
      return false;
    }
    console.log("No Validation Error Found");
    // ... submit to API or something
    setIsSubmit(true);
    try {
      var myHeaders = new Headers();

      //myHeaders.append("Authorization", `Bearer ${userToken})`);
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(formValues);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/announcement/Announcement-Update`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log("result", result);
          if (result.status === 200) {
            setFormValues("");
            toast("Project Announcement Update successfully");
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    console.log(errors);
    return errors;
  };

  return (
    <Layout>
      <div className="main-container">
        <aside className="sidebar">
          <Sidebar />
        </aside>
        <main className="maincontent">
          <div>
            <h3 className="addproject">Edit Project </h3>

            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="Project-form"
            >
              <div className="form-group">
                <label>Project id</label>
                <input
                  type="text"
                  name="announcement_id"
                  placeholder="Project id"
                  value={formValues.announcement_id}
                  onChange={handleChange}
                />
              </div>
              <p className="err-message">{formErrors.announcement_id}</p>
              <div className="form-group">
                <label>Project Name</label>
                <input
                  type="text"
                  name="project_name"
                  value={formValues.project_name}
                  placeholder="Project Name"
                  onChange={handleChange}
                />
              </div>
              <p className="err-message">{formErrors.project_name}</p>

              <div className="form-group">
                <label>Project Description</label>
                <textarea
                  type="textarea"
                  name="project_description"
                  placeholder="Description"
                  value={formValues.project_description}
                  onChange={handleChange}
                ></textarea>
              </div>
              <p className="err-message">{formErrors.project_description}</p>
              <div className="form-group">
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={formValues.city}
                  placeholder="City"
                  onChange={handleChange}
                />
              </div>
              <p className="err-message">{formErrors.city}</p>
              <div className="form-group">
                <label>Country</label>
                <input
                  type="text"
                  name="country"
                  value={formValues.country}
                  placeholder="Country"
                  onChange={handleChange}
                />
              </div>
              <p className="err-message">{formErrors.country}</p>
              <br />
              <div className="form-group">
                <button type="submit" className="btn btn-primary">
                  <i className="far fa-paper-plane"></i>Submit Project
                </button>
              </div>
            </form>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default EditProject;
