import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BaseUrl } from "../../Api/api.js";
import { Link } from "react-router-dom";
import "./Customer.css";
import Popup from "reactjs-popup";
import "./OpenModel.css";
import { AiOutlineCloseSquare } from "react-icons/ai";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const EditMilestone = () => {
  const [data, setData] = useState([]);
  const ID = useParams().announcement_id;
  const P_ID = useParams().proposal_id;
  const ProjectCount = localStorage.getItem("ProjectCount");
  const userToken = localStorage.getItem("token");
  const announcementid = localStorage.getItem("announcement_id");

  const [formValues, setFormValues] = useState(initialFormData);
  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        announcement_id: announcementid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Get-Vendor-List-apply-Announcment`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setData(result.data);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const initialFormData = {
    milestone_description: null,
    user_milestone_percent: null,
    user_milestone_amount: null,
    milstone_timeline: null,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      initialFormData.milestone_description == null ||
      initialFormData.milstone_timeline == null ||
      initialFormData.user_milestone_amount == null ||
      initialFormData.user_milestone_percent == null
    ) {
      data.map((item) => {
        if (ID == item.announcement_id && P_ID == item.proposal_id)
          return item.milestonelist.map((key, index) => {
            initialFormData.milestone_description = key.milestone_description;
            initialFormData.milstone_timeline = key.milstone_timeline;
            initialFormData.user_milestone_amount = key.user_milestone_amount;
            initialFormData.user_milestone_percent = key.user_milestone_percent;
          });
      });
    }
  };
  console.log(initialFormData);
  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          <br />
          <div className="proposal-wrapper">
            <div className="proposal-form">
              <h2>Milestone Request Form</h2>

              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <div className="proposalform">
                  <div className="form-group">
                    <label>Project Milestone</label>
                    <div>
                      {(function (rows, i, len) {
                        while (++i < len) {
                          rows.push(
                            <div className="forminput" key={i}>
                              <div>
                                <select
                                  className="mpercent"
                                  name={i + 1}
                                  value={initialFormData.user_milestone_percent}
                                >
                                  <option>Select %</option>
                                  <option value="0.1">10%</option>
                                  <option value="0.2">20%</option>
                                  <option value="0.3">30%</option>
                                  <option value="0.4">40%</option>
                                  <option value="0.5">50%</option>
                                </select>
                              </div>
                              <div>
                                <input
                                  type="text"
                                  label="Milestone Amount"
                                  // name={i+1}
                                  disabled
                                  placeholder="400"
                                  // onChange={handleChangePri}
                                />
                              </div>
                              <div>
                                <input
                                  type="text"
                                  label="Milestone Amount"
                                  name={i + 1}
                                  placeholder="Milestone Description"
                                />
                              </div>
                              <div>
                                <input
                                  type="date"
                                  label="Milestone Timeline"
                                  name={i + 1}
                                  placeholder="timeline"
                                />
                              </div>
                            </div>
                          );
                        }

                        return rows;
                      })([], 0, ProjectCount)}
                    </div>
                  </div>
                  <p className="err-message">{}</p>
                </div>
                <br />
                <div>
                  <button className="btn">Send Request</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMilestone;
