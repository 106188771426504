import React, { useState, useEffect } from "react";
import "../../Vendor/vendorProfile/page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import Coins from "../../../assets/images/coins.svg";
import Starratings from "./starratings";
import { BaseUrl } from "../../../Api/api";
import moment from "moment";
import noreviews from "../../../assets/images/noreview.png";
import { Link } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader";

const CustomerReviews = () => {
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const userToken = localStorage.getItem("token");

  useEffect(() => {
    setPageLoading(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendor/Get-Customer-Reviews-in-Vendor-Profile`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result);
          setData(result.data);
          setPageLoading(false);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar isShow={false} />
          <main className="maincontent">
            <div>
              <section>
                <div className="basic-info">
                  <h3>Company Reviews</h3>
                </div>
              </section>

              <section className="clientReviews">
                {/* <h3>Reviews</h3>
              <p>5 Revies received from Customers</p> */}
                {Array.isArray(data) ? (
                  data.map((item) => {
                    return (
                      <div className="customerReviews">
                        <div className="reviewBody">
                          <div className="reviewName">
                            {item.company_name.charAt(0)}
                          </div>
                          <div className="reviewClientDetails">
                            <h4>{item.company_name}</h4>
                            <p>{item.country}</p>
                            <span>{moment(item.created_at).format("L")}</span>
                          </div>
                        </div>

                        <div className="reviewcontent">
                          <div className="reviewstars">
                            <Starratings stars={item.stars} /> {item.stars}
                          </div>
                          <p>{item.reviews}</p>
                          {/* <div className="reviewscoins">
                        <img src={Coins} alt="Coins" />
                        Congratulations, You Won 500 Coins
                      </div> */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="noreviews">
                    <div className="noreviewcont">
                      <h3>No Reviews Found</h3>

                      <Link to="/vendor-project-list">
                        <button>Apply Project</button>
                      </Link>
                    </div>

                    <img src={noreviews} alt="noreview" />
                  </div>
                )}
              </section>

              {/* <div className="formbuton">
              <input type="submit" className="save-button" value="Save" />
              {/* <input
                type="submit"
                className="save-button"
                value="Skip"
                onClick={handleSkip}
              /> *
            </div>*/}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default CustomerReviews;
