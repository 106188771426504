import React from "react";
import Layout from "../../components/Layout";
import "./Product.css";
import office365 from "../../assets/images/office365.jpg";
import { BsYoutube, BsFileEarmarkPdf } from "react-icons/bs";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
const ProductPage = () => {
  return (
    <Layout>
      <div className="breadcrum">
        <span>
          Home {">"} Products Category {">"} Product Page
        </span>
      </div>
      <div className="product-page-body">
        <div className="product-img">
          <img src={office365} alt="office" />
        </div>
        <div className="product-description">
          <h2>Microsoft Office Solutions 360</h2>
          <p>
            Be powerfully productive with apps to create, connect and share
            wherever you go:
            <div className="product-adv">
              <FaArrowAltCircleRight className="product-adv-icon" />
              <p>
                &nbsp; Create high-quality reports, essays and className
                presentations with smart assistance features in Word, Excel &
                PowerPoint.
              </p>
            </div>
            <div className="product-adv">
              <FaArrowAltCircleRight className="product-adv-icon" />
              <p>
                &nbsp; Save up to 500,000 photos* & files with 1 TB of OneDrive
                cloud storage (*assumes 2 MB file size).
              </p>
            </div>
            <div className="product-adv">
              <FaArrowAltCircleRight className="product-adv-icon" />
              <p>
                &nbsp; Enjoy an ad-free email and calendar experience with
                Outlook.
              </p>
            </div>
          </p>
          {/* <div className="product-download">
            <span>
              <BsYoutube className="proicons" />
              &nbsp; <h4>Product Video</h4>
            </span>
            <span>
              <BsFileEarmarkPdf className="proicons" />
              &nbsp; <h4>Product Brouchure</h4>
            </span>
          </div> */}

          <button className="btn btn-primary">Request Quotation</button>
        </div>
        <div className="product-vendor">
          <h2>Office 365 Partners</h2>
          <article className="vendor-listing">
            <h3>TechCurve</h3>
            <span>Bangkok, Thailand</span>
          </article>
          <article className="vendor-listing">
            <h3>Fyoocher</h3>
            <span>Mahoba, India</span>
          </article>
          <article className="vendor-listing">
            <h3>Neon Infotech</h3>
            <span>Pattaya, Thailand</span>
          </article>
          <article className="vendor-listing">
            <h3>NISA Solutions</h3>
            <span>Navi Mumbai, India</span>
          </article>
          <article className="vendor-listing">
            <h3>MUSK Solutions</h3>
            <span>New York, USA</span>
          </article>
        </div>
      </div>
      <section className="product-info">
        <div className="tabs-wrapper">
          <ul className="nav-tabs" role="tablist">
            <li className="nav-item tab is-active">
              <a href="#whatis">What is Microsoft 365 ?</a>
            </li>

            <li className="nav-item tab">
              <a href="#features">Features</a>
            </li>

            <li className="nav-item tab">
              <a href="#Specifications">Specifications</a>
            </li>

            <li className="nav-item tab">
              <a href="#Compatibility">Compatibility</a>
            </li>
          </ul>
        </div>
        <div id="whatis" className="product-info-section">
          <h3>What is Microsoft 365 for Business?</h3>
          <p>
            Microsoft 365 for Business is a cloud-based E-mail backup software
            that helps you in keeping a record of your organization’s sent or
            received emails. The software comes with core Microsoft Office
            applications that include Word, Excel, PowerPoint, OneNote, Outlook
            etc. The software works across multiple devices, facilitate
            communication and work management, provides you with monthly
            features updates, and 1TB cloud storage per user.
          </p>

          <p>
            Other than Windows 10, Windows 8.1, Windows 7 Service Pack 1, the
            software is compatible with two most recent versions of MacOS. With
            Microsoft 365, all your apps and services are forever connected with
            your teams’ apps and also the wider web. This facilitates better
            coordination with the team and helps you save time.
          </p>
        </div>
        <div id="features" className="product-info-section">
          <h3>Microsoft 365 for Business Features</h3>
          <p>
            In key features, Microsoft 365 for business offers a list of tools
            that will make your work life real easy. Here they are:
          </p>

          <ul>
            <li>
              Monthly updates: Microsoft 365 business India gets monthly updated
              for the latest versions of Outlook, Word, Excel, PowerPoint for
              Windows or Mac, OneNote etc.
            </li>
            <li>
              Works across multiple devices: With Microsoft office for business,
              you can work across multiple devices without changing any settings
              or adding a new extension on your PC, tablet, or any other device.
            </li>
            <li>
              Communication and work management: The software’s simple-to-use
              chat feature, Microsoft Kaizala, makes it easy for you and your
              teammates to connect and coordinate with each other.
            </li>
            <li>
              1TB secure cloud storage: With Microsoft office 365, you can
              store, share and edit photos, documents, and more on your devices’
              1TB OneDrive cloud storage.
            </li>
            <li>
              Professional digital storytelling: It enables you to create
              interactive reports, presentations, and newsletters with its Sway
              tool.
            </li>
          </ul>
        </div>
        <div id="Specifications" className="product-info-section">
          <h3>Microsoft 365 for Business Specifications</h3>
          <div className="speclist">
            <div className="spechead">Supported Platforms :</div>
            <div className="specpoint">Windows iOS Android</div>
          </div>
          <div className="speclist">
            <div className="spechead">Device: :</div>
            <div className="specpoint">Desktop Mobile Tablet iPad</div>
          </div>
          <div className="speclist">
            <div className="spechead">Deployment :</div>
            <div className="specpoint">Web-Based</div>
          </div>
          <div className="speclist">
            <div className="spechead">Suitable For :</div>
            <div className="specpoint">All Industries</div>
          </div>
          <div className="speclist">
            <div className="spechead">Business Specific:</div>
            <div className="specpoint">All Businesses</div>
          </div>
          <div className="speclist">
            <div className="spechead">Language:</div>
            <div className="specpoint">English</div>
          </div>
        </div>
        <div id="Compatibility" className="product-info-section">
          <h3>Microsoft 365 Business: Platform Compatibility:</h3>
          <p>
            The software is compatible with the devices of the following
            specification:
          </p>

          <p>
            The software is compatible with Windows 10, Windows 8.1, Windows 7
            Service Pack 1.
          </p>
          <p>
            Office 2019, Office 2016, Office 2013 and Office 2011 versions works
            with Mac devices.
          </p>
          <p>
            Former versions of the software such as Office 2010 and Office 2007
            are likely to work with reduced functionality.
          </p>
          <br />
          <h4>Supported Languages for 'Microsoft 365 for Business Download'</h4>
          <p>
            The software supports all languages. Whereas, OneNote available in
            English, Hindi, Kannada, Telugu, Tamil, Malayalam, Gujarati,
            Bengali, Marathi, Punjabi, Assamese, Odia, and Konkani. Meanwhile,
            the publisher is available in English and Hindi.
          </p>
        </div>
      </section>
      <section className="product-video">
        <div className="getdemo">
          <h3>Get Microsoft 365 for Business Demo</h3>
          <p>
            We make it happen! Get your hands on the best solution based on your
            needs.
          </p>
        </div>
        <div className="videolink">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/Q_VjRni-G74"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </section>
    </Layout>
  );
};

export default ProductPage;
