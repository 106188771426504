import React, { useState, useEffect } from "react";
import "./Login.css";
import { FaUserCircle } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { login } from "../../Store/Reducers/userSlice";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";

const VendorLogin = () => {
  const initialValues = {
    user_name: "",
    password: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = validate(formValues);
    //setFormErrors(validate(formValues));
    if (Object.keys(error).length !== 0) {
      setFormErrors(error);
      console.log("Error Found");
      return false;
    }
    console.log("No Validation Error Found");
    setIsSubmit(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify(formValues);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendor/Vendor-login`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          if (result.status === 200) {
            setFormValues("");
            //alert(result.message);
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("logintype", 2);
            localStorage.setItem("user", JSON.stringify(result.data));
            localStorage.setItem(
              "userinfo",
              JSON.stringify(result.data.user_info)
            );
            toast("You have successfully login with ConQt Platform");
            window.location = "/vendor-project-list";
          } else {
            toast(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
    dispatch(login({ formValues, loggedIn: true }));
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log(formValues);
    }
  }, [formErrors]);

  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim()) {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    if (!email_regex.test(values["user_name"])) {
      errors.user_name = "This is not valid email format";
    } else if (!PWD_REGEX.test(values["password"])) {
      errors.password =
        "Password Minimum eight and maximum 10 characters, at least one uppercase letter, one lowercase letter, one number and one special character";
    }
    console.log(errors);
    return errors;
  };

  return (
    // <div className="login-wrapper">
    //   <div className="login-form">
    //     <h2>Log in to Connect</h2>
    //     <form
    //       className="loginform"
    //       onSubmit={handleSubmit}
    //       noValidate
    //       autoComplete="off"
    //     >
    //       <div className="login-form-field">
    //         <FaUserCircle className="form-icon" />
    //         <input
    //           type="email"
    //           name="user_name"
    //           value={formValues.user_name}
    //           placeholder="Username or Email ID"
    //           required
    //           onChange={handleChange}
    //         />
    //       </div>
    //       <p className="err-message">{formErrors.user_name}</p>
    //       <div className="login-form-field">
    //         <RiLockPasswordFill className="form-icon" />
    //         <input
    //           type="password"
    //           name="password"
    //           placeholder="Enter your Password"
    //           minLength="8"
    //           value={formValues.password}
    //           onChange={handleChange}
    //           required
    //         ></input>
    //       </div>
    //       <p className="err-message">{formErrors.password}</p>
    //       <button className="btn" onClick={handleSubmit}>
    //         Login
    //       </button>
    //     </form>
    //     <div className="formline">&nbsp;</div>
    //     <p className="notreg">Don't have an Connect account?</p>
    //     <Link to="/register">
    //       <button className="btn-signup">Sign Up</button>
    //     </Link>
    //   </div>
    // </div>
    <>
      <div className="formline">&nbsp;</div>
      <form
        className="loginform"
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
      >
        <div className="login-form-field">
          <FaUserCircle />
          <input
            type="email"
            name="user_name"
            value={formValues.user_name}
            placeholder="Partner email ID"
            required
            onChange={handleChange}
          />
        </div>
        <p className="err-message">{formErrors.user_name}</p>
        <div className="login-form-field">
          <RiLockPasswordFill />
          <input
            type="password"
            name="password"
            placeholder="Enter your password"
            minLength="8"
            // value={formValues.password}
            onChange={handleChange}
            required
          ></input>
        </div>
        <p className="err-message">{formErrors.password}</p>
        <button className="btn" onClick={handleSubmit}>
          Login
        </button>
        <Link to="/reset-password">
          <p className="fpass">Forgot Password?</p>
        </Link>
      </form>
    </>
  );
};

export default VendorLogin;
