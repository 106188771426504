import React, { useEffect, useState } from "react";
import "./customerContact.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import DataTable from "react-data-table-component";
import axios from "axios";
import { BaseUrl } from "../../Api/api";
import moment from "moment";
import EyeIcon from "../../assets/images/Eye-icon.png";
import PageLoader from "../../components/PageLoader/PageLoader";
import { useNavigate } from "react-router-dom";

const CustomerContact = () => {
  const navigate = useNavigate();

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#304FFE",
        color: "white",
        fontSize: "15px",
        width: "200px",
        fontWeight: "500",
        fontfamily: "Poppins",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "16px",
        color: "black",
        fontWeight: "500",
        letterSpacing: "0.5px",
        whiteSpace: "break-spaces",
        marginTop: "5px",
        marginBottom: "5px",
        fontfamily: "Poppins",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };
  const [pageLoading, setPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      name: "Project",
      selector: (row) => row.project_name,
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Position",
      selector: (row) => row.position,
    },

    {
      name: "Email",
      selector: (row) => row.contact_person_email,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
    },
    // {
    //         name:"Stage",
    //         selector: row => row.project_name,
    // },
    {
      name: "Date",
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY h:mm A"),
    },

    // {
    //         name: "Action",
    //         cell: (result) => (
    //                 <div
    //                         className="project-list-eye-icon"
    //                 // onClick={() => {
    //                 //         dispatch(updateProjectInformation(result));
    //                 //         setShowPopup(true);
    //                 // }}
    //                 >
    //                         <img className="eye-icon" src={EyeIcon} alt=""></img>
    //                 </div>
    //         ),
    // },
  ]);

  const onRowClicked = (row) => {
    console.log("row", row);
    navigate(`/new-buy-customer-details-page/${row.project_id}`);
  };

  useEffect(() => {
    setPageLoading(true);
    const token = localStorage.getItem("token");
    // const token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJfaWQiOjM4LCJ1c2VyX25hbWUiOiJhYmMxMjNAZ21haWwuY29tIiwibmFtZSI6IkNvbXBhbnkgbmFtZSIsImxvZ2luX3R5cGUiOiJWIn0sImlhdCI6MTY4MDA3MzQ3MCwiZXhwIjoxNjgyNjY1NDcwfQ.Ya4eKhLLr3tGzvBnGfvzyHjUxCuDTyMJvya-K9LYCNc`;
    axios
      .get(`${BaseUrl}/vendordashboard/Get-All-Transactions-By-Vendor`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((result) => {
        console.log("customer contact data", result.data.data);
        setData(result.data.data);
        setPageLoading(false);
      })
      .catch((err) => {
        console.log("error in customer ", err);
      });
  }, []);


  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar isShow={false} />
          <div className="container">
            {" "}
            <p className=" cc-top-name">Opportunities </p>
          </div>
          <div className="container">
            <DataTable
              data={ data.length > 0 ? data : []}
              columns={columns}
              customStyles={customStyles}
              onRowClicked={onRowClicked}
              // pagination={sortedArray && sortedArray.length > 10}
              // paginationRowsPerPageOptions={[5, 10]}
              // paginationPerPage={10}
              pagination
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerContact;
