import React, { useState } from "react";
import "./Register.css";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";

const customerregistersteps = () => {
  //const [page, setPage] = useState(1);

  const FormTitles = ["CustomerPersonal", "CustomerBusiness"];
  return (
    <Layout>
      <div className="register-toggle">
        <div className="register-window">
          <div className="registerhead">
            <h1>Customer Signup</h1>
          </div>
          <div className="progressbar"></div>
          <form>
            <div></div>
            <div>Body</div>
            <div>
              <button className="tog-reg-btn">Prev</button>
              <button className="tog-reg-btn">Next</button>
            </div>
          </form>
          <div className="login-account">
            <p>
              Already have an account ? <Link to="/login">Log In </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default customerregistersteps;
