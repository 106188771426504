import React, { useState, useEffect } from "react";
import "../../Vendor/vendorProfile/page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { MdModeEdit } from "react-icons/md";
import DragDrop from "./dragandrop";
import { BaseUrl } from "../../../Api/api";
import { toast } from "react-toastify";
import { progressBarValidation } from "./utils.js";
import CommonApi from "../../../Api/commonApi";

const CompanyDocumentsEdit = () => {
  const [selectedFilesNames, setSelectedFileNames] = useState([]);
  const [overAllProgressValue, setOverAllProgressValue] = useState(0);
  const [userFile, setUserFile] = useState({});
  const [data, setData] = useState([]);

  const dragFiles = (files) => {
    let temp = [];
    console.log(files, "from partent");
    Object.values(files).forEach((key) => {
      temp.push(key.name);
    });
    console.log(temp, "file names");
    setSelectedFileNames(temp);
    setUserFile(files);
  };

  const handleSkip = (e) => {
    window.location = "/customer-reviews";
  };

  const handleNext = (e) => {
    var myHeaders = new Headers();

    const userToken = localStorage.getItem("token");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    var formdata = new FormData();
    Object.values(userFile).forEach((value) => {
      let url = URL.createObjectURL(value);
      formdata.append("files", value, url);
    });
    var requestOptions = {
      method: "POST",

      headers: myHeaders,

      body: formdata,

      redirect: "follow",
    };

    fetch(`${BaseUrl}/user/User-Documents-Upload`, requestOptions)
      .then((response) => response.json())

      .then((result) => {
        toast("Your Profile Saved Successfully");
        console.log("docresult", result);
        localStorage.setItem(
          "profile_complete_status",
          result.data.profile_complete_status
        );
        window.location = "/customer-documents";
      })

      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    try {
      var myHeaders = new Headers();
      const userToken = localStorage.getItem("token");
      myHeaders.append("Authorization", `Bearer ${userToken}`);

      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/user/Get-User-Documents`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(result.data.document_list);
          CommonApi.CustomerProfileApi().then((mcpdata) => {
            console.log(mcpdata, "mcpdata");
            if (mcpdata.status === 200) {
              localStorage.setItem(
                "profile_complete_status",
                JSON.stringify(mcpdata.data[0].profile_complete_status)
              );
              setOverAllProgressValue(mcpdata.data[0].profile_complete_status);
            }
          });
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);
  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar
          isShow={true}
          value={localStorage.getItem("profile_complete_status")}
        />
        <main className="maincontent">
          <div>
            <section>
              <div className="basic-info">
                <h3>Company Documents</h3>
                {/* <h4>
                  Edit
                  <MdModeEdit />
                </h4> */}
              </div>
            </section>

            <div className="companydoctext">
              <p>Upload All the company documents Here.</p>
            </div>

            <div>
              <DragDrop onChange={dragFiles} />
              <h6 className="last-heading">
                Documents, JPGs, JPEGs, and PNGs supported.
              </h6>
              {Object.values(userFile).map((value) => {
                return (
                  <div className="documents-url-name">
                    <p>{value.name}</p>
                    <a
                      className="link-file"
                      href={URL.createObjectURL(value)}
                      target={"_blank"}
                    >
                      view
                    </a>
                  </div>
                );
              })}
            </div>

            <div className="formbuton">
              <input
                type="submit"
                className="save-button"
                value="Save"
                onClick={handleNext}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default CompanyDocumentsEdit;
