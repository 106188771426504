const transformProjectTableData = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        //created_at: e.created_at.split("T")[0].split("-").reverse().join("-"),
        created_at: e.created_at,
        project_name: e.project_name,
        announcement_id: e.announcement_id,
        solution_name: e.solution_name,
        vendor_apply_count:
          e.vendor_apply_count > 99 ? 99 + "+" : e.vendor_apply_count,
        project_expire: e.project_expire,
        project_expiry_datetime: e.project_expiry_datetime.split(" ")[0],
        status: e.status,
        project_budget: +e.project_budget
          ? "$" +
            e.project_budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : "Not Set",
        attachment_url: e.attachment_url,
        attachment_name: e.attachment_name,
        project_description: e.project_description,
        country: e.country,
        city: e.city,
        project_status:
          e.project_status === 0
            ? "Applied"
            : e.project_status === 1
            ? "In Progress"
            : e.project_status === 2
            ? "Completed"
            : "Not Available",
      };
    });
  }
};

const transformProposalTableData = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        proposal_id: e.proposal_id,
        announcement_id: e.announcement_id,
        project_resources: e.project_resources,
        proposal_status: e.proposal_status,
        project_support: e.project_support,
        out_of_scope: e.out_of_scope,
        terms_conditions: e.terms_conditions,
        vendor_id: e.vendor_id,
        vendor_name: e.vendor_name? e.vendor_name.slice(0,1)+"******":"----",
        created_at: e.created_at,
        price: e.price,
        customer_amount: e.customer_amount ? e.customer_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :"---",
        vendor_currency: e.vendor_currency,
        timeline: e.timeline,
        timeline_unit: e.timeline_unit,
        perposal_description: e.perposal_description,
        status: e.status,
        project_name: e.project_name,
        project_budget: e.project_budget,
        attachment_url: e.attachment_url,
        project_description: e.project_description,
      };
    });
  }
};
const transformProductData = (tableData) => {
  if (tableData && tableData.length > 0) {
    return tableData.map((e, index) => {
      return {
        id: e.id,
        product_name: e.product_name?e.product_name:"---",
        company_name: e.brand_name?e.brand_name:"---",
        verified: e.is_verified,
        category: e.category_name?e.category_name:"---",
        added_on: e.created_at ?new Date (e.created_at).toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false
        }) : "---",
        price: e.price?e.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"---",
        is_price:e.is_price,
        product_id:e.product_id,
        initial_price:e.initial_price,
        is_tax_include:e.is_tax_include,
        gst_percentage:e.gst_percentage,
        price_currency:e.price_currency != null ? e.price_currency : "",
        discount:e.discount,
        product_status:e.product_status 
      };
    });
  }
}
module.exports = {
  transformProjectTableData,
  transformProposalTableData,
  transformProductData,
};
