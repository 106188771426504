import React, { useState, useEffect } from "react";
import "./page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import IT from "./images/IT.jpg";
import ui from "./images/ui.jpg";
import hardware from "./images/hardware.jpg";
import office from "./images/office.jpg";
import PM from "./images/PM.jpg";
import { BsPlusSquareFill } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
// import bar30 from "../NewProfile/images/bar30.png";
import Popup from "reactjs-popup";
import { BaseUrl } from "../../../Api/api";
// import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Multiselect } from "multiselect-react-dropdown";
import { useNavigate } from "react-router-dom";
import addservices from "../../../assets/images/addservices.png";
import PageLoader from "../../../components/PageLoader/PageLoader";

const ServicesandPreference = () => {
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);

  const userToken = localStorage.getItem("token");
  let navigate = useNavigate();

  useEffect(() => {
    setPageLoading(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendor/Get-Vendor-Service-Preferences-Profile`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          if (result.data.length && result.data[0].service_preferences) {
            setData(result.data);
            setPageLoading(false);
          } else {
            setPageLoading(false);
            //navigate("/services-and-preference/edit");
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleNext = (e) => {
    window.location = "/portfolio";
  };

  const handleSkip = (e) => {
    window.location = "/portfolio";
  };

  return (
    <>
     {pageLoading && (
       <PageLoader/>
      )}

    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar
          isShow={true}
          value={data.length > 0 && data[0].profile_complete_status}
        />
        <main className="maincontent">
          <div>
            <section>
              <div className="basic-info">
                <h3>Services & Preferences</h3>
                {data.length && data[0].service_preferences ? (
                  <Link to="/services-and-preference/edit">
                    <h4>
                      Edit
                      <MdModeEdit />
                    </h4>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </section>
            <section className="mainservicesList">
              <div className="mainlistservices">
                {data.length && data[0].service_preferences ? (
                  data.map((item) => {
                    return (
                      <div className="serlistitem">
                        <p>{item.service_preferences}</p>
                      </div>
                    );
                  })
                ) : (
                  <div className="noreviews">
                    <div className="noreviewcont">
                      <h3>No Services Found</h3>

                      <Link to="/services-and-preference/edit">
                        <button>Add Services</button>
                      </Link>
                    </div>

                    <img src={addservices} alt="noreview" />
                  </div>
                )}
              </div>
            </section>

            <div className="formbuton">
              {/* <Link to={`/AgencyProfile`}> */}
              <input
                type="submit"
                className="save-button"
                value="Skip"
                onClick={handleSkip}
              />
              <input
                type="submit"
                className="save-button"
                value="Next"
                onClick={handleNext}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
    </>
  );
};
export default ServicesandPreference;
