import React, { useState, useEffect } from "react";
import "./page.css";
import { toast } from "react-toastify";
import { BaseUrl ,RazorPaymentkey} from "../../../Api/api";
import { profileApi } from "../../../Api/commonApi";
import {BsCoin} from "react-icons/bs";
import CommonApi from "../../../Api/commonApi";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const SubscriptionPayment = () => {
  const [selectedRadioButton, setSelectedRadioButton] = useState();
  const [subscriptionPayment, setSubscriptionPayment] = useState([]);
  const [cardActive, setCardActive] = useState(false);
  const user = JSON.parse(localStorage.getItem("userinfo"));
  const [valueAmount, setValueAmount] = useState(0);
  const Token = localStorage.getItem("token");
   
  var showAmount = "INR 1180";
  var amount = selectedRadioButton;
  var currency = "USD";

  // console.log(user);
  // var country = user.country;
  // console.log(country, "this is user country");
  // if (country.toLowerCase() == "india") {
  //   var showAmount = "INR 1180";
  //   var amount = 118000;
  //   var currency = "INR";
  // } else if (country.toLowerCase() == "thailand") {
  //   var showAmount = "THB 1000";
  //   var amount = 100000;
  //   var currency = "THB";
  // } else {
  //   var showAmount = "$20 USD";
  //   var amount = 2000;
  //   var currency = "USD";
  // }

  const registrationCharges = "20.0";
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const loadScript = (src) => {
    return new Promise((resovle) => {
      const script = document.createElement("script");
      script.src = src;

      script.onload = () => {
        resovle(true);
      };

      script.onerror = () => {
        resovle(false);
      };

      document.body.appendChild(script);
    });
  };

  const displayRazorpay = async (amount, subscription_id) => {
    setCardActive(true);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("You are offline... Failed to load Razorpay SDK");
      return;
    }

    console.log("this is amount", amount);

    const options = {
      key: RazorPaymentkey,
      currency: currency,
      //order_id: "743941",
      receipt: "receipt_order_74394",
      amount: amount,
      name: "Razor Payment",
      description: "Thanks for purchasing",
      image:
        "https://mern-blog-akky.herokuapp.com/static/media/logo.8c649bfa.png",

      handler: function (response) {
        console.log(response);

        let responseData = {
          transactions_id: response.razorpay_payment_id,
          currency: options.currency,
          amount: options.amount / 100,
          transaction_status: "COMPLETED",
        };
        console.log(responseData, "razorpay transaction success response");

        //POST API For Transactions
        // toast(response.razorpay_payment_id);
        // toast("Payment Successfully");
        var myHeaders = new Headers();
        myHeaders.append("authorization", `Bearer ${Token}`);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          transactions_id: response.razorpay_payment_id,
          currency: options.currency,
          amount: options.amount / 100,
          transaction_status: "COMPLETED",
          subscription_id: subscription_id,
        });
        console.log(raw, "raw json data  from post api");
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          `${BaseUrl}/vendordashboard/Subscription-Vendor-Transaction-Update`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => { 
            if(result.status == 200){
              CommonApi.ProfileApi().then((vsdata) => {
                console.log("ApiRes", vsdata);
                if (vsdata.status === 200) {
                  localStorage.setItem("userinfo", JSON.stringify(vsdata.data));
                  toast("Thanks for purchasing");
                  window.location= "/subscription";
                }
              });
            } else {
              toast(result.message);
            }        
          })
          
          .catch((error) => console.log("error", error));
      },
      prefill: {
        name: "Enter Your Name",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleClick = (e) => {
    setSelectedRadioButton(e.target.value);
  };
  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("authorization", `Bearer ${Token}`);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        `${BaseUrl}/vendordashboard/Get-Vendor-Subscription-list`,
        requestOptions
      )
        .then((res) => res.json())
        .then((result) => {
          setSubscriptionPayment(result.data);
          console.log(result.data, "result");
        });
      
    } catch (err) {
      console.log(err, "error");
    }
  }, []);

  const PaymentCard = (props) => {
    return (
      <div className="payment-card-container">
        <h3 className="payment-card-title"> {props.title}</h3>
        <p className="payment-card-price">
          <h5 className="card-dollar">$</h5> {props.amount}
        </p>
        <p className="payment-card-coins">{parse(props.description)}</p>
        {/* <button
          className="payment-card-button"
          onClick={() =>
            displayRazorpay(props.amount * 100, props.subscriptionId)
          }
        >
          Start Now
        </button> */}
        {props.amount != 0 ?  (<button
          className="payment-card-button"
          onClick={() =>
            displayRazorpay(props.amount * 100, props.subscriptionId)
          }
        >
          Start Now
        </button>) : ("")
          
        }
      </div>
    );
  };
  return (
    <section className="addons">
      <div className="curbal"><h2>Current Balance</h2> - <BsCoin />{user.available_point + " coins"}</div>
      <div className="paymentcardslist">
      {subscriptionPayment.map((paymentObject) => {
        return (
          <div className="payment-card">
            <PaymentCard
              amount={paymentObject.subcription_amount}
              title={paymentObject.subscription_title.toUpperCase()}
              description={paymentObject.subscription_description}
              subscriptionId={paymentObject.subscription_plan_id}
            />
          </div>
        );
      })}
      </div>
    </section>
  );
};

export default SubscriptionPayment;
