const initialState = [
  {
    project_name: "Credit to Bank for city update",
    announcement_id: "6",
    project_description: "Credit to Bank Project Information for city update",
    city: "Mumbai",
    country: "India",
  },
  {
    project_name: "Credit to Bank for city",
    announcement_id: "7",
    project_description:
      "Credit to Bank Project Information for city sasdasdasd",
    city: "Bhopal",
    country: "India",
  },
];

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PROJECT":
      state = [...state, action.payload];
      return state;

    default:
      return state;
  }
};

export default projectReducer;
