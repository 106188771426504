import React from 'react'
import Sidebar from '../Sidebar'
import Topbar from '../Topbar'

const DraftTable = () => {
  return (
    <>
      <div className="dashboard-container">
        <Sidebar  />
        <div className="main-dashboard-container">
          <Topbar  />
          <main className="maincontent">
            <div className="addproborder">
              <h3 className="addproject">Draft Project</h3>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

export default DraftTable