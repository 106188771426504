import React, { useState, useEffect } from "react";
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import "rsuite/dist/rsuite.css";
import "./OtpVerificationModal.css";
import OtpInput from "react-otp-input";
import verified from "../../../assets/images/Verified.png";
import otpClose from "../../../assets/images/otp-close.svg";
import { toast } from "react-toastify";
import { BaseUrl } from "../../../Api/api";

const OtpVerificationModal = ({
  open,
  handleClose,
  vendorMail,
  vendorDetails,
  phone_no,
  showingResend,
  customerMail,
  customerDetails,
}) => {
  const [otp, setOtp] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isToken, setIsToken] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [delay, setDelay] = useState(600);
  const minutes = Math.floor(delay / 60);
  const seconds = Math.floor(delay % 60);

  let index = vendorMail ? vendorMail.indexOf("@") : customerMail.indexOf("@");
  let newEmail = vendorMail
    ? "xxxxx" + vendorMail.slice(index)
    : "xxxxx" + customerMail.slice(index);

  useEffect(() => {
    const timer = setInterval(() => {
      setDelay(delay - 1);
    }, 1000);

    if (delay === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [delay]);

  const onClickSubmit = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      user_name: vendorMail ? vendorMail : customerMail,
      otp: otp,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}${
        vendorMail ? "/vendor/Otp-Verification" : "/user/Otp-Verification"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "api response");
        if (result.data.token) {
          toast("Your Mail Verified Successfully Click On Done For Next Step");
          setShowSuccessPopup(true);
          setIsToken(result.data.token);
        } else {
          toast("OTP incorrect or expired. Click on RESEND");
          console.log("error");
          setShowResend(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  const onClickResend = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      user_name: vendorMail ? vendorMail : customerMail,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}${vendorMail ? "/vendor/Resend-OTP" : "/user/Resend-OTP"}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          toast("OTP Resended  Successfully");
          setOtp("");
          setDelay(600);
        } else {
          toast(result.message);
          console.log("error");
        }
      })
      .catch((error) => console.log("error", error));
  };

  // console.log(customerDetails, "customer details");
  const onClickDone = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = vendorMail
      ? JSON.stringify({
          company_name: vendorDetails.company_name,
          title: vendorDetails.title,
          first_name: vendorDetails.first_name,
          last_name: vendorDetails.last_name,
          user_name: vendorDetails.user_name,
          password: vendorDetails.password,
          confirm_password: vendorDetails.confirm_password,
          country: vendorDetails.country,
          state: vendorDetails.state,
          currency: vendorDetails.currency,
          currency_code: vendorDetails.currency_code,
          symbol: vendorDetails.symbol,
          city: vendorDetails.city,
          zipcode: vendorDetails.zipcode,
          address: vendorDetails.address,
          solutions: vendorDetails.solutions,
          phone_no: phone_no,
          token: isToken,
        })
      : JSON.stringify({
          title: customerDetails.title,
          firstname: customerDetails.firstname,
          lastname: customerDetails.lastname,
          user_name: customerDetails.user_name,
          phonenumber: customerDetails.phonenumber,
          country: customerDetails.country,
          state: customerDetails.state,
          currency: customerDetails.currency,
          currency_code: customerDetails.currency_code,
          symbol: customerDetails.symbol,
          city: customerDetails.city,
          zipcode: customerDetails.zipcode,
          password: customerDetails.password,
          confirm_password: customerDetails.confirm_password,
          department_name: customerDetails.department_name,
          position: customerDetails.position,
          address: customerDetails.address,
          companyname: customerDetails.companyname,
          token: isToken,
        });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}${
        vendorMail
          ? "/vendor/Vendor-Registration-For-Otp"
          : "/user/User-Ragistration-For-Otp"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "result");
        window.location = "/login";
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <>
      {!showSuccessPopup && (
        <Modal open={open} onClose={handleClose}>
          <Modal.Header style={{ marginTop: "20px" }}>
            <span
              onClick={handleClose}
              role="button"
              class="rs-modal-header-close rs-btn-close"
              title="Close"
              aria-label="Close"
            >
              <img src={otpClose} alt="" />
            </span>

            <Modal.Title>
              <h4 className="otp-header">
                Verify Your <br /> Account
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="otp-text">
              We have sent you an <strong>OTP</strong> to{" "}
              <span className="otp-mail">{newEmail}</span>
            </p>
            <div className="otp-time">
              <span>
                {minutes}:{seconds}
              </span>
            </div>
            <div className="otp-inputs">
              <OtpInput
                inputType="number"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => (
                  <input className="otp-inputs" {...props} />
                )}
              />
            </div>
            {showResend && (
              <p className="otp-error">
                OTP incorrect or expired. Click on RESEND
              </p>
            )}
          </Modal.Body>
          <div className="otp-resend-div">
            {(showResend || delay === 0 || showingResend) && (
              <>
                <p className="otp-resend">I didn't receive any code. </p>
                <button className="otp-resend-btn" onClick={onClickResend}>
                  RESEND
                </button>
              </>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="otp-submit"
              onClick={onClickSubmit}
              disabled={otp.length < 6 || delay === 0}
            >
              Submit
            </button>
          </div>
        </Modal>
      )}

      {showSuccessPopup && (
        <Modal open={open} onClose={handleClose}>
          <Modal.Body></Modal.Body>
          <div className="otp-icon-div">
            <img className="otp-verified-icon" src={verified} alt="" />
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <p className="otp-success-text">
              Your Account is Created and Verified Successfully
            </p>
          </div>

          <div className="d-flex align-items-center justify-content-center">
            <button className="otp-submit otp-done" onClick={onClickDone}>
              Done
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default OtpVerificationModal;
