import React, { useState, useEffect } from "react";
import { BaseUrl } from "../../Api/api.js";
import { Link } from "react-router-dom";

const RecentProject = () => {
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem("token");
  //console.log(data);

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/announcement/User-Announcement-List`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(result.data);
          localStorage.setItem(
            "ProjectCount",
            JSON.stringify(result.data.length)
          );
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="container">
      <table className="responsive-table">
        <thead className="responsive-table__head">
          <tr className="responsive-table__row">
            <th className="responsive-table__head__title responsive-table__head__title--name">
              project Name
            </th>
            <th className="responsive-table__head__title responsive-table__head__title--status">
              Status
            </th>
            <th className="responsive-table__head__title responsive-table__head__title--types">
              Category
            </th>
            <th className="responsive-table__head__title responsive-table__head__title--update">
              Project Created Date
            </th>
          </tr>
        </thead>
        <tbody className="responsive-table__body">
          {data?.slice(0, 5).map((item, announcement_id) => {
            return (
              <tr className="responsive-table__row">
                <td className="responsive-table__body__text responsive-table__body__text--name text-left"  style={{borderBottom:"1px solid #bfbfbf",
                borderTop:"none", borderLeft:"none", borderRight:"none"
              }}>
                  <Link to={`/projectlist`} className="">{item.project_name}</Link>
                </td>
                <td className="responsive-table__body__text responsive-table__body__text--status"
                style={{borderBottom:"1px solid #bfbfbf",
                borderTop:"none", borderLeft:"none", borderRight:"none"
              }}>
                  {item.project_status == 1
                    ? "In Progress"
                    : item.project_status == 2
                    ? "Completed"
                    : "Applied"}
                </td>
                <td className="responsive-table__body__text responsive-table__body__text--types"  style={{borderBottom:"1px solid #bfbfbf",
                borderTop:"none", borderLeft:"none", borderRight:"none"
              }}>
                  {item.solution_name}
                </td>
                <td className="responsive-table__body__text responsive-table__body__text--update" style={{borderBottom:"1px solid #bfbfbf",
                borderTop:"none", borderLeft:"none", borderRight:"none"
              }}>
                  {item.created_at.toLocaleString().split("T")[0]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RecentProject;
