import React, { useEffect, useState } from "react";
import "../Customer/Customer.css";
import Sidebar from "./Sidebar";
import Proposal from "./Proposal";
import Topbar from "./Topbar";

const Projectlist = () => {
  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />
        <Proposal />
      </div>
    </div>
  );
};

export default Projectlist;
