import React, { useState } from "react";
import Layout from "../../components/Layout";
import Proposal from "./Proposal";

const SingleProject = () => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <Layout>
      <section className="project-section">
        {openModal && <Proposal closeModal={setOpenModal} />}
        <div className="project-head">
          <div>
            <h2>Projects Title 1</h2>
            <p>Bangkok, Thailand</p>
          </div>

          <div>
            <button
              className="apply-btn"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Apply for Project
            </button>
          </div>
        </div>
        <div className="project-desc">
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>
          <ul>
            <li>The standard Lorem Ipsum passage, used since the 1500s</li>
            <li>Section 1.10.32 of "de Finibus Bonorum et Malorum"</li>
            <li>1914 translation by H. Rackham</li>
            <li>men who are so beguiled and demoralized by the charms</li>
            <li>Section 1.10.33 of "de Finibus Bonorum et Malorum"</li>
            <li>1914 translation by H. Rackham</li>
            <li>men who are so beguiled and demoralized by the charms</li>
            <li>Section 1.10.33 of "de Finibus Bonorum et Malorum"</li>
          </ul>
          <p>
            "At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores et quas molestias excepturi sint occaecati cupiditate non
            provident, similique sunt in culpa qui officia deserunt mollitia
            animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis
            est et expedita distinctio. Nam libero tempore, cum soluta nobis est
            eligendi optio cumque nihil impedit quo minus id quod maxime placeat
            facere possimus, omnis voluptas assumenda est, omnis dolor
            repellendus. Temporibus autem quibusdam et aut officiis debitis aut
            rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint
            et molestiae non recusandae. Itaque earum rerum hic tenetur a
            sapiente delectus, ut aut reiciendis voluptatibus maiores alias
            consequatur aut perferendis doloribus asperiores repellat."
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default SingleProject;
