import React, { useState, useEffect } from "react";
import "../Customer/Customer.css";
import "./view.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Popup from "reactjs-popup";

const Vendorviewprojectlist = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem("token");
  const ID = useParams().proposal_id;
  console.log(ID);

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendordashboard/Vendor-Proposal-List`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          console.log("result", result.data);
          setData(result.data);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  data.map((item) => {
    item.milestonelist.map((key) => {
      console.log("milestone details", key.milestone_description);
    });
  });

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />

        <main className="maincontent">
          <div>
            {data
              .filter((p_id) => p_id.proposal_id == ID)
              .map((item) => {
                console.log(item);
                return (
                  <>
                    <h3 className="VPDproposalhead">Project Proposal</h3>
                    <div className="VPDcard1">
                      <table>
                        <tbody>
                          <tr>
                            <td className=" ctitle">Project Name</td>
                            <td className=" cbody">
                              {parse(item.project_name)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className=" ctitle">Project Description</td>
                            <td className="cbody">
                              {parse(item.project_description)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className=" ctitle">Resources</td>
                            <td className=" cbody">
                              {parse(item.proposal_resources)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className=" ctitle">Support</td>
                            <td className=" cbody">
                              {parse(item.proposal_support)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className=" ctitle">Scope of Work</td>
                            <td className=" cbody">
                              {parse(item.perposal_description)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className="ctitle">Out of Scope</td>
                            <td className="cbody">
                              {parse(item.out_of_scope)}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className=" ctitle">Price</td>
                            <td className=" cbody">
                              {item.price} {item.currency}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className="ctitle">Timeline</td>
                            <td className="cbody">
                              {item.timeline} {item.timeline_unit}
                            </td>
                          </tr>

                          <tr className="wrap">
                            <td className="ctitle">Terms & Conditions</td>
                            <td className="cbody">
                              {parse(item.proposal_termsconditions)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    {item.proposal_status == 0 ? (
                      <></>
                    ) : item.proposal_status == 1 ? (
                      <>
                        <h3 className="VPDproposalhead2">Milestone</h3>
                        <div className="container">
                          <table className="rwd-table">
                            <tbody>
                              <tr className="milestone-heading">
                                <th>S.No.</th>
                                <th>Description </th>
                                <th>Percent</th>
                                <th>Amount</th>
                                <th>Timeline </th>
                              </tr>

                              {item.milestonelist.map((key, index) => {
                                return (
                                  <tr>
                                    <td
                                      className="milestone-data"
                                      data-th="S.No. "
                                    >
                                      {index + 1}
                                    </td>
                                    <td
                                      className="milestone-data"
                                      data-th="Description "
                                    >
                                      {key.milestone_description}{" "}
                                    </td>
                                    <td
                                      className="milestone-data"
                                      data-th="Percent "
                                    >
                                      {key.milestone_percent}%
                                    </td>
                                    <td
                                      className="milestone-data"
                                      data-th="Amount "
                                    >
                                      {item.currency} {key.milestone_amount}
                                    </td>
                                    <td
                                      className="milestone-data"
                                      data-th="Timeline "
                                    >
                                      {key.milstone_timeline}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                    {item.proposal_status == 1 ? (
                      <div className="mileview">
                        {/* <button
                              className="btn selectproposal"
                              onClick={() => {
                                formValues.action_type = 1;
                                formValues.announcement_id =
                                  item.announcement_id;
                                formValues.proposal_id = item.proposal_id;
                                setOpenModal(true);
                                proposalAcceptedList();
                              }}
                            >
                              Start Project
                            </button> */}

                        <button
                          className="btn-selectproposal"
                          onClick={() => {
                            window.location = `/ViewVendorMilestone/${item.proposal_id}`;
                          }}
                        >
                          View Milestone
                        </button>
                      </div>
                    ) : item.proposal_status == 0 ? (
                      <>
                        <Link to={`/Vendor-Project-Apply-List`}>
                          <button className="btn-apply_milestone">Back</button>
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
          </div>
        </main>
      </div>
    </div>
  );
};

export default Vendorviewprojectlist;
