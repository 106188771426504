import React, { useState, useEffect } from "react";
import "./Customer.css";
import "./responsive.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BaseUrl } from "../../Api/api.js";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Popup from "reactjs-popup";
import { AiFillEye } from "react-icons/ai";
import trophy from "../../assets/images/trophy.png";
import { useNavigate } from "react-router-dom";

const FinalProject = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem("token");
  console.log(data);

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Customer-Final-Project-List`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("result", result.data);
          setData(result.data);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);

  let navigate = useNavigate();

  const handleID = (proposal_id) => {
    console.log("handle Pro Id", proposal_id);
    // window.location(`/final-project-list/${proposal_id}/`);
    navigate(`/final-project-list/${proposal_id}`);
  };

  return (
    <>
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />

          <div className="container">
            <table className="rwd-table">
              <tbody>
                <tr class="tabletopback">
                  <th>Project Name</th>
                  <th>Price</th>
                  <th>Days</th>
                  <th>Project Status</th>
                  {/*<th>Created Date</th> */}
                  <th>View</th>
                </tr>

                {data.map((item, announcement_id) => {
                  let count = item.milestonelist.length + 1;
                  localStorage.setItem("ProjectCount", count);
                  return (
                    // <a href={`/final-project-list/${item.proposal_id}/`}>
                    <tr onClick={() => handleID(item.proposal_id)}>
                      <td data-th="Project Name">{item.project_name}</td>
                      <td data-th="Project Price">
                        {item.price.toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                        &nbsp;
                        {item.currency}
                      </td>
                      <td data-th="Project Timeline">
                        {item.timeline} {item.timeline_unit}
                      </td>
                      <td
                        data-th="Project Status"
                        className="cell scroll text-center"
                      >
                        <span>
                          {item.proposal_status == 2 ? (
                            <p className="selecttag">Rejected</p>
                          ) : item.proposal_status == 1 ? (
                            <p className="text-center awards">
                              <img src={trophy} alt="trophy" />
                            </p>
                          ) : (
                            <li className="reviewprop">Review</li>
                          )}
                        </span>
                      </td>
                      {/*  <td data-th="Date">{item.created_at}</td>*/}
                      <td
                        data-th="Select Vendor"
                        className="cell scroll text-center"
                      >
                        <Link
                          to={`/final-project-list/${item.proposal_id}/`}
                          className="viewmore"
                        >
                          <AiFillEye />
                        </Link>
                      </td>

                      {/* <td data-th="Invoice Date">
                      <Popup
                        trigger={<button className="viewmore btn">View</button>}
                        modal
                        nested
                      >
                        {(close) => (
                          <div className="popup">
                            <div className="popup-content">
                              <button className="close-btn" onClick={close}>
                                &times;
                              </button>

                              <table className="rwd-table">
                                <tbody>
                                  <tr style={{ color: "#000" }}>
                                    <td>Project Name</td>
                                    <td>{parse(item.project_name)}</td>
                                  </tr>
                                  <tr>
                                    <td>Project Description</td>
                                    <td>{parse(item.project_description)}</td>
                                  </tr>
                                  <tr>
                                    <td>Proposal Resources</td>
                                    <td>{parse(item.proposal_resources)}</td>
                                  </tr>
                                  <tr>
                                    <td>Proposal Support</td>
                                    <td>{parse(item.proposal_support)}</td>
                                  </tr>
                                  <tr>
                                    <td>Proposal Description</td>
                                    <td>{parse(item.perposal_description)}</td>
                                  </tr>
                                  <tr>
                                    <td>Proposal Out of Scope</td>
                                    <td>{parse(item.out_of_scope)}</td>
                                  </tr>
                                  <tr>
                                    <td>Proposal Price</td>
                                    <td>
                                      {item.price} {item.currency}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Proposal Timeline</td>
                                    <td>
                                      {item.timeline} {item.timeline_unit}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Proposal Terms & Conditions</td>
                                    <td>{item.proposal_termsconditions}</td>
                                  </tr>
                                  <tr>
                                    <td className="b">S.No.</td>
                                    <td>
                                      <td
                                        className="b"
                                        style={{ width: "25%" }}
                                      >
                                        Milestone Description{" "}
                                      </td>
                                      <td
                                        className="b"
                                        style={{ width: "42%" }}
                                      >
                                        Milestone Percent
                                      </td>
                                      <td
                                        className="b"
                                        style={{ width: "45%" }}
                                      >
                                        Milestone Amount
                                      </td>
                                      <td className="b">Milestone Timeline</td>
                                      <td></td>
                                    </td>
                                  </tr>
                                  {item.milestonelist.map((key, index) => {
                                    return (
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>
                                          <td style={{ width: "30%" }}>
                                            {key.milestone_description}{" "}
                                          </td>
                                          <td style={{ width: "25%" }}>
                                            {key.user_milestone_percent}%
                                          </td>
                                          <td style={{ width: "28%" }}>
                                            {item.currency}{" "}
                                            {key.user_milestone_amount}
                                          </td>
                                          <td>{key.milstone_timeline}</td>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                           
                              <div className="popup-footer">
                                <button
                                  className="btn-signup"
                                  onClick={() => {
                                    close();
                                  }}
                                >
                                  Close
                                </button>
                                <Link to={`/milestone/${item.proposal_id}`}>
                                  <button className="btn-signup">
                                    Project Progress
                                  </button>
                                </Link>
                                <Link
                                  to={`/edit-milestone/${item.proposal_id}`}
                                >
                                  <button className="btn-signup">
                                    Request Changes in Milestone
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        )}
                      </Popup>
                    </td> */}
                    </tr>
                    //</a>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinalProject;
