import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { BaseUrl } from "../../Api/api.js";
import { Link } from "react-router-dom";
import "./Customer.css";
import Popup from "reactjs-popup";
import "./OpenModel.css";
import { AiOutlineCloseSquare } from "react-icons/ai";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import { FaCircle } from "react-icons/fa";

const ViewProposalListDetails = () => {
  const [data, setData] = useState([]);
  const [vdata, setVData] = useState([]);
  const [openModal, setOpenModal] = useState();
  const [showMore, setShowMore] = useState(false);
  const ID = useParams().announcement_id;
  const p_id = useParams().proposal_id;
  //console.log("params", ID);

  const userinfo = JSON.parse(localStorage.getItem("user"));
  const userInfoData = userinfo.user_info;

  const userToken = localStorage.getItem("token");
  const announcementid = localStorage.getItem("announcement_id");
  //console.log("announcementid", announcementid);
  let user = JSON.parse(localStorage.getItem("user"));

  user = user.user_info;

  console.log("user_info", user.currency_code);

  const initialFormData = {
    announcement_id: "",
    proposal_id: "",
    action_type: "",
  };

  const [formValues, setFormValues] = useState(initialFormData);

  const getProposalList = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        announcement_id: announcementid,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Get-Vendor-List-apply-Announcment`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log("proposal", result.data);
          setData(result.data);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProposalList();
  }, []);

  const proposalAcceptedList = () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        proposal_id: formValues.proposal_id,
        announcement_id: formValues.announcement_id,
        action_type: formValues.action_type,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/announcement/Proposal-Acceptance-By-Customer`,
        requestOptions
      )
        .then((response) => response.json())
        // .then((result) => console.log("2", result))
        .then((result) => {
          console.log("2", result);
          console.log(result.status);
          if (result.status == 200) {
            toast("Partner Successfully Selected");
            window.location = `/ViewCustomerMilestone/${p_id}`;
          }
          getProposalList();
        })

        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  const handleMilestone = () => {
    toast.success("Thank you for select vendor for your announcement");
    // // on confirmation a mouse click:
    window.location.href = "/customer-milestone";
    // // redirec to payment:
    // window.location.replace("/customer-invoice-payment");
  };

  data.map((key) => {
    key.milestonelist.map((item, index) => {
      if (index == 0) {
        console.log("Milestone Price", item);
      }
    });
  });

  const toggle = (i) => {
    if (showMore == i) {
      return setShowMore(null);
    }
    setShowMore(i);
  };

  console.log(p_id);

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />
        <main className="maincontent">
          <div>
            {data.map((item) => {
              if (ID == item.announcement_id && p_id == item.proposal_id) {
                return (
                  <>
                    <h3 className="VPDproposalhead">Project Proposal Data</h3>
                    <div className="VPDcard1">
                      <table>
                        <tr>
                          <td className=" ctitle">Project Name</td>
                          <td className=" cbody">{parse(item.project_name)}</td>
                        </tr>

                        <tr className="wrap">
                          <td className=" ctitle">Vendor Name</td>
                          <td className=" cbody">
                            {item.proposal_status == 0 ? (
                              <></>
                            ) : item.proposal_status == 1 ? (
                              <> {item.vendor_name}</>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>

                        <tr className="wrap">
                          <td className=" ctitle">Project Description</td>
                          <td className="cbody">
                            {showMore == item ? (
                              <p>{parse(item.project_description)}</p>
                            ) : (
                              <p>
                                {parse(
                                  item.project_description.substring(0, 300)
                                )}
                              </p>
                            )}
                            {item.project_description.length <= 300 ? (
                              ""
                            ) : (
                              <span
                                className="markascomplete"
                                style={{ color: "blue", cursor: "pointer" }}
                                onClick={() => toggle(item)}
                              >
                                {showMore == item ? "Show less" : "Show more"}
                              </span>
                            )}
                          </td>
                        </tr>

                        <tr className="wrap">
                          <td className=" ctitle">Project Resources</td>
                          <td className=" cbody">
                            {parse(item.project_resources)}
                          </td>
                        </tr>

                        <tr className="wrap">
                          <td className=" ctitle">Project Support</td>
                          <td className=" cbody">
                            {parse(item.project_support)}
                          </td>
                        </tr>

                        <tr className="wrap">
                          <td className="ctitle">Proposal Out of Scope</td>
                          <td className="cbody">{parse(item.out_of_scope)}</td>
                        </tr>

                        <tr className="wrap">
                          <td className=" ctitle">Proposal Price</td>
                          <td className=" cbody">
                            {item.customer_amount} {userInfoData.currency_code}
                          </td>
                        </tr>

                        <tr className="wrap">
                          <td className="ctitle">Proposal Timeline</td>
                          <td className="cbody">
                            {item.timeline} {item.timeline_unit}
                          </td>
                        </tr>

                        <tr className="wrap">
                          <td className="ctitle">
                            Proposal Terms & Conditions
                          </td>
                          <td className="cbody">
                            {parse(item.terms_conditions)}
                          </td>
                        </tr>
                      </table>
                    </div>
                    <br />
                    {item.milestonelist.length != 0 ? (
                      <div>
                        <h3>Milestone Data</h3>
                        <div className="mtableprops">
                          <table className="rwd-table">
                            <tbody>
                              <tr className="milestone-heading">
                                <th className="tcmi">Sr.No.</th>
                                <th>Milestone Description </th>
                                <th className="tcmi">Milestone Percent</th>
                                <th className="tcmi">Milestone Amount</th>
                                <th className="tcmi">Milestone Timeline </th>
                              </tr>

                              {item.milestonelist.map((key, index) => {
                                return (
                                  <tr>
                                    <td
                                      className="milestone-data tcmi"
                                      data-th="Sr.No."
                                    >
                                      {index + 1}
                                    </td>
                                    <td
                                      className="milestone-data"
                                      data-th="Milestone Description"
                                    >
                                      {key.milestone_description}
                                    </td>
                                    <td
                                      className="milestone-data tcmi"
                                      data-th="Milestone Percent"
                                    >
                                      {key.user_milestone_percent}%
                                    </td>
                                    <td
                                      className="milestone-data tcmi"
                                      data-th="Milestone Amount"
                                    >
                                      {key.user_milestone_amount}
                                      {key.user_currency}
                                    </td>
                                    <td
                                      className="milestone-data tcmi"
                                      data-th="Milestone Timeline"
                                    >
                                      {key.milstone_timeline}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>

                        {/* <p className="proposalnote">
                      Note - Please review all the terms and conditions given by
                      vendor on the project and select the vendor Accordingly.
                      On click on the select vendor rest of proposal goes
                      decline from the list.
                    </p> */}

                        {item.proposal_status == 0 ? (
                          <div className="footerbtn">
                            <button
                              className="btn selectproposal"
                              onClick={() => {
                                formValues.action_type = 1;
                                formValues.announcement_id =
                                  item.announcement_id;
                                formValues.proposal_id = item.proposal_id;
                                setOpenModal(true);
                                proposalAcceptedList();
                              }}
                            >
                              Select Vendor
                            </button>

                            <button
                              className="btn selectproposal"
                              onClick={() => {
                                window.location = `/projectlist/${item.announcement_id}/${item.project_name}`;
                              }}
                            >
                              Back
                            </button>
                          </div>
                        ) : item.proposal_status == 1 ? (
                          <>
                            {/* <Link to={`/milestone/${item.announcement_id}/${item.proposal_id}`}> */}
                            <Link to={`/ViewCustomerMilestone/${p_id}`}>
                              <button className="btn-apply_milestone">
                                View Milestone
                              </button>
                            </Link>
                            {/* <button
                              className="btn selectproposal"
                              onClick={() => {
                               window.location=`/projectlist/${item.announcement_id}/${item.project_name}`
                              }}
                            >
                              Back
                            </button>  */}
                            {/* </Link> */}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              }
            })}
          </div>
        </main>
      </div>
    </div>
  );
};

export default ViewProposalListDetails;
