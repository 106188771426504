import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { useForm, FormProvider, Controller } from "react-hook-form";
import toolTip from "../../../assets/images/tooltip.png";
import uploadFile from "../../../assets/images/apuploadfile.png";
import { Tooltip, Whisper, Button } from "rsuite";
import { FileUploader } from "react-drag-drop-files";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Form from "react-bootstrap/Form";
import redcross from "../../../assets/images/redcross.png";
import { useDispatch, useSelector } from "react-redux";
import AddGallery from "../AddNewProducts/AddGallery";
import { useDropzone } from "react-dropzone";
import ProductFilter from "../AddNewProducts/ProductFilter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseUrl } from "../../../Api/api";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { useNavigate } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader";

const VendorEditProduct = () => {
  const { selectedSingleProductData, selectedProductId } = useSelector(
    (state) => state.productList
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentData, setCurrentData] = useState({});
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const gstPercentage = watch("gst_percentage");
  const initialPrice = watch("initial_price");
  const discount = watch("discount");
  const priceWithoutDiscount = watch("price_without_discount");
  const methods = useForm();
  const Product_id = selectedSingleProductData.id;
  // console.log("selectedSingleProductData", selectedSingleProductData);

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.type.startsWith("image/") && file.size <= 5000000) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width === 500 && img.height === 500) {
            setProductImage((prevFiles) => [...prevFiles, file]);
            console.log(productImage, "product image loaded");
          } else {
            console.log("wrong file");
            toast.error(
              "Invalid Product Images Size Please Select Image Size Height & Width Of 500px Only"
            );
          }
        };
      }
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    minFiles: 2,
    onDrop,
  });

  const [inputValue, setInputValue] = useState("");
  const [count, setCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("dropdown");
  const [dropdownValue, setDropdownValue] = useState({ logos: "", name: "" });
  const [brandImage, setBrandImage] = useState("");
  const [productImage, setProductImage] = useState([]);
  const [selectPricing, setSelectPricing] = useState(currentData.is_price);
  const [showProductError, setShowProductError] = useState(false);
  const [productValue, setProductValue] = useState("");
  const [productCount, setProductCount] = useState(0);
  const [finalPriceValue, setFinalPriceValue] = useState(null);
  const [price, setPrice] = useState(currentData.initial_price);
  const [percentage, setPercentage] = useState();
  const [selectedDiscounts, setSelectedDiscounts] = useState(
    +currentData.discount
  );
  const [discountedValue, setDiscountedValue] = useState(null);
  const [includeTax, setIncludeTax] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [setProductImages, setShowProductImages] = useState([]);
  const currentTax = currentData.is_tax_include == 1 ? true : false;
  const [tax, setTax] = useState();
  const [switchState, setSwitchState] = useState(currentTax);
  const [priceError, setPriceError] = useState(false);
  const [taxValue, setTaxValue] = useState(0);
  const userToken = localStorage.getItem("token");
  useEffect(() => {
    const currentTax = currentData.is_tax_include == 1 ? true : false;
    setSwitchState(currentTax);
    const InitialProductCount =
      currentData &&
      currentData.product_name &&
      currentData.product_name.length;
    setProductCount(InitialProductCount);
    const InitialDescCount =
      currentData &&
      currentData.product_information &&
      currentData.product_information.length;
    setCount(InitialDescCount);
    const InitialTaxType = currentData && currentData.is_tax_include;
    setTax(InitialTaxType);
    const currentTaxType = currentData.is_tax_include == 1 ? true : false;
    // console.log(currentTaxType, "current tax type");
    setIncludeTax(currentTaxType);
    setPercentage(currentData.gst_percentage);
  }, [currentData]);
  // console.log("setting switchstate", switchState);
  // console.log(is_tax_include , "is_tax_include")
  // console.log(includeTax, "include tax state value");
  const onSubmit = (data) => {
    // console.log(data, "on submit @@@");
    setPageLoading(true);

    let industryID = [];
    let industryName = [];
    let departmentName = [];
    let departmentID = [];

    if (
      Array.isArray(data.industry_name) &&
      typeof data.industry_name[0] === "object"
    ) {
      industryID = data.industry_name.map((obj) => obj.id);
    }
    if (data.industry_name && Array.isArray(data.industry_name)) {
      industryName = data.industry_name.map((obj) => obj.industry_name);
    }

    if (data.department_name && Array.isArray(data.department_name)) {
      departmentName = data.department_name.map((obj) => obj.department_name);
      departmentID = data.department_name.map((obj) => obj.id);
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    var formdata = new FormData();
    formdata.append("product_name", data.product_name);
    formdata.append("category_id", data.category_id);
    formdata.append("product_description", data.Description);
    formdata.append("product_information", data.product_information);
    formdata.append("features", data.Features);
    formdata.append("specifications", data.Specification);
    formdata.append("video_link", data.video_link);
    formdata.append("price_currency", data.price_currency);
    formdata.append("category_name", data.category_name);
    formdata.append(
      "department_id",
      departmentID.length > 0 ? departmentID : data.department_id
    );
    formdata.append(
      "department_name",
      departmentName.length > 0 ? departmentName : data.department_name
    );
    formdata.append(
      "industry_id",
      industryID.length > 0 ? industryID : data.industry_id
    );
    formdata.append(
      "industry_name",
      industryName.length > 0 ? industryName : data.industry_name
    );
    formdata.append("video_description", "video_description");
    productImage.forEach((element) => {
      formdata.append("gallery", element);
    });
    formdata.append("brand_name", data.brand_name);
    formdata.append("is_price", data.is_price);
    formdata.append(
      "is_tax_include",
      tax != undefined ? tax : currentData.is_tax_include
    );
    formdata.append("initial_price", data.initial_price);
    formdata.append(
      "gst_percentage",
      data.gst_percentage > 0 && tax == 1 ? data.gst_percentage : 0
    );
    formdata.append("discount", data.discount ? data.discount : 0);
    formdata.append(
      "price",
      data.price ? Number(data.price).toFixed(2) : currentData.price
    );
    formdata.append(
      "price_without_discount",
      data.price_without_discount && tax == 1 ? data.price_without_discount : 0
    );
    formdata.append("price_type", data.price_type);
    formdata.append("offer_text", data.offer_text);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${BaseUrl}/product/New/${Product_id}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          // console.log(result, "api response");
          setPageLoading(false);
          toast.success("Product Updated Successfully");
          navigate("/vendor-product-lists");
          window.scrollTo(0, 0);
        } else if (
          result.message ==
          "Not Update Or Invalid Image Type Or Price is not correct."
        ) {
          setPageLoading(false);
          toast.error(
            "Price Fields and Discount Field Not Valid Please Try Again"
          );
          setPriceError(true);
        } else {
          setPageLoading(false);
          console.log(result, "api error");
          toast.error("Network Error");
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handlePriceChange = (e) => {
    console.log("price changed");

    setPrice(e.target.value);
    calculateTaxAddedValue(e.target.value, percentage);
    // calculateDiscountedFinalValue(priceWithoutDiscount, discount);
  };

  const handlePercentageChange = (e) => {
    // console.log(e.target.value, "percentage value from handle change");
    setPercentage(e.target.value);
    calculateTaxAddedValue(
      price ? price : currentData.initial_price,
      e.target.value
    );

    // console.log(
    //   watch("price_without_discount"),
    //   watch("discount"),
    //   "from values with out discount and discount"
    // );
    // calculateDiscountedFinalValue(
    //   watch("price_without_discount"),
    //   watch("discount")
    // );
  };

  const calculateTaxAddedValue = (price, percentage) => {
    console.log(price, percentage, "price and percentage");
    const priceValue = Number(price) || 0;
    const percentageValue = Number(percentage) || 0;
    const percentageAmount = priceValue * (percentageValue / 100);
    const result = priceValue + percentageAmount;
    setFinalPriceValue(result.toFixed(2) || null);
    setTaxValue(percentage);
    calculateDiscountedFinalValue(
      result,
      selectedDiscounts ? selectedDiscounts : currentData.discount
    );
    console.log(result, selectedDiscounts, "inside of calculate final  value");
  };
  const handleDiscountsChange = (e) => {
    // console.log(e.target.value, "handleDiscountsChange e value");
    const discountValue = e.target.value;
    setSelectedDiscounts(discountValue);

    // calculateTaxAddedValue(
    //   watch("price_without_discount"),
    //   taxValue ? taxValue : currentData.gst_percentage
    // );
    calculateDiscountedFinalValue(
      watch("price_without_discount"),
      e.target.value
    );
    setValue("discount", e.target.value);
    // console.log(
    //   finalPriceValue,
    //   e.target.value,
    //   currentData.price_without_discount,
    //   "inside of discount change"
    // );
  };
  const calculateDiscountedFinalValue = (value, Discount) => {
    console.log("calculateDiscountedFinalValue called");
    let discountedAmount = value ? value : currentData.price_without_discount;
    const discountValue = Discount;
    discountedAmount = discountedAmount - value * (discountValue / 100);
    setDiscountedValue(discountedAmount ? discountedAmount : null);
  };

  const handleInputProduct = (event) => {
    const newValue = event.target.value;
    setProductValue(newValue);
    setProductCount(newValue.length);
  };

  const MAX_WIDTH = 100;
  const MIN_WIDTH = 80;
  const MAX_HEIGHT = 100;
  const MIN_HEIGHT = 80;
  const handleChange = (files) => {
    setDropdownValue({ logos: "", name: "" });
    const image = files;
    const img = new Image();
    img.src = window.URL.createObjectURL(image);
    img.onload = () => {
      const { width, height } = img;
      if (
        width >= MIN_WIDTH &&
        width <= MAX_WIDTH &&
        height >= MIN_HEIGHT &&
        height <= MAX_HEIGHT
      ) {
        setBrandImage(image);
      } else {
        toast.error(
          "Invalid Brand Logo Size Please Select Image Size Height & Width Of 100px Only"
        );
        console.log("Invalid image dimensions");
      }
    };
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setCount(e.target.value.length);
  };
  const fileTypes = ["JPEG", "PNG"];

  const handleChangePricing = (e) => {
    setSelectPricing(e.target.value);
  };
  const editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "undo",
        "redo",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    placeholder: "Enter text here...",
  };
  editorConfig.toolbar.items.splice(
    editorConfig.toolbar.items.indexOf("table"),
    1
  );
  useEffect(() => {
    // setPercentage(e.target.value);
    // window.location.reload();
  }, [percentage, price]);
  // const handleChangeSwitch = (state) => {
  //   setIncludeTax(state.target.checked);
  //   setSwitchState(!switchState);
  //   console.log(switchState , "Switch state")
  //   if (switchState) {
  //     setPercentage(0);
  //   }
  //   const is_tax_include = includeTax ? 0 : 1;
  //   console.log(includeTax, is_tax_include, "include tax state");
  //   setTax(is_tax_include);
  //   const gstPercentage = watch("gst_percentage");
  //   const initialPrice = watch("initial_price");
  //   const discount = watch("discount");
  //   const priceWithoutDiscount = watch("price_without_discount");
  //   // console.log(
  //   //   discount,
  //   //   priceWithoutDiscount,
  //   //   initialPrice,
  //   //   "inside of switch"
  //   // );
  //   // console.log(switchState , "switch state")
  //   // if (!switchState) {
  //   //   calculateDiscountedFinalValue(discount, +priceWithoutDiscount);
  //   //   console.log("switch state is false ", discount, priceWithoutDiscount);
  //   // } else if (switchState) {
  //   //   calculateDiscountedFinalValue(discount, initialPrice);
  //   //   console.log("switch state is true ", discount, initialPrice);
  //   // }
  // };
  // console.log(is_tax_include, "include tax");

  const handleChangeSwitch = (event) => {
    const newState = event.target.checked;
    setIncludeTax(newState);
    setSwitchState((prevState) => {
      const newSwitchState = !prevState;
      // console.log(newSwitchState, newState, switchState, "new switch state");
      if (!newSwitchState) {
        calculateTaxAddedValue(price ? price : currentData.initial_price, 0);
        setValue("gst_percentage", 0);
      }
      if (!newSwitchState) {
        calculateDiscountedFinalValue(
          watch("initial_price"),
          watch("discount")
        );
      } else {
        calculateDiscountedFinalValue(
          watch("price_without_discount"),
          watch("discount")
        );
      }
      return newSwitchState;
    });
    const is_tax_include = newState ? 1 : 0;
    setTax(is_tax_include);
  };

  const handleChangeRemoveBrand = () => {
    setBrandImage("");
  };

  const handleDelete = (index) => {
    const newImages = [...productImage];
    newImages.splice(index, 1);
    setProductImage(newImages);
  };

  useEffect(() => {
    setPageLoading(true);
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${BaseUrl}/product/Get-Product-By-Id?product_id=${Product_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        // console.log(res, "product api by id");
        if (res.status === 200) {
          window.scrollTo(0, 0);
          setCurrentData(res.data?.productDetail);
          setShowProductImages(res.data?.productImages);
          setPageLoading(false);
          calculateTaxAddedValue(
            currentData.initial_price,
            currentData.gst_percentage
          );
          setDropdownValue({ logos: "", name: currentData?.brand_name });
        } else {
          setPageLoading(false);
          toast.error("Something went wrong");
        }
      })
      .catch((error) => console.log("error", error));
  }, []);

  useEffect(() => {
    setValue("price", discountedValue);
    setValue(
      "price_without_discount",
      finalPriceValue && finalPriceValue != "0.00"
        ? finalPriceValue
        : currentData.price_without_discount
    );
  }, [finalPriceValue, discountedValue, setValue]);

  useEffect(() => {
    for (let key in currentData) {
      if (currentData[key] === null) {
        Object.keys(currentData)?.map((key) => {
          // console.log(key, currentData[key]);
          setValue(key, currentData[key]);
        });
      }
    }
    setValue("initial_price", currentData.initial_price);
  }, [currentData]);

  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />

          {currentData && (
            <FormProvider {...methods}>
              <form
                className="add-new-product-main"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="add-product-border">
                  <h3 className="add-product">Edit Product Information</h3>
                </div>
                <div className="product-border">
                  <h3 className="addproduct">Product</h3>
                </div>
                <div className="anp-left-right-div">
                  <div className="anp-left-div">
                    <div className="pt-2">
                      <div className="anp-label-input">
                        <label className="anp-label">Product Name </label>
                        <Whisper
                          trigger="click"
                          speaker={
                            <Tooltip>
                              Product Name should be max 50 characters
                            </Tooltip>
                          }
                        >
                          <Button appearance="subtle" type="button">
                            {" "}
                            <img className="tool-tip" src={toolTip} alt="" />
                          </Button>
                        </Whisper>
                      </div>
                      <div>
                        <input
                          type="text"
                          className="anp-input"
                          //   value={productValue}
                          maxLength="50"
                          {...register("product_name", {
                            required: true,
                            minLength: 3,
                            maxLength: 50,
                            onChange: handleInputProduct,
                          })}
                        />
                        {errors.product_name && (
                          <span className="error-msg">
                            Please enter the product name
                          </span>
                        )}
                        <p className="anp-input-count">{productCount}/50</p>
                      </div>
                    </div>
                    <div className="pt-2">
                      <div
                        className="anp-label-input"
                        style={{ display: "block" }}
                      >
                        <label className="anp-label">Product Brand </label>{" "}
                        <br />
                      </div>

                      <input
                        type="text"
                        className="anp-input"
                        disabled
                        {...register("brand_name", {
                          required: true,
                        })}
                      />
                    </div>
                    <div className="pt-2">
                      <div className="anp-label-input">
                        <label className="anp-label">Short Description </label>
                        <Whisper
                          trigger="click"
                          speaker={
                            <Tooltip>
                              Short description should be max 150 characters
                            </Tooltip>
                          }
                        >
                          <Button appearance="subtle">
                            {" "}
                            <img className="tool-tip" src={toolTip} alt="" />
                          </Button>
                        </Whisper>
                      </div>
                      <textarea
                        type="text"
                        className="anp-input anp-textarea"
                        maxLength="150"
                        // value={inputValue}
                        {...register("product_information", {
                          required: true,
                          minLength: 3,
                          maxLength: 150,
                          onChange: (e) => handleInputChange(e),
                        })}
                      />
                      {errors.product_information && (
                        <span className="error-msg">
                          Please enter the short description
                        </span>
                      )}
                      <p className="anp-input-count">{count}/150</p>
                    </div>
                  </div>
                  <div className="anp-right-div">
                    {(selectedOption === "input" ||
                      selectedOption === "dropdown") && (
                      <div className="anp-label-input">
                        <p className="anp-label">Brand Logo </p>
                        <Whisper
                          trigger="click"
                          speaker={<Tooltip>100*100px Image only</Tooltip>}
                        >
                          <Button appearance="subtle">
                            {" "}
                            <img className="tool-tip" src={toolTip} alt="" />
                          </Button>
                        </Whisper>
                      </div>
                    )}
                    <div className="anp-add-brand-div pb-3">
                      {selectedOption === "input" && (
                        <div style={{ width: "150px" }}>
                          <FileUploader
                            multiple={false}
                            handleChange={(files) => handleChange(files)}
                            name="file"
                            //   disabled={true}
                            children={
                              <div className="anp-upload-brand-div">
                                <div className="anp-upload-brand">
                                  <img
                                    className="anp-upload-brand-img"
                                    src={uploadFile}
                                    alt=""
                                  />{" "}
                                </div>
                                <button type="button" className="anp-btn-brand">
                                  {" "}
                                  +{" "}
                                </button>
                              </div>
                            }
                            types={fileTypes}
                          />
                          <p className="anp-input-count pt-3">
                            drop file here, paste or browse
                          </p>
                        </div>
                      )}

                      <div className="anp-selected-b-img">
                        <img
                          className="anp-selected-brand-img"
                          src={
                            brandImage
                              ? URL.createObjectURL(brandImage)
                              : currentData.brand_logo_url
                          }
                          alt="Selected Image"
                        />
                        <p className="anp-b-img-name">
                          {brandImage.name
                            ? brandImage.name
                            : currentData.brand_logo_name}
                        </p>
                        {brandImage && (
                          <button
                            type="button"
                            style={{ marginTop: "-100px", padding: "0px" }}
                            onClick={handleChangeRemoveBrand}
                          >
                            <img
                              src={redcross}
                              alt=""
                              className="anp-red-cross"
                            />{" "}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="anp-product-imgs-div">
                      <div className="anp-gallery-div">
                        <div>
                          <div className="anp-add-product-div">
                            <div
                              className="anp-label-input"
                              style={{ marginTop: "-28px" }}
                            >
                              <p className="anp-label">Product Image </p>
                              <Whisper
                                trigger="click"
                                speaker={
                                  <Tooltip>
                                    500*500px Image only and add minimum two
                                    images
                                  </Tooltip>
                                }
                              >
                                <Button appearance="subtle" type="button">
                                  {" "}
                                  <img
                                    className="tool-tip"
                                    src={toolTip}
                                    alt=""
                                  />
                                </Button>
                              </Whisper>
                            </div>

                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {isDragActive ? (
                                <p>Drop the files here ...</p>
                              ) : (
                                <div className="anp-upload-product-div">
                                  <div className="anp-upload-product">
                                    <div className="anp-add-product-img-div">
                                      <img
                                        className="anp-upload-product-img"
                                        src={uploadFile}
                                        alt=""
                                      />{" "}
                                    </div>
                                    <p className="anp-browse-text">
                                      Browse and choose the files you want to
                                      upload from your computer
                                    </p>
                                  </div>
                                  <button
                                    className="anp-btn-brand anp-btn-product"
                                    type="button"
                                  >
                                    {" "}
                                    +{" "}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>

                          <p className="anp-input-count pt-2">
                            500*500px Images only <br /> and add minimum two
                            images
                          </p>
                          {showProductError && (
                            <span className="error-msg">
                              {" "}
                              Please add product images atleast two
                            </span>
                          )}
                        </div>
                      </div>
                      {(productImage.length > 0 ||
                        setProductImages.length > 0) && (
                        <AddGallery
                          images={productImage}
                          onDelete={handleDelete}
                          oldImages={
                            !productImage.length > 0 && setProductImages
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>

                <ProductFilter
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  data={currentData}
                />
                <div className="product-border">
                  <h3 className="addproduct">Pricing</h3>
                </div>
                <div className="anp-pricing-div">
                  <select
                    className="anp-input anp-pricing-dropdown"
                    disabled
                    {...register("is_price", {
                      required: true,
                      onChange: (e) => handleChangePricing(e),
                    })}
                  >
                    <option value="Price">Fixed Pricing</option>{" "}
                    <option value="Package">Packages</option>{" "}
                    <option value="Quote">Request On Quote</option>
                  </select>
                  {errors.is_price && (
                    <span className="error-msg">
                      Please select the brand name
                    </span>
                  )}
                  {(selectPricing === "Package" ||
                    currentData.is_price === "Package") && (
                    <p className="anp-quote-text">
                      Packages selected for this product
                    </p>
                  )}
                  {(selectPricing === "Quote" ||
                    currentData.is_price === "Quote") && (
                    <p className="anp-quote-text">
                      Request a Quote Option selected for this product.
                    </p>
                  )}
                </div>

                {(selectPricing === "Price" ||
                  currentData.is_price === "Price") && (
                  <>
                    <div className="anp-select-Package-Div">
                      <div>
                        <label htmlFor="" className="anp-price-drop-label">
                          Select Package
                        </label>{" "}
                        <br />
                        <select
                          className="anp-select-drop"
                          {...register("price_type", {
                            required: selectPricing === "Price",
                          })}
                        >
                          {" "}
                          <option value="">Select</option>
                          <option value="FixedPrice">Fixed Price</option>
                          <option value="Monthly">Monthly</option>
                          <option value="Quarterly">Quarterly</option>
                          <option value="HalfYearly">Half Yearly</option>
                          <option value="Yearly">Yearly</option>
                        </select>
                        {errors.price_type && (
                          <span className="error-msg">
                            Please select the package type
                          </span>
                        )}
                        <p className="anp-price-text">Select package type</p>
                      </div>
                      <div>
                        <label htmlFor="" className="anp-price-drop-label">
                          Currency
                        </label>
                        <br />
                        <input
                          disabled
                          value={currentData.price_currency}
                          className="anp-input anp-select-drop"
                          {...register("price_currency", {
                            // required: selectPricing === "Price",
                          })}
                        ></input>
                        {errors.price_currency && (
                          <span className="error-msg">
                            Please select the currency
                          </span>
                        )}
                        <p className="anp-price-text">Select Currency</p>
                      </div>
                      <div>
                        <label htmlFor="" className="anp-price-drop-label">
                          Product Price
                        </label>
                        <br />
                        <input
                          placeholder="Input amount"
                          type="number"
                          className="anp-select-drop"
                          {...register("initial_price", {
                            required: selectPricing === "Price",
                            onChange: handlePriceChange,
                            value: price ? price : currentData.initial_price,
                          })}
                        />
                        {errors.initial_price && (
                          <span className="error-msg">
                            Please enter the price
                          </span>
                        )}

                        <p className="anp-price-text">
                          Enter product price without GST
                        </p>
                      </div>
                    </div>
                    <div className="anp-select-Package-Div">
                      <div>
                        <label
                          htmlFor=""
                          className="anp-price-drop-label"
                        ></label>
                        {currentData && (
                          <Form className="anp-toggle">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label="Include tax"
                              onChange={handleChangeSwitch}
                              checked={switchState}
                            />
                          </Form>
                        )}
                        {/* {JSON.stringify(switchState)} */}
                        <p className="anp-price-text">
                          Toggle on to include gst
                        </p>
                      </div>
                      {switchState && (
                        <>
                          {" "}
                          <div>
                            <label htmlFor="" className="anp-price-drop-label">
                              Tax Percentage
                            </label>
                            <br />
                            <div name="" id="" className="anp-select-drop">
                              <input
                                type="number"
                                placeholder="Input text"
                                className="anp-tax-input"
                                value={switchState ? percentage : 0}
                                {...register("gst_percentage", {
                                  required: switchState,
                                  onChange: (e) => handlePercentageChange(e),
                                  min: 0,
                                  max: 99,
                                  maxLength: 2,
                                  valueAsNumber: true,
                                  pattern: /^[0-9]*$/,
                                })}
                              />
                            </div>
                            <p className="anp-price-text">
                              Enter Tax percentage
                            </p>

                            {/* <br /> */}
                            {errors.gst_percentage && (
                              <span className="error-msg">
                                Please enter the valid tax percentage
                              </span>
                            )}
                            {priceError && (
                              <span className="error-msg">
                                Please re-enter the tax percentage
                              </span>
                            )}
                          </div>
                          <div>
                            <label htmlFor="" className="anp-price-drop-label">
                              Price Including Tax (MRP)
                            </label>
                            <br />
                            <input
                              className="anp-select-drop"
                              placeholder="Input text"
                              name="price_without_discount"
                              disabled
                              {...register("price_without_discount", {
                                value:
                                  finalPriceValue !== null &&
                                  finalPriceValue != "0.00"
                                    ? finalPriceValue
                                    : currentData.price_without_discount,
                              })}
                              // ref={register}
                            />
                            {/* {JSON.stringify(finalPriceValue)} */}
                          </div>{" "}
                        </>
                      )}
                    </div>
                    <div className="anp-select-Package-Div">
                      <div className="anp-disc-div">
                        <label htmlFor="" className="anp-price-drop-label">
                          Discount
                        </label>{" "}
                        <br />
                        <input
                          type="number"
                          placeholder="Please enter discount percentage"
                          className="anp-input anp-select-drop"
                          {...register("discount", {
                            required: selectPricing === "Price",
                            onChange: (e) => handleDiscountsChange(e),
                            min: 0,
                            max: 99,
                            maxLength: 2,
                            valueAsNumber: true,
                            pattern: /^[0-9]*$/,
                          })}
                        />
                        {errors.discount && (
                          <span className="error-msg">
                            Please enter the valid discount percentage
                          </span>
                        )}
                        {priceError && (
                          <span className="error-msg">
                            Please re-enter the discount percentage
                          </span>
                        )}
                      </div>
                      <div>
                        <label htmlFor="" className="anp-price-drop-label">
                          Offer
                        </label>
                        <br />
                        <input
                          {...register("offer_text", {
                            minLength: 3,
                            maxLength: 50,
                          })}
                          className="anp-select-drop"
                          placeholder="Input text"
                        />
                        <p className="anp-price-text">
                          Enter available offer for this product{" "}
                        </p>
                      </div>
                      <div>
                        <label htmlFor="" className="anp-price-drop-label">
                          Total Price
                        </label>
                        <br />
                        <input
                          value={
                            discountedValue !== null
                              ? Number(discountedValue).toFixed(2)
                              : price
                              ? price
                              : currentData.price
                          }
                          className="anp-select-drop"
                          disabled
                          name="price"
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="product-border">
                  <h3 className="addproduct">Description</h3>
                </div>
                <div className="pt-3">
                  <Controller
                    name="Description"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <CKEditor
                          editor={ClassicEditor}
                          config={editorConfig}
                          data={
                            currentData && currentData.product_description
                              ? currentData.product_description
                              : value
                          }
                          onBlur={onBlur}
                          onChange={(event, editor) =>
                            onChange(editor.getData())
                          }
                        />
                        {errors.Description && (
                          <span className="error-msg">
                            Please enter the description
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="product-border">
                  <h3 className="addproduct">Specification </h3>
                </div>
                <div className="pt-3">
                  <Controller
                    name="Specification"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <CKEditor
                          editor={ClassicEditor}
                          config={editorConfig}
                          data={
                            currentData && currentData.specifications
                              ? currentData.specifications
                              : value
                          }
                          onBlur={onBlur}
                          onChange={(event, editor) =>
                            onChange(editor.getData())
                          }
                        />
                        {errors.Specification && (
                          <span className="error-msg">
                            Please enter the specification
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="product-border">
                  <h3 className="addproduct">Features </h3>
                </div>
                <div className="pt-3">
                  <Controller
                    name="Features"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <>
                        <CKEditor
                          editor={ClassicEditor}
                          config={editorConfig}
                          data={
                            currentData && currentData.features
                              ? currentData.features
                              : value
                          }
                          onBlur={onBlur}
                          onChange={(event, editor) =>
                            onChange(editor.getData())
                          }
                        />
                        {errors.Features && (
                          <span className="error-msg">
                            Please enter the features
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="product-border">
                  <h3 className="addproduct">Upload Video (Optional ) </h3>
                </div>
                <input
                  className="anp-input mt-2"
                  placeholder="Enter URL"
                  type="url"
                  {...register("video_link", {
                    required: true,
                    minLength: 3,
                    maxLength: 150,
                  })}
                />
                {errors.video_link && (
                  <span className="error-msg">Please enter the video link</span>
                )}
                <div className="anp-btn-save-div">
                  <button className="anp-btn-save" type="submit" name="submit">
                    Save
                  </button>
                </div>
              </form>
            </FormProvider>
          )}
        </div>
      </div>
    </>
  );
};

export default VendorEditProduct;
