import React, { useState, useEffect } from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";
import "./Vendor.css";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IoIosAddCircle } from "react-icons/io";
import { BsFillDashCircleFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import CommonApi from "../../Api/commonApi";

const Modal = ({ closeModal }) => {
  const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
  const announcement_id = localStorage.getItem(
    "vendorproject",
    // announcement_id
  );

  const initialFormData = {
    announcement_id: announcement_id.toString(),
    // announcement_id: announcement_id,
    currency: user.user_info.currency_code,
    price: "",
    timeline: "",
    timeline_unit: "",
    perposal_description: "",
    project_resources: "",
    project_support: "",
    out_of_scope: "",
    terms_conditions: "",
    milestone_description: "",
    milestone_percent: "",
    milestone_count: "1",
    milestone_amount: "",
    milestone_timeline: "",
  };

  const projectName = useParams().project_name;
  const [agree, setAgree] = useState(false);
  const [Description, setDescription] = useState([]);
  const [Percent, setPercent] = useState([]);
  const [M_Timeline, setM_Timeline] = useState([]);
  const [inputFields, setinputField] = useState([
    { milestone_percent: "", milestone_description: "" },
  ]);

  const handleChangeInput = (index, event) => {
    const values = [...inputFields];
    values[index][event.target.name] = event.target.value;
    setinputField(values);
  };

  const handleAddField = () => {
    setinputField([
      ...inputFields,
      {
        milestone_percent: "",
        milestone_description: "",
      },
    ]);
  };

  const handleRemoveField = () => {
    const values = [...inputFields];
    values.splice(values.index, 1);
    setinputField(values);
  };

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree);
    // Don't miss the exclamation mark
  };

  const [formValues, setFormValues] = useState(initialFormData);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [File, setFile] = useState(null);
  const userToken = localStorage.getItem("token");

  console.log(formValues);

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    // var ImageData = JSON.stringify(e.target.files[0]);
    // console.log(ImageData, "Category Image");
    // localStorage.setItem("CategoryImage", ImageData);
  };

  const handleChangeText = (e, name) => {
    console.log(e);
    setFormValues((prevState) => ({
      ...prevState,
      ["perposal_description"]:
        name == "perposal_description" ? e : formValues.perposal_description,
      ["project_resources"]:
        name == "project_resources" ? e : formValues.project_resources,
      ["project_support"]:
        name == "project_support" ? e : formValues.project_support,
      ["out_of_scope"]: name == "out_of_scope" ? e : formValues.out_of_scope,
      ["terms_conditions"]:
        name == "terms_conditions" ? e : formValues.terms_conditions,
    }));
  };

  const handleChange = (e) => {
    setFormValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeDes = (e) => {
    setDescription((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangePer = (e) => {
    setPercent((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeTim = (e) => {
    setM_Timeline((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  // const err = ()=>{
  //   err = 'Click on  "I agree button"'
  //   toast(err)
  // }
  const handleSubmit = async (e) => {
    e.preventDefault();
    formValues.currency = user.user_info.currency_code;
    try {
      formValues.milestone_amount = String(
        (formValues.price * Percent["1"]) / 100
      );
      formValues.milestone_description = Description["1"];
      formValues.milestone_percent = Percent["1"];
      formValues.milestone_timeline = M_Timeline["1"];

      for (var i = 1; i < formValues.milestone_count; i++) {
        formValues.milestone_description = formValues.milestone_description.concat(
          "||",
          Description[i + 1]
        );
        formValues.milestone_percent = formValues.milestone_percent.concat(
          ",",
          Percent[i + 1]
        );
        formValues.milestone_timeline = formValues.milestone_timeline.concat(
          ",",
          M_Timeline[i + 1]
        );
        let milestone_amount = (formValues.price * Percent[i + 1]) / 100;
        formValues.milestone_amount = formValues.milestone_amount.concat(
          ",",
          milestone_amount
        );
      }
    } catch {
      console.log("Please check your input");
    }

    let error = validate(formValues);
    setFormErrors(validate(formValues));

    // if (File == null) {
    //   const error = "File Is Not Uploaded";
    //   toast.error(error, { theme: "colored" });
    // }

    if (Object.keys(error).length !== 0) {
      setFormErrors(error);
      console.log("Error Found");
      return false;
    }
    console.log("No Validation Error Found");
    // ... submit to API or something
    setIsSubmit(true);
    try {
      // alert("All Is Good, Go for Final Handle Submit");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      myHeaders.append("Authorization", `Bearer ${userToken}`);

      // var formdata = new FormData();
      // formdata.append("image", File);
      // formdata.append("announcement_id", formValues.announcement_id);
      // formdata.append("currency", formValues.currency);
      // formdata.append("price", formValues.price);
      // formdata.append("timeline", formValues.timeline);
      // formdata.append("timeline_unit", formValues.timeline_unit);
      // formdata.append("perposal_description", formValues.perposal_description);
      // formdata.append("project_resources", formValues.project_resources);
      // formdata.append("project_support", formValues.project_support);
      // formdata.append("out_of_scope", formValues.out_of_scope);
      // formdata.append("terms_conditions", formValues.terms_conditions);
      // formdata.append(
      //   "milestone_description",
      //   formValues.milestone_description
      // );
      // formdata.append("milestone_count", formValues.milestone_count);
      // formdata.append("milestone_percent", formValues.milestone_percent);
      // formdata.append("milestone_amount", formValues.milestone_amount);
      // formdata.append("milestone_timeline", formValues.milestone_timeline);

      // for (var pair of formdata.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(formValues),
        redirect: "follow",
      };

      console.log(requestOptions, "requestOptions");

      fetch(`${BaseUrl}/vendordashboard/Proposal-Apply`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          result = JSON.parse(result);
          console.log(result);
          if (result.status === 200) {
            toast("Your Proposal Submitted successfully");
            setFormValues("");
            //localStorage.setItem("Project", JSON.stringify(result.data));
            //alert(result.message);
            CommonApi.ProfileApi().then((vsdata) => {
              console.log("ApiRes", vsdata);
              if (vsdata.status === 200) {
                localStorage.setItem("userinfo", JSON.stringify(vsdata.data));
                window.location = "/Vendor-Project-Apply-List";
              }
            });
          } else {
            toast.error(result.message);
          }
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      console.log("formValues", formValues);
    }
  }, [formErrors]);

  const uploadImg = (events) => {
    console.log(events.target.files);
  };

  const validate = (values) => {
    console.log("validate", values);
    const errors = {};

    Object.entries(values).forEach(([key, value]) => {
      if (!String(values[key]).trim() || String(values[key]) == "undefined") {
        const keyname = key;

        const Keyname = keyname.charAt(0).toUpperCase() + keyname.slice(1);

        errors[key] = `${Keyname} Field is required`;
      }
    });
    console.log(errors);
    return errors;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(formValues.milestone_percent == undefined);
  return (
    <div className="proposal-wrapper">
      <div className="proposal-form">
        <h2 className="proformHeading">Build Your Proposal</h2>
        <div className="projectName">
          <h3>Project Name - {projectName}</h3>
        </div>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <div className="proposalform">
            <div className="form-group">
              <lable>Enter Project Amount</lable>
              <div className="currencydetails">
                <input
                  type="text"
                  name="price"
                  value={formValues.price}
                  placeholder="Enter Project Amount"
                  onChange={handleChange}
                  className="inputfield"
                />
                <select
                  name="currency"
                  className="currencies"
                  value={formValues.currency}
                  onChange={handleChange}
                >
                  <option value={user.user_info.currency_code}>
                    {user.user_info.currency_code}
                  </option>
                </select>
              </div>
            </div>
            <p className="err-message">{formErrors.price}</p>
            <p className="err-message">{formErrors.currency}</p>
            <div className="form-group">
              <lable>Project Timeline</lable>
              <div className="currencydetails">
                <input
                  type="text"
                  name="timeline"
                  value={formValues.timeline}
                  placeholder="Enter Project Timeline"
                  onChange={handleChange}
                  className="inputfield"
                />
                <select
                  name="timeline_unit"
                  className="currencies"
                  value={formValues.timeline_unit}
                  onChange={handleChange}
                >
                  <option value="Select">Select</option>
                  <option value="Days">Days</option>
                  <option value="Months">Months</option>
                </select>
              </div>
            </div>
            <p className="err-message">{formErrors.timeline}</p>
            <p className="err-message">{formErrors.timeline_unit}</p>
            <div className="form-group">
              <label>Project Scope of Work</label>
              <CKEditor
                type="texteditor textarea"
                name="perposal_description"
                placeholder="Description"
                editor={ClassicEditor}
                data="<p>Enter the Project Scope of Work Here.</p>"
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChangeText(data, "perposal_description");
                  console.log({ event, editor, data });
                }}
                value={formValues.perposal_description}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              {/* <textarea
                type="textarea"
                name="perposal_description"
                placeholder="Description"
                value={formValues.perposal_description}
                onChange={handleChange}
              ></textarea> */}
            </div>
            <p className="err-message">{formErrors.perposal_description}</p>

            <div className="form-group">
              <label>Select Total No. of Milestone</label>
              <div>
                <div className="forminput">
                  <div>
                    <select
                      className="mpercent"
                      name="milestone_count"
                      onChange={handleChange}
                    >
                      <option value="1">1</option>
                      {/* <option value="2">2</option> */}
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <p className="err-message">{formErrors.milestone_count}</p>

            <div className="form-group">
              <label>Project Milestone</label>
              <div>
                <div className="forminput">
                  <div>
                    {formValues.milestone_count == 1 ? (
                      <select
                        className="mpercent"
                        name="1"
                        value={Percent[0]}
                        onChange={handleChangePer}
                      >
                        <option value="0%">Select %</option>
                        <option value="100">100%</option>
                      </select>
                    ) : formValues.milestone_count == 2 ? (
                      <select
                        className="mpercent"
                        name="1"
                        value={Percent[0]}
                        onChange={handleChangePer}
                      >
                        <option value="0%">Select %</option>
                        <option value="50">50%</option>
                      </select>
                    ) : (
                      <select
                        className="mpercent"
                        name="1"
                        value={Percent[0]}
                        onChange={handleChangePer}
                      >
                        <option>Select %</option>
                        <option value="10">10%</option>
                        <option value="20">20%</option>
                        <option value="30">30%</option>
                        <option value="40">40%</option>
                        <option value="50">50%</option>
                      </select>
                    )}
                  </div>

                  <div>
                    <input
                      type="text"
                      label="Milestone Amount"
                      // name="1"
                      placeholder={(formValues.price * Percent["1"]) / 100}
                      // value={M_price[0] = formValues.price*Percent["1"]}
                      disabled
                      // onChange={handleChangePri}
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      label="Milestone Amount"
                      name="1"
                      placeholder="Milestone Description"
                      value={Description[0]}
                      onChange={handleChangeDes}
                    />
                  </div>
                  <div>
                    <input
                      type="date"
                      label="Milestone Timeline"
                      name="1"
                      placeholder="timeline"
                      value={M_Timeline[0]}
                      onChange={handleChangeTim}
                    />
                  </div>
                </div>
                {(function (rows, i, len) {
                  while (++i < len) {
                    rows.push(
                      <div className="forminput" key={i}>
                        <div>
                          {formValues.milestone_count == 2 ? (
                            <select
                              className="mpercent"
                              name={i + 1}
                              value={Percent[i + 1]}
                              onChange={handleChangePer}
                            >
                              <option>Select %</option>
                              <option value="10">50%</option>
                            </select>
                          ) : (
                            <select
                              className="mpercent"
                              name={i + 1}
                              value={Percent[i + 1]}
                              onChange={handleChangePer}
                            >
                              <option>Select %</option>
                              <option value="10">10%</option>
                              <option value="20">20%</option>
                              <option value="30">30%</option>
                              <option value="40">40%</option>
                              <option value="50">50%</option>
                            </select>
                          )}
                        </div>
                        <div>
                          <input
                            type="text"
                            label="Milestone Amount"
                            // name={i+1}
                            disabled
                            placeholder={
                              (formValues.price * Percent[i + 1]) / 100
                            }
                            // onChange={handleChangePri}
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            label="Milestone Amount"
                            name={i + 1}
                            placeholder="Milestone Description"
                            value={Description[i + 1]}
                            onChange={handleChangeDes}
                          />
                        </div>
                        <div>
                          <input
                            type="date"
                            label="Milestone Timeline"
                            name={i + 1}
                            placeholder="timeline"
                            value={M_Timeline[i + 1]}
                            onChange={handleChangeTim}
                          />
                        </div>
                      </div>
                    );
                    console.log("percent array", Percent[i + 1]);
                  }

                  return rows;
                })([], 0, formValues.milestone_count)}
                <p className="err-message">{formErrors.milestone_percent}</p>
                <p className="err-message">{formErrors.milestone_amount}</p>
                <p className="err-message">
                  {formErrors.milestone_description}
                </p>
                <p className="err-message">{formErrors.milestone_timeline}</p>
                {/* <h4>Milestone 2</h4>
                <div className="forminput">
                  <div>
                    <input type="text" name="project" value="25%" />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="project"
                      placeholder="Enter Your Milestone 2"
                      value={formValues.milestone_description_2}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <h4>Milestone 3</h4>
                <div className="forminput">
                  <div>
                    <input type="text" name="project" value="25%" />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="project"
                      placeholder="Enter Your Milestone 3"
                      value={formValues.milestone_description_3}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <h4>Milestone 4</h4>
                <div className="forminput">
                  <div>
                    <input type="text" name="project" value="25%" />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="project"
                      placeholder="Enter Your Milestone 4"
                      value={formValues.milestone_description_4}
                      onChange={handleChange}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <p className="err-message">{formErrors.milestone}</p>

            <div className="form-group">
              <label>Project Resources</label>
              <CKEditor
                type="texteditor textarea"
                name="project_resources"
                placeholder="Project Resources"
                editor={ClassicEditor}
                data="<p>Enter the Project Resources Here.</p>"
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChangeText(data, "project_resources");
                  console.log({ event, editor, data });
                }}
                value={formValues.project_resources}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              {/* <textarea
                type="textarea"
                name="project_resources"
                placeholder="Resources"
                value={formValues.project_resources}
                onChange={handleChange}
              ></textarea> */}
            </div>
            <p className="err-message">{formErrors.project_resources}</p>
            <div className="form-group">
              <label>Project Support</label>
              <CKEditor
                type="texteditor textarea"
                name="project_support"
                placeholder="formValues.project Support"
                editor={ClassicEditor}
                data="<p>Enter What type of support get to customer in this project.</p>"
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChangeText(data, "project_support");
                  console.log({ event, editor, data });
                }}
                value={formValues.project_support}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              {/* <textarea
                type="textarea"
                name="project_support"
                placeholder="Support"
                value={formValues.project_support}
                onChange={handleChange}
              ></textarea> */}
            </div>
            <p className="err-message">{formErrors.project_support}</p>
            <div className="form-group">
              <label>Out of Scope</label>
              <CKEditor
                type="texteditor textarea"
                name="out_of_scope"
                placeholder="Out of Scope"
                editor={ClassicEditor}
                data="<p>Enter the out of scope Here.</p>"
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChangeText(data, "out_of_scope");
                  console.log({ event, editor, data });
                }}
                value={formValues.out_of_scope}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              {/* <textarea
                type="textarea"
                name="out_of_scope"
                placeholder="Out of Scope"
                value={formValues.out_of_scope}
                onChange={handleChange}
              ></textarea> */}
            </div>
            <p className="err-message">{formErrors.out_of_scope}</p>
            <div className="form-group">
              <label>Terms & Conditions</label>
              <CKEditor
                type="texteditor textarea"
                name="terms_conditions"
                placeholder="Terms & Conditions"
                editor={ClassicEditor}
                data="<p>Enter the Terms and Conditions Here.</p>"
                onReady={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleChangeText(data, "terms_conditions");
                  console.log({ event, editor, data });
                }}
                value={formValues.terms_conditions}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              {/* <textarea
                type="textarea"
                name="terms_conditions"
                placeholder="Terms & Conditions"
                value={formValues.terms_conditions}
                onChange={handleChange}
              ></textarea> */}
            </div>
            <p className="err-message">{formErrors.terms_conditions}</p>
            {/* <div style={{ width: "37%" }}>
              <input
                type="file"
                value={formErrors.image}
                onChange={handleFileInput}
              /> */}
            {/* <button className="fileUpload" id="fileInputButton">
                Upload File
              </button> 
            </div>*/}
            {/* <p className="err-message">{formErrors.File}</p> */}
            <div className="iagree">
              <input type="checkbox" id="agree" onChange={checkboxHandler} />
              <label htmlFor="agree">
                I agree to <b>terms and conditions</b>
              </label>
            </div>
          </div>
          {/* <br /> */}
          <div>
            <button disabled={!agree} className="btn submit-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
