import React from 'react'
import "./CustomerProposalList.css"
import MsSuccess from "../../assets/images/ms-success.png"

const MilestoneStatusCard = ({backgroundColorMain ,textpadding,Image ,Content,textsize}) => {
  return (
    <div className='ms-status-main' style={{backgroundColor:`${backgroundColorMain}`}}>
      <div className='ms-status-img'><img src={Image} alt=""/></div>
      <div className='ms-status-text' style={{padding:`${textpadding}` ,fontSize:`${textsize}`}} ><p>{Content}</p></div>
    </div>
  )
}

export default MilestoneStatusCard
