import React, { useEffect } from "react";
import Layout from "../../components/Layout";
import CustomerGuide from "./CustomerGuide";
import {Link} from "react-router-dom";
import submit_proposal2 from "../../assets/conqt_guidelines/submit_proposal2.jpg"
import getproject2 from "../../assets/conqt_guidelines/getproject2.jpg"

const ConqtCustomer = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <section className="">
        <div className="contact-heading conuni">
          <h1>Contact University</h1>
          <h2>ConQt - First Businesse Matching Marketplace</h2>
        </div>
        <div className="universitycontent">
          <CustomerGuide />
        </div>
        {/* <div className="steps videocenter">
        <iframe width="800" height="450" src="https://www.youtube.com/embed/EKlLXvU8KPs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div> */}
        <br />
          <br />
        <div>
            <div className="ourrecentpost">
                <h2>Other Related Topics</h2>
            </div>
        <div className="universityblog">
          <Link to="/how-to-get-a-project">
            <div className="uniblog">
            <div class="box-content recentPost">
             <img src={getproject2} alt="blogimg" />
              <div className="blogcontent">
                <h1 id="box-head" contenteditable="true">How to get a Project ?</h1>
              </div>
            </div>
          </div>
          </Link>
          <Link to="/how-to-submit-a-proposal">
          <div className="uniblog">
            <div class="box-content recentPost">
             <img src={submit_proposal2} alt="blogimg" />
              <div className="blogcontent">
              <h1 id="box-head" contenteditable="true">How to Submit a Proposal?</h1>
              </div>
            </div>
          </div></Link>
          {/* <div className="uniblog">
            <div class="box-content recentPost">
             <img src={submit_proposal2} alt="blogimg" />
              <div className="blogcontent">
              <Link to=""><h1 id="box-head" contenteditable="true">How to Submit a Proposal?</h1></Link>
              </div>
            </div>
          </div> */}
        </div>
        </div>
      </section>
    </Layout>
  );
};

export default ConqtCustomer;
