import React from "react";
import { AiOutlineCloseSquare } from "react-icons/ai";

const Modal = ({ closeModal }) => {
  return (
    <div className="ModelBackground">
      <div className="modalContainer">
        <div className="closebtn" onClick={() => closeModal(false)}>
          <AiOutlineCloseSquare className="close" />
        </div>
        <div className="">
          <h2>Projects Title 1</h2>
          <p>Bangkok, Thailand</p>
        </div>

        <div>
          <div className="proposalform">
            <input type="text" placeholder="Price" className="inputfield" />
            <input
              type="text"
              placeholder="Timeline / Duration of Delivery"
              className="inputfield"
            />
            <div className="file-input-wrapper">
              {/* <input
                type="text"
                id="fileName"
                className="file_input_textbox"
                readonly="readonly"
              /> */}
              <input type="file" name="file" />
              <button className="btn-file-input" id="fileInputButton">
                Upload File
              </button>
            </div>
          </div>
          <div>
            <button className="btn">Submit Proposal</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
