import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { BaseUrl } from "../../Api/api.js";
import { useSelector } from "react-redux";
import completed from "../../assets/images/completed-icon.png";
import inprogress from "../../assets/images/inprogress-icon.png";
import lock from "../../assets/images/lock.png";
import Close from "../../assets/images/close-info.png";
import Proposal from "../../assets/images/Proposal.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CustomerAcceptReject from "./CustomerAcceptReject";
import MsSuccess from "../../assets/images/ms-success.png";
import MilestoneStatusCard from "./MilestoneStatusCard";
import MSreject from "../../assets/images/ms-reject.svg";
import MSStarted from "../../assets/images/ms-started.svg";
import MSWaiting from "../../assets/images/ms-waiting.svg";
import { Popover, Whisper, Button } from "rsuite";
import CustomerSelectedProposal from "./CustomerSelectedProposal";
import PageLoader from "../../components/PageLoader/PageLoader";

const CustomerMilestone = () => {
        const navigate = useNavigate();
        const { projectDetails } = useSelector((state) => state.customerProjectInfo);
        const [data, setData] = useState([]);
        const [proposalData, setProposalData] = useState({});
        const [popup, showPopup] = useState(false);
        const [selectedData, showSelectedData] = useState({});
        const [viewMore, setViewMore] = useState(false);
        const [proposalPopup, ShowProposalPopup] = useState(false);
        const [pageLoading, setPageLoading] = useState(false);
        const id =
                projectDetails.announcement_id && projectDetails.announcement_id.toString();
        const user = JSON.parse(localStorage.getItem("user"));

        const onClickBack = () => {
                navigate("/projectlist");
        };
        useEffect(() => {
                setPageLoading(true);
                const userToken = localStorage.getItem("token");
                try {
                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${userToken}`);
                        myHeaders.append("Content-Type", "application/json");
                        var raw = JSON.stringify({
                                announcement_id: id,
                        });
                        var requestOptions = {
                                method: "POST",
                                headers: myHeaders,
                                redirect: "follow",
                                body: raw,
                        };

                        fetch(
                                `${BaseUrl}/announcement/Customer-Final-Project-Milestne-List`,
                                requestOptions
                        )
                                .then((response) => response.json())
                                .then((result) => {
                                        // console.log("Customer-Final-Project-Milestne-List", result.data);
                                        setPageLoading(false);
                                        setData(result.data.milestoneList);
                                        setProposalData(result.data.proposalDetail);
                                        // console.log(result.data.proposalDetail, "result.data.proposalDetail");
                                })
                                .catch((error) => console.log("error", error));
                } catch (err) {
                        console.log(err);
                }
        }, []);
        // console.log(data, "milestoneList");
        const onClickReview = (e) => {
                showSelectedData(e);
                // console.log(e, "milestoneList on review click");
                showPopup(!popup);
        };
        const onClickPopOver = () => {
                setViewMore(!viewMore);
        };
        const onClickViewProposal = () => {
                ShowProposalPopup(true);
        };

        return (
                <>
                        {pageLoading && <PageLoader />}
                        <CustomerSelectedProposal
                                isShow={proposalPopup}
                                milestoneDetails={data}
                                proposalDetails={proposalData}
                                onClickOnClose={() => {
                                        ShowProposalPopup(false);
                                }}
                        />
                        {popup && (
                                <CustomerAcceptReject
                                        isShow={showPopup}
                                        data={selectedData}
                                        onClickClose={() => {
                                                showPopup(false);
                                        }}
                                />
                        )}
                        <div className="dashboard-container">
                                <Sidebar />
                                <div className="main-dashboard-container">
                                        <Topbar />

                                        <div className="ms-back-btn-view-btn-div">
                                                <div
                                                        style={{ marginLeft: "15px" }}
                                                        className="customer-popup-close"
                                                        onClick={onClickBack}
                                                >
                                                        <img className="project-icon" src={Close} alt="" />
                                                </div>
                                                {/* <center>
              {" "}
              {pageLoading && <Loader size="lg" speed="normal" />}
            </center> */}
                                                <button
                                                        className="ms-view-all-proposal-btn ms-proposal-btn"
                                                        onClick={onClickViewProposal}
                                                >
                                                        View Proposal
                                                </button>
                                                <img
                                                        className="ms-view-all-proposal-in-mb"
                                                        src={Proposal}
                                                        alt=""
                                                        onClick={onClickViewProposal}
                                                />
                                        </div>

                                        <div className="milestone-main-div">
                                                {data &&
                                                        data.map((e, index) => {
                                                                return (
                                                                        <>
                                                                                <div style={{ display: "flex" }}>
                                                                                        {e.milestone_start_close_status === 2 ||
                                                                                                e.milestone_start_close_status === 1 ? (
                                                                                                <div
                                                                                                        className={
                                                                                                                data.length == 1 ? "hide-vertical-line" : ""
                                                                                                        }
                                                                                                >
                                                                                                        <img
                                                                                                                className="milestone-status-icon"
                                                                                                                src={
                                                                                                                        e.milestone_start_close_status === 2
                                                                                                                                ? completed
                                                                                                                                : inprogress
                                                                                                                }
                                                                                                                alt=""
                                                                                                        ></img>
                                                                                                        <div className="vertical-line"></div>
                                                                                                </div>
                                                                                        ) : (
                                                                                                <div
                                                                                                        className={
                                                                                                                data.length == 1 ? "hide-vertical-line" : ""
                                                                                                        }
                                                                                                >
                                                                                                        <div
                                                                                                                className={
                                                                                                                        e.count === 1 &&
                                                                                                                                (e.milestone_start_close_status === 2 || 1)
                                                                                                                                ? "vertical-status-count"
                                                                                                                                : "in-active-vertical-status-count"
                                                                                                                }
                                                                                                        >
                                                                                                                {e.count}
                                                                                                        </div>
                                                                                                        <div
                                                                                                                className={
                                                                                                                        e.count === 1 &&
                                                                                                                                (e.milestone_start_close_status === 2 || 1)
                                                                                                                                ? "vertical-line"
                                                                                                                                : "in-active-vertical-line"
                                                                                                                }
                                                                                                        ></div>
                                                                                                </div>
                                                                                        )}

                                                                                        <div
                                                                                                className={
                                                                                                        (e.count == 1 && e.user_transaction_status == 0) ||
                                                                                                                (e.vendor_mark_as_complete_status == 1 &&
                                                                                                                        e.customer_status == 1 &&
                                                                                                                        e.user_transaction_status == 0 &&
                                                                                                                        e.milestone_start_close_status == 2)
                                                                                                                ? "milestone-card-div-active"
                                                                                                                : e.milestone_start_close_status == 0 && e.count > 1
                                                                                                                        ? "milestone-card-div in-active-milestone"
                                                                                                                        : "milestone-card-div"
                                                                                                }
                                                                                        >
                                                                                                <div className="milestone-header-status">
                                                                                                        <div>
                                                                                                                <h3 className="customer-milestone-header">
                                                                                                                        Project Milestone {e.count}
                                                                                                                </h3>

                                                                                                                <p className="customer-milestone-desc">
                                                                                                                        {e.milestone_description.length > 50 ? (
                                                                                                                                <div className="ms-desc-btn">
                                                                                                                                        <p className="ms-desc">
                                                                                                                                                {e.milestone_description.replace(
                                                                                                                                                        /^(.{50}[^\s]*).*/,
                                                                                                                                                        "$1"
                                                                                                                                                )}
                                                                                                                                        </p>
                                                                                                                                        <Whisper
                                                                                                                                                placement="bottomStart"
                                                                                                                                                trigger="click"
                                                                                                                                                speaker={
                                                                                                                                                        <Popover
                                                                                                                                                                arrow={false}
                                                                                                                                                                className="desc-popup"
                                                                                                                                                        >
                                                                                                                                                                {e.milestone_description}
                                                                                                                                                        </Popover>
                                                                                                                                                }
                                                                                                                                        >
                                                                                                                                                <Button
                                                                                                                                                        className="ms-view-more-desc"
                                                                                                                                                        onClick={onClickPopOver}
                                                                                                                                                >
                                                                                                                                                        {!viewMore ? "View more" : "View less"}
                                                                                                                                                </Button>
                                                                                                                                        </Whisper>
                                                                                                                                        {e.count > 1
                                                                                                                                                ? e.count > 1 &&
                                                                                                                                                e.milestone_start_close_status === 0 && (
                                                                                                                                                        <div className="cs-ms-lock-div">
                                                                                                                                                                <img
                                                                                                                                                                        className="cs-ms-lock"
                                                                                                                                                                        src={lock}
                                                                                                                                                                        alt=""
                                                                                                                                                                />
                                                                                                                                                        </div>
                                                                                                                                                )
                                                                                                                                                : ""}
                                                                                                                                </div>
                                                                                                                        ) : (
                                                                                                                                <div className="ms-desc-btn">
                                                                                                                                        {e.milestone_description}
                                                                                                                                </div>
                                                                                                                        )}
                                                                                                                </p>
                                                                                                                <div>
                                                                                                                        {(e.count == 1 &&
                                                                                                                                e.user_transaction_status == 0) ||
                                                                                                                                (e.vendor_mark_as_complete_status == 1 &&
                                                                                                                                        e.customer_status == 1 &&
                                                                                                                                        e.user_transaction_status == 0 &&
                                                                                                                                        e.milestone_start_close_status == 2) ? (
                                                                                                                                <div className="ms-amount-btn">
                                                                                                                                        <p className="ms-amount-text">
                                                                                                                                                {" "}
                                                                                                                                                Amount{" "}
                                                                                                                                                <span className="ms-amount">
                                                                                                                                                        {" "}
                                                                                                                                                        {Number(e.user_milestone_amount)
                                                                                                                                                                .toFixed(2)
                                                                                                                                                                .toString()
                                                                                                                                                                .replace(
                                                                                                                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                                                                                                                        ","
                                                                                                                                                                )}{" "}
                                                                                                                                                        {user.user_info.currency_code}
                                                                                                                                                </span>
                                                                                                                                        </p>
                                                                                                                                        <Link
                                                                                                                                                className="milestone-pay-now-btn"
                                                                                                                                                to={`/checkout/${proposalData.proposal_id}/${index}/${e.id}`}
                                                                                                                                        >
                                                                                                                                                Pay Now
                                                                                                                                        </Link>
                                                                                                                                </div>
                                                                                                                        ) : (
                                                                                                                                e.user_transaction_status == 1 && (
                                                                                                                                        <Link
                                                                                                                                                to={`/receipt/${e.id}/${index + 0}/${proposalData.proposal_id
                                                                                                                                                        }`}
                                                                                                                                        >
                                                                                                                                                <button
                                                                                                                                                        className={
                                                                                                                                                                (e.vendor_mark_as_complete_status ==
                                                                                                                                                                        1 &&
                                                                                                                                                                        e.customer_status == 0) ||
                                                                                                                                                                        (e.vendor_mark_as_complete_status ==
                                                                                                                                                                                1 &&
                                                                                                                                                                                e.customer_status == 2)
                                                                                                                                                                        ? "milestone-pay-now-btn download"
                                                                                                                                                                        : "milestone-pay-now-btn download-receipt"
                                                                                                                                                        }
                                                                                                                                                >
                                                                                                                                                        Download Receipt
                                                                                                                                                </button>
                                                                                                                                        </Link>
                                                                                                                                )
                                                                                                                        )}
                                                                                                                </div>
                                                                                                        </div>

                                                                                                        <p
                                                                                                                className={
                                                                                                                        e.milestone_start_close_status === 2
                                                                                                                                ? "milestone-status"
                                                                                                                                : "milestone-status-not-started"
                                                                                                                }
                                                                                                        >
                                                                                                                {e.milestone_start_close_status === 1 &&
                                                                                                                        e.vendor_mark_as_complete_status == 0 ? (
                                                                                                                        <div className="status-card-parent-div">
                                                                                                                                <MilestoneStatusCard
                                                                                                                                        backgroundColorMain={"#304FFE"}
                                                                                                                                        Image={MSStarted}
                                                                                                                                        textpadding={"20px"}
                                                                                                                                        textsize={"15px"}
                                                                                                                                        Content={"Vendor has started milestone"}
                                                                                                                                />
                                                                                                                        </div>
                                                                                                                ) : (e.vendor_mark_as_complete_status == 1 &&
                                                                                                                        e.customer_status == 0) ||
                                                                                                                        (e.vendor_mark_as_complete_status == 1 &&
                                                                                                                                e.customer_status == 2) ? (
                                                                                                                        <div className="ms-review-text-btn">
                                                                                                                                <MilestoneStatusCard
                                                                                                                                        backgroundColorMain={"#304FFE"}
                                                                                                                                        Image={MSWaiting}
                                                                                                                                        textpadding={"10px"}
                                                                                                                                        textsize={"13px"}
                                                                                                                                        Content={
                                                                                                                                                "Vendor has successfully completed the milestone kindly review it"
                                                                                                                                        }
                                                                                                                                />
                                                                                                                                <button
                                                                                                                                        className="milestone-review-btn"
                                                                                                                                        onClick={() => {
                                                                                                                                                onClickReview(e);
                                                                                                                                        }}
                                                                                                                                >
                                                                                                                                        Review
                                                                                                                                </button>
                                                                                                                        </div>
                                                                                                                ) : e.vendor_mark_as_complete_status == 2 &&
                                                                                                                        e.customer_status == 2 ? (
                                                                                                                        <MilestoneStatusCard
                                                                                                                                backgroundColorMain={"#FF0000"}
                                                                                                                                Image={MSreject}
                                                                                                                                textpadding={"10px"}
                                                                                                                                textsize={"15px"}
                                                                                                                                Content={
                                                                                                                                        "Milestone rejected Waiting  for vendor to rework on milestone"
                                                                                                                                }
                                                                                                                        />
                                                                                                                ) : e.milestone_start_close_status === 2 ? (
                                                                                                                        <div className="status-card-parent-div">
                                                                                                                                <MilestoneStatusCard
                                                                                                                                        backgroundColorMain={"#3eb489"}
                                                                                                                                        Image={MsSuccess}
                                                                                                                                        textpadding={"10px"}
                                                                                                                                        textsize={"15px"}
                                                                                                                                        Content={
                                                                                                                                                "Milestone" +
                                                                                                                                                " " +
                                                                                                                                                e.count +
                                                                                                                                                " " +
                                                                                                                                                "completed successfully"
                                                                                                                                        }
                                                                                                                                />
                                                                                                                        </div>
                                                                                                                ) : (e.count === 1 &&
                                                                                                                        e.milestone_start_close_status === 0 &&
                                                                                                                        e.user_transaction_status === 1) ||
                                                                                                                        ((data[index - 1]
                                                                                                                                ? data[index - 1]
                                                                                                                                        .milestone_start_close_status === 2
                                                                                                                                : "") &&
                                                                                                                                e.count > 1 &&
                                                                                                                                e.milestone_start_close_status === 0 &&
                                                                                                                                e.user_transaction_status === 0) ? (
                                                                                                                        <>
                                                                                                                                <div className="status-card-parent-div">
                                                                                                                                        <MilestoneStatusCard
                                                                                                                                                backgroundColorMain={"#304FFE"}
                                                                                                                                                textpadding={"10px"}
                                                                                                                                                textsize={"15px"}
                                                                                                                                                Image={MSWaiting}
                                                                                                                                                Content={
                                                                                                                                                        "Waiting for Vendor to Start the milestone"
                                                                                                                                                }
                                                                                                                                        />
                                                                                                                                </div>
                                                                                                                        </>
                                                                                                                ) : (
                                                                                                                        " "
                                                                                                                )}
                                                                                                        </p>
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                                <hr
                                                                                        className={
                                                                                                data.length == 1 ? "hide-vertical-line" : "ms-hr"
                                                                                        }
                                                                                ></hr>
                                                                        </>
                                                                );
                                                        })}
                                        </div>
                                </div>
                        </div>
                </>
        );
};

export default CustomerMilestone;
