import React, { useEffect, useState } from "react";
import "../Project/Project.css";
import Proposal from "./Proposal";
import { toast } from "react-toastify";
import Approved from "../../assets/images/approved.svg";
import cc from "../../assets/images/cc.svg";
import coin from "../../assets/images/coin.svg";
import { Link, useNavigate } from "react-router-dom";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import CountdownTimer from "../../components/CountdownTimer";
import { BsCoin } from "react-icons/bs";
import { BaseUrl } from "../../Api/api.js";
import {
        MdLocationPin,
        MdAttachment,
        MdKeyboardArrowUp,
        MdKeyboardArrowDown,
} from "react-icons/md";
import { BsCashCoin } from "react-icons/bs";
import { BsBookmarkStar, BsFillCheckCircleFill } from "react-icons/bs";
//import Deductcoins from "../../assets/images/100coins.png";
import Popup from "reactjs-popup";
import closeButton from "./vendorProfile/images/closeButton.png";

const Projectlist = (props) => {
        const [Search, setSearch] = useState("");
        const [data, setData] = useState([]);
        const [originalData, setOriginalData] = useState([]);
        const [openModal, setOpenModal] = useState(false);
        const [sortValue, setsortValue] = useState("");
        const [showMore, setShowMore] = useState(null);
        const [popup, setPopup] = useState(false);
        const [projectApplyUrl, setProjectApplyUrl] = useState("");
        let vendorInfo = JSON.parse(localStorage.getItem("userinfo"));
        let projectList = JSON.parse(localStorage.getItem("results"));

        const sortOptions = ["city", "country", "solution name", "status"];
        const currentTime = new Date().getTime();
        const navigate = useNavigate();


        console.log("Vendor Info", vendorInfo.vendor_id);

        const handleSort = (e) => {
                let value = e.target.value;
                setsortValue(value);
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        redirect: "follow",
                };

                fetch(
                        `${BaseUrl}/announcement/Announcement-List-New?_sort=${value}`,
                        requestOptions
                )
                        .then((response) => response.json())
                        .then((result) => {
                                setData(result.data);
                        })
                        .catch((error) => console.log("error", error));
        };

        useEffect(() => {
                props.updateLoaderState(true);
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                        vendor_id: vendorInfo.vendor_id,
                });

                console.log("raw", raw);

                var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                };

                fetch(`${BaseUrl}/announcement/Announcement-List-New`, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {
                                localStorage.setItem("results", JSON.stringify(result.data));
                                setData(result.data);
                                setOriginalData(result.data);
                                props.updateLoaderState(false);
                                console.log(result.data);
                        })
                        .catch((error) => console.log("error", error));
        }, []);

        const announcementid = (announcement_id, project_name) => {
                localStorage.setItem("vendorproject", announcement_id);

                console.log("Vendor Info", vendorInfo);

                if (
                        (vendorInfo.activation_status == 0 ||
                                vendorInfo.activation_status == 1) &&
                        vendorInfo.available_point < 100
                ) {
                        toast.error("To Apply Project, You need to buy coins.");
                        window.location = "/subscription";
                        setOpenModal(false);
                } else {
                        console.log("set Project Appply URL ", popup);
                        setProjectApplyUrl(`${announcement_id}/${project_name}`);
                        setPopup(true);
                        // setTimeout(
                        //   () =>
                        //     (window.location = `vendor-project-list/${announcement_id}/${project_name}`),
                        //   10000
                        // );
                }
        };

        const filterItems = (catItems) => {
                const updatedItems = projectList.filter((solName) => {
                        return solName.solution_name == catItems;
                });

                setData(updatedItems);
        };
        const key = [...new Set(originalData.map((key) => key.country))];

        const handleChangeSelection = (e) => {
                console.log(e);
                console.log(e.target[e.target.selectedIndex].getAttribute("value"));
                if (e.target[e.target.selectedIndex].getAttribute("value") === null) {
                        setData(originalData);
                } else {
                        const updatedItems = originalData.filter((curElem) => {
                                return (
                                        curElem.country ===
                                        e.target[e.target.selectedIndex].getAttribute("value")
                                );
                        });
                        setData(updatedItems);
                }
        };

        const keys = [...new Set(originalData.map((key) => key.solution_name))];

        const handleChangeSelect = (e) => {
                console.log(e);
                console.log(e.target[e.target.selectedIndex].getAttribute("value"));
                if (e.target[e.target.selectedIndex].getAttribute("value") === null) {
                        setData(originalData);
                } else {
                        const updatedItems = originalData.filter((curElem) => {
                                return (
                                        curElem.solution_name ===
                                        e.target[e.target.selectedIndex].getAttribute("value")
                                );
                        });
                        setData(updatedItems);
                }
        };

        const toggle = (i) => {
                // console.log("what is i", i);
                if (showMore == i) {
                        return setShowMore(null);
                }
                setShowMore(i);
        };

        return (
                <>
                        <div className="filters">
                                <div className="FiterBtn">
                                        <select
                                                className="cityfilter"
                                                name="Filter By Solutions"
                                                placeholder="Filter By Solutions"
                                                onChange={handleChangeSelect}
                                        >
                                                <option className="btn btn-Cfilter">Select Solutions</option>
                                                {keys.map((keys) => (
                                                        <option className="btn btn-Cfilter" value={keys}>
                                                                {keys}
                                                        </option>
                                                ))}
                                        </select>

                                        {/* <button
              className="btn btn-filter"
              onClick={() => setData(projectList)}
            >
              All Projects
            </button>
            <button
              className="btn btn-filter"
              onClick={() => filterItems("Resource Required")}
            >
              Resource Required
            </button>
            <button
              className="btn btn-filter"
              onClick={() => filterItems("Web Development")}
            >
              Web Development
            </button> */}
                                        <select
                                                className="cityfilter"
                                                name="Filter By Country"
                                                placeholder="Filter By Country"
                                                onChange={handleChangeSelection}
                                        >
                                                <option className="btn btn-Cfilter">Select Country</option>
                                                {key.map((key) => (
                                                        <option className="btn btn-Cfilter" value={key}>
                                                                {key}
                                                        </option>
                                                ))}
                                        </select>
                                        <input
                                                className="projectsearch"
                                                type="text"
                                                placeholder="Search..."
                                                onChange={(e) => setSearch(e.target.value)}
                                        />
                                </div>

                                {/*<div className="filter2">
           <ul>
            {
              data.filter((project)=>project.project_name.toLowerCase().includes(Search)).map((project)=>(
                <li>{project.project_name}</li>
              ))
            }
          </ul> 
        </div>*/}
                                {/* <div>
          <div className="dropdown-dark">
            <select
              name="one"
              className="dropdown-select"
              onChange={handleSort}
              value={sortValue}
            >
              <option value="">Please Select Value</option>
              {sortOptions.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div> */}
                        </div>

                        {openModal && <Proposal closeModal={setOpenModal} />}
                        {data
                                .filter(
                                        (item) =>
                                                item.project_name.toLowerCase().includes(Search) ||
                                                item.project_name.toUpperCase().includes(Search) ||
                                                item.city.toLowerCase().includes(Search) ||
                                                item.city.toUpperCase().includes(Search) ||
                                                item.country.toLowerCase().includes(Search) ||
                                                item.country.toUpperCase().includes(Search)
                                )
                                .map((item, index) => {
                                        const TargetTime = new Date(item.project_expire).getTime();

                                        return (
                                                <div>
                                                        <article className="project-list" key={item.announcement_id}>
                                                                <div className="project-details">
                                                                        <div className="projecttitlemain">
                                                                                <div>
                                                                                        <div>
                                                                                                <h2>
                                                                                                        <Link
                                                                                                                to={`/vendor-projects/${item.project_name}/${index}/${item.announcement_id}`}
                                                                                                        >
                                                                                                                {item.project_name}

                                                                                                                <BsFillCheckCircleFill style={{ margin: "0px 12px" }} />
                                                                                                        </Link>

                                                                                                </h2>
                                                                                                <p className="project-publish-date">
                                                                                                        Published on : {item.created_at.split(" ")[0]}
                                                                                                </p>
                                                                                        </div>
                                                                                        {/* <div>
                                                                                                {item.vendor_id == vendorInfo.vendor_id ? (
                                                                                                        <span class="badge bg-success">Applied</span>) : ("")}
                                                                                        </div> */}
                                                                                </div>
                                                                                <div className="">
                                                                                        {/* <GiMoneyStack />
                                                                                        {item.project_budget == null ||
                                                                                                item.project_budget == "Budget Not Set" ? (
                                                                                                "Budget Not Set"
                                                                                        ) : (
                                                                                                <>$ {item.project_budget}</>
                                                                                        )} */}
                                                                                        {item.vendor_id == vendorInfo.vendor_id ? (
                                                                                                <span class="badge bg-success">Applied</span>
                                                                                        ) : (
                                                                                                ""
                                                                                        )}
                                                                                </div>
                                                                        </div>

                                                                        <div className="projectfeatures">
                                                                                {/* <span className="protags"> */}
                                                                                <div>
                                                                                        <span>
                                                                                                <BsBookmarkStar /> Project Category
                                                                                        </span>
                                                                                        {parse(item.solution_name)}
                                                                                </div>
                                                                                <div className="borderstraight">
                                                                                        <span>
                                                                                                <MdLocationPin /> Location
                                                                                        </span>
                                                                                        {item.city}, {item.country}
                                                                                </div>
                                                                                <div className="borderstraight-left">
                                                                                        <span>
                                                                                                <BsCoin />
                                                                                                Budget
                                                                                        </span>
                                                                                        {item.project_budget == null ||
                                                                                                item.project_budget == "Budget Not Set" ? (
                                                                                                "Not Set"
                                                                                        ) : (
                                                                                                <>
                                                                                                        {Number(item.project_budget).toLocaleString("en-US", {
                                                                                                                style: "currency",
                                                                                                                currency: "USD",
                                                                                                                minimumFractionDigits: "0",
                                                                                                                maximumFractionDigits: "0",
                                                                                                        })}
                                                                                                </>
                                                                                        )}
                                                                                </div>
                                                                                <div>
                                                                                        <span>
                                                                                                <MdAttachment /> Attachement
                                                                                        </span>
                                                                                        {item.attachment_url == null ? (
                                                                                                "No Attachment"
                                                                                        ) : (
                                                                                                <a href={item.attachment_url} target="_blank">
                                                                                                        View
                                                                                                </a>
                                                                                        )}
                                                                                </div>
                                                                        </div>

                                                                        {showMore == index ? (
                                                                                <p>{parse(item.project_description)}</p>
                                                                        ) : (
                                                                                <p>{parse(item.project_description?.substring(0, 320))}</p>
                                                                        )}
                                                                        <span
                                                                                className="markascomplete"
                                                                                onClick={() => toggle(index)}
                                                                        >
                                                                                {/* {console.log("length", item.project_description.length)} */}
                                                                                {item.project_description?.length > 300 ? (
                                                                                        showMore == index ? (
                                                                                                <>
                                                                                                        View Less
                                                                                                        <MdKeyboardArrowUp />
                                                                                                </>
                                                                                        ) : (
                                                                                                <>
                                                                                                        View More
                                                                                                        <MdKeyboardArrowDown />
                                                                                                </>
                                                                                        )
                                                                                ) : (
                                                                                        ""
                                                                                )}
                                                                        </span>
                                                                </div>
                                                                <div className="project-apply">
                                                                        {/* <div>
                                                                                <CountdownTimer targetDate={String(item.project_expire)} />
                                                                        </div> */}
                                                                        {/* <div>
                    <div id="activity">
                      <div className="cointextdeduct">
                        <p>Project Apply Charges 100 Coins</p>
                      </div>
                      <div className="coinnote">
                        100
                        <BsCashCoin className="decoin" />
                      </div>
                    </div>
                  </div> */}
                                                                        <div className="d-flex align-items-center justify-content-flex-end">
                                                                                {item.vendor_id == vendorInfo.vendor_id ? (
                                                                                        <>
                                                                                                <Link
                                                                                                        to={`/new-buy-customer-details-page/${item.announcement_id}`}
                                                                                                >
                                                                                                        <button
                                                                                                                className="apply-btn mx-2 d-flex align-items-center"
                                                                                                                style={{ width: "210px" }}
                                                                                                        >
                                                                                                                <span>
                                                                                                                        <img
                                                                                                                                src={cc}
                                                                                                                                alt=""
                                                                                                                                style={{ width: "30px" }}
                                                                                                                                className="px-1"
                                                                                                                        />
                                                                                                                </span>
                                                                                                                Contact Customer
                                                                                                        </button>
                                                                                                </Link>
                                                                                                {/* <button
                                                                                                                className="apply-btn"
                                                                                                                style={{ backgroundColor: "gray" }}
                                                                                                        >
                                                                                                                Already Applied
                                                                                                        </button> */}
                                                                                        </>
                                                                                ) : currentTime > TargetTime ? (
                                                                                        <button
                                                                                                className="apply-btn"
                                                                                                style={{ backgroundColor: "red" }}
                                                                                        >
                                                                                                You Missed it!
                                                                                        </button>
                                                                                ) : (
                                                                                        <>
                                                                                                <Link to={`/new-buy-customer-details-page/${item.announcement_id}`}>
                                                                                                        <button
                                                                                                                className="apply-btn mx-2 d-flex align-items-center"
                                                                                                                style={{ width: "210px" }}
                                                                                                        >
                                                                                                                <span>
                                                                                                                        <img
                                                                                                                                src={cc}
                                                                                                                                alt=""
                                                                                                                                style={{ width: "30px" }}
                                                                                                                                className="px-1"
                                                                                                                        />
                                                                                                                </span>
                                                                                                                Contact Customer
                                                                                                        </button>
                                                                                                </Link>
                                                                                        </>
                                                                                )}
                                                                        </div>
                                                                </div>

                                                                <Popup open={popup}>
                                                                        <div className="ApplyPoopup">
                                                                                {/* <button
                      onClick={() => {
                        setPopup(false);
                      }}
                      className="areyousure-close-button"
                      width="30px;"
                      //  onClick={close}
                    >
                      <img src={closeButton} alt="" />
                    </button> */}
                                                                                To Apply this Project, 100 coins deducted from current
                                                                                balance.
                                                                                <div className="coingsbtn">
                                                                                        <Link to={projectApplyUrl}>
                                                                                                <button className="btn coin-select">
                                                                                                        Apply Project
                                                                                                </button>
                                                                                        </Link>
                                                                                        <button
                                                                                                className="btn coin-select closebtn"
                                                                                                onClick={() => {
                                                                                                        setPopup(false);
                                                                                                }}
                                                                                        >
                                                                                                Close
                                                                                        </button>
                                                                                </div>
                                                                        </div>
                                                                </Popup>
                                                                {/* <div className="project-apply">
                  {item.vendor_id == vendorInfo.vendor_id ? (
                    <button
                      className="apply-btn"
                      style={{ backgroundColor: "gray" }}
                    >
                      Already Applied
                    </button>
                  ) : currentTime > TargetTime ? (
                    <button
                      className="apply-btn"
                      style={{ backgroundColor: "red" }}
                    >
                      You Missed it!
                    </button>
                  ) : (
                    <>
                      <Link to={`${item.announcement_id}/${item.project_name}`}>
                      <button
                        className="apply-btn"
                        onClick={() => {
                          // setOpenModal(true);
                          announcementid(
                            item.announcement_id,
                            item.project_name
                          );
                        }}
                      >
                        Apply for project
                      </button>
                     
                      <br />
                      <CountdownTimer
                        targetDate={String(item.project_expire)}
                      />
                    </>
                  )}

                  
                </div> */}
                                                        </article>
                                                </div>
                                        );
                                })}
                </>
        );
};

export default Projectlist;
