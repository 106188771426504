import { createSlice } from "@reduxjs/toolkit";

export const myProfile = createSlice({
  name: "userProfile",
  initialState: {
   overAllStatusBarValue:0
  }, 
  reducers: {
    updateStatusValue: (state, action) => {
      // console.log("*****in the store",action)
      state.overAllStatusBarValue = action.payload;
    }
  },
});

export const { updateStatusValue } = myProfile.actions;

export default myProfile.reducer;
