import React, { useEffect, useState } from "react";
import "../Vendor/Vendor.css";
import {
        AiFillProject,
        AiOutlineShoppingCart,
        AiOutlineProject,
        AiFillCheckCircle,
} from "react-icons/ai";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { MdPostAdd } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { MdLogout } from "react-icons/md";
import { MdDashboardCustomize, MdDrafts } from "react-icons/md";
import logo from "../../assets/images/logo-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, logout } from "../../Store/Reducers/userSlice.js";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import { VscOrganization, VscPreview } from "react-icons/vsc";
import { HiOutlineDocumentText } from "react-icons/hi";
import { GiReceiveMoney } from "react-icons/gi";
import { FiImage } from "react-icons/fi";
import { BsGear, BsBank } from "react-icons/bs";
import { LogoutUrl } from "../../Api/api";
import { useLocation, useNavigate } from "react-router-dom";
import FirstDraft from "../../components/Modal/SaveDraft/firstDraft";
import { NavLink } from 'react-router-dom';

const Sidebar = ({ isDirty }) => {
        const navigate = useNavigate();
        const user = useSelector((state) => JSON.parse(localStorage.getItem("user")));
        const dispatch = useDispatch();
        const [status, setStatus] = useState(false);
        const [myAccount, SetMyAccount] = useState(false);

        const { pathname } = useLocation();

        const [firstDraft, setFirstDraft] = useState(false);
        const [draftPath, setDraftPath] = useState("");

        const handleDashboard = () => {
                navigate("/customer-dashboard");
        };

        const handleSidebar = (path) => {
                if (isDirty) {
                        setFirstDraft(true);
                        setDraftPath(path);
                } else {
                        navigate(path);
                }
        };

        const handleLogout = (e) => {
                e.preventDefault();
                dispatch(logout(user));
                localStorage.clear();
                window.location = LogoutUrl;
        };

        const handleClose = () => {
                setFirstDraft(false);
                // setIsDirty(false)
        }

        useEffect(() => {
                if (pathname === "/new-add-project" ||
                        pathname === "/projectlist" ||
                        pathname === "/draft-table"

                ) {
                        setStatus(true)
                }
        }, [status])

        useEffect(() => {
                if (pathname === "/profile" ||
                        pathname === "/profile-edit" ||
                        pathname === "/company-profile" ||
                        pathname === "/company-profile/edit" ||
                        pathname === "/customer-documents" ||
                        pathname === "/customer-documents/add" ||
                        pathname === "/customer-reviews-list" ||
                        pathname === "/customer-reviews/add" ||
                        pathname === "/customer-product-reviews" ||
                        pathname === "/customer-bank-details" ||
                        pathname === "/customer-bank-details/add" ||
                        pathname === "/customer-transaction-list"
                ) {
                        SetMyAccount(true)
                }
        }, [myAccount])

        return (

                <>
                        <FirstDraft open={firstDraft} handleClose={handleClose} draftPath={draftPath} />
                        <div className="dashboard-navigation">
                                <div className="dashboardMenu">
                                        <a href={LogoutUrl} >
                                                <span className="menu-title">
                                                        <span className="menu-title" >
                                                                <img src={logo} alt="logo" width="{150}" />
                                                        </span>
                                                </span>
                                        </a>

                                        <div onClick={() => handleSidebar("/customer-dashboard")}
                                                style={{ marginTop: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/customer-dashboard" ? "active-side-link" : ""
                                                        }`}>
                                                <MdDashboardCustomize style={{ width: "25px", height: "25px" }} />
                                                Customer Dashboard
                                        </div>

                                        <div onClick={() => setStatus(!status)} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/cust" ? "active-side-link" : ""
                                                }`}>
                                                <AiOutlineShoppingCart style={{ width: "25px", height: "25px" }} />
                                                My Project
                                                <span style={{ marginLeft: "67px" }}>
                                                        <HiOutlineChevronDoubleDown style={{ width: "18px", height: "18px" }} />
                                                </span>
                                        </div>
                                        {status ? <>
                                                <div onClick={() => handleSidebar("/new-add-project")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/new-add-project" ? "active-side-link" : ""
                                                                }`}>
                                                        <AiOutlineAppstoreAdd style={{ width: "25px", height: "25px" }} />
                                                        Create Project
                                                </div>

                                                <div onClick={() => handleSidebar("/projectlist")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/projectlist" ? "active-side-link" : ""
                                                                }`}>
                                                        <AiOutlineProject style={{ width: "25px", height: "25px" }} />
                                                        View Projects
                                                </div>

                                                <div onClick={() => handleSidebar("/draft-table")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/draft-table" ? "active-side-link" : ""
                                                                }`}>
                                                        <MdDrafts style={{ width: "25px", height: "25px" }} />
                                                        Draft Project
                                                </div>
                                        </> : null
                                        }

                                        <div onClick={() => SetMyAccount(!myAccount)} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/ects" ? "active-side-link" : ""
                                                }`}>
                                                <CgProfile style={{ width: "25px", height: "25px" }} />
                                                My Account
                                                <span style={{ marginLeft: "60px" }}>
                                                        <HiOutlineChevronDoubleDown style={{ width: "18px", height: "18px" }} />
                                                </span>
                                        </div>
                                        {myAccount ? <>
                                                <div onClick={() => handleSidebar("/profile")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/profile" || pathname === "/profile-edit" ? "active-side-link" : ""
                                                                }`}>
                                                        <MdPostAdd style={{ width: "25px", height: "25px" }} />
                                                        My Profile
                                                </div>

                                                <div onClick={() => handleSidebar("/company-profile")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side 
                            ${pathname === "/company-profile" ||
                                                                        pathname === "/company-profile/edit"
                                                                        ? "active-side-link" : ""
                                                                }`}>
                                                        <VscOrganization style={{ width: "25px", height: "25px" }} />
                                                        Company Profile
                                                </div>

                                                <div onClick={() => handleSidebar("/customer-documents")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/customer-documents" ||
                                                                pathname === "/customer-documents/add" ? "active-side-link" : ""
                                                                }`}>
                                                        <HiOutlineDocumentText style={{ width: "25px", height: "25px" }} />
                                                        Company Documents
                                                </div>

                                                <div onClick={() => handleSidebar("/customer-reviews-list")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/customer-reviews-list"
                                                                || pathname === "/customer-reviews/add"
                                                                ? "active-side-link" : ""
                                                                }`}>
                                                        <VscPreview style={{ width: "25px", height: "25px" }} />
                                                        Reviews
                                                </div>

                                                <div onClick={() => handleSidebar("/customer-product-reviews")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/customer-product-reviews" ? "active-side-link" : ""
                                                                }`}>
                                                        <VscPreview style={{ width: "25px", height: "25px" }} />
                                                        Product Reviews
                                                </div>

                                                <div onClick={() => handleSidebar("/customer-bank-details")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/customer-bank-details"
                                                                || pathname === "/customer-bank-details/add"
                                                                ? "active-side-link" : ""
                                                                }`}>
                                                        <BsBank style={{ width: "20px", height: "20px" }} />
                                                        Bank Details
                                                </div>

                                                <div onClick={() => handleSidebar("/customer-transaction-list")}
                                                        style={{ paddingLeft: "40px" }} className={`d-flex align-items-center justify-content-start link-side ${pathname === "/customer-transaction-list" ? "active-side-link" : ""
                                                                }`}>
                                                        <GiReceiveMoney style={{ width: "25px", height: "25px" }} />
                                                        Transaction List
                                                </div> </> : null}
                                        <div onClick={(e) => handleLogout(e)} className="d-flex align-items-center justify-content-start link-side">
                                                <MdLogout style={{ width: "25px", height: "25px" }} />
                                                Logout
                                        </div>
                                </div>
                        </div>

                </>

        );
};

export default Sidebar;
