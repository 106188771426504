import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/footers";

function Layout(props) {
  //console.log(props.children);
  return (
    <main>
      <Header />
      <div className="content">{props.children}</div>

      <Footer />
    </main>
  );
}

export default Layout;
