import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import "./CustomerProposalList.css";
import Topbar from "./Topbar";
import DataTable from "react-data-table-component";
import { BaseUrl } from "../../Api/api.js";
import { transformProposalTableData } from "../../Utils/CustomerProjectList";
import { useDispatch, useSelector } from "react-redux";
import EyeIcon from "../../assets/images/Eye-icon.png";
import VendorIcon from "../../assets/images/vendor-icon.png";
import AmountTag from "../../assets/images/amount-tag-icon.png";
import ClockIcon from "../../assets/images/project-date-icon.png";
import Descripiton from "../../assets/images/descripiton-icon.png";
import ProjectIcon from "../../assets/images/project-name-icon.png";
import ResourcesIcon from "../../assets/images/resources-icon.png";
import SupportIcon from "../../assets/images/support-icon.png";
import ShowMore from "../../assets/images/show-more-icon.png";
import Showless from "../../assets/images/show-less-icon.png";
import { useNavigate } from "react-router-dom";
import Close from "../../assets/images/close-info.png";
import { updateProposalList } from "../../../src/Store/Reducers/CustomerProposalListReducer";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const CustomerProposalList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { proposalDetails } = useSelector(
    (state) => state.customerProposalList
  );

  const [showMore, setShowMore] = useState(false);
  const [data, setData] = useState(proposalDetails && proposalDetails[0]);
  const [popup, ShowPopup] = useState(false);
  const [acceptPopup, ShowAcceptPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // console.log(proposalDetails, "*******************");
  const allColumns = [
    {
      id: "Vendor name",
      name: "Vendor name",
      selector: (result) => result.vendor_name,
    },

    {
      id: "Project name",
      name: "Project name",
      selector: (result) => result.project_name,
      sortable: true,
    },

    {
      id: "Proposal Amount",
      name: "Proposal Amount",
      selector: (result) =>
        result.customer_amount + " " + result.vendor_currency,
      // sortable: true,
    },
    {
      id: "Action",
      name: "Action",
      cell: (result) => (
        <div
          className="project-list-eye-icon"
          onClick={() => {
            setData(result);
          }}
        >
          <img className="eye-icon" src={EyeIcon} alt=""></img>
        </div>
      ),
    },
  ];

  const allColumnsInMobileView = [
    {
      id: "Vendor name",
      name: "Vendor name",
      selector: (result) => result.vendor_name,
    },

    {
      id: "Proposal Amount",
      name: "Proposal Amount",
      selector: (result) =>
        result.vendor_currency + " " + result.customer_amount,
      // sortable: true,
    },
    {
      id: "Action",
      name: "Action",
      cell: (result) => (
        <div
          className="project-list-eye-icon"
          onClick={() => {
            setData(result);
          }}
        >
          <img className="eye-icon" src={EyeIcon} alt=""></img>
        </div>
      ),
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#304FFE",
        color: "white",
        fontSize: "14px",
        width: "100%",
        fontWeight: "400",
        fontfamily: "Poppins",
      },
    },
    rows: {
      style: {
        cursor: "pointer",
        textTransform: "capitalize",
        fontSize: "14px",
        color: "black",
        fontWeight: "500",
        letterSpacing: "0.5px",
        whiteSpace: "nowrap",
        marginTop: "5px",
        marginBottom: "5px",
        fontfamily: "Poppins",
        width: "100%",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
  };
  const results = transformProposalTableData(proposalDetails);

  // console.log(data, "propsoal  data");
  const onClickBack = () => {
    navigate("/projectlist");
  };
  const onRowClicked = (row) => {
    // console.log(row, "proposal row clicked");
    // dispatch(updateProposalList(row))
    setData(row);
  };
  const onRowClickedInMobileView = (row) => {
    setData(row);
    ShowPopup(true);
  };
  const onClickPopupBack = () => {
    window.location.reload();
  };

  const onClickStartButton = () => {
    ShowAcceptPopup(true);
  };
  const onClickCancel = () => {
    ShowAcceptPopup(false);
  };
  const onClickStart = () => {
    setIsSubmitted(true);
    if (isChecked) {
      const userToken = localStorage.getItem("token");
      try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({
          proposal_id: data.proposal_id,
          announcement_id: data.announcement_id,
          action_type: 1,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: raw,
        };

        fetch(
          `${BaseUrl}/announcement/Proposal-Acceptance-By-Customer`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            // console.log("Proposal-Acceptance-By-Customer", result);
            // console.log(data.announcement_id , "data.announcement_id")
            dispatch(updateProposalList(data.announcement_id));
            navigate("/customer-milestone-list");
          })
          .catch((error) => console.log("error", error));
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <>
      {acceptPopup && (
        <>
          <div
            className="project-details-popup accept-popup-in-mb"
            style={{ position: "fixed" }}
          >
            <div
              className="milestone-review-popup ms-accept-popup-in-mb"
              style={{ minHeight: "230px" }}
            >
              <div className="ms-popup-main">
                <div className="ms-start-and-close">
                  <p className="ms-start-project">Start Project</p>
                  <p className="ms-accept-close" onClick={onClickCancel}>
                    X
                  </p>
                </div>
                <div className="ms-cs-checkbox">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <label className="cs-ms-label">
                    I agree to start and selecting the Vendor for the project
                  </label>
                </div>
                {!isChecked && isSubmitted && (
                  <div style={{ color: "red" }}>Please check the box</div>
                )}
                <div className="ms-btn-div" style={{ marginTop: "50px" }}>
                  <button className="ms-btn-approve" onClick={onClickStart}>
                    Start
                  </button>
                  <button className="ms-btn-reject" onClick={onClickCancel}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>{" "}
          </div>
        </>
      )}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />

          <div className="proposal-header-close-div">
            <h1 className="proposal-list-header">Proposal</h1>
            <div className="proposal-popup-close" onClick={onClickBack}>
              <img
                style={{ marginTop: "-15px" }}
                className="project-icon"
                src={Close}
                alt=""
              />
            </div>
          </div>
          {/* <div className="proposal-all-vendor">
            <h3 className={"projects-status"}>All Vendor</h3>
          </div> */}
          <div className="customer-proposal-table-details">
            <div className="customer-proposal-table">
              <DataTable
                data={results}
                columns={allColumns}
                customStyles={customStyles}
                onRowClicked={onRowClicked}
                pagination={data && data.length > 10}
                // paginationRowsPerPageOptions={[4, 5, 6]}
              />
            </div>

            {!popup && (
              <div className="customer-proposal-table-in-mobile-view">
                <DataTable
                  data={results}
                  columns={allColumnsInMobileView}
                  customStyles={customStyles}
                  onRowClicked={onRowClickedInMobileView}
                  pagination={data && data.length > 10}
                  // paginationRowsPerPageOptions={[4, 5, 6]}
                  fixedHeader
                  fixedHeaderScrollHeight="420px"
                />
              </div>
            )}

            {popup && (
              <>
                <div className="proposal-details-popup">
                  <button
                    className="proposal-popup-back-btn"
                    onClick={onClickPopupBack}
                  >
                    {" "}
                    {"<"} Back{" "}
                  </button>
                </div>
                <div className="customer-proposal-details-in-mb">
                  <div className="customer-amount-vendor-div">
                    <div>
                      <p className="vendor-name-label">
                        <img className="project-icon" src={VendorIcon} alt="" />
                        Vendor name
                      </p>
                      <p className="vendor-name-text-in-mb">
                        {data && data.vendor_name}
                      </p>
                    </div>
                    <div>
                      <p className="vendor-name-label">
                        <img className="project-icon" src={AmountTag} alt="" />
                        Proposal Amount
                      </p>
                      <p className="vendor-name-text-in-mb">
                        {data &&
                          data.customer_amount + " " + data.vendor_currency}
                      </p>
                    </div>
                  </div>
                  <div className="customer-amount-vendor-div">
                    <div>
                      <p className="vendor-name-label">
                        <img
                          className="project-timeline-icon"
                          src={ClockIcon}
                          alt=""
                        />
                        Timeline
                      </p>
                      <p className="vendor-name-text-in-mb">
                        {data && data.timeline + " " + data.timeline_unit}
                      </p>
                    </div>
                  </div>
                  <div className="customer-amount-vendor-div">
                    <div>
                      <p className="vendor-name-label">
                        <img
                          className="project-icon"
                          src={Descripiton}
                          alt=""
                        />
                        Description
                      </p>
                      <p className="customer-proposal-desc-in-mb">
                        {data &&
                          (showMore
                            ? parse(data.perposal_description)
                            : parse(
                                data.perposal_description.substring(0, 50)
                              ))}

                        {data.perposal_description.length > 50 && (
                          <button
                            className="proposal-show-more-button"
                            onClick={() => setShowMore(!showMore)}
                          >
                            {showMore ? "Show Less" : "Show More"}
                            {showMore ? (
                              <img
                                className="project-icon"
                                src={ShowMore}
                                alt=""
                              />
                            ) : (
                              <img
                                className="project-icon"
                                src={Showless}
                                alt=""
                              />
                            )}
                          </button>
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="customer-amount-vendor-div">
                    <div>
                      <p className="vendor-name-label">
                        <img
                          className="project-icon"
                          src={ResourcesIcon}
                          alt=""
                        />
                        Project Resources
                      </p>
                      <p className="customer-proposal-desc-in-mb">
                        {parse(data.project_resources)}
                      </p>
                    </div>
                  </div>
                  <div className="customer-amount-vendor-div">
                    <div>
                      <p className="vendor-name-label">
                        <img
                          className="project-icon"
                          src={SupportIcon}
                          alt=""
                        />
                        Project Support
                      </p>
                      <p className="customer-proposal-desc-in-mb">
                        {parse(data.project_support)}
                      </p>
                    </div>
                  </div>
                  <div className="customer-amount-vendor-div">
                    <div>
                      <p className="vendor-name-label">
                        <img
                          className="project-icon"
                          src={ProjectIcon}
                          alt=""
                        />
                        Scope of Work
                      </p>
                      <p className="customer-proposal-desc-in-mb">
                        {parse(data.out_of_scope)}
                      </p>
                    </div>
                  </div>
                  <div className="customer-amount-vendor-div">
                    <div>
                      <p className="vendor-name-label">
                        <img
                          className="project-icon"
                          src={Descripiton}
                          alt=""
                        />
                        Terms & Conditions
                      </p>
                      <p className="customer-proposal-desc-in-mb">
                        {parse(data.terms_conditions)}
                      </p>
                    </div>
                  </div>
                  <div className="customer-start-project-btn-div">
                    <button
                      className="customer-start-project-btn-in-mb"
                      onClick={onClickStartButton}
                    >
                      Start Project
                    </button>
                  </div>
                </div>
              </>
            )}

            <div className="customer-proposal-details">
              <div className="customer-proposal-details-header">
                <div className="">Proposal Detail</div>
                <div
                  className="customer-start-project-btn-div"
                  style={{ marginBottom: "0px" }}
                >
                  <button
                    className="customer-start-project-btn cs-start-btn"
                    onClick={onClickStartButton}
                  >
                    Start Project
                  </button>
                </div>
              </div>
              <div className="customer-amount-vendor-div">
                <div>
                  <p className="vendor-name-label">
                    <img className="project-icon" src={VendorIcon} alt="" />
                    Vendor name
                  </p>
                  <p className="vendor-name-text">{data && data.vendor_name}</p>
                </div>
                <div>
                  <p className="vendor-name-label">
                    <img className="project-icon" src={AmountTag} alt="" />
                    Proposal Amount
                  </p>
                  <p className="vendor-name-text">
                    {data && data.customer_amount + " " + data.vendor_currency}
                  </p>
                </div>
              </div>
              <div className="customer-amount-vendor-div">
                <div>
                  <p className="vendor-name-label">
                    <img
                      className="project-timeline-icon"
                      src={ClockIcon}
                      alt=""
                    />
                    Timeline
                  </p>
                  <p className="vendor-name-text">
                    {data && data.timeline + " " + data.timeline_unit}
                  </p>
                </div>
              </div>
              <div className="customer-amount-vendor-div">
                <div>
                  <p className="vendor-name-label">
                    <img className="project-icon" src={Descripiton} alt="" />
                    Description
                  </p>
                  <p className="customer-proposal-desc">
                    {data &&
                      (showMore
                        ? parse(data.perposal_description)
                        : parse(data.perposal_description.substring(0, 50)))}
                  </p>
                  {data.perposal_description.length > 50 && (
                    <button
                      className="proposal-show-more-button"
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore ? "Show Less" : "Show More"}
                      {showMore ? (
                        <img className="project-icon" src={ShowMore} alt="" />
                      ) : (
                        <img className="project-icon" src={Showless} alt="" />
                      )}
                    </button>
                  )}
                </div>
              </div>
              <div className="customer-amount-vendor-div">
                <div>
                  <p className="vendor-name-label">
                    <img className="project-icon" src={ResourcesIcon} alt="" />
                    Project Resources
                  </p>
                  <p className="customer-proposal-desc">
                    {parse(data.project_resources)}
                  </p>
                </div>
              </div>
              <div className="customer-amount-vendor-div">
                <div>
                  <p className="vendor-name-label">
                    <img className="project-icon" src={SupportIcon} alt="" />
                    Project Support
                  </p>
                  <p className="customer-proposal-desc">
                    {parse(data.project_support)}
                  </p>
                </div>
              </div>
              <div className="customer-amount-vendor-div">
                <div>
                  <p className="vendor-name-label">
                    <img className="project-icon" src={ProjectIcon} alt="" />
                    Scope of Work
                  </p>
                  <p className="customer-proposal-desc">
                    {parse(data.out_of_scope)}
                  </p>
                </div>
              </div>
              <div className="customer-amount-vendor-div">
                <div>
                  <p className="vendor-name-label">
                    <img className="project-icon" src={Descripiton} alt="" />
                    Terms & Conditions
                  </p>
                  <p className="customer-proposal-desc">
                    {parse(data.terms_conditions)}
                  </p>
                </div>
              </div>
              {/* <div className="customer-start-project-btn-div">
                <button
                  className="customer-start-project-btn"
                  onClick={onClickStart}
                >
                  Accept & Start Project
                </button>
              </div> */}
            </div>
          </div>
          {/* <div className="customer-proposal-back-btn-div">
            <button className="customer-proposal-back-btn" onClick={onClickBack}>Back</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CustomerProposalList;
