import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useForm, Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { BaseUrl } from "../../Api/api";
import { toast } from "react-toastify";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router";
import PageLoader from "../../components/PageLoader/PageLoader";

const NewAddProject = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  const [solutions, setSolutions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState("");
  const [pageLoading, setPageLoading] = useState(null);
  const token = localStorage.getItem("token");

  const handleDateChange = (date, e) => {
    setStartDate(date);
    var format_date = String(moment(date).format("YYYY-MM-DD HH:mm:ss"));
    setValue("project_expiry_datetime", format_date);
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];

    // check if the file is selected
    if (file) {
      const fileType = file.type;
      // check if the file type is not PDF
      if (fileType !== "application/pdf") {
        toast.warning(`File type must be PDF format`);
        setSelectedFileError("File must be in PDF format.");
        e.target.value = "";
        setSelectedFile(null);
      } else {
        setSelectedFileError("");
        setSelectedFile(e.target.files[0]);
        console.log(e.target.files[0]);
      }
    } else {
      setSelectedFileError("");
      setSelectedFile(null);
    }
  };

  const onSubmit = (data) => {
    setPageLoading(true);
    var formdata = new FormData();
    formdata.append("project_name", data.project_name);
    formdata.append("project_description", data.project_description);
    formdata.append("city", data.city);
    formdata.append("country", data.country);
    formdata.append("solution_name", data.solution_name);
    formdata.append(
      "project_budget",
      data.project_budget ? data.project_budget : "Budget not set"
    );
    formdata.append("project_expiry_datetime", startDate);
    if (selectedFile) {
      formdata.append("attachment", selectedFile);
    }

    axios
      .post(`${BaseUrl}/announcement/Create-Announcement`, formdata, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setPageLoading(false);
        if (response.data.status === 200) {
          toast.success("Product Publish Successfully");
          navigate("/projectlist");
        } else if (response.data.status === 401) {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setPageLoading(false);
      });
  };

  useEffect(() => {
    reset();
  }, []);
  // solutions API
  useEffect(() => {
    axios
      .get(`${BaseUrl}/vendor/Get-Solutions`)
      .then((response) => {
        if (response.data.status === 200) {
          setSolutions(response.data.data);
        } else if (response.data.status === 401) {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Country API
  useEffect(() => {
    axios
      .get(`${BaseUrl}/common/Get-All-Country`)
      .then((response) => {
        if (response.data.status === 2000) {
          setCountries(response.data.data.country_list);
        } else if (response.data.status === 401) {
          console.log("Unauthorized");
        }
      })
      .catch((error) => {
        console.log("country catch error", error);
      });
  }, []);

  const selectedCountry = watch("country");

  // state api caliing based on the selected country

  useEffect(() => {
    if (selectedCountry) {
      axios
        .get(`${BaseUrl}/universal/getAllStates/${selectedCountry}`)
        .then((res) => {
          if (res.data.status === 200) {
            setStates(res.data.data);
          } else if (res.data.status === 401) {
            console.log("state error");
          }
        })
        .catch((err) => {
          console.log("state catch error", err);
        });
    }
  }, [selectedCountry]);

  const selectedState = watch("state");

  // city api calling based on the selected state and the selected country

  useEffect(() => {
    if (selectedCountry && selectedState) {
      axios
        .get(
          `${BaseUrl}/universal//getAllCities/${selectedCountry}/${selectedState}`
        )
        .then((res) => {
          if (res.data.status === 200) {
            setCities(res.data.data);
          } else if (res.data.status === 401) {
            console.log("city error");
          }
        })
        .catch((err) => {
          console.log("city catch error", err);
        });
    }
  }, [selectedCountry && selectedState]);

  const editorConfig = {
    placeholder: "Enter the Project Description Here....",
  };
  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />
          <main className="maincontent">
            <div className="addproborder">
              <h3 className="addproject">Create Project</h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="Project-form">
              <div className="fieldsgroup">
                <div className="formfieldsinput">
                  <div className="form-group">
                    <label>Project / Service Name</label>
                    <input
                      className="customer-project-input"
                      type="text"
                      placeholder="Project Name"
                      {...register("project_name", {
                        required: "Please enter the project name",
                      })}
                    />
                    {errors.project_name && (
                      <p className="error-msg">{errors.project_name.message}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label>Project / Services Description</label>

                    <Controller
                      name="project_description"
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Please enter the project description",
                      }}
                      render={({ field }) => (
                        <>
                          <CKEditor
                            config={editorConfig}
                            editor={ClassicEditor}
                            onBlur={field.onBlur}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              field.onChange(data);
                            }}
                          />
                          {errors.project_description && (
                            <p className="error-msg">
                              {errors.project_description.message}
                            </p>
                          )}
                        </>
                      )}
                    />
                  </div>

                  <div className="form-group">
                    <label>Announcement Category</label>
                    <select
                      type="text"
                      placeholder="Select Solution"
                      // className="selectlist"
                      className="selectdropdownlist"
                      {...register("solution_name", {
                        required: "Please select the solution",
                        maxLength: 80,
                      })}
                    >
                      <option value="">Select Solution</option>
                      {solutions.map((e) => (
                        <option value={e.solutions_name}>
                          {e.solutions_name}
                        </option>
                      ))}
                    </select>
                    {errors.solution_name && (
                      <p className="error-msg">
                        {errors.solution_name.message}
                      </p>
                    )}
                  </div>

                  <div className="fieldsgroup">
                    <div className="formfieldsinput">
                      <div className="form-group">
                        <label>Country</label>
                        <select
                          type="text"
                          placeholder="Select Country"
                          // className="selectlist"
                          className="selectdropdownlist"
                          {...register("country", {
                            required: "Please select the country",
                            maxLength: 80,
                          })}
                        >
                          <option value="">Select Country</option>
                          {countries.map((e) => (
                            <option value={e.country}>{e.country}</option>
                          ))}
                        </select>
                      </div>
                      {errors.country && (
                        <span className="error-msg">
                          {errors.country.message}
                        </span>
                      )}
                    </div>
                    <div className="formfieldsinput">
                      <div className="form-group">
                        <label>State</label>
                        <select
                          type="text"
                          placeholder="Select State"
                          // className="selectlist"
                          className="selectdropdownlist"
                          {...register("state", {
                            required: "Please select the state",
                            maxLength: 80,
                            // onChange : handleChangestate,
                          })}
                        >
                          <option value="">Select state</option>
                          {states.map((state) => (
                            <option value={state}>{state}</option>
                          ))}
                        </select>
                      </div>
                      {errors.state && (
                        <span className="error-msg">
                          {errors.state.message}
                        </span>
                      )}
                    </div>
                    <div className="formfieldsinput">
                      <div className="form-group">
                        <label>City</label>
                        <select
                          type="text"
                          placeholder="Select City"
                          // className="selectlist"
                          className="selectdropdownlist"
                          {...register("city", {
                            required: "Please select the city",
                            maxLength: 80,
                          })}
                        >
                          <option value="">Select City</option>
                          {cities.map((city) => (
                            <option value={city}>{city}</option>
                          ))}
                        </select>
                      </div>
                      {errors.city && (
                        <span className="error-msg">{errors.city.message}</span>
                      )}
                    </div>
                  </div>

                  <div className="fieldsgroup">
                    <div className="formfieldsinput">
                      <div className="form-group">
                        <label>Announcement Expiry Date</label>

                        <DatePicker
                          className="customer-project-input"
                          selected={startDate}
                          onChange={handleDateChange}
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="dd-MM-yyyy hh:mm aa"
                          name="project_expiry_datetime"
                        />
                      </div>
                    </div>
                    <div className="formfieldsinput">
                      <div className="form-group">
                        <label>
                          Project Budget in $USD{" "}
                          <span className="formnote">
                            (Enter Your Project Budget here)
                          </span>
                        </label>
                        <input
                          className="customer-project-input"
                          type="text"
                          name="project_budget"
                          {...register("project_budget", {
                            required: false,
                          })}
                        />
                      </div>
                      {/* <p className="err-message">{formErrors.budget}</p> */}
                    </div>
                  </div>

                  <div className="form-group">
                    <label>
                      Attachment
                      <span className="formnote">(Attach PDF files Only)</span>
                    </label>
                    <input
                      type="file"
                      // value={selectedFile}
                      onChange={handleFileInput}
                      accept="application/pdf"
                    />
                  </div>
                  {selectedFileError && (
                    <span className="error-msg">{selectedFileError}</span>
                  )}

                  <div className="form-group submit-btn-customer">
                    <button
                      type="submit"
                      className="btn btn-primary project-submit"
                    >
                      <i className="far fa-paper-plane"></i>Submit Project
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </main>
        </div>
      </div>
    </>
  );
};

export default NewAddProject;
