
import Layout from "../../components/Layout";
import React, { useEffect } from "react";

const TermsConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Layout>
      <div className="termsconditions">
        <h2> Shipping & Delivery Policy (Products) –</h2>
        <p>
          For International buyers, orders are shipped and delivered through
          registered international courier companies and/or International speed
          post only. For domestic buyers, orders are shipped through registered
          domestic courier companies and /or speed post only. Orders are shipped
          within 15 working days or as per the delivery date agreed at the time
          of order confirmation and delivering of the shipment subject to
          Courier Company / post office norms. Your Company Name is not liable
          for any delay in delivery by the courier company / postal authorities
          and only guarantees to hand over the consignment to the courier
          company or postal authorities within 15 working days from the date of
          the order and payment or as per the delivery date agreed at the time
          of order confirmation. Delivery of all orders will be to registered
          address of the buyer as per the credit/debit card only at all
          times(Unless specified at the time of Order). Your Company Name is in
          no way responsible for any damage to the order while in transit to the
          buyer. Payments Your Company Name is proud to use Payment Gateway Name
          for fast, easy and efficient secure payments. All major credit cards
          are accepted.
        </p>
        <br />
        <h2>Shipping & Delivery Policy (Services) - </h2>
        <p>
          Delivery of our services will be confirmed on your mail ID as
          specified during registration. For any issues in utilizing our
          services you may contact our helpdesk on +66 98 865 6621.
        </p>
      </div>
    </Layout>
  );
};

export default TermsConditions;
