import React, { useState, useEffect } from "react";
import "../../Vendor/vendorProfile/page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { BsInfoCircle } from "react-icons/bs";
import { MdModeEdit } from "react-icons/md";
import { Link } from "react-router-dom";
import { FcCameraIdentification } from "react-icons/fc";
import "react-phone-number-input/style.css";
import PhoneInput, {
        isPossiblePhoneNumber,
        isValidPhoneNumber,
} from "react-phone-number-input";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch } from "react-redux";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./utils";
import Spinner from "../../../components/Header/Spinner";
import { toast } from "react-toastify";
import { BaseUrl } from "../../../Api/api.js";
import CommonApi from "../../../Api/commonApi";
import axios from "axios";

const MyProfileEdit = () => {
        let navigate = useNavigate();
        const dispatch = useDispatch();
        const [error, showerror] = useState(false);
        const [phone, setPhone] = useState("");
        const [showBar, setShowBar] = useState("0");
        const [picture, setPicture] = useState("");
        const [pictureUrl, setPictureUrl] = useState("");
        const [userData, setUserData] = useState({});
        const [pageLoading, setPageLoading] = useState(false);
        const [profile, setProfile] = useState(false);
        const [countryList, setCountryList] = useState([]);


        const [countries, setCountries] = useState([]);
        const [states, setStates] = useState([]);
        const [cities, setCities] = useState([]);
        const [state, setState] = useState();
        const [city, setCity] = useState();
        const [country, setCountry] = useState();

        const userToken = localStorage.getItem("token");
        const userinfo = JSON.parse(localStorage.getItem("user"));

        const {
                register,
                handleSubmit,
                watch,
                reset,
                setValue,
                formState: { errors },
        } = useForm();


        // calling the country API
        useEffect(() => {
                axios
                        .get(`${BaseUrl}/common/Get-All-Country`)
                        .then((response) => {
                                if (response.data.status === 2000) {
                                        setCountries(response.data.data.country_list);
                                }
                                else if (response.data.status === 401) {
                                        console.log("Unauthorized")
                                }
                        })
                        .catch((error) => {
                                console.log("country catch error", error)
                        })
        }, [])

        const selectedCountry = watch('country');

        // state api caliing based on the selected country

        useEffect(() => {
                if (selectedCountry) {
                        axios
                                .get(`${BaseUrl}/universal/getAllStates/${selectedCountry}`)
                                .then((res) => {
                                        if (res.data.status === 200) {
                                                setStates(res.data.data)

                                        }
                                        else if (res.data.status === 401) {
                                                console.log("state error")
                                        }
                                })
                                .catch((err) => {
                                        console.log("state catch error", err)
                                })
                }
        }, [selectedCountry]);

        const selectedState = watch('state');

        // city api calling based on the selected state and the selected country

        useEffect(() => {
                if (selectedCountry && selectedState) {
                        axios
                                .get(`${BaseUrl}/universal//getAllCities/${selectedCountry}/${selectedState}`)
                                .then((res) => {
                                        if (res.data.status === 200) {
                                                setCities(res.data.data)
                                        }
                                        else if (res.data.status === 401) {
                                                console.log("city error")
                                        }
                                })
                                .catch((err) => {
                                        console.log("city catch error", err)
                                })
                }
        }, [selectedCountry && selectedState]);

        const selectedCity = watch("city")



        const watchAllFields = watch();
        const handleChange = (e) => {
                setPicture(e.target.files[0]);
                let url = URL.createObjectURL(e.target.files[0]);
                setProfile(true);
                setPictureUrl(url);
        };

        // const handleChangeState = (e) => {
        //         setState(e.target.value);
        //         setCity("");
        // }
        //  const handleChangeCity = (e) => {
        //         setCity(e.target.value);
        //  }
        //  const handleChangeCountry = (e) => {
        //         setCountry(e.target.value);
        //         setState("");
        //         setCity("")
                
        //  }

        const onSubmit = async (data) => {
                data = { ...data, phone: phone };
                if (isValidPhoneNumber(phone)) {
                        dispatch(updateStatusValue(progressBarValidation(1)));
                      
                        const formdata = new FormData();
                        
                        formdata.append(
                                "company_name",
                                data.company_name == undefined
                                        ? userData.company_name
                                        : userinfo.company_name
                        );
                        formdata.append("first_name", data.first_name);
                        formdata.append("last_name", data.last_name);
                        //formdata.append("email", data.email);
                        formdata.append("phone_number", data.phone);
                        if (pictureUrl.includes("blob")) {
                                formdata.append("files", picture, pictureUrl);
                        }
                        formdata.append("position", data.position);
                        formdata.append("department_name", data.department_name);
                        formdata.append("address", data.address);
                        formdata.append("city", data.city);
                        formdata.append("state", data.state);
                        formdata.append("country",data.country);
                        formdata.append("zipcode", data.zipcode);

                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${userToken}`);
                        var requestOptions = {
                                method: "POST",
                                body: formdata,
                                headers: myHeaders,
                                redirect: "follow",
                        };
                        fetch(`${BaseUrl}/user/User-Update-Profile`, requestOptions)
                                .then((response) => response.json())
                                .then((result) => {
                                        if (result.status === 200) {
                                                toast("Your Profile Saved Successfully");
                                                CommonApi.CustomerProfileApi().then((mcpdata) => {
                                                        if (mcpdata.status === 200) {
                                                                localStorage.setItem("userInfo", JSON.stringify(mcpdata.data));
                                                                navigate("/profile");
                                                        }
                                                });

                                                showerror(false);
                                        } else {
                                                showerror(true);
                                        }
                                })
                                .catch((error) => console.log("error", error));
                }
        };


        useEffect(() => {
                setPageLoading(true);
                const Token = localStorage.getItem("token");
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${Token}`);
                var requestOptions = {
                        method: "GET",
                        headers: myHeaders,
                        redirect: "follow",
                };
                let data;

                fetch(`${BaseUrl}/user/Get-User-My-Profile`, requestOptions)
                        .then((response) => response.json())
                        .then((result) => setUserData(result.data[0]))
                        .catch((error) => console.log("error", error));
                setPageLoading(false);
        }, []);


        useEffect(() => {
                if (userData) {
                        Object.keys(userData)?.map(key => {
                                setValue(key, userData[key])
                                setPictureUrl(userData.profile_img_url);
                                setPhone(
                                        userData.phone_no ? userData.phone_no : userinfo.user_info.phone_number
                                );
                        })
                }

        }, [userData])




        useEffect(() => {
                const afterRemovingTheDuplicates = Object.values(watchAllFields).filter(
                        (value) => value && value.length > 0
                );
                const percentage = (afterRemovingTheDuplicates.length / 13) * 100;
                if (percentage > 100) {
                        setShowBar("" + 100);
                } else {
                        setShowBar("" + percentage);
                }
        }, [watchAllFields]);

        return (
                <div className="dashboard-container">
                        <Sidebar />
                        <div className="main-dashboard-container">
                                <Topbar
                                        isShow={true}
                                        value={
                                                userData && userData.profile_complete_status
                                                        ? userData.profile_complete_status
                                                        : 0
                                        }
                                />
                                <main className="maincontent">
                                        <div className="mainpro">
                                                {/* <Topbar /> */}
                                                {pageLoading && <Spinner />}
                                                <section>
                                                        <div className="basic-info">
                                                                <h3>My Profile</h3>
                                                                {/* <Link to="/profile-edit">
                  <h4>
                    Edit
                    <MdModeEdit />
                  </h4>
                </Link> */}
                                                        </div>
                                                </section>

                                                <form onSubmit={handleSubmit(onSubmit)} className="profileform">
                                                        <section>
                                                                {/* <div className="profile-info"></div> */}
                                                                <div className="division">
                                                                        <div className="upload-file">
                                                                                <h3>
                                                                                        Profile Image<span className="star">*</span>
                                                                                </h3>
                                                                                <div className="profilepicupload">
                                                                                        <img src={pictureUrl} alt="" />
                                                                                        <div className="uploadImageProfile">
                                                                                                <FcCameraIdentification />
                                                                                                <input
                                                                                                        type="file"
                                                                                                        name="myfile"
                                                                                                        onChange={handleChange}
                                                                                                />
                                                                                        </div>
                                                                                </div>
                                                                                {profile && <p>Your Profile Uploaded Successfully</p>}
                                                                                <p className="profilepicdes">
                                                                                        300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
                                                                                </p>
                                                                        </div>
                                                                        <div className="progressbar">
                                                                                <div className="bar-profile">
                                                                                        <h4>Completion Progress</h4>
                                                                                        {/* <img src={barr} alt="" /> */}
                                                                                        <div className="circle-container">
                                                                                                <CircularProgressbar
                                                                                                        value={showBar}
                                                                                                        text={Math.round(showBar) + "%"}
                                                                                                        styles={buildStyles({
                                                                                                                pathColor: "#1fc94f",
                                                                                                                textColor: "#001488",
                                                                                                                backgroundColor: "#3e98c7",
                                                                                                        })}
                                                                                                />
                                                                                                <p>
                                                                                                        {showBar == 100 ? "" : "Please Complete Your Profile"}
                                                                                                </p>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                        </section>

                                                        <section className="pro-info">
                                                                <div className="first-last-names">
                                                                        <div className="d-flex flex-column col-12 col-md-6" style={{ marginRight: "10px" }}>
                                                                                <label htmlFor="" className='dep-label py-1'>First Name</label>
                                                                                <input
                                                                                        type="text"
                                                                                        placeholder="Add your name here"
                                                                                        {...register("first_name", {
                                                                                                required: "First name is required",
                                                                                                minLength: {
                                                                                                        value: 3,
                                                                                                        message: "First name must be at least 3 characters long",
                                                                                                },
                                                                                                maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "First name cannot exceed 20 characters",
                                                                                                },
                                                                                                pattern: {
                                                                                                        value: /^[A-Za-z]+$/,
                                                                                                        message: "Only letters are allowed in the first name",
                                                                                                },
                                                                                        })}
                                                                                />
                                                                                {errors.first_name && <span className="error-msg">
                                                                                        {errors.first_name.message}</span>}
                                                                        </div>
                                                                        <div className="d-flex flex-column col-12 col-md-6">
                                                                                <label htmlFor="" className='dep-label py-1'>Last Name</label>
                                                                                <input
                                                                                        type="text"
                                                                                        placeholder="Add your name here"
                                                                                        {...register("last_name", {
                                                                                                required: "Last name is required",
                                                                                                minLength: {
                                                                                                        value: 3,
                                                                                                        message: "Last name must be at least 3 characters long",
                                                                                                },
                                                                                                maxLength: {
                                                                                                        value: 20,
                                                                                                        message: "Last name cannot exceed 20 characters",
                                                                                                },
                                                                                                pattern: {
                                                                                                        value: /^[A-Za-z]+$/,
                                                                                                        message: "Only letters are allowed in the Last name",
                                                                                                },
                                                                                        })}
                                                                                />
                                                                                {errors.last_name && <span className="error-msg">
                                                                                        {errors.last_name.message}</span>}
                                                                        </div>
                                                                </div>
                                                                <div className="proformfield">
                                                                        <label>
                                                                                Email<span className="star">*</span>
                                                                        </label>
                                                                        <input
                                                                                type="email"
                                                                                placeholder="Add your email here"
                                                                                {...register("email", {
                                                                                        disabled: true,
                                                                                })}
                                                                                readonly
                                                                        />
                                                                        {errors.email && (
                                                                                <span className="error-msg">
                                                                                        Please enter the email address
                                                                                </span>
                                                                        )}
                                                                        <div className="protip">
                                                                                <BsInfoCircle />
                                                                                <p>It already set, you can’t change it</p>
                                                                        </div>
                                                                </div>
                                                        </section>

                                                        <section className="details-info">
                                                                <div className="proformfield">
                                                                        <label>
                                                                                Contact Number<span className="star">*</span>
                                                                        </label>

                                                                        <PhoneInput
                                                                                country="india"
                                                                                placeholder="+919870153547"
                                                                                name="phone_number"
                                                                                international
                                                                                value={phone}
                                                                                onChange={(e) => {
                                                                                        setPhone(e);
                                                                                }}
                                                                        />
                                                                        {phone && !isValidPhoneNumber(phone) && (
                                                                                <span className="error-msg">
                                                                                        Please enter the valid phone number
                                                                                </span>
                                                                        )}
                                                                        {!phone && !error && (
                                                                                <span className="error-msg">
                                                                                        Please enter the phone number
                                                                                </span>
                                                                        )}
                                                                        <div className="profile-info svg"></div>
                                                                </div>
                                                                <div className="profile-address">
                                                                        <label>
                                                                                Company Name<span className="star">*</span>
                                                                        </label>
                                                                        <input
                                                                                type="text"
                                                                                placeholder="Add Company Name here"
                                                                                {...register("company_name", {
                                                                                        disabled: true,
                                                                                })}
                                                                        />
                                                                        {errors.company_name && (
                                                                                <span className="error-msg">
                                                                                        Please enter the company name
                                                                                </span>
                                                                        )}
                                                                </div>
                                                                <div className="proformfield" style={{ marginTop: 20 }}>
                                                                        <label>
                                                                                Position<span className="star">*</span>
                                                                        </label>

                                                                        <input
                                                                                type="text"
                                                                                //name="category_id"
                                                                                className="selectlist"
                                                                                // onChange={handleChange}
                                                                                placeholder="Enter Your Position"
                                                                                {...register("position", {
                                                                                        required: true,
                                                                                        minLength: 3,
                                                                                        maxLength: 30,
                                                                                })}
                                                                        ></input>
                                                                        {errors.position && (
                                                                                <span className="error-msg">
                                                                                        You need to enter at least 3 characters, max length is 30
                                                                                        characters
                                                                                </span>
                                                                        )}

                                                                        <div className="profile-info svg"></div>
                                                                </div>

                                                                <div className="proformfield">
                                                                        <label>
                                                                                Department<span className="star">*</span>
                                                                        </label>
                                                                        <input
                                                                                // name="category_id"
                                                                                className="selectlist"
                                                                                // onChange={handleChange}
                                                                                type="text"
                                                                                placeholder="Enter Department Name"
                                                                                {...register("department_name", {
                                                                                        required: true,
                                                                                        minLength: 1,
                                                                                        maxLength: 50,
                                                                                })}
                                                                        ></input>
                                                                        {errors.department_name && (
                                                                                <span className="error-msg">
                                                                                        You need to enter at least 3 characters, max length is 50
                                                                                        characters
                                                                                </span>
                                                                        )}

                                                                        <div className="profile-info svg"></div>
                                                                </div>
                                                        </section>

                                                        <section className="details-info">
                                                                <div className="proformfield pt-20">
                                                                        <div className="profile-address">
                                                                                <label>
                                                                                        Location / Address<span className="star">*</span>
                                                                                </label>

                                                                                <input
                                                                                        type="text"
                                                                                        placeholder="Add address here"
                                                                                        //value={address}
                                                                                        {...register("address", {
                                                                                                required: true,
                                                                                        })}
                                                                                />
                                                                                {errors.address && (
                                                                                        <span className="error-msg">
                                                                                                Please enter the address
                                                                                        </span>
                                                                                )}
                                                                        </div>
                                                                </div>

                                                                <div className="first-last-names pt-20">
                                                                        <div className="d-flex flex-column col-12 col-md-6" style={{ marginRight: "10px" }}>
                                                                                <label>
                                                                                        Country <span className="star">*</span>
                                                                                </label>
                                                                                <select
                                                                                        type="text"
                                                                                        placeholder="Select Country"
                                                                                        className="selectlist"
                                                                                        {...register("country", {
                                                                                                required: "Please select the country",
                                                                                                maxLength: 80,
                                                                                                // onChange : handleChangeCountry,
                                                                                        })}
                                                                                >
                                                                                        <option value="">Select Country</option>
                                                                                        {countries.map((e) => (
                                                                                                <option value={e.country}>
                                                                                                        {e.country}
                                                                                                </option>
                                                                                        ))}
                                                                                </select>
                                                                                {errors.country && (
                                                                                        <span className="error-msg">
                                                                                                {errors.country.message}
                                                                                        </span>
                                                                                )}
                                                                        </div>

                                                                        <div className="d-flex flex-column col-12 col-md-6">
                                                                                <label>
                                                                                        City<span className="star">*</span>
                                                                                </label>
                                                                                <select
                                                                                        type="text"
                                                                                        placeholder="Select Country"
                                                                                      
                                                                                        className="selectlist"
                                                                                        {...register("city", {
                                                                                                required: "Please enter the city",
                                                                                                maxLength: 80,
                                                                                                // onChange : handleChangeCity
                                                                                        })}
                                                                                >
                                                                                        <option value="">Select City</option>
                                                                                        {cities.map((city, index) => (
                                                                                                <option value={city} key={index}>
                                                                                                        {city}
                                                                                                </option>
                                                                                        ))}
                                                                                </select>
                                                                                {errors.city && (
                                                                                        <span className="error-msg">{errors.city.message}</span>
                                                                                )}
                                                                        </div>
                                                                </div>

                                                                <div className="first-last-names pt-20">
                                                                        <div className="d-flex flex-column col-12 col-md-6" style={{ marginRight: "10px" }}>
                                                                                <label>
                                                                                        State/Province <span className="star">*</span>
                                                                                </label>
                                                                                <select
                                                                                        type="text"
                                                                                        placeholder="Select State/Province"
                                                                                        className="selectlist"
                                                                                        {...register("state", {
                                                                                                required: "Please select the State/Province",
                                                                                                maxLength: 80,
                                                                                                // onChange: handleChangeState
                                                                                        })}
                                                                                >
                                                                                        <option value="">Select state</option>
                                                                                        {states.map((state, index) => (
                                                                                                <option key={index} value={state}>
                                                                                                        {state}
                                                                                                </option>
                                                                                        ))}
                                                                                </select>
                                                                                {errors.state && (
                                                                                        <span className="error-msg">
                                                                                                {errors.state.message}
                                                                                        </span>
                                                                                )}
                                                                        </div>

                                                                        <div className="d-flex flex-column col-12 col-md-6">
                                                                                <label>
                                                                                        Pin Code<span className="star">*</span>
                                                                                </label>
                                                                                <input
                                                                                        type="number"
                                                                                        placeholder="Add Pin Code here"
                                                                                        //value={userinfo.zipcode}
                                                                                        {...register("zipcode", {
                                                                                                required: true,
                                                                                                minLength: 4,
                                                                                                maxLength: 8,
                                                                                        })}
                                                                                />
                                                                                {errors.zipcode && (
                                                                                        <span className="error-msg">
                                                                                                Please enter the Pin Code
                                                                                        </span>
                                                                                )}
                                                                        </div>
                                                                </div>
                                                        </section>

                                                        <div className="formbuton">
                                                                {/* <Link to={`/AgencyProfile`}> */}
                                                                <input type="submit" className="save-button" value="Save" />
                                                                {/*<input
                  type="submit"
                  className="save-button"
                  value="Skip"
                  onClick={handleSkip}
                />
                 </Link> */}

                                                                {/* <Link to={`/AgencyProfile`}>
                <button className="save-button">Skip</button>
              </Link> */}
                                                        </div>
                                                </form>
                                        </div>
                                </main>
                        </div>
                </div>
        );
};
export default MyProfileEdit;
