import React, { useState, useEffect } from "react";
import "./page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { MdModeEdit } from "react-icons/md";
import { BsCash } from "react-icons/bs";
import { BsPlusSquareFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import bar40 from "./images/bar40.png";
import Popup from "reactjs-popup";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinFill } from "react-icons/ri";
import { ImLink } from "react-icons/im";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import DragDrop from "./dragandrop";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Viewer from "react-viewer";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./util.js";
import { useDispatch } from "react-redux";
import { BaseUrl } from "../../../Api/api";
import { toast } from "react-toastify";
import CommonApi from "../../../Api/commonApi";
const MyProfile = () => {
  const dispatch = useDispatch();
  const [error, showerror] = useState(false);
  const [showBar, setShowBar] = useState("0");
  const [selectedFilesNames, setSelectedFileNames] = useState([]);
  const [userFile, setUserFile] = useState({});
  const [visible, setVisible] = useState(false);
  const [activeImageUrl, setActiveImageUrl] = useState("");
  const [companyDes, setCompanyDes] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [overAllProgressValue, setOverAllProgressValue] = useState(0);
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const [userData, setUserData] = useState({});
  const watchAllFields = watch();
  const handleSkip = (e) => {
    window.location = "/subscription";
  };

  const dragFiles = (files) => {
    let temp = [];
    Object.values(files).forEach((key) => {
      temp.push(key.name);
    });
    setSelectedFileNames(temp);
    setUserFile(files);
  };

  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  
  const onSubmit = (data) => {
    const Token = localStorage.getItem("token");
    const toGetCurrencySymbol = localStorage.getItem("user");
    const currencySymbol = JSON.parse(toGetCurrencySymbol).user_info.symbol;
    const overAllProgressBar = JSON.parse(toGetCurrencySymbol).user_info
      .profile_complete_status;
    data = { ...data };
    dispatch(updateStatusValue(progressBarValidation(4)));
    const formdata = new FormData();
    formdata.append("project_price", data.project_price);
    formdata.append("project_currency", currencySymbol);
    formdata.append("project_duration", data.project_duration);
    formdata.append("project_website_link", data.project_website);
    formdata.append("social_link", data.social_link);
    Object.values(userFile).forEach((value) => {
      let url = URL.createObjectURL(value);
      formdata.append("files", value, url);
    });



    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Token}`);
    var requestOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${BaseUrl}/vendor/Vendor-Portfolio-Profile`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 200) {
          toast("Your Profile Saved Successfully");
          navigate("/portfolio");
          showerror(false);
        } else {
          showerror(true);
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    const afterRemovingTheDuplicates = Object.values(watchAllFields).filter(
      (value) => value.length > 0
    );
    console.log(afterRemovingTheDuplicates.length);
    const percentage = (afterRemovingTheDuplicates.length / 4) * 100;
    if (percentage > 100) {
      setShowBar("" + 100);
    } else {
      setShowBar("" + percentage);
    }
  }, [watchAllFields]);
  //get api for details
  useEffect(() => {
    try {
      var myHeaders = new Headers();
      const userToken = localStorage.getItem("token");
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendor/Get-Vendor-Portfolio-Profile`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setUserData(result.data[0]);
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
    try {
      var myHeaders = new Headers();
      const userToken = localStorage.getItem("token");
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/vendor/Get-Vendor-Agency-Profile`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setCompanyDes(result.data[0].about_agency);
          setCompanyName(result.data[0].agency_name);
          CommonApi.ProfileApi().then((vsdata) => {
            console.log("ApiRes", vsdata);
            if (vsdata.status === 200) {
              setOverAllProgressValue(vsdata.data.profile_complete_status);
            }
          });
        })
        .catch((error) => console.log("error", error));
    } catch (err) {
      console.log(err);
    }
  }, []);
  useEffect(() => {
    if (userData) {
      Object.entries(userData).forEach(([name, value]) =>
        setValue(name, value)
      );
    }
  }, [userData]);

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar isShow={true} value={overAllProgressValue} />
        <main className="maincontent">
          <div>
            <section>
              <div className="basic-info">
                <h3>Portfolio</h3>
                {/* <h4>
                  Edit
                  <MdModeEdit />
                </h4> */}
              </div>
            </section>
            <br />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="profileform">
                <section className="division">
                  <div className="head-para">
                    <h3>{companyName}</h3>
                    <p>{companyDes}</p>
                  </div>
                  <div className="progressbar">
                    <div className="bar-profile">
                      <h4>Completion Progress</h4>
                      {/* <img src={barr} alt="" /> */}
                      <div className="circle-container">
                        <CircularProgressbar
                          value={showBar}
                          text={Math.round(showBar) + "%"}
                          styles={buildStyles({
                            pathColor: "#1fc94f",
                            textColor: "#001488",
                            backgroundColor: "#3e98c7",
                          })}
                        />
                        <p>Please Complete Your Profile</p>
                      </div>
                    </div>
                  </div>
                </section>

                <div className="profile-info svg"></div>

                <section>
                  <div className="social">
                    <span>
                      <p>Project Price</p>
                      <input
                        type="number"
                        placeholder="$4000.00"
                        {...register("project_price", {
                          required: true,
                        })}
                      />
                      <BsCash />
                      {errors.project_price && (
                        <span className="error-msg-portfolio">
                          Please enter the project price
                        </span>
                      )}
                    </span>

                    <span>
                      <p>Project Duration</p>
                      <input
                        type="text"
                        placeholder="2 Month"
                        {...register("project_duration", {
                          required: true,
                        })}
                      />
                      {errors.project_duration && (
                        <span className="error-msg-portfolio">
                          Please enter the project duration
                        </span>
                      )}
                    </span>
                  </div>
                </section>

                <section>
                  <div className="weblink">
                    <p>Project Website link</p>
                    <div className="projectlink">
                      <ImLink />
                      <input
                        type="link"
                        placeholder="https://www.techcurve.com/"
                        {...register("project_website", {
                          required: true,
                          pattern: {
                            value: urlRegex,
                            message: "Invalid URL format. Please enter a valid URL.",
                          },
                        })}
                      />
                    </div>
                    {errors.project_website && (
                      <span className="error-msg">
                        {errors.project_website.message}
                      </span>
                    )}
                  </div>
                  <div className="weblink">
                    <p>Social link</p>

                    <div className="projectlink">
                      <ImLink />
                      <input
                        type="link"
                        placeholder="https://youtu.be/www.techcurve.co1234544"
                        {...register("social_link", {
                          required: true,
                          pattern: {
                            value: urlRegex,
                            message: "Invalid URL format. Please enter a valid URL.",
                          },
                        })}
                      />
                    </div>
                    {errors.social_link && (
                      <span className="error-msg">
                       {errors.social_link.message}
                      </span>
                    )}
                  </div>
                </section>
                <br />
                <h4>Upload Portfolio Images Here</h4>

                <DragDrop onChange={dragFiles} />
                <h6 className="last-heading">
                  300 x 300px recommended. JPGs, JPEGs, and PNGs supported.
                </h6>

                {selectedFilesNames.map((value) => {
                  return <p>{value}</p>;
                })}

                {Object.values(userFile).map((value) => {
                  return (
                    <>
                      <div className="portfolio-screenshots">
                        <img
                          src={URL.createObjectURL(value)}
                          alt=""
                          onClick={() => {
                            setVisible(true);
                            setActiveImageUrl(URL.createObjectURL(value));
                          }}
                        />
                      </div>
                    </>
                  );
                })}
                <Viewer
                  visible={visible}
                  onClose={() => {
                    setVisible(false);
                  }}
                  zoomSpeed={0.2}
                  images={[{ src: activeImageUrl, alt: activeImageUrl }]}
                />
              </div>

              <div className="formbuton">
                {/* <Link to={`/AgencyProfile`}> */}
                <input type="submit" className="save-button" value="Save" />
                {/*<input
                  type="submit"
                  className="save-button"
                  value="Skip"
                  onClick={handleSkip}
                />
                 </Link> */}

                {/* <Link to={`/AgencyProfile`}>
                <button className="save-button">Skip</button>
              </Link> */}
              </div>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};
export default MyProfile;
