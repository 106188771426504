import React, { useEffect, useState } from "react";
import "../Project/Project.css";
import Proposal from "./Proposal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import CountdownTimer from "../../components/CountdownTimer";
import { BaseUrl } from "../../Api/api.js";
import { BsCoin } from "react-icons/bs";
import cc from "../../assets/images/cc.svg";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useParams } from "react-router-dom";
import { MdLocationPin, MdAttachment } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { BsBookmarkStar, BsFillCheckCircleFill } from "react-icons/bs";

const Projectlist = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const ID = useParams().announcement_id;

  let vendorInfo = JSON.parse(localStorage.getItem("userinfo"));
  const currentTime = new Date().getTime();
  console.log("current time", currentTime);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      vendor_id: vendorInfo.vendor_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}/announcement/Announcement-List-New`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        localStorage.setItem("results", JSON.stringify(result.data));
        setData(result.data.filter((key) => key.announcement_id == ID));
      })
      .catch((error) => console.log("error", error));
  }, []);

  const announcementid = (announcement_id) => {
    localStorage.setItem("vendorproject", announcement_id);

    if (vendorInfo.activation_status == 1 && vendorInfo.available_point < 100) {
      toast("To Apply Project, You need to buy coins.");
      window.location = "/subscription";
      setOpenModal(false);
    }
  };
  console.log(ID);
  console.log(data);
  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />
        <>
          {openModal && <Proposal closeModal={setOpenModal} />}
          {data.map((item) => {
            const TargetTime = new Date(item.project_expire).getTime();

            return (
              <div>
                <article className="project-list" key={item.announcement_id}>
                  <div className="project-details">
                    <div className="projecttitlemain">
                      <h2>
                        {item.project_name}
                        <BsFillCheckCircleFill />
                      </h2>
                      {/* <div className="budgettab">
                        {/* <GiMoneyStack /> *
                        {item.project_budget == null ||
                        item.project_budget == "Budget Not Set" ? (
                          "Budget Not Set"
                        ) : (
                          <>$ {item.project_budget}</>
                        )}
                      </div> */}

                      <div className="">
                        {/* <GiMoneyStack />
                                                                                        {item.project_budget == null ||
                                                                                                item.project_budget == "Budget Not Set" ? (
                                                                                                "Budget Not Set"
                                                                                        ) : (
                                                                                                <>$ {item.project_budget}</>
                                                                                        )} */}
                        {item.vendor_id == vendorInfo.vendor_id ? (
                          <span class="badge bg-success">Applied</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="projectfeatures">
                      {/* <span className="protags"> */}
                      <div>
                        <span>
                          <BsBookmarkStar /> Project Category
                        </span>
                        {parse(item.solution_name)}
                      </div>
                      <div className="borderstraight">
                        <span>
                          <MdLocationPin /> Location
                        </span>
                        {item.city}, {item.country}
                      </div>
                      <div className="borderstraight-left">
                        <span>
                          <BsCoin />
                          Budget
                        </span>
                        {item.project_budget == null ||
                        item.project_budget == "Budget Not Set" ? (
                          "Not Set"
                        ) : (
                          <>
                            {Number(item.project_budget).toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: "0",
                                maximumFractionDigits: "0",
                              }
                            )}
                          </>
                        )}
                      </div>
                      <div>
                        <span>
                          <MdAttachment /> Attachement
                        </span>
                        {item.attachment_url == null ? (
                          "No Attachment"
                        ) : (
                          <a href={item.attachment_url} target="_blank">
                            View
                          </a>
                        )}
                      </div>
                    </div>

                    <p>{parse(item.project_description)}</p>
                  </div>

                  <div className="project-apply">
                    {/* <div>
                      <CountdownTimer
                        targetDate={String(item.project_expire)}
                      />
                    </div> */}

                    <div className="d-flex align-items-center justify-content-flex-end">
                      {item.vendor_id == vendorInfo.vendor_id ? (
                        <>
                          <Link
                            to={`/new-buy-customer-details-page/${item.announcement_id}`}
                          >
                            <button
                              className="apply-btn mx-2 d-flex align-items-center"
                              style={{ width: "210px" }}
                            >
                              <span>
                                <img
                                  src={cc}
                                  alt=""
                                  style={{ width: "30px" }}
                                  className="px-1"
                                />
                              </span>
                              Contact Customer
                            </button>
                          </Link>
                          {/* <button
                                                                                                                className="apply-btn"
                                                                                                                style={{ backgroundColor: "gray" }}
                                                                                                        >
                                                                                                                Already Applied
                                                                                                        </button> */}
                        </>
                      ) : currentTime > TargetTime ? (
                        <button
                          className="apply-btn"
                          style={{ backgroundColor: "red" }}
                        >
                          You Missed it!
                        </button>
                      ) : (
                        <>
                          <Link
                                to={`/new-buy-customer-details-page/${item.announcement_id}`}
                          >
                            <button
                              className="apply-btn mx-2 d-flex align-items-center"
                              style={{ width: "210px" }}
                            >
                              <span>
                                <img
                                  src={cc}
                                  alt=""
                                  style={{ width: "30px" }}
                                  className="px-1"
                                />
                              </span>
                              Contact Customer
                            </button>
                          </Link>
                        </>
                      )}
                    </div>
                  </div>

                  {/* <div className="project-apply">
                    {item.vendor_id == vendorInfo.vendor_id ? (
                      <button
                        className="apply-btn"
                        style={{ backgroundColor: "gray" }}
                      >
                        Already Applied
                      </button>
                    ) : currentTime > TargetTime ? (
                      <button
                        className="apply-btn"
                        style={{ backgroundColor: "red" }}
                      >
                        You Missed It!
                      </button>
                    ) : (
                      <>
                        <Link
                          to={`${item.announcement_id}/${item.project_name}`}
                        >
                          <button
                            className="apply-btn"
                            onClick={() => {
                              setOpenModal(true);
                              announcementid(item.announcement_id);
                            }}
                          >
                            Apply for project
                          </button>
                        </Link>
                        <br />
                        <CountdownTimer
                          targetDate={String(item.project_expiry_datetime)}
                        />
                      </>
                    )}

                    {/* </a> 
                  </div> */}
                </article>
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
};

export default Projectlist;
