import React from "react";
import Faq from "react-faq-component";
import again_login_phone from "../../assets/conqt_guidelines/again_login_phone.png";
import again_login from "../../assets/conqt_guidelines/again_login.png";
import announcement from "../../assets/conqt_guidelines/announcement.png";
import announcement_pc from "../../assets/conqt_guidelines/announcement_pc.png";
import project from "../../assets/conqt_guidelines/project.png";
import project_pc from "../../assets/conqt_guidelines/project_pc.png";
import projectlist from "../../assets/conqt_guidelines/projectlist.png";
import projectlist_pc from "../../assets/conqt_guidelines/projectlist_pc.png";
import "./steps.css";

const data = {
  title: (
    <div>
      <h4>How to get a Project ?</h4>
    </div>
  ),
  rows: [
    {
      title: (
        <div className="step-heading">
          <h4>Step 1: Login</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={again_login_phone} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={again_login} alt="" />
          </span>

          <div className="steps-para">
            <p>Login as a Partner. </p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 2: Announcement</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={announcement} alt="On Phone" />
            <p>1. Click on Announcement text.</p>
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={announcement_pc} alt="" />
            <p>1. Check the footer.</p>
            <p>2. Click on Announcement text.</p>
          </span>

          <div className="steps-para">
            <p>
              Once you click on the Announcement you will redirect to the
              Project page.{" "}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 3: Project</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <img src={project} alt="On Phone" />
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={project_pc} alt="" />
          </span>

          <div className="steps-para">
            <p>1. Check the Projects and read about them.</p>
            <p>
              2. After understanding the project, you can submit the Proposal.
            </p>
            <p>
              3. When a proposal gets submitted successfully, you can move to
              the Project-list page from where you can apply for the projects
              and can complete rest of the process.{" "}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="step-heading">
          <h4>Step 4: Registration Form</h4>
        </div>
      ),
      content: (
        <div className="steps">
          <span>
            <h4>On Mobile Screen</h4>
            <div className="formimage">
              <img src={projectlist} alt="On Phone" />
            </div>
          </span>

          <span className="conqtimage">
            <h4>On Computer Screen</h4>
            <img src={projectlist_pc} alt="On Phone" />
          </span>

          <div className="steps-para">
            <p>You can apply for the project by going through this page. </p>
          </div>
        </div>
      ),
    },
  ],
};

export default function faq() {
  return (
    <div>
      <Faq data={data} />
    </div>
  );
}
