import React, { useState, useCallback, useEffect } from "react";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import "./AddNewProducts.css";
import { useForm, FormProvider, Controller } from "react-hook-form";
import toolTip from "../../../assets/images/tooltip.png";
import uploadFile from "../../../assets/images/apuploadfile.png";
import { Tooltip, Whisper, Button } from "rsuite";
import Productinputs from "./Productinputs";
import { FileUploader } from "react-drag-drop-files";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Form from "react-bootstrap/Form";
// import plusInput from "../../../assets/images/plusInput.png";
// import deleteIcon from "../../../assets/images/delete-icon.png";
// import check from "../../../assets/images/check.png";
// import uncheck from "../../../assets/images/uncheck.png";
import redcross from "../../../assets/images/redcross.png";
import { getBrandListData } from "../../../Store/Reducers/BrandListReducer";
import { getAllCountryList } from "../../../Store/Reducers/CountryList";
import { useDispatch, useSelector } from "react-redux";
import AddGallery from "./AddGallery";
import { useDropzone } from "react-dropzone";
import ProductFilter from "./ProductFilter";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseUrl } from "../../../Api/api";
import "react-bootstrap-typeahead/css/Typeahead.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { Typeahead } from "react-bootstrap-typeahead";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { useNavigate } from "react-router-dom";

const AddNewProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { brandList, loading } = useSelector((state) => state.brandList);
  const { countryList } = useSelector((state) => state.countryList);
  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      if (file.type.startsWith("image/") && file.size <= 5000000) {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          if (img.width === 500 && img.height === 500) {
            setProductImage((prevFiles) => [...prevFiles, file]);
            // console.log(productImage, "product image loaded");
          } else {
            console.log("wrong file");
            toast.error(
              "Invalid Product Images Size Please Select Image Size Height & Width Of 500px Only"
            );
          }
        };
      }
    });
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    minFiles: 2,
    onDrop,
  });
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const methods = useForm();
  const [inputValue, setInputValue] = useState("");
  const [count, setCount] = useState(0);
  const [selectedOption, setSelectedOption] = useState("dropdown");
  const [addNewInput, setAddNewInput] = useState("");
  const [dropdownValue, setDropdownValue] = useState({ logos: "", name: "" });
  const [showDropdown, setShowDropdown] = useState(false);
  const [brandImage, setBrandImage] = useState("");
  const [productImage, setProductImage] = useState([]);
  const [selectPricing, setSelectPricing] = useState("Price");
  const [switchState, setSwitchState] = useState(false);
  const [setCheckPoints, setShowCheckPoints] = useState(false);
  const [divs, setDivs] = useState(
    Array.from({ length: 6 }, (_, i) => ({ id: i, content: `Div ${i + 1}` }))
  );
  const [showProductError, setShowProductError] = useState(false);
  const [productValue, setProductValue] = useState("");
  const [productCount, setProductCount] = useState(0);
  const [finalPriceValue, setFinalPriceValue] = useState(null);
  const [price, setPrice] = useState("");
  const [percentage, setPercentage] = useState("");
  const [selectedDiscounts, setSelectedDiscounts] = useState([]);
  const [discountedValue, setDiscountedValue] = useState(null);
  const [includeTax, setIncludeTax] = useState(0);
  const [brandImageError, setBrandImageError] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const Discount = Array.from({ length: 101 }, (_, i) => ({
    value: i,
    label: i + "%",
  }));
  const userToken = localStorage.getItem("token");
  // console.log(brandImage ? brandImage : null, "brand IMAGES");
  const onSubmit = (data) => {
    // console.log(data.pice_currency, "pice currency");
    setPageLoading(true);
    if (selectedOption === "input") {
      if (brandImage) {
        setBrandImageError(false);
      } else {
        setBrandImageError(true);
      }
    }
    // console.log(brandImageError, "brandImageError");
    if (productImage.length >= 2) {
      const myString = data.brand_name;
      const myBrandName = 1;
      const myArray = myString.split("_");
      const BrandName = myArray[myBrandName] && myArray[myBrandName].trim();
      const Category = data.category_name;
      const CategoryData = Category.split("_");
      const CategoryId = CategoryData[0];
      const CategoryName = CategoryData[1];
      const industryID = data.industry_name.map((obj) => obj.id);
      const industryName = data.industry_name.map((obj) => obj.industry_name);
      const departmentName = data.department_name.map(
        (obj) => obj.department_name
      );
      const departmentID = data.department_name.map((obj) => obj.id);
      // console.log(brandImage, "brandImage");
      const firstProductImage = productImage.shift();
      const restOfProductImage = productImage;
      console.log(firstProductImage, "firstProductImage");
      console.log(restOfProductImage, "restOfProductImage");
      // console.log(data, "data from on submit");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      var formdata = new FormData();
      formdata.append("product_name", data.product_name);
      formdata.append("category_id", CategoryId);
      formdata.append("product_description", data.Description);
      formdata.append("product_information", data.product_information);
      formdata.append("features", data.Features);
      formdata.append("specifications", data.Specification);
      formdata.append("video_link", data.video_link);
      formdata.append("category_name", CategoryName);
      formdata.append("department_id", departmentID);
      formdata.append("department_name", departmentName);
      formdata.append("industry_id", industryID);
      formdata.append("industry_name", industryName);
      restOfProductImage.forEach((element) => {
        formdata.append("gallery", element);
      });
      formdata.append(
        "brand_name",
        data.new_brand_name ? data.new_brand_name : BrandName
      );
      formdata.append("brand_image", brandImage ? brandImage : "");
      formdata.append("image", firstProductImage);
      formdata.append("is_price", data.is_price);
      formdata.append("is_tax_include", is_tax_include);
      formdata.append("initial_price", data.initial_price);
      formdata.append(
        "gst_percentage",
        data.gst_percentage > 0 ? data.gst_percentage : 0
      );
      formdata.append("discount", data.discount ? data.discount[0].value : 0);
      formdata.append("price", data.price ? data.price.toFixed(2) : 0);
      formdata.append("price_without_discount", data.price_without_discount);
      formdata.append("price_type", data.price_type);
      formdata.append(
        "price_currency",
        data.pice_currency && data.pice_currency.split("-")[1].trim()
      );
      formdata.append("offer_text", data.offer_text);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${BaseUrl}/product/Add-Product-New`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === 200) {
            // console.log(result);
            setPageLoading(false);
            toast.success("Product Added Successfully");
            navigate("/vendor-product-lists");
            // setTimeout(function () {
            //   window.location.reload();
            // }, 3000);
          } else {
            setPageLoading(false);
            if (brandImageError === true) {
              toast.error("Please add a brand image");
            }
            if (brandImageError === false) {
              toast.error("Network Error");
            }
          }
        })
        .catch((error) => console.log("error", error));
    } else {
      setPageLoading(false);
      toast.error("Please Add Product Images");
      if (productImage.length < 2) {
        setShowProductError(true);
      }
    }
  };
  const handlePriceChange = (e) => {
    setPrice(e.target.value);
    calculateFinalValue(e.target.value, percentage);
  };

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
    calculateFinalValue(price, e.target.value);
  };
  const calculateFinalValue = (price, percentage) => {
    const priceValue = Number(price) || 0;
    const percentageValue = Number(percentage) || 0;
    const percentageAmount = priceValue * (percentageValue / 100);
    const result = priceValue + percentageAmount;
    setFinalPriceValue(result.toFixed(2) || null);
    calculateDiscountedValue(result, selectedDiscounts);
  };
  const handleDiscountsChange = (selectedList) => {
    setSelectedDiscounts(selectedList);
    calculateDiscountedValue(finalPriceValue, selectedList);
    setValue("discount", selectedList);
  };
  const calculateDiscountedValue = (value, Discount) => {
    let discountedAmount = value;
    Discount.forEach((discount) => {
      const discountValue = Number(discount.label.slice(0, -1)) || 0;
      discountedAmount = discountedAmount - value * (discountValue / 100);
    });
    setDiscountedValue(discountedAmount ? discountedAmount : null);
  };

  // console.log(finalPriceValue, "finalPriceValue");

  const handleInputProduct = (event) => {
    const newValue = event.target.value;
    setProductValue(newValue);
    setProductCount(newValue.length);
  };

  const MAX_WIDTH = 100;
  const MIN_WIDTH = 80;
  const MAX_HEIGHT = 100;
  const MIN_HEIGHT = 80;
  const handleChange = (files) => {
    setDropdownValue({ logos: "", name: "" });
    const image = files;
    const img = new Image();
    img.src = window.URL.createObjectURL(image);
    img.onload = () => {
      const { width, height } = img;
      if (
        width >= MIN_WIDTH &&
        width <= MAX_WIDTH &&
        height >= MIN_HEIGHT &&
        height <= MAX_HEIGHT
      ) {
        setBrandImage(image);
      } else {
        toast.error(
          "Invalid Brand Logo Size Please Select Image Size Height & Width Of 100px Only"
        );
        console.log("Invalid image dimensions");
      }
    };
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setCount(e.target.value.length);
  };
  const fileTypes = ["JPEG", "PNG", "SVG", "JPG", "PDF"];
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setAddNewInput("");
    setDropdownValue({ logos: "", name: "" });
  };

  const handleBrandDropdownChange = (e) => {
    const selectedValue = e.target.value;
    const [logo, name] = e.target.value.split("_");
    const selectedOptions = brandList.find(
      (option) => option.brand_logo_url == logo
    );
    const logos =
      selectedOptions.brand_logo_url.length && selectedOptions.brand_logo_url;
    setDropdownValue({ logos, name });
    setBrandImage("");
  };

  const handleChangePricing = (e) => {
    setSelectPricing(e.target.value);
  };
  const editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "undo",
        "redo",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    placeholder: "Enter text here...",
  };
  editorConfig.toolbar.items.splice(
    editorConfig.toolbar.items.indexOf("table"),
    1
  );
  const is_tax_include = includeTax ? 1 : 0;
  const handleChangeSwitch = (state) => {
    setIncludeTax(state.target.checked);
    // console.log(state.target.value, "state");
    setSwitchState(!switchState);
    // console.log(switchState, "switch state");
  };

  const handleChangeRemoveBrand = () => {
    setBrandImage("");
  };

  const handleDelete = (index) => {
    const newImages = [...productImage];
    newImages.splice(index, 1);
    setProductImage(newImages);
  };

  //   console.log(Discount, "discount");

  useEffect(() => {
    setPageLoading(true);
    dispatch(getBrandListData());
    dispatch(getAllCountryList());
    setPageLoading(false);
  }, []);

  useEffect(() => {
    setValue("price", discountedValue);
    setValue("price_without_discount", finalPriceValue);
  }, [finalPriceValue, discountedValue, setValue]);

  return (
    <>
      {loading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar />

          <FormProvider {...methods}>
            <form
              className="add-new-product-main"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="add-product-border">
                <h3 className="add-product">Add Product</h3>
              </div>
              <div className="product-border">
                <h3 className="addproduct">Product</h3>
              </div>
              <div className="anp-left-right-div">
                <div className="anp-left-div">
                  <div className="pt-2">
                    <div className="anp-label-input">
                      <label className="anp-label">Product Name </label>
                      <Whisper
                        trigger="click"
                        speaker={
                          <Tooltip>
                            Product Name should be max 50 characters
                          </Tooltip>
                        }
                      >
                        <Button appearance="subtle" type="button">
                          {" "}
                          <img className="tool-tip" src={toolTip} alt="" />
                        </Button>
                      </Whisper>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="anp-input"
                        //   value={productValue}
                        maxLength="50"
                        {...register("product_name", {
                          required: true,
                          minLength: 3,
                          maxLength: 50,
                          onChange: handleInputProduct,
                        })}
                      />
                      {errors.product_name && (
                        <span className="error-msg">
                          Please enter the product name
                        </span>
                      )}
                      <p className="anp-input-count">{productCount}/50</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div
                      className="anp-label-input"
                      style={{ display: "block" }}
                    >
                      <label className="anp-label">Product Brand </label> <br />
                      <div className="ap-radios-div">
                        <label className="ap-label-radio">
                          <input
                            type="radio"
                            name="options"
                            value="input"
                            onChange={handleOptionChange}
                            checked={selectedOption === "input"}
                          />
                          <span className="radio"></span> &nbsp; Add New Brand
                        </label>
                        <div class="vl"></div>
                        <label className="ap-label-radio">
                          <input
                            type="radio"
                            name="options"
                            value="dropdown"
                            checked={selectedOption === "dropdown"}
                            onChange={handleOptionChange}
                          />
                          <span className="radio"></span> &nbsp; Select existing
                        </label>
                      </div>
                    </div>
                    {selectedOption === "input" && (
                      <Productinputs
                        register={register}
                        selectedOption={selectedOption}
                        errors={errors}
                      />
                    )}
                    {selectedOption === "dropdown" && (
                      <>
                        <select
                          name="BrandList"
                          className="anp-input"
                          {...register("brand_name", {
                            required: selectedOption === "dropdown",
                            onChange: (e) => handleBrandDropdownChange(e),
                          })}
                        >
                          {" "}
                          <option value="">Select Brand Name</option>
                          {brandList &&
                            brandList.map((e) => {
                              return (
                                <option
                                  key={e.id}
                                  value={`${e.brand_logo_url}_${e.brand_name}`}
                                >
                                  {e.brand_name}
                                </option>
                              );
                            })}
                        </select>
                        {errors.brand_name && (
                          <span className="error-msg">
                            Please select the brand name
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <div className="pt-2">
                    <div className="anp-label-input">
                      <label className="anp-label">Short Description </label>
                      <Whisper
                        trigger="click"
                        speaker={
                          <Tooltip>
                            Short description should be max 150 characters
                          </Tooltip>
                        }
                      >
                        <Button appearance="subtle">
                          {" "}
                          <img className="tool-tip" src={toolTip} alt="" />
                        </Button>
                      </Whisper>
                    </div>
                    <textarea
                      type="text"
                      className="anp-input anp-textarea"
                      maxLength="150"
                      // value={inputValue}
                      {...register("product_information", {
                        required: true,
                        minLength: 3,
                        maxLength: 150,
                        onChange: (e) => handleInputChange(e),
                      })}
                    />
                    {errors.product_information && (
                      <span className="error-msg">
                        Please enter the short description
                      </span>
                    )}
                    <p className="anp-input-count">{count}/150</p>
                  </div>
                </div>
                <div className="anp-right-div">
                  {(selectedOption === "input" ||
                    selectedOption === "dropdown") && (
                    <div className="anp-label-input">
                      <p className="anp-label">Brand Logo </p>
                      <Whisper
                        trigger="click"
                        speaker={<Tooltip>100*100px Image only</Tooltip>}
                      >
                        <Button appearance="subtle">
                          {" "}
                          <img className="tool-tip" src={toolTip} alt="" />
                        </Button>
                      </Whisper>
                    </div>
                  )}
                  <div className="anp-add-brand-div pb-3">
                    {selectedOption === "input" && (
                      <div style={{ width: "150px" }}>
                        <FileUploader
                          multiple={false}
                          handleChange={(files) => handleChange(files)}
                          name="file"
                          //   disabled={true}
                          children={
                            <div className="anp-upload-brand-div">
                              <div className="anp-upload-brand">
                                <img
                                  className="anp-upload-brand-img"
                                  src={uploadFile}
                                  alt=""
                                />{" "}
                              </div>
                              <button type="button" className="anp-btn-brand">
                                {" "}
                                +{" "}
                              </button>
                            </div>
                          }
                          types={fileTypes}
                        />
                        <p className="anp-input-count pt-3">
                          drop file here, paste or browse
                        </p>
                        {brandImageError && (
                          <span className="error-msg">
                            Please add brand image
                          </span>
                        )}
                      </div>
                    )}
                    {(brandImage || dropdownValue.logos) && (
                      <div className="anp-selected-b-img">
                        <img
                          className="anp-selected-brand-img"
                          src={
                            brandImage
                              ? URL.createObjectURL(brandImage)
                              : dropdownValue.logos
                          }
                          alt="Selected Image"
                        />
                        <p className="anp-b-img-name">
                          {brandImage.name
                            ? brandImage.name
                            : dropdownValue.name}
                        </p>
                        {brandImage && (
                          <button
                            type="button"
                            style={{ marginTop: "-100px", padding: "0px" }}
                            onClick={handleChangeRemoveBrand}
                          >
                            <img
                              src={redcross}
                              alt=""
                              className="anp-red-cross"
                            />{" "}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="anp-product-imgs-div">
                    <div className="anp-gallery-div">
                      <div>
                        <div className="anp-add-product-div">
                          <div
                            className="anp-label-input"
                            style={{ marginTop: "-28px" }}
                          >
                            <p className="anp-label">Product Image </p>
                            <Whisper
                              trigger="click"
                              speaker={
                                <Tooltip>
                                  500*500px Image only and add minimum two
                                  images
                                </Tooltip>
                              }
                            >
                              <Button appearance="subtle" type="button">
                                {" "}
                                <img
                                  className="tool-tip"
                                  src={toolTip}
                                  alt=""
                                />
                              </Button>
                            </Whisper>
                          </div>
                          {/* <FileUploader
                        // multiple={true}
                        single
                        handleChange={(files, event) =>
                          ProducthandleChange(files, event)
                        }
                        name="file"
                        //   disabled={true}
                        children={
                          <div className="anp-upload-product-div">
                            <div className="anp-upload-product">
                              <div className="anp-add-product-img-div">
                                <img
                                  className="anp-upload-product-img"
                                  src={uploadFile}
                                  alt=""
                                />{" "}
                              </div>
                              <p className="anp-browse-text">
                                Browse and choose the files you want to upload
                                from your computer
                              </p>
                            </div>
                            <button className="anp-btn-brand anp-btn-product">
                              {" "}
                              +{" "}
                            </button>
                          </div>
                        }
                        types={fileTypes}
                      /> */}
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}

                              //   {...register("gallery", {
                              //     required: true,
                              //     validate: (value) => value.length >= 2
                              //   })}
                            />
                            {isDragActive ? (
                              <p>Drop the files here ...</p>
                            ) : (
                              <div className="anp-upload-product-div">
                                <div className="anp-upload-product">
                                  <div className="anp-add-product-img-div">
                                    <img
                                      className="anp-upload-product-img"
                                      src={uploadFile}
                                      alt=""
                                    />{" "}
                                  </div>
                                  <p className="anp-browse-text">
                                    Browse and choose the files you want to
                                    upload from your computer
                                  </p>
                                </div>
                                <button
                                  className="anp-btn-brand anp-btn-product"
                                  type="button"
                                >
                                  {" "}
                                  +{" "}
                                </button>
                              </div>
                            )}
                            {/* {productImage.map((file) => (
                    console.log(file,"upload"),
                    <div key={file.name}>
                      <p>{file.name}</p>
                      <img src={URL.createObjectURL(file)} alt={file.name} />
                    </div>
                  ))} */}
                          </div>
                        </div>
                        {/* {errors.gallery && (
                          <span className="error-msg">
                          </span>
                        )} */}
                        {/* {errors.gallery &&
                          errors.gallery.type === "required" && (
                            <span className="error-msg">
                              Please add product images
                            </span>
                          )}
                        {errors.gallery &&
                          errors.gallery.type === "minLength" && (
                            <span className="error-msg">
                              You must select at least two files
                            </span>
                          )} */}

                        {/* <img src={URL.createObjectURL(productImage[0])} alt="not " /> */}
                        <p className="anp-input-count pt-2">
                          500*500px Images only <br /> and add minimum two
                          images
                        </p>
                        {showProductError && (
                          <span className="error-msg">
                            {" "}
                            Please add product images atleast two
                          </span>
                        )}
                      </div>
                    </div>
                    {productImage.length > 0 && (
                      <AddGallery
                        images={productImage}
                        onDelete={handleDelete}
                      />
                    )}
                  </div>
                </div>
              </div>

              <ProductFilter
                register={register}
                errors={errors}
                setValue={setValue}
              />
              <div className="product-border">
                <h3 className="addproduct">Pricing</h3>
              </div>
              <div className="anp-pricing-div">
                <select
                  className="anp-input anp-pricing-dropdown"
                  {...register("is_price", {
                    required: true,
                    onChange: (e) => handleChangePricing(e),
                  })}
                >
                  <option value="Price">Fixed Pricing</option>{" "}
                  <option value="Package">Packages</option>{" "}
                  <option value="Quote">Request On Quote</option>
                </select>
                {errors.is_price && (
                  <span className="error-msg">
                    Please select the brand name
                  </span>
                )}
                {selectPricing === "Package" && (
                  <p className="anp-quote-text">
                    Packages selected for this product
                  </p>
                )}
                {selectPricing === "Quote" && (
                  <p className="anp-quote-text">
                    Request a Quote Option selected for this product.
                  </p>
                )}
              </div>

              {selectPricing === "Price" && (
                <>
                  <div className="anp-select-Package-Div">
                    <div>
                      <label htmlFor="" className="anp-price-drop-label">
                        Select Package
                      </label>{" "}
                      <br />
                      <select
                        className="anp-select-drop"
                        {...register("price_type", {
                          required: selectPricing === "Price",
                        })}
                      >
                        {" "}
                        <option value="">Select</option>
                        <option value="FixedPrice">Fixed Price</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="HalfYearly">Half Yearly</option>
                        <option value="Yearly">Yearly</option>
                      </select>
                      {errors.price_type && (
                        <span className="error-msg">
                          Please select the package type
                        </span>
                      )}
                      <p className="anp-price-text">Select package type</p>
                    </div>
                    <div>
                      <label htmlFor="" className="anp-price-drop-label">
                        Currency
                      </label>
                      <br />
                      <select
                        name=""
                        id=""
                        className="anp-select-drop"
                        {...register("pice_currency", {
                          required: selectPricing === "Price",
                        })}
                      >
                        <option value="">Select</option>
                        {countryList &&
                          countryList.map((e) => {
                            return (
                              <option key={e.code}>
                                {e.code} - {e.symbol}
                              </option>
                            );
                          })}
                      </select>
                      {errors.pice_currency && (
                        <span className="error-msg">
                          Please select the currency
                        </span>
                      )}
                      <p className="anp-price-text">Select Currency</p>
                    </div>
                    <div>
                      <label htmlFor="" className="anp-price-drop-label">
                        Product Price
                      </label>
                      <br />
                      <input
                        placeholder="Input amount"
                        type="number"
                        className="anp-select-drop"
                        {...register("initial_price", {
                          required: selectPricing === "Price",
                          onChange: handlePriceChange,
                        })}
                      />
                      {errors.initial_price && (
                        <span className="error-msg">
                          Please enter the price
                        </span>
                      )}

                      <p className="anp-price-text">
                        Enter product price without GST
                      </p>
                    </div>
                  </div>
                  <div className="anp-select-Package-Div">
                    <div>
                      <label
                        htmlFor=""
                        className="anp-price-drop-label"
                      ></label>
                      <Form className="anp-toggle">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          label="Include tax"
                          onChange={handleChangeSwitch}
                        />
                      </Form>
                      <p className="anp-price-text">Toggle on to include gst</p>
                    </div>
                    {switchState && (
                      <>
                        {" "}
                        <div>
                          <label htmlFor="" className="anp-price-drop-label">
                            Tax Percentage
                          </label>
                          <br />
                          <div name="" id="" className="anp-select-drop">
                            <select
                              {...register("tax_type", {
                                required: selectPricing === "Price",
                              })}
                            >
                              <option value="">Select</option>
                              <option value="gst">GST</option>
                              <option value="vat">VAT</option>
                            </select>
                            <input
                              type="number"
                              placeholder="Input text"
                              className="anp-tax-input"
                              {...register("gst_percentage", {
                                required: switchState,
                                onChange: handlePercentageChange,
                                maxLength: 3,
                              })}
                            />
                          </div>
                          <p className="anp-price-text">Enter Tax percentage</p>
                          {errors.tax_type && (
                            <span className="error-msg">
                              Please select tax type
                            </span>
                          )}{" "}
                          <br />
                          {errors.gst_percentage && (
                            <span className="error-msg">
                              Please enter the percentage
                            </span>
                          )}
                        </div>
                        <div>
                          <label htmlFor="" className="anp-price-drop-label">
                            Price Including Tax (MRP)
                          </label>
                          <br />
                          <input
                            className="anp-select-drop"
                            placeholder="Input text"
                            name="price_without_discount"
                            disabled
                            value={
                              finalPriceValue !== null ? finalPriceValue : "N/A"
                            }
                          />
                        </div>{" "}
                      </>
                    )}
                  </div>
                  <div className="anp-select-Package-Div">
                    <div className="anp-disc-div">
                      <label htmlFor="" className="anp-price-drop-label">
                        Discount
                      </label>{" "}
                      <br />
                      <Form.Group>
                        <Typeahead
                          id="searchable-dropdown"
                          labelKey="label"
                          options={Discount}
                          placeholder="Select Discount"
                          onChange={handleDiscountsChange}
                          selected={selectedDiscounts}
                        />
                      </Form.Group>
                      <input
                        {...register("discount", {
                          required: selectPricing === "Price",
                        })}
                        type="hidden"
                      />
                      {errors.discount && (
                        <span className="error-msg">
                          Please select the discount
                        </span>
                      )}
                    </div>
                    <div>
                      <label htmlFor="" className="anp-price-drop-label">
                        Offer
                      </label>
                      <br />
                      <input
                        {...register("offer_text", {
                          minLength: 3,
                          maxLength: 50,
                        })}
                        className="anp-select-drop"
                        placeholder="Input text"
                      />
                      <p className="anp-price-text">
                        Enter available offer for this product{" "}
                      </p>
                    </div>
                    <div>
                      <label htmlFor="" className="anp-price-drop-label">
                        Total Price
                      </label>
                      <br />
                      <input
                        value={
                          discountedValue !== null
                            ? Number(discountedValue).toFixed(2)
                            : "N/A"
                        }
                        className="anp-select-drop"
                        disabled
                        name="price"
                      />
                    </div>
                  </div>
                </>
              )}

              {/* <AddPackages selectPricing={selectPricing} handlePackageCheckboxChange={handlePackageCheckboxChange} handleChangeSwitch={handleChangeSwitch}/> */}

              <div className="product-border">
                <h3 className="addproduct">Description</h3>
              </div>
              <div className="pt-3">
                {/* <CKEditors name="myEditor" control={control} rules={{ required: true }} error={errors.myEditor} /> */}

                <Controller
                  name="Description"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfig}
                        data={value}
                        onBlur={onBlur}
                        onChange={(event, editor) => onChange(editor.getData())}
                      />
                      {errors.Description && (
                        <span className="error-msg">
                          Please enter the description
                        </span>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="product-border">
                <h3 className="addproduct">Specification </h3>
              </div>
              <div className="pt-3">
                <Controller
                  name="Specification"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfig}
                        data={value}
                        onBlur={onBlur}
                        onChange={(event, editor) => onChange(editor.getData())}
                      />
                      {errors.Specification && (
                        <span className="error-msg">
                          Please enter the specification
                        </span>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="product-border">
                <h3 className="addproduct">Features </h3>
              </div>
              <div className="pt-3">
                <Controller
                  name="Features"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfig}
                        data={value}
                        onBlur={onBlur}
                        onChange={(event, editor) => onChange(editor.getData())}
                      />
                      {errors.Features && (
                        <span className="error-msg">
                          Please enter the features
                        </span>
                      )}
                    </>
                  )}
                />
              </div>
              <div className="product-border">
                <h3 className="addproduct">Upload Video (Optional ) </h3>
              </div>
              <input
                className="anp-input mt-2"
                placeholder="Enter URL"
                type="url"
                {...register("video_link", {
                  required: true,
                  minLength: 3,
                  maxLength: 150,
                })}
              />
              {errors.video_link && (
                <span className="error-msg">Please enter the video link</span>
              )}
              <div className="anp-btn-save-div">
                <button className="anp-btn-save" type="submit" name="submit">
                  Save
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default AddNewProducts;
