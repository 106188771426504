import React, { useState, useEffect } from "react";
import "../../Vendor/vendorProfile/page.css";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { MdModeEdit } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { BiFilterAlt } from "react-icons/bi";
import moment from "moment";
import { updateStatusValue } from "../../../Store/Reducers/myProfileReducer";
import { progressBarValidation } from "./utils.js";
import { useDispatch } from "react-redux";
import nodoc from "../../../assets/images/nodoc.jpg";
import { BaseUrl } from "../../../Api/api";
import PageLoader from "../../../components/PageLoader/PageLoader";

const TransactionsList = () => {
  const navigate = useNavigate()
  const Token = localStorage.getItem("token");
  const toGetCurrencySymbol = localStorage.getItem("user");
  const currencySymbol = JSON.parse(toGetCurrencySymbol).user_info.symbol;
  const overAllProgressBar = localStorage.getItem("profile_percentage");
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [sortedArray, setSortedArray] = useState([]);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const [overAllProgressValue, setOverAllProgressValue] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);


const handleAddProject = () => {
  navigate("/new-add-project")
};
  const onFilterClick = () => {
    setShowDropdown(true);
  };
  const onClickView = () => {
    console.log(dateFrom, dateTo, "view button clicked");
    const result = transactionList.filter((e) => {
      return (
        moment(dateFrom) >= moment(e.created_at.split(" ")[0]) &&
        moment(dateTo) <= moment(e.created_at.split(" ")[0])
      );
    });
    setSortedArray([...result]);
  };
  const onChangeFrom = (e) => {
    setDateFrom(e.target.value);
  };
  const onChangeTo = (e) => {
    setDateTo(e.target.value);
  };

  const onClickSuccessOrFailure = (e, value) => {
    e.preventDefault();
    const output = transactionList.filter((e) => {
      return e.transaction_status === value;
    });
    setSortedArray([...output]);
  };
  useEffect(() => {
    setPageLoading(true);
    const Token = localStorage.getItem("token");
    try {
      var myHeaders = new Headers();
      myHeaders.append("authorization", `Bearer ${Token}`);
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(`${BaseUrl}/user/Get-User-Transactions`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          console.log("transactions", result.data);
          setSortedArray(result.data.transactionList);
          setTransactionList(result.data);
          setPageLoading(false);

          console.log(result.data, "result");
        });
    } catch (err) {
      console.log(err, "error");
    }
  }, []);

  return (
    <>
      {pageLoading && <PageLoader />}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar isShow={true} value={overAllProgressValue} />
          <main className="maincontent">
            <div>
              <section>
                <div className="basic-info">
                  <h3>Customer Transaction List</h3>
                  {/* <h4>
                  Edit
                  <MdModeEdit />
                </h4> */}
                </div>
              </section>
              <section className="payment-table">
                <table>
                  {sortedArray.length != 0 ? (
                    sortedArray.map((value) => {
                      <tr>
                        <th>Date</th>
                        <th>Transaction ID</th>
                        <th>Receipt No</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>

                      return (
                        <tr>
                          <td>{value.transaction_date}</td>
                          <td>{value.transaction_id}</td>
                          <td>{value.invoice_no}</td>
                          <td>
                            {value.currency_code} {value.amount}
                          </td>
                          {
                            <td>
                              {value.transaction_status === "COMPLETED" ? (
                                <p className="payment-success">Success</p>
                              ) : (
                                <p className="payment-failed">Failure</p>
                              )}
                            </td>
                          }
                        </tr>
                      );
                    })
                  ) : (
                    <div className="noreviews mt-3">
                      <div className="noreviewcont">
                        <h3>No Transactions List Found</h3>

                        {/* <Link to="/add-projects"> */}
                          <button onClick={handleAddProject}>Add Projects</button>
                        {/* </Link> */}
                      </div>

                      <img src={nodoc} alt="noreview" />
                    </div>
                  )}
                </table>
              </section>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
export default TransactionsList;
