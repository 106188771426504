import React, { useEffect, useState } from "react";
import "../Customer/Customer.css";
import Sidebar from "./Sidebar";
import ProjectList from "./Project-list";
import Topbar from "./Topbar";
import PageLoader from "../../components/PageLoader/PageLoader";

const Projectlist = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const updateLoaderState = (newState) => {
    setPageLoading(newState);
  };
  return (
    <>
      {" "}
      {pageLoading && (
       < PageLoader/>
      )}
      <div className="dashboard-container">
        <Sidebar />
        <div className="main-dashboard-container">
          <Topbar isShow={false} />
          <ProjectList updateLoaderState={updateLoaderState} />
        </div>
      </div>
    </>
  );
};
export default Projectlist;
