import React from 'react';
import RightIcon from '../../../assets/images/rightIcon.svg';
import './nbcd.css';

const ProjectDetail = ({ image, title, subTitle }) => {
        return (
                <>
                        <div className='pro-sm-cards'>
                                <div className='d-flex align-items-center'>
                                        <div className='px-3'>
                                                <img src={image} alt="" />
                                        </div>
                                        <div className='d-flex flex-column'>
                                                <div className='box-title'>
                                                        {title}
                                                </div>
                                                <div className='box-sub-title'>
                                                        {subTitle}
                                                </div>
                                        </div>

                                </div>
                                <div className='px-3'>
                                        <img src={RightIcon} alt="" />
                                </div>
                        </div>
                </>
        )
}

export default ProjectDetail