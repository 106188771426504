import React, { useState, useEffect } from "react";
import { Modal } from "rsuite";
import deleteIcon from "../../../assets/images/expo-delete.png";
import successIcon from "../../../assets/images/success.png";
import otpClose from "../../../assets/images/otp-close.svg";
import "./ProductDelete.css";
import { BaseUrl } from "../../../Api/api";
import { useDispatch } from "react-redux";
import { getProductListTableData } from "../../../Store/Reducers/VendorProductListReducer";

const ProductDelete = ({
  open,
  handleClose,
  singleProductData,
  setOpen,
}) => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const userToken = localStorage.getItem("token");
  const dispatch = useDispatch();
  const onClickConfirm = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);

    var raw = JSON.stringify({
      product_id: singleProductData.id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}/product/Delete-Product`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setShowSuccessPopup(true);
        console.log(result, "api result");
      })
      .catch((error) => console.log("error", error));
  };
  const handleCloseOnOk = () => {
    setShowSuccessPopup(false);
    setOpen(false);
    dispatch(getProductListTableData());
  };

  return (
    <>
      {!showSuccessPopup && (
        <div className="product-delete">
          <Modal open={open} onClose={handleClose}>
            {/* <style>
              {`.rs-modal-content {
          width: ${1500}px;
          height: ${450}px;
          min-width:600px }`}
            </style> */}
            <Modal.Header style={{ marginTop: "20px" }}>
              <span
                onClick={handleClose}
                role="button"
                class="rs-modal-header-close rs-btn-close"
                title="Close"
                aria-label="Close"
              >
                <img src={otpClose} alt="" />
              </span>
            </Modal.Header>
            <Modal.Body>
              <div>
                <img src={deleteIcon} alt="" />
              </div>
              <h3 className="pro-text pt-4">Are you sure!</h3>
              <p className="product-delete-text pt-2">
                Are you confirm to delete it?
              </p>
              <div className="product-btn-div pt-3">
                <button className="product-cancel-btn" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  onClick={onClickConfirm}
                  className="product-confirm-btn"
                >
                  Confirm
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
      {showSuccessPopup && (
        <div className="product-delete">
          <Modal open={open} onClose={handleClose}>
            {/* <style>
              {`.rs-modal-content {
          width: ${1500}px;
          height: ${450}px;
          min-width:600px }`}
            </style> */}
            <Modal.Header style={{ marginTop: "20px" }}>
              <span
                onClick={handleClose}
                role="button"
                class="rs-modal-header-close rs-btn-close"
                title="Close"
                aria-label="Close"
              >
                <img src={otpClose} alt="" />
              </span>
            </Modal.Header>
            <Modal.Body>
              <div>
                <img src={successIcon} alt="" />
              </div>
              <h3 className="pro-text pt-4">Done!</h3>
              <p className="product-delete-text pt-2">
                Delete Product successfully
              </p>
              <div className="product-btn-div pt-3">
                <button
                  onClick={handleCloseOnOk}
                  className="product-confirm-btn"
                >
                  Ok
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ProductDelete;
