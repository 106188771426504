import React, { useState, useEffect } from "react";
import "../../Vendor/vendorProfile/page.css";
import { BsFillPersonBadgeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { BaseUrl } from "../../../Api/api";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import Starratings from "./starratings";
import { MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import noreviews from "../../../assets/images/noreview.png";
import PageLoader from "../../../components/PageLoader/PageLoader";

const CustomerReviewsList = () => {
        const [data, setData] = useState([]);
        const [showBar, setShowBar] = useState("0");
        const [pageLoading, setPageLoading] = useState(false);

        let navigate = useNavigate();
        const userToken = localStorage.getItem("token");
        const userinfo = JSON.parse(localStorage.getItem("userinfo"));
        const user = JSON.parse(localStorage.getItem("user"));

        useEffect(() => {
                setPageLoading(true);
                try {
                        var myHeaders = new Headers();
                        myHeaders.append("Authorization", `Bearer ${userToken}`);
                        myHeaders.append("Content-Type", "application/json");

                        var requestOptions = {
                                method: "GET",
                                headers: myHeaders,
                                redirect: "follow",
                        };

                        fetch(`${BaseUrl}/user/Get-User-All-Projects-Review`, requestOptions)
                                .then((response) => response.json())
                                .then((result) => {
                                        console.log("result", result.data);
                                        if (result.data.allProjectReviews.length > 0) {
                                                setData(result.data.allProjectReviews);
                                                setPageLoading(false);
                                        } else {
                                                setPageLoading(false);
                                                // navigate("/bank-details/edit");
                                        }
                                        localStorage.setItem(
                                                "profile_complete_status",
                                                result.data.profile_complete_status.profile_complete_status
                                        );
                                })
                                .catch((error) => console.log("error", error));
                } catch (err) {
                        setPageLoading(false);
                        console.log(err);
                }
        }, []);

        const handleNext = (e) => {
                window.location = "/customer-product-reviews";
        };

        const handleSkip = (e) => {
                window.location = "/customer-product-reviews";
        };
        const handleAddProject = () => {
                navigate("/new-add-project")
        }

        return (
                <>
                        {pageLoading && <PageLoader />}
                        <div className="dashboard-container">
                                <Sidebar />
                                <div className="main-dashboard-container">
                                        <Topbar
                                                isShow={true}
                                                // value={
                                                //   data.length > 0 &&
                                                //   data[0].profile_complete_status &&
                                                //   data[0].profile_complete_status
                                                // }
                                                value={localStorage.getItem("profile_complete_status")}
                                        />
                                        <main className="maincontent">
                                                <div className="basic-info">
                                                        <h3>All Reviews</h3>
                                                        <Link to="/customer-reviews/add">
                                                                <h4>
                                                                        Add Review
                                                                        <MdModeEdit />
                                                                </h4>
                                                        </Link>
                                                </div>
                                                <div className="customerreviewlist">
                                                        {data.length > 0 ? (
                                                                data.map((item, id) => {
                                                                        return (
                                                                                <div className="custratinglist">
                                                                                        <div className="custratings">
                                                                                                <h5>
                                                                                                        {item.average_rating}
                                                                                                        <Starratings stars={item.average_rating} />
                                                                                                </h5>
                                                                                                <div className="ratingfield">
                                                                                                        <p>Delivery</p> <p>{item.project_delivery_stars}</p>
                                                                                                </div>
                                                                                                <div className="ratingfield">
                                                                                                        <p>Support</p> <p>{item.project_support_stars}</p>
                                                                                                </div>
                                                                                                <div className="ratingfield">
                                                                                                        <p>Timeline Quality</p>
                                                                                                        <p>{item.timeline_quality_stars}</p>
                                                                                                </div>
                                                                                                <div className="ratingfield">
                                                                                                        <p>Value for money</p> <p>{item.money_value_stars}</p>
                                                                                                </div>
                                                                                                <div className="ratingoverall">
                                                                                                        <p>Overall Likes</p>
                                                                                                        <p>{item.project_likely_number}/10</p>
                                                                                                </div>
                                                                                        </div>
                                                                                        <div className="projectreviewscont">
                                                                                                <h4>{item.project_name}</h4>
                                                                                                <p>{item.reviews}</p>
                                                                                                <div className="reviewcust">
                                                                                                        <BsFillPersonBadgeFill />{" "}
                                                                                                        {user.user_info.company_name}
                                                                                                </div>
                                                                                        </div>
                                                                                </div>
                                                                        );
                                                                })
                                                        ) : (
                                                                <div className="noreviews">
                                                                        <div className="noreviewcont">
                                                                                <h3>No Reviews Found</h3>

                                                                                {/* <Link to="/add-projects"> */}
                                                                                        <button onClick={handleAddProject}>Add Projects</button>
                                                                                {/* </Link> */}
                                                                        </div>
                                                                        <img src={noreviews} alt="bankdetails" />
                                                                </div>
                                                        )}

                                                        <div className="formbuton">
                                                                <input
                                                                        type="submit"
                                                                        className="save-button"
                                                                        value="Skip"
                                                                        onClick={handleSkip}
                                                                />

                                                                <input
                                                                        type="submit"
                                                                        className="save-button"
                                                                        value="Next"
                                                                        onClick={handleNext}
                                                                />
                                                        </div>
                                                </div>
                                        </main>
                                </div>
                        </div>
                </>
        );
};
export default CustomerReviewsList;
