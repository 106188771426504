import React, { useState, useEffect } from 'react';
// import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import Modal from "react-modal";
import "rsuite/dist/rsuite.css";
import saveDraft from "../../../assets/images/saveDraft.svg";
import './savedraft.css';
import SecondDraft from './secondDraft';
import { useNavigate } from 'react-router-dom';


const FirstDraft = ({ open, handleClose, draftPath}) => {
        const navigate = useNavigate()

        const [showDraft, setShowDraft] = useState(false);
        const [close, setClose] = useState(handleClose);

        const handleCloseDraft = () => {
                setShowDraft(false)
        };
        const handleShowDraft = () => {
                setShowDraft(true);
                handleClose(false);
        }

        const handleNavigation = () => {
                handleClose(false)
                navigate(draftPath)
        }

        return (
                <>

                        <SecondDraft  showDraft={showDraft} handleCloseDraft={handleCloseDraft}/>

                        <Modal
                                isOpen={open}
                                onRequestClose={handleClose}
                                className="project-restrict-popup-draft"
                                overlayClassName="pr-modal-overlay"
                                shouldCloseOnOverlayClick={false}
                        >
                                <div className="d-flex align-items-center justify-content-center">
                                        <img
                                                src={saveDraft}
                                                alt=""
                                                style={{ width: "60px", height: "60px" }}
                                        />
                                </div>

                                <div className="mt-3" style={{ textAlign: "center" }}>
                                        <h2 className="sv-title">Save as draft?</h2>
                                        <p className="sv-text py-2">
                                        Do you want to save this project as draft?<br />
                                        </p>
                                </div>
                                <div className='d-flex align-items-center justify-content-center gap-4'>
                                        <button className='discard-btn' onClick={handleNavigation}>Discard</button>
                                        <button className='save-draft-btn' onClick={handleShowDraft}>Save as Draft</button>
                                </div>
                        </Modal>
                </>
        )
}

export default FirstDraft