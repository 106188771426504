import React, { useState, useEffect } from "react";
import "../Vendor/view.css";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { BaseUrl } from "../../Api/api.js";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Popup from "reactjs-popup";

const Milestone = () => {
  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [item, setitem] = useState([]);
  const userToken = localStorage.getItem("token");
  const ID = useParams().proposal_id;
  console.log(ID);

  useEffect(() => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${userToken}`);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${BaseUrl}/vendordashboard/Milestone-Update-Complete`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          console.log("result", result.data);
          setData(result.data);
        })
    .catch((error) => console.log("error", error));}
     catch (err) {
    console.log(err);
    }
  }, []);

  data.map((item) => {
   item.milestonelist.map((key)=>{
    console.log("milestone details",key.milestone_description)
   })
    
  }
  )

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="main-dashboard-container">
        <Topbar />

        <main className="maincontent">
          <div>
<section>
<div className="background1">
        <div className="VMheading1"><h1>Project Milestone 1</h1></div>
        <div className="VMheading2"><h2>D2</h2></div>
        <div className="VMheading3"><b>Invoice Amount - THB 239/-</b></div>
        <div className="VMmile">Milestone in Progress</div>
        <Link to={`/CMarkascomplete/${item.milestone_id}`}><button className="startmilestone">
        Start Milestone</button></Link>
    </div>
    </section>
    <section>
    <div className="background1">
        <div className="VMheading1"><h1>Project Milestone 1</h1></div>
        <div className="VMheading2"><h2>D2</h2></div>
        <div className="VMheading3"><b>Invoice Amount - THB 239/-</b></div>
        <div className="VMmile">Milestone in Progress</div>
        <Link to={`/CMarkascomplete/${item.milestone_id}`}><button className="startmilestone">
        Start Milestone</button></Link>
    </div>
</section>
<section>
<div className="background1">
        <div className="VMheading1"><h1>Project Milestone 1</h1></div>
        <div className="VMheading2"><h2>D2</h2></div>
        <div className="VMheading3"><b>Invoice Amount - THB 239/-</b></div>
        <div className="VMmile">Milestone in Progress</div>
        <Link to={`/CMarkascomplete/${item.milestone_id}`}><button className="startmilestone">
        Start Milestone</button></Link>
    </div>
</section>
                  
                 {/* {item.proposal_status == 0 ? (
                          <>
                            {/* <button
                              className="btn selectproposal"
                              onClick={() => {
                                formValues.action_type = 1;
                                formValues.announcement_id =
                                  item.announcement_id;
                                formValues.proposal_id = item.proposal_id;
                                setOpenModal(true);
                                proposalAcceptedList();
                              }}
                            >
                              Start Project
                            </button> 

                             <button
                              className="btn-selectproposal"
                              onClick={() => {
                               window.location=`/ViewVendorMilestone/${item.announcement_id}/${item.project_name}`
                              }}
                            >
                              View Milestone
                            </button> 
                          </>
                        ) : 
                        item.proposal_status == 1 ? (
                          <>
                            <Link to={`/ViewVendorMilestone`}>
                            <button
                              className="btn-apply_milestone"
                            >
                              View Milestone
                            </button>
                            </Link>
                        
                          </>
                        ) : (
                        <></>
                        )}

                  </> */}
    
          </div>
        </main>
      </div>
    </div>
  );
};

export default Milestone;
 